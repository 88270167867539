import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function EmailForm({ consumerEmail }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Email address:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="email"
          id="email"
          placeholder="Email address"
          value={consumerEmail || ''}
          disabled
        />
      </Col>
    </>
  );
}

export default memo(EmailForm);
