import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function DefaultPaymentMethodForm({ consumerDefaultPaymentMethod, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Default Payment Method:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-filter"
          type="select"
          name="defaultPaymentMethod"
          id="defaultPaymentMethod"
          noValidate
          value={consumerDefaultPaymentMethod}
          onChange={changeValue}
        >
          <option value="ACH">ACH</option>
          <option value="CARD">CARD</option>
        </Input>
      </Col>
    </>
  );
}

export default memo(DefaultPaymentMethodForm);
