import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Env } from '../context/env-context';

function Auth0ProviderWithRedirectCallback({ children }) {
  const navigate = useNavigate();

  const onRedirectCallback = appState => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
  // for a full list of the available properties on the provider
  const config = Env;
  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      ...(config.audience ? { audience: config.audience } : null),
      redirect_uri: window.location.origin,
    },
    onRedirectCallback,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
}

export default Auth0ProviderWithRedirectCallback;
