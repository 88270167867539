import { Spinner } from '../../../../../components/Spinner';

function NewObjectBridgePointsWidget({ loading, bridgePointsBalance }) {
  return (
    <div className="bo-border-container bg-light rounded w-100 p-0">
      <Spinner visible={loading.bridgePointsBalance} size={36} borderRadius={5}>
        <div className="p-2">
          <p className="bo-new-object-text-bold">BridgePoints™</p>
          <div className="mt-2 d-flex justify-content-evenly">
            <div>
              <p className="bo-new-object-text-xxl-bold">
                {bridgePointsBalance
                  ? Number(bridgePointsBalance.currentBalance).toLocaleString('en-US')
                  : 'N/A'}
              </p>
              <p className="bo-new-object-text-sm">Current</p>
            </div>
            <div>
              <p className="bo-new-object-text-xxl-bold">
                {bridgePointsBalance
                  ? Number(bridgePointsBalance.availableBalance).toLocaleString('en-US')
                  : 'N/A'}
              </p>
              <p className="bo-new-object-text-sm">Available</p>
            </div>
          </div>
        </div>
      </Spinner>
    </div>
  );
}

export default NewObjectBridgePointsWidget;
