import { useCallback, useEffect, useState } from 'react';

export const useCustomContextMenu = targetId => {
  const [contextMenuKey, setContextMenuKey] = useState('');
  const [popoverTarget, setPopoverTarget] = useState(null);

  useEffect(() => {
    const div = document.createElement('div');

    div.id = targetId;
    div.style.position = 'absolute';
    div.style.height = '0px';
    div.style.width = '0px';
    div.style.pointerEvents = 'none';
    document.body.appendChild(div);

    setPopoverTarget(targetId);

    return () => {
      const element = document.getElementById(targetId);

      if (element) {
        document.body.removeChild(element);
      }
    };
  }, [targetId]);

  const updateContextMenuPosition = useCallback(
    (left, top) => {
      const element = document.getElementById(popoverTarget);

      if (element) {
        element.style.left = `${left}px`;
        element.style.top = `${top}px`;
      }

      setContextMenuKey(crypto.randomUUID());
    },
    [popoverTarget],
  );

  const toggleContextMenu = useCallback(() => {
    setContextMenuKey('');
  }, []);

  return {
    popoverTarget,
    contextMenuKey,
    updateContextMenuPosition,
    toggleContextMenu,
  };
};
