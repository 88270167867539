import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { modalStyle } from '../../../../utils/modal';

function ConsumerBridgePointsModal({ consumer, isOpen, close }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [spinner, setSpinner] = useState(false);

  ReactModal.setAppElement(document.getElementById('root'));

  const createManualTransaction = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/bridgepoints/transaction`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, desc: description, amount: Number(amount) }),
    };

    try {
      await makeRequest(config);

      setAmount(0);
      setDescription('');

      toast.success('Successful Transaction');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleCreate = async () => {
    if (!description) {
      toast.error('Description is required');

      return;
    }
    if (!amount) {
      toast.error('Amount is required');

      return;
    }

    setSpinner(true);
    await createManualTransaction();
    setSpinner(false);
    close();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('400px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinner} text="">
        <h5>Manual Transaction</h5>
        <Row className="mb-2 mt-4 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Description:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <Input
              className="bo-input"
              type="text"
              name="description"
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Points:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <Input
              className="bo-input"
              type="number"
              name="amount"
              id="amount"
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button className="mt-5 bo-button" onClick={handleCreate}>
              Create
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={close}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerBridgePointsModal;
