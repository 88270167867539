import { memo } from 'react';
import { Col, Input } from 'reactstrap';

function ProspectAddressLine3Form({ prospectCity, prospectState, prospectZip, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line" />
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="city"
          id="city"
          placeholder="City"
          value={prospectCity || ''}
          onChange={changeValue}
        />
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="state"
          id="state"
          placeholder="State"
          value={prospectState || ''}
          onChange={changeValue}
          maxLength={2}
        />
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="zip"
          id="zip"
          placeholder="Zip"
          value={prospectZip || ''}
          onChange={changeValue}
          maxLength={5}
        />
      </Col>
    </>
  );
}

export default memo(ProspectAddressLine3Form);
