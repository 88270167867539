import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportRepaymentAnalytics({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      job: {
        header: 'Job',
        width: 12,
        style: { alignment: { horizontal: 'left' } },
      },
      trialTimestamp: {
        header: 'Time',
        width: 22,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      blind: {
        header: 'Is Blind',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      aggregatorName: {
        header: 'Aggregator Name',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer ID',
        width: 18,
        style: { alignment: { horizontal: 'right' } },
      },
      consumerName: {
        header: 'Consumer Name',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      advanceId: {
        header: 'Advance ID',
        width: 18,
        style: { alignment: { horizontal: 'right' } },
      },
      preTrialAdvanceStatus: {
        header: 'Advance Status',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      preTrialRepaymentMode: {
        header: 'Advance Repayment Mode',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      expectedAmount: {
        header: 'Expected Amount',
        width: 26,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#' },
      },
      outstandingAmount: {
        header: 'Outstanding amount',
        width: 26,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#' },
      },
      repaymentType: {
        header: 'Repayment Type',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      actionTaken: {
        header: 'Action Taken',
        width: 25,
        style: { alignment: { horizontal: 'left' } },
      },
      reason: {
        header: 'Reason',
        width: 40,
        style: { alignment: { horizontal: 'left' } },
      },
      reschedule: {
        header: 'Reschedule',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      actualAmount: {
        header: 'Actual amount',
        headerBgColor: '#f3a83c',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#' },
      },
      fee: {
        header: 'Fee',
        headerBgColor: '#f3a83c',
        width: 18,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      transferType: {
        header: 'Payment Method',
        headerBgColor: '#f3a83c',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      transferStatus: {
        header: 'Transfer Status',
        headerBgColor: '#f3a83c',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      failureReason: {
        header: 'Failure Reason',
        headerBgColor: '#f3a83c',
        width: 28,
        style: { alignment: { horizontal: 'left' } },
      },
      postTransferAction: {
        header: 'Post Transfer Action',
        headerBgColor: '#f3a83c',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const restructuredReport = useMemo(
    () => report.map(r => ({ ...r, uuid: crypto.randomUUID() })),
    [report],
  );

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Repayment Analytics', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = restructuredReport.map(r => ({
      ...r,
      trialTimestamp: r.trialTimestamp ? formatExcelDate(new Date(r.trialTimestamp)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };

    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= 16) {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f3a83c' },
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
        };
      }
    });

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [restructuredReport, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (restructuredReport.length > 0) {
      return restructuredReport.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [restructuredReport, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="uuid"
      />
    </>
  );
}

export default ReportRepaymentAnalytics;
