export const getOpenIdvLink = consumerLastIdv => {
  const { id, client_user_id } = consumerLastIdv;

  if (client_user_id.includes('dev') || client_user_id.includes('stage')) {
    return `https://dashboard.plaid.com/sandbox/identity_verification/sessions/${id}`;
  }

  return `https://dashboard.plaid.com/identity_verification/sessions/${id}`;
};

export const getOpenMonitorLink = consumerLastWls => {
  const {
    search_terms: { watchlist_program_id },
    client_user_id,
    id,
  } = consumerLastWls;

  if (client_user_id.includes('dev') || client_user_id.includes('stage')) {
    // eslint-disable-next-line max-len
    return `https://dashboard.plaid.com/sandbox/monitor/programs/${watchlist_program_id}/screenings/${id}`;
  }

  return `https://dashboard.plaid.com/monitor/programs/${watchlist_program_id}/screenings/${id}`;
};
