import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { modalStyle } from '../../../../utils/modal';

function ConsumerFundingModal({ consumer, isOpen, topup, close, getFunding, prediction }) {
  const { apiOriginConsumer, apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [amount, setAmount] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [spinner, setSpinner] = useState(false);

  ReactModal.setAppElement(document.getElementById('root'));

  const sendMfa = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/mfa/send`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, mfaUseCase: 'ADVANCE' }),
    };

    try {
      const response = await makeRequest(config);

      setSpinner(false);
      toast.success(`MFA code has been sent by ${response.mfaChannel} to ${response.mfaMask}`);
    } catch (error) {
      setSpinner(false);
      toast.error(error.message);
    }
  };

  const createFunding = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/create_manual`,
      method: 'POST',
      data: JSON.stringify({
        cosnumerId: consumer.id,
        offerId: prediction.activeOffer ? prediction.activeOffer.offerId : null,
        requestedAmount: parseInt(amount, 10),
        textualReason: '',
        type: topup ? 'TOPUP' : 'NEW',
        topupFundingId: null,
        mfaCode,
        consent: true,
      }),
    };

    try {
      await makeRequest(config);

      toast.success(topup ? 'Top-up created successfully' : 'Advance created successfully');
      getFunding(consumer.id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreate = async () => {
    if (!mfaCode) {
      toast.error('MFA code is required');

      return;
    }
    if (!amount) {
      toast.error('Amount is required');

      return;
    }

    setSpinner(true);
    await createFunding();
    setAmount('');
    setMfaCode('');
    setSpinner(false);
    close();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('400px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinner} text="">
        <h5>{topup ? 'Create Top-up' : 'Create Advance'}</h5>
        <Row className="mb-2 mt-4 mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Amount:</Label>
          </Col>
          <Col md={4} className="bo-same-line">
            {prediction && prediction.activeOffer ? (
              <Input
                className="bo-filter"
                type="select"
                name="amount"
                id="amount"
                noValidate
                value={amount}
                onChange={e => setAmount(e.target.value)}
              >
                {prediction.activeOffer.allAmounts.map(amt => (
                  <option key={amt} value={amt}>
                    {amt}
                  </option>
                ))}
              </Input>
            ) : (
              <Input
                className="bo-input"
                type="text"
                name="amount2"
                id="amount22"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-2 mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>MFA Code:</Label>
          </Col>
          <Col md={4} className="bo-same-line">
            <Input
              className="bo-input"
              type="text"
              name="mfaCode"
              id="mfaCode"
              value={mfaCode}
              onChange={e => setMfaCode(e.target.value)}
            />
          </Col>
          <Col md={4} className="bo-same-line">
            <Button className="bo-link" color="link" onClick={() => sendMfa()}>
              Send MFA
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button className="mt-5 bo-button" onClick={handleCreate}>
              Create
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={close}>
              Close
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerFundingModal;
