import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AggregatorLookupKeywords({ aggregatorLookupKeywords, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Lookup keywords:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="lookupKeywords"
          id="lookupKeywords"
          placeholder="Keywords"
          value={aggregatorLookupKeywords || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorLookupKeywords);
