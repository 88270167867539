import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner } from '../../components/Spinner';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import { useTableContext } from '../../context/table-context';
import ConsumersFormController from './components/ConsumersFormController';
import ConsumersTableContainer from './components/ConsumersTableContainer';
import Loading from '../../components/Loading';

function Consumers() {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginAggregator, apiOriginConsumer, apiOriginMonitor } = useEnv();

  const [spinner, setSpinner] = useState(false);

  const {
    noPageRefreshRef,
    selectedAggregatorFilter,
    setSelectedAggregatorFilter,
    selectedConsumerIdsFilter,
    setSelectedConsumerIdsFilter,
    getTable,
    updateTable,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    changeStatusFilter,
    changeBankStatusFilter,
    changeFlagsFilter,
    searchKey,
  } = useTableContext();

  const table = getTable({
    tableName: 'consumers',
    initSortBy: 'createDate',
    initSortOrder: 'desc',
    initRowsPerPage: 25,
  });

  const {
    sortBy,
    sortOrder,
    page,
    rowsPerPage,
    totalRows,
    rows,
    statusFilter,
    bankStatusFilter,
    flagsFilter,
    search,
  } = table;

  const [aggregatorNames, setAggregatorNames] = useState({});
  const [rerunJobs, setRerunJobs] = useState([]);

  const changeAggregatorFilter = e => {
    setSelectedAggregatorFilter(e.target.value);
    updateTable({
      tableName: 'consumers',
      newTable: {
        ...table,
        page: 0,
      },
    });
  };

  const clearConsumerIdsFilter = () => {
    setSelectedConsumerIdsFilter({ consumerIds: null, message: null });
    updateTable({
      tableName: 'consumers',
      newTable: {
        ...table,
        page: 0,
      },
    });
  };

  const getAllRerunJobs = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_all_rerun_jobs`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setRerunJobs(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAllRerunJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAggregatorNames = async ids => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get_names`,
      params: ids,
      method: 'GET',
    };

    const data = await makeRequest(config);

    setAggregatorNames(data);
  };

  const getData = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get`,
      method: 'GET',
      params: {
        sortBy,
        sortOrder,
        aggregatorFilter: selectedAggregatorFilter,
        statusFilter,
        bankStatusFilter,
        flagsFilter,
        searchText: search,
        page,
        perPage: rowsPerPage,
        consumerIds: selectedConsumerIdsFilter.consumerIds,
      },
    };

    const data = await makeRequest(config);
    const aggIds = Array.from(data, x => x.aggregatorId).filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    getAggregatorNames(aggIds);

    return data;
  };

  const getTotalRows = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/count`,
      method: 'GET',
      params: {
        aggregatorFilter: selectedAggregatorFilter,
        statusFilter,
        bankStatusFilter,
        flagsFilter,
        searchText: search,
        consumerIds: selectedConsumerIdsFilter.consumerIds,
      },
    };

    const data = await makeRequest(config);

    return data.count;
  };

  const getAll = async () => {
    setSpinner(true);
    try {
      const data = await getData();
      const total = await getTotalRows();

      if (data && total !== null) {
        updateTable({
          tableName: 'consumers',
          newTable: {
            ...table,
            rows: data,
            totalRows: total,
          },
        });
      }
    } catch (error) {
      toast.error(`Faled getting data: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (!noPageRefreshRef.current) {
      getAll();
    } else {
      noPageRefreshRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusFilter,
    selectedAggregatorFilter,
    selectedConsumerIdsFilter,
    search,
    bankStatusFilter,
    flagsFilter,
    page,
    rowsPerPage,
    sortBy,
    sortOrder,
  ]);

  const deleteConsumer = async id => {
    if (window.confirm('Are you sure?')) {
      try {
        setSpinner(true);
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/delete`,
          method: 'POST',
          data: JSON.stringify({ id }),
        };

        await makeRequest(config);

        getAll();
      } catch (error) {
        setSpinner(false);
        toast.error(error.message);
      }
    }
  };

  return (
    <Container className="mb-5">
      <ConsumersFormController
        spinner={spinner}
        setSpinner={setSpinner}
        selectedAggregatorFilter={selectedAggregatorFilter}
        changeAggregatorFilter={changeAggregatorFilter}
        selectedConsumerIdsFilter={selectedConsumerIdsFilter}
        clearConsumerIdsFilter={clearConsumerIdsFilter}
        statusFilter={statusFilter}
        changeStatusFilter={changeStatusFilter}
        bankStatusFilter={bankStatusFilter}
        changeBankStatusFilter={changeBankStatusFilter}
        flagsFilter={flagsFilter}
        changeFlagsFilter={changeFlagsFilter}
        search={search}
        searchKey={searchKey}
      />
      <Spinner visible={spinner} text="">
        <ConsumersTableContainer
          sortOrder={sortOrder}
          sortBy={sortBy}
          sortHandler={sortHandler}
          rows={rows}
          aggregatorNames={aggregatorNames}
          rerunJobs={rerunJobs}
          deleteConsumer={deleteConsumer}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Spinner>
    </Container>
  );
}

export default withAuthenticationRequired(Consumers, { onRedirecting: () => <Loading /> });
