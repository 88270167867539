import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FullNameForm({ consumerFirstName, consumerLastName, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Name:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First name"
          value={consumerFirstName || ''}
          onChange={changeValue}
        />
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last name"
          value={consumerLastName || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(FullNameForm);
