import { useMemo } from 'react';
import { Row } from 'reactstrap';
import FundingIdForm from './forms/FundingIdForm';
import FundingStatusForm from './forms/FundingStatusForm';
import FundingAggregatorNameForm from './forms/FundingAggregatorNameForm';
import FundingOpenTimestampForm from './forms/FundingOpenTimestampForm';
import FundingConsumerNameForm from './forms/FundingConsumerNameForm';
import FundingRepaymentModeForm from './forms/FundingRepaymentModeForm';
import FundingReasonForm from './forms/FundingReasonForm';
import FundingRepaymentTrialsForm from './forms/FundingRepaymentTrialsForm';
import FundingOriginalAmountForm from './forms/FundingOriginalAmountForm';
import FundingChangeRepaymentDateForm from './forms/FundingChangeRepaymentDateForm';
import FundingTopUpsAmountForm from './forms/FundingTopUpsAmountForm';
import FundingCloseDateForm from './forms/FundingCloseDateForm';
import FundingRepaymentsAmountForm from './forms/FundingRepaymentsAmountForm';
import FundingCloseReasonForm from './forms/FundingCloseReasonForm';
import FundingOutstandingAmountForm from './forms/FundingOutstandingAmountForm';
import FundingPayrollDelayedForm from './forms/FundingPayrollDelayedForm';
import FundingRedFlags from './FundingRedFlags';
import FundingActionRow from './FundingActionRow';
import FundingNextRepaymentDate from './forms/FundingNextRepaymentDate';
import FundingTotalFeesForm from './forms/FundingTotalFeesForm';
import FundingLatestRepaymentDateForm from './forms/FundingLatestRepaymentDate';
import FundingBridgePointsSoFarForm from './forms/FundingBridgePointsSoFarForm';
import FundingBridgePointsRemainingForm from './forms/FundingBridgePointsRemainingForm';
import FundingExpectedBalanceForm from './forms/FundingExpectedBalanceForm';
import FundingBlindCollectionForm from './forms/FundingBlindCollectionForm';

function FundingFormController({
  funding,
  consumer,
  bridgePointsBalance,
  totalExpectedBalance,
  setSpinner,
  getFunding,
  openRepaymentModal,
  openRepaymentDateModal,
}) {
  const remainingBridgePoints = useMemo(() => {
    if (totalExpectedBalance) {
      return totalExpectedBalance - bridgePointsBalance.openFundingBalance;
    }

    return bridgePointsBalance.openFundingRemainingBalance;
  }, [totalExpectedBalance, bridgePointsBalance]);

  const expectedBalanceOnClose = useMemo(
    () =>
      bridgePointsBalance.currentBalance -
      bridgePointsBalance.openFundingBalance -
      remainingBridgePoints,
    [bridgePointsBalance, remainingBridgePoints],
  );

  return (
    <>
      <Row className="mb-2 align-items-center">
        <FundingIdForm fundingId={funding.id} />
        <FundingStatusForm
          fundingId={funding.id}
          fundingStatus={funding.status}
          setSpinner={setSpinner}
          getFunding={getFunding}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingAggregatorNameForm fundingConsumerAggregatorName={funding.consumerAggregatorName} />
        <FundingOpenTimestampForm fundingCreateTimestamp={funding.createTimestamp} />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingConsumerNameForm
          fundingConsumerId={funding.consumerId}
          fundingConsumerFirstName={funding.consumerFirstName}
          fundingConsumerLastName={funding.consumerLastName}
        />
        <FundingRepaymentModeForm
          fundingId={funding.id}
          fundingRepaymentMode={funding.repaymentMode}
          setSpinner={setSpinner}
          getFunding={getFunding}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingReasonForm fundingTextualReason={funding.textualReason} />
        <FundingRepaymentTrialsForm fundingActualRepaymentTrials={funding.actualRepaymentTrials} />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingOriginalAmountForm fundingAmount={funding.amount} />
        <FundingNextRepaymentDate
          fundingRepaymentMode={funding.repaymentMode}
          fundingNextRepaymentDate={funding.nextRepaymentDate}
        />
        <FundingChangeRepaymentDateForm
          fundingRepaymentMode={funding.repaymentMode}
          openRepaymentDateModal={openRepaymentDateModal}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingTopUpsAmountForm
          fundingTopupsSum={funding.topupsSum}
          fundingTopupsCount={funding.topupsCount}
        />
        <FundingLatestRepaymentDateForm fundingMaxRepaymentDate={funding.maxRepaymentDate} />
        <FundingChangeRepaymentDateForm
          fundingRepaymentMode={funding.repaymentMode}
          openRepaymentDateModal={openRepaymentDateModal}
          latestDate
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingRepaymentsAmountForm
          fundingRepaymentsSum={funding.repaymentsSum}
          fundingRepaymentsCount={funding.repaymentsCount}
        />
        <FundingCloseDateForm fundingCloseTimestamp={funding.closeTimestamp} />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingOutstandingAmountForm fundingOutstandingAmount={funding.outstandingAmount} />
        <FundingCloseReasonForm fundingCloseReason={funding.closeReason} />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingTotalFeesForm fundingFeesSum={funding.feesSum} />
        <FundingPayrollDelayedForm
          fundingIsDelayedAfterPayrollCluster={funding.isDelayedAfterPayrollCluster}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingBridgePointsSoFarForm
          fundingStatus={funding.status}
          bridgePointsBalanceOpenFundingBalance={bridgePointsBalance.openFundingBalance}
        />
        <FundingBlindCollectionForm
          fundingStatus={funding.status}
          fundingRepaymentMode={funding.repaymentMode}
          consumerBankAccountStatus={consumer?.bankAccountStatus}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingBridgePointsRemainingForm
          fundingStatus={funding.status}
          totalExpectedBalance={totalExpectedBalance}
          remainingBridgePoints={remainingBridgePoints}
          expectedBalanceOnClose={expectedBalanceOnClose}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FundingExpectedBalanceForm
          fundingStatus={funding.status}
          expectedBalanceOnClose={expectedBalanceOnClose}
          totalExpectedBalance={totalExpectedBalance}
        />
      </Row>
      {funding.flags.length > 0 && <FundingRedFlags fundingFlags={funding.flags} />}
      <FundingActionRow
        fundingId={funding.id}
        setSpinner={setSpinner}
        getFunding={getFunding}
        openRepaymentModal={openRepaymentModal}
      />
    </>
  );
}

export default FundingFormController;
