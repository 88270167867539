import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function FundingAggregatorNameForm({ fundingConsumerAggregatorName }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Aggregator Name:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="aggregatorName"
          id="aggregatorName"
          value={fundingConsumerAggregatorName || ''}
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingAggregatorNameForm);
