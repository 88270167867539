import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useEnv } from '../../../context/env-context';
import { makeRequest } from '../../../utils/makeRequest';

function ProspectActionRow({ prospect, getProspect, setSpinner }) {
  const navigate = useNavigate();
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const saveProspect = async exit => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/prospect/save`,
      method: 'POST',
      data: JSON.stringify(prospect),
    };

    try {
      const data = await makeRequest(config);

      getProspect(data);
      setSpinner(false);
      toast.success('Prospect successfully saved!');
      if (exit) {
        navigate(`/prospects/${prospect.aggregatorId}`);
      }
    } catch (error) {
      setSpinner(false);
      toast.error(error.message);
    }
  };

  return (
    <Row className="mt-5 mb-2 align-items-center">
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Button className="bo-button" onClick={() => saveProspect(false)}>
          Save
        </Button>
        <Button className="ms-3 bo-button" onClick={() => saveProspect(true)}>
          Save & Exit
        </Button>
      </Col>
    </Row>
  );
}

export default ProspectActionRow;
