import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

function ActivityTable({
  reportDate,
  bpUsageAllTimes,
  bpUsageThisMonth,
  bpUsageThisYear,
  extendedAdvancesAllTimesAmount,
  extendedAdvancesAllTimesCount,
  extendedAdvancesThisMonthAmount,
  extendedAdvancesThisMonthCount,
  extendedAdvancesThisYearAmount,
  extendedAdvancesThisYearCount,
  thisMonthGrantedBridgepoints,
  thisYearGrantedBridgepoints,
  allTimesGrantedBridgepoints,
}) {
  return (
    <View style={styles.activityTable}>
      <View
        style={[styles.activityTableRow, styles.activityTableTopRow, styles.activityTableRowBorder]}
      >
        <View style={styles.activityTableRowTitleContainer} />
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>{reportDate.format('MMM YYYY')}</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>{`YTD ${reportDate.format('YYYY')}`}</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>All Times</Text>
        </View>
      </View>
      <View style={[styles.activityTableRow, styles.activityTableRowBorder]}>
        <View style={styles.activityTableRowTitleContainer}>
          <Text style={styles.activityTableRowValue}>Advances extended</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(extendedAdvancesThisMonthCount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(extendedAdvancesThisYearCount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(extendedAdvancesAllTimesCount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
      </View>
      <View style={[styles.activityTableRow, styles.activityTableRowBorder]}>
        <View style={styles.activityTableRowTitleContainer}>
          <Text style={styles.activityTableRowValue}>Amount extended</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            $
            {Number(extendedAdvancesThisMonthAmount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            $
            {Number(extendedAdvancesThisYearAmount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            $
            {Number(extendedAdvancesAllTimesAmount).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
      </View>
      <View style={[styles.activityTableRow, styles.activityTableRowBorder]}>
        <View style={styles.activityTableRowTitleContainer}>
          <Text style={styles.activityTableRowValue}>Granted BridgePoints™</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(thisMonthGrantedBridgepoints).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(thisYearGrantedBridgepoints).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(allTimesGrantedBridgepoints).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </Text>
        </View>
      </View>
      <View style={styles.activityTableRow}>
        <View style={styles.activityTableRowTitleContainer}>
          <Text style={styles.activityTableRowValue}>Used BridgePoints™</Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(bpUsageThisMonth).toLocaleString('en-US', { maximumFractionDigits: 0 })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(bpUsageThisYear).toLocaleString('en-US', { maximumFractionDigits: 0 })}
          </Text>
        </View>
        <View style={styles.activityTableValueContainer}>
          <Text style={styles.activityTableRowValue}>
            {Number(bpUsageAllTimes).toLocaleString('en-US', { maximumFractionDigits: 0 })}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default ActivityTable;
