import moment from 'moment';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

function BillingTable({
  reportDate,
  billableUsers,
  startUsers,
  joinedUsers,
  leftUsers,
  endUsers,
  totalAmount,
}) {
  return (
    <View style={styles.billingTable}>
      <View style={[styles.billingTableRow, styles.billingTableRowBorder]}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>Billable Users*</Text>
        </View>
        <View style={[styles.billingTableValueContainer, styles.billingTableValueTopRight]}>
          <Text style={styles.billingTableRowValue}>{billableUsers}</Text>
        </View>
      </View>
      <View style={styles.billingTableRow}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>Users:</Text>
        </View>
      </View>
      <View style={[styles.billingTableRow, styles.billingTableRowBorder]}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>
            {`End of ${moment(reportDate).subtract(1, 'month').format('MMMM YYYY')}`}
          </Text>
        </View>
        <View style={styles.billingTableValueContainer}>
          <Text style={styles.billingTableRowValue}>{startUsers}</Text>
        </View>
      </View>
      <View style={[styles.billingTableRow, styles.billingTableRowBorder]}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>
            {`Joined during ${reportDate.format('MMMM YYYY')}`}
          </Text>
        </View>
        <View style={styles.billingTableValueContainer}>
          <Text style={styles.billingTableRowValue}>{joinedUsers}</Text>
        </View>
      </View>
      <View style={[styles.billingTableRow, styles.billingTableRowBorder]}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>
            {`Left during ${reportDate.format('MMMM YYYY')}`}
          </Text>
        </View>
        <View style={styles.billingTableValueContainer}>
          <Text style={styles.billingTableRowValue}>{leftUsers}</Text>
        </View>
      </View>
      <View style={[styles.billingTableRow, styles.billingTableRowBorder]}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={styles.billingTableRowTitle}>
            {`End of ${reportDate.format('MMMM YYYY')}`}
          </Text>
        </View>
        <View style={styles.billingTableValueContainer}>
          <Text style={styles.billingTableRowValue}>{endUsers}</Text>
        </View>
      </View>
      <View style={styles.billingTableRow}>
        <View style={styles.billingTableRowTitleContainer}>
          <Text style={[styles.billingTableRowTitle, styles.billingTableRowTotal]}>
            Total Subscription Fee This Month
          </Text>
        </View>
        <View style={[styles.billingTableValueContainer, styles.billingTableValueBottomRight]}>
          <Text style={[styles.billingTableRowValue, styles.billingTableRowTotal]}>
            {`$${
              totalAmount
                ? Number(totalAmount).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })
                : '0'
            }`}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default BillingTable;
