import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import { useTableContext } from '../../../../context/table-context';
import { useNavigate } from 'react-router-dom';

function DashboardPaymentMethodWidget({
  title,
  paymentMethods,
  paymentMethodKey,
  consumers,
  handleClick,
}) {
  const calculate = (dividend, divisor) =>
    `${Number((dividend / divisor) * 100).toLocaleString('en-US', {
      maximumFractionDigits: 1,
    })}% `;

  return (
    <div className="bo-border-container rounded m-1 bo-min-w-200">
      <p className="text-capitalize bo-badge">{title}</p>
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-center h6 me-3">Active</p>
        {paymentMethods &&
        paymentMethods[`${paymentMethodKey}Active`] !== null &&
        paymentMethods[`${paymentMethodKey}Active`] !== undefined &&
        paymentMethods[`${paymentMethodKey}Active`].count !== null &&
        paymentMethods[`${paymentMethodKey}Active`].count !== undefined &&
        consumers.active !== null &&
        consumers.active !== undefined &&
        consumers.active !== 0 ? (
          <div className="d-flex align-items-center">
            <Button
              className="bo-button-inline-link p-0 me-1 d-flex"
              color="link"
              onClick={() =>
                handleClick(
                  paymentMethods[`${paymentMethodKey}Active`].list,
                  `Dashboard → Payment Methods → ${title} → Active`,
                )
              }
            >
              <p className="text-center h6">
                {calculate(paymentMethods[`${paymentMethodKey}Active`].count, consumers.active)}
              </p>
            </Button>
            <span className="bo-text-sm mb-2">
              ({paymentMethods[`${paymentMethodKey}Active`].count}/{consumers.active})
            </span>
          </div>
        ) : (
          <p className="h6">N/A</p>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-center h6 me-3">Billable</p>
        {paymentMethods &&
        paymentMethods[`${paymentMethodKey}Billable`] !== null &&
        paymentMethods[`${paymentMethodKey}Billable`] !== undefined &&
        paymentMethods[`${paymentMethodKey}Billable`].count !== null &&
        paymentMethods[`${paymentMethodKey}Billable`].count !== undefined &&
        consumers.billable !== null &&
        consumers.billable !== undefined &&
        consumers.billable !== 0 ? (
          <div className="d-flex align-items-center">
            <Button
              className="bo-button-inline-link p-0 me-1 d-flex"
              color="link"
              onClick={() =>
                handleClick(
                  paymentMethods[`${paymentMethodKey}Billable`].list,
                  `Dashboard → Payment Methods → ${title} → Billable`,
                )
              }
            >
              <p className="text-center h6">
                {calculate(paymentMethods[`${paymentMethodKey}Billable`].count, consumers.billable)}
              </p>
            </Button>
            <span className="bo-text-sm mb-2">
              ({paymentMethods[`${paymentMethodKey}Billable`].count}/{consumers.billable})
            </span>
          </div>
        ) : (
          <p className="h6">N/A</p>
        )}
      </div>
    </div>
  );
}

function DashboardPaymentMethods({ aggregators, getAggregators }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [consumers, setConsumers] = useState({
    active: null,
    billable: null,
  });
  const [aggregatorId, setAggregatorId] = useState();

  const { clearTableFilters, setSelectedConsumerIdsFilter } = useTableContext();

  const getPaymentMethods = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_payment_methods_by_aggregator/v2`,
      method: 'GET',
      params: {
        aggregatorId,
      },
    };

    try {
      const response = await makeRequest(config);

      setPaymentMethods(response);
    } catch (error) {
      toast.error(`Failed getting payment methods: ${error.message}`);
    }
  };

  const getConsumers = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_counts_by_aggregator`,
      method: 'GET',
      params: {
        aggregatorId,
      },
    };

    try {
      const response = await makeRequest(config);

      setConsumers({
        active: response.byStatus.ACTIVE,
        billable: response.billableConsumers,
      });
    } catch (error) {
      toast.error(`Failed getting consumers: ${error.message}`);
    }
  };

  const getAll = async () => {
    setSpinner(true);
    getAggregators();
    await getPaymentMethods();
    await getConsumers();
    setSpinner(false);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatorId]);

  const changeAggregatorFilter = e => setAggregatorId(e.target.value);

  const handleClick = (consumerIds, message) => {
    clearTableFilters({
      tableName: 'consumers',
      initSortBy: 'createDate',
      initSortOrder: 'desc',
      initRowsPerPage: 25,
    });
    setSelectedConsumerIdsFilter({
      consumerIds: consumerIds.join(','),
      message,
    });
    navigate('/consumers');
  };

  return (
    <Card className="mb-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Payment Methods
        <div className="d-flex align-items-center">
          <Input
            className="bo-filter w-100 ms-3"
            type="select"
            name="aggregatorFilter"
            id="aggregatorFilter"
            placeholder="filter"
            noValidate
            value={aggregatorId}
            onChange={changeAggregatorFilter}
          >
            <option value="">All Standard Aggregators</option>
            {aggregators.map(agg => (
              <option key={agg.id} value={agg.id}>
                {agg.name}
              </option>
            ))}
          </Input>
          <Button className="bo-button-inline-link" color="link" onClick={getAll}>
            Refresh
          </Button>
        </div>
      </CardHeader>
      <CardBody className="bo-min-h-100">
        <Spinner visible={spinner} text="">
          <div className="d-flex justify-content-center flex-wrap">
            <DashboardPaymentMethodWidget
              title="Connected Accounts"
              paymentMethods={paymentMethods}
              paymentMethodKey="connected"
              consumers={consumers}
              handleClick={handleClick}
            />

            <DashboardPaymentMethodWidget
              title="Cards On File"
              paymentMethods={paymentMethods}
              paymentMethodKey="cardOnFile"
              consumers={consumers}
              handleClick={handleClick}
            />

            <DashboardPaymentMethodWidget
              title="Cards Required"
              paymentMethods={paymentMethods}
              paymentMethodKey="cardRequired"
              consumers={consumers}
              handleClick={handleClick}
            />
          </div>
        </Spinner>
      </CardBody>
    </Card>
  );
}

export default DashboardPaymentMethods;
