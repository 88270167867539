import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import { string_to_slug } from '../../../utils/slug';
import { useEnv } from '../../../context/env-context';
import { makeRequest } from '../../../utils/makeRequest';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function ConsumersActions({ selectedAggregatorFilter, spinner, setSpinner }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [actionsOpen, setActionsOpen] = useState(false);

  const actionsToggle = () => setActionsOpen(!actionsOpen);

  const getConsumersFile = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/export`,
      method: 'GET',
      params: { aggregatorId: selectedAggregatorFilter },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    } finally {
      setSpinner(false);
    }
  };

  const downloadConsumersFile = async () => {
    const binaryData = [];
    const data = await getConsumersFile();

    if (data) {
      binaryData.push(data);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `consumers_${string_to_slug('AAA')}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  return (
    <Dropdown isOpen={actionsOpen} toggle={actionsToggle} disabled={spinner}>
      <DropdownToggle className="bo-dropdown" caret>
        Actions&nbsp;&nbsp;&nbsp;
      </DropdownToggle>
      <DropdownMenu className="bo-dropdown-menu">
        <DropdownItem className="bo-dropdown-menu-item" disabled={selectedAggregatorFilter === ''}>
          Change status - Bulk
        </DropdownItem>
        <DropdownItem
          className="bo-dropdown-menu-item"
          disabled={selectedAggregatorFilter === ''}
          onClick={downloadConsumersFile}
        >
          Export
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ConsumersActions;
