import { useState, useEffect, useCallback } from 'react';
import { Container, Row } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import AggregatorFormController from './components/AggregatorFormController';
import AggregatorTabController from './components/AggregatorTabController';
import AggregatorSaveButton from './components/AggregatorSaveButton';
import AggregatorUniqueUrlsModal from './components/modals/AggregatorUniqueUrlsModal';
import Loading from '../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';

function Aggregator() {
  const params = useParams();
  const navigate = useNavigate();
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [aggregator, setAggregator] = useState(false);
  const [uniformUrl, setUniformUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [urlsCount, setUrlsCount] = useState(0);

  const getAggregator = useCallback(
    async id => {
      if (id === 'new') {
        setAggregator({
          id: 'new',
          type: 'STANDARD',
          name: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zip: null,
          phoneNumber: null,
          contactName: null,
          contactPhone: null,
          contactEmail: null,
          useEmployeeIdProspects: false,
          hideEmployeeIdProspects: false,
          allowAnonymousSignups: false,
          useEmployeeIdAnonymous: false,
          maxAllowedSignups: null,
          genericSignup: false,
        });

        return;
      }

      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config1 = {
        token,
        url: `${apiOriginAggregator}/get/${id}`,
        method: 'GET',
      };

      const config2 = {
        token,
        url: `${apiOriginAggregator}/token/uniform`,
        method: 'GET',
        params: { aggregatorId: id },
      };

      try {
        const data = await makeRequest(config1);

        setAggregator(data);

        const url = await makeRequest(config2);

        setUniformUrl(url);
      } catch (error) {
        if (error.message === `Query falied for aggregtor id ${id}!`) {
          navigate('/aggregators');
          toast.error(`Aggregator ID ${id} not found!`);
        } else {
          toast.error(error.message);
        }
      } finally {
        setSpinner(false);
      }
    },
    [getAccessTokenSilently, apiOriginAggregator, navigate],
  );

  useEffect(() => {
    getAggregator(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeValue = useCallback(e => {
    // eslint-disable-next-line prefer-const
    let { name, value } = e.target;

    if (name === 'state') {
      value = value.toUpperCase();
    } else if (name === 'maxAllowedSignups') {
      value = Number(value);
    } else if (
      name === 'defaultFreeCreditTransfers' ||
      name === 'defaultFreeDebitTransfers' ||
      name === 'defaultTransferFee' ||
      name === 'defaultBpInitQuota' ||
      name === 'defaultBpMonthlyQuota' ||
      name === 'defaultBpCap' ||
      name === 'defaultPrePaydayClusterExtension' ||
      name === 'defaultPostPaydayClusterExtension'
    ) {
      value = value === null ? null : Number(value);
    } else if (
      name === 'monthlySubscriptionFee' ||
      name === 'numberOfEmployees' ||
      name === 'turnoverBenchmerk' ||
      name === 'employeeReplacementCost'
    ) {
      value = value ? Number(value) : null;
    }

    setAggregator(prevAggregator => ({ ...prevAggregator, [name]: value }));
  }, []);

  const changeChecked = useCallback(
    e =>
      setAggregator(prevAggregator => ({ ...prevAggregator, [e.target.name]: e.target.checked })),
    [],
  );

  const openModal = useCallback(() => {
    setUrlsCount(aggregator.maxAllowedSignups);
    setShowModal(true);
  }, [aggregator?.maxAllowedSignups]);
  const closeModal = useCallback(() => setShowModal(false), []);

  const handleCountChange = useCallback(e => setUrlsCount(e.target.value), []);

  if (!aggregator) {
    return <Spinner visible={spinner} text="Loading..." />;
  }

  return (
    <>
      <Spinner visible={spinner} text="">
        <Container className="mb-5 pb-1">
          <Row className="mb-3">
            <h2>Aggregator</h2>
          </Row>
          <AggregatorFormController aggregator={aggregator} changeValue={changeValue} />
          <AggregatorTabController
            aggregator={aggregator}
            setSpinner={setSpinner}
            changeChecked={changeChecked}
            changeValue={changeValue}
            uniformUrl={uniformUrl}
            openModal={openModal}
          />
          <AggregatorSaveButton aggregator={aggregator} setSpinner={setSpinner} />
        </Container>
      </Spinner>
      <AggregatorUniqueUrlsModal
        showModal={showModal}
        closeModal={closeModal}
        aggregator={aggregator}
        urlsCount={urlsCount}
        handleCountChange={handleCountChange}
        setSpinner={setSpinner}
      />
    </>
  );
}

export default withAuthenticationRequired(Aggregator, { onRedirecting: () => <Loading /> });
