import { useState } from 'react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { states } from '../../../../../utils/states';
import { NumericFormat } from 'react-number-format';
import { useClockContext } from '../../../../../context/clock-context';

function NewObjectPopoverAddressForm({
  consumerAddressLine1,
  consumerAddressLine2,
  consumerCity,
  consumerState,
  consumerZip,
  updateConsumer,
}) {
  const { setConsumerLocation } = useClockContext();

  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [address, setAddress] = useState({
    addressLine1: consumerAddressLine1,
    addressLine2: consumerAddressLine2,
    city: consumerCity,
    state: consumerState,
    zip: consumerZip,
  });

  const handleSave = async () => {
    setSpinner(true);
    await updateConsumer({ ...address });
    setConsumerLocation({ city: address.city, state: address.state, country: 'US' });
    setSpinner(false);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setAddress({
      addressLine1: consumerAddressLine1,
      addressLine2: consumerAddressLine2,
      city: consumerCity,
      state: consumerState,
      zip: consumerZip,
    });
  };

  return (
    <>
      <Label className="bo-new-object-input-label mt-3">Address</Label>
      <Button
        id="popover_address_form"
        className="bo-new-object-text-input-container"
        onClick={handleToggle}
      >
        <div className="me-auto">
          <Input
            className="bo-new-object-text-input bo-w-150"
            type="text"
            readOnly
            value={consumerAddressLine1}
          />
          <Input
            className="bo-new-object-text-input bo-w-150"
            type="text"
            readOnly
            value={consumerAddressLine2}
          />
          <div className="d-flex">
            <Input className="bo-new-object-text-input" type="text" readOnly value={consumerCity} />
            <Input
              className="bo-new-object-text-input"
              type="text"
              readOnly
              value={consumerState}
            />
            <Input className="bo-new-object-text-input" type="text" readOnly value={consumerZip} />
          </div>
        </div>
        <div className="bo-new-object-input-button-container">
          <div className="bo-new-object-input-button-icon">
            <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
          </div>
        </div>
      </Button>
      <Popover
        target="popover_address_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <Label className="bo-new-object-popover-form-menu-label">Address line 1</Label>
            <Input
              type="text"
              className="bo-new-object-popover-form-menu-input"
              value={address.addressLine1}
              onChange={e => setAddress({ ...address, addressLine1: e.target.value })}
            />
            <Label className="bo-new-object-popover-form-menu-label mt-2">Address line 2</Label>
            <Input
              type="text"
              className="bo-new-object-popover-form-menu-input"
              value={address.addressLine2}
              onChange={e => setAddress({ ...address, addressLine2: e.target.value })}
            />
            <div className="d-flex mt-2 gap-2">
              <div>
                <Label className="bo-new-object-popover-form-menu-label">City</Label>
                <Input
                  type="text"
                  className="bo-new-object-popover-form-menu-input"
                  value={address.city}
                  onChange={e => setAddress({ ...address, city: e.target.value })}
                />
              </div>
              <div>
                <Label className="bo-new-object-popover-form-menu-label">State</Label>
                <Input
                  type="select"
                  className="bo-new-object-popover-form-menu-input bo-w-80"
                  value={address.state}
                  onChange={e => setAddress({ ...address, state: e.target.value })}
                >
                  {states.map(state => (
                    <option value={state} key={state}>
                      {state}
                    </option>
                  ))}
                </Input>
              </div>
              <div>
                <Label className="bo-new-object-popover-form-menu-label">Zip</Label>
                <NumericFormat
                  type="text"
                  className="bo-new-object-popover-form-menu-input bo-w-100"
                  value={address.zip}
                  onChange={e => setAddress({ ...address, zip: e.target.value })}
                  maxLength={5}
                  customInput={Input}
                  isAllowed={values => values.formattedValue.length <= 5}
                  allowNegative={false}
                  decimalScale={0}
                  allowLeadingZeros
                />
              </div>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <Button
                className="bo-new-object-button"
                onClick={handleSave}
                disabled={
                  !address.addressLine1 ||
                  !address.city ||
                  !address.state ||
                  address.zip.length !== 5
                }
              >
                Save
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverAddressForm;
