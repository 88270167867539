import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { modalStyle } from '../../../../utils/modal';

function ConsumerGhostingModal({ consumer, isOpen, close, getConsumer }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [ghostedConsumerId, setGhostedConsumerId] = useState('');
  const [spinner, setSpinner] = useState(false);

  ReactModal.setAppElement(document.getElementById('root'));

  const ghostConsumer = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/ghost`,
      method: 'POST',
      data: JSON.stringify({ ghostingConsumerId: consumer.id, ghostedConsumerId }),
    };

    try {
      await makeRequest(config);
      await getConsumer(consumer.id);

      setGhostedConsumerId('');
      close();

      toast.success('Successfully ghosting consumer');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleGhost = async () => {
    if (!ghostedConsumerId) {
      toast.error('Ghosted id is required');

      return;
    }

    setSpinner(true);
    await ghostConsumer();
    setSpinner(false);
    close();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('420px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinner} text="">
        <h5>Ghosting</h5>
        <Row className="mb-2 mt-3 align-items-center">
          <Col md={6} className="bo-same-line">
            <Label>Ghosted Consumer Id:</Label>
          </Col>
          <Col md={6} className="bo-same-line">
            <Input
              className="bo-input"
              type="text"
              name="ghostingConsumerId"
              id="ghostingConsumerId"
              value={ghostedConsumerId}
              onChange={e =>
                setGhostedConsumerId(
                  parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : '',
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button className="bo-button" onClick={handleGhost}>
              Ghost
            </Button>
            <Button className="ms-3 bo-button" onClick={close}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerGhostingModal;
