import { useState, useEffect, memo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ConsumerGhosting({
  consumerId,
  consumerGhostedConsumerId,
  openGhostingModal,
  getConsumer,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [ghostedConsumer, setGhostedConsumer] = useState(null);

  const getGhostedConsumer = async id => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get/${id}`,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      setGhostedConsumer(response);
    } catch (error) {
      toast.error('Can’t get ghosted consumer details');
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (consumerGhostedConsumerId) {
      getGhostedConsumer(consumerGhostedConsumerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unghostConsumer = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      try {
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/unghost`,
          method: 'POST',
          data: JSON.stringify({ ghostingConsumerId: consumerId }),
        };

        await makeRequest(config);
        await getConsumer(consumerId);

        setGhostedConsumer(null);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Spinner visible={spinner} text="">
      {consumerGhostedConsumerId ? (
        <div className="mb-2 mt-3">
          <Row className="mt-2 ms-1 me-1">
            <Col sm="2">
              <Button className="bo-button-inline bo-button-gray" onClick={unghostConsumer}>
                Unghost
              </Button>
            </Col>
          </Row>
          <Row className="mt-2 ms-1 me-1">
            {ghostedConsumer ? (
              <Col sm="12">
                {`Currently ghosting consumer: ${ghostedConsumer.firstName}
                  ${ghostedConsumer.lastName} (${ghostedConsumer.id})`}
              </Col>
            ) : (
              <Col sm="12">Currently ghosting consumer: {consumerGhostedConsumerId}</Col>
            )}
          </Row>
        </div>
      ) : (
        <div className="mb-2 mt-3">
          <Row className="mt-2 ms-1 me-1">
            <Col sm="2">
              <Button className="bo-button-inline bo-button-gray" onClick={openGhostingModal}>
                Ghost
              </Button>
            </Col>
          </Row>
          <Row className="mt-2 ms-1 me-1">
            <Col sm="12">Currently not ghosting</Col>
          </Row>
        </div>
      )}
    </Spinner>
  );
}

export default memo(ConsumerGhosting);
