import { useCallback, useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { NumericFormat } from 'react-number-format';

function NewObjectGhostingForm({ consumerId, consumerGhostedConsumerId, setConsumer }) {
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [ghostedConsumer, setGhostedConsumer] = useState(null);
  const [ghostedConsumerId, setGhostedConsumerId] = useState(consumerGhostedConsumerId || null);

  const getGhostedConsumer = useCallback(
    async id => {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/get/${id}`,
        method: 'GET',
      };

      try {
        const response = await makeRequest(config);

        setGhostedConsumer(response);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    },
    [apiOriginConsumer, getAccessTokenSilently],
  );

  useEffect(() => {
    if (consumerGhostedConsumerId) {
      getGhostedConsumer(consumerGhostedConsumerId);
    }
  }, [getGhostedConsumer, consumerGhostedConsumerId]);

  const ghostConsumer = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/ghost`,
      method: 'POST',
      data: JSON.stringify({ ghostingConsumerId: consumerId, ghostedConsumerId }),
    };

    try {
      await makeRequest(config);

      setConsumer(prevConsumer => ({ ...prevConsumer, ghostedConsumerId }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const unghostConsumer = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      try {
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/unghost`,
          method: 'POST',
          data: JSON.stringify({ ghostingConsumerId: consumerId }),
        };

        await makeRequest(config);

        setConsumer(prevConsumer => ({ ...prevConsumer, ghostedConsumerId: null }));
        setGhostedConsumerId(null);
        setGhostedConsumer(null);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <Label className="bo-new-object-input-label">Ghosted Consumer Id</Label>
          <NumericFormat
            type="text"
            className="bo-new-object-text-input"
            value={ghostedConsumerId || ''}
            onValueChange={({ floatValue }) => setGhostedConsumerId(floatValue)}
            customInput={Input}
            allowNegative={false}
            decimalScale={0}
            readOnly={spinner || consumerGhostedConsumerId}
          />
          <div
            className={`bo-new-object-text-bottom-border ${
              consumerGhostedConsumerId ? '' : 'bo-new-object-text-bottom-border-active'
            }`}
          />
        </div>
        <Button
          className="bo-new-object-inline-button"
          onClick={consumerGhostedConsumerId ? unghostConsumer : ghostConsumer}
          disabled={spinner || !ghostedConsumerId}
        >
          {consumerGhostedConsumerId ? 'Unghost' : 'Ghost'}
        </Button>
      </div>
      {consumerGhostedConsumerId && (
        <div>
          <Label className="bo-new-object-input-label">Ghosted Consumer Name</Label>
          {spinner ? (
            <div className="bo-new-object-text-input">
              <CircularProgress color="inherit" size={14} />
            </div>
          ) : (
            <Input
              className="bo-new-object-text-input"
              type="text"
              readOnly
              value={
                ghostedConsumer ? `${ghostedConsumer.firstName} ${ghostedConsumer.lastName}` : ''
              }
            />
          )}
        </div>
      )}
    </>
  );
}

export default NewObjectGhostingForm;
