import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Spinner } from '../../../../components/Spinner';
import { useEffect, useMemo, useState } from 'react';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  REPAYMENT_TRIALS_ACTION,
  REPAYMENT_TRIALS_STATUS_COLOR,
  REPAYMENT_TRIALS_TYPE,
} from '../../../../constants/fundings';

function FundingRepaymentActivity({ fundingId }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [repaymentTrials, setRepaymentTrials] = useState([]);
  const [checked, setChecked] = useState(true);

  const getRepaymentTrials = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/repayment_trials`,
      method: 'GET',
      params: { fundingId },
    };

    try {
      const response = await makeRequest(config);

      setRepaymentTrials(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getRepaymentTrials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRepaymentLineColor = repaymentTrial => {
    if (REPAYMENT_TRIALS_STATUS_COLOR[repaymentTrial.transferStatus]) {
      return REPAYMENT_TRIALS_STATUS_COLOR[repaymentTrial.transferStatus];
    }

    if (
      repaymentTrial.trialAction === 'NO_REPAYMENT' &&
      repaymentTrial.trialActionReason !== 'Not a repayment date' &&
      repaymentTrial.trialActionReason !== 'Not a business day' &&
      repaymentTrial.trialActionReason !== 'Advance status is CLOSING' &&
      repaymentTrial.trialActionReason !== 'Advance repayment mode is MANUAL'
    ) {
      return 'text-danger';
    }

    return '';
  };

  const filteredRepaymentTrials = useMemo(() => {
    if (checked) {
      return repaymentTrials.filter(x => x.trialActionReason !== 'Not a repayment date');
    }

    return repaymentTrials;
  }, [checked, repaymentTrials]);

  return (
    <Spinner visible={spinner}>
      <Row className="pt-4 mb-4 ms-1">
        <Col sm="8" className="d-flex">
          <Button className="bo-button-inline bo-button-gray" onClick={getRepaymentTrials}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <div className="d-flex align-items-center ms-3">
            <Input
              className="ms-1 bo-checkbox"
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(prevChecked => !prevChecked)}
            />
            <Label className="p-0 mt-1 mb-0 me-0">
              Hide &lsquo;Not a repayment date&rsquo; trials
            </Label>
          </div>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Timestamp</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Job #</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
              <StyledTableCell align="left">Reason</StyledTableCell>
              <StyledTableCell align="left">Blind</StyledTableCell>
              <StyledTableCell align="left">Transfer ID</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRepaymentTrials
              .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
              .map(repaymentTrial => (
                <TableRow hover tabIndex={-1} key={repaymentTrial.timestamp}>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {moment
                      .utc(repaymentTrial.timestamp)
                      .local()
                      .format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {REPAYMENT_TRIALS_TYPE[repaymentTrial.trialType] || repaymentTrial.trialType}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {repaymentTrial.jobNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {REPAYMENT_TRIALS_ACTION[repaymentTrial.trialAction] ||
                      repaymentTrial.trialAction}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {repaymentTrial.trialActionReason}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {repaymentTrial.isBlind ? 'Yes' : ''}
                  </StyledTableCell>
                  <StyledTableCell align="left" className={getRepaymentLineColor(repaymentTrial)}>
                    {repaymentTrial.transferId}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
}
export default FundingRepaymentActivity;
