import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { makeRequest } from '../../../../utils/makeRequest';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function ReportsDatesInputModalForm({ close, setSpinner, createReport }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [aggregatorId, setAggregatorId] = useState('');
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  const [aggregators, setAggregators] = useState([]);

  const getAggregators = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);
    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const data = await makeRequest(config);

      setAggregators(data);
    } catch (error) {
      toast.error(`Faled getting aggregators: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () =>
    createReport({
      aggregatorId: Number(aggregatorId),
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
    });

  return (
    <div className="bo-consumer-economics-modal-form">
      <div>
        <Row className="align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Aggregator:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <Input
              className="bo-filter bo-w-250"
              type="select"
              name="aggregators"
              id="aggregators"
              placeholder="aggregators"
              noValidate
              value={aggregatorId}
              onChange={e => setAggregatorId(e.target.value)}
            >
              <option value="">All Standard Aggregators</option>
              {aggregators.map(agg => (
                <option key={agg.id} value={agg.id}>
                  {agg.name}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>From:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <DatePicker
              className="bo-input bo-date-picker bo-w-250"
              onChange={e => setDateFrom(e)}
              selected={new Date(dateFrom)}
              dateFormat="yyyy-MM-dd"
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>To:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <DatePicker
              className="bo-input bo-date-picker bo-w-250"
              onChange={e => setDateTo(e)}
              selected={new Date(dateTo)}
              dateFormat="yyyy-MM-dd"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col className="text-center mt-5">
          <Button className="bo-button" onClick={handleCreate}>
            Create
          </Button>
          <Button className="ms-3 bo-button" onClick={close}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ReportsDatesInputModalForm;
