import { useEffect, useMemo, useState } from 'react';

export const useReportDimensions = (minContainerHeight = 120, outsideContainerHeight = 316) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const { innerHeight, innerWidth } = window;

    setWindowHeight(innerHeight);
    setWindowWidth(innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const height = useMemo(() => {
    const diff = windowHeight - outsideContainerHeight;

    if (diff > minContainerHeight) {
      return diff;
    }

    return minContainerHeight;
  }, [windowHeight, outsideContainerHeight, minContainerHeight]);

  const width = useMemo(() => {
    let diff = 1940;

    if (windowWidth <= 2000) {
      diff = windowWidth - 48;
    }

    if (windowWidth <= 1200) {
      diff = 912;
    }

    if (windowWidth <= 990) {
      return windowWidth - 48;
    }

    return diff;
  }, [windowWidth]);

  return {
    height,
    width,
  };
};
