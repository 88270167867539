import { toast } from 'react-toastify';
import { Row, Col, Button } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';

function SuperuserModalButtons({ superuser, formErrors, setSpinner, getUsers, onModalClose }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const mandatoryOk = () =>
    superuser.firstName &&
    superuser.lastName &&
    superuser.email &&
    superuser.password &&
    superuser.confirmPassword;

  const createSuperuser = async () => {
    setSpinner(true);

    try {
      const { firstName, lastName, email, password } = superuser;

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/create_superuser`,
        method: 'POST',
        data: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
        }),
      };

      await makeRequest(config);
      onModalClose();
      getUsers();

      toast.success('Superuser has been successfully created!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Row>
      <Col className="text-center">
        <Button
          className="mt-5 bo-button"
          onClick={createSuperuser}
          disabled={
            Object.entries(formErrors || {}).filter(([, value]) => value).length > 0 ||
            !mandatoryOk()
          }
        >
          Create
        </Button>
        <Button className="ms-3 mt-5 bo-button" onClick={onModalClose}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export default SuperuserModalButtons;
