import { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { faCheckCircle, faPencil, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { useRef } from 'react';

function NewObjectMultiLineTextInput({ label, initialValue, labelClassName, maxLength, onSave }) {
  const [spinner, setSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);

  const editRef = useRef(null);
  const readOnlyRef = useRef(null);

  const handleSave = async () => {
    setSpinner(true);
    await onSave(value);
    setEditMode(false);
    setSpinner(false);
  };

  useEffect(() => {
    const ref = editMode ? editRef.current : readOnlyRef.current;

    if (ref) {
      ref.style.height = 'auto';
      ref.style.height = `${Math.min(ref.scrollHeight, 150)}px`;
    }
  }, [editMode]);

  const handleCancel = () => {
    setEditMode(!editMode);
    setValue(initialValue);
  };

  return (
    <>
      <Label className={`bo-new-object-input-label ${labelClassName}`}>{label}</Label>
      {editMode ? (
        <div className="bo-new-object-multiline-text-input-container">
          <div className="w-100">
            <Input
              innerRef={editRef}
              className="bo-new-object-multiline-text-input"
              type="textarea"
              value={value}
              onChange={e => setValue(e.target.value)}
              maxLength={maxLength}
              readOnly={spinner}
              onInput={() => {
                if (editRef.current) {
                  editRef.current.style.height = 'auto';
                  editRef.current.style.height = `${Math.min(editRef.current.scrollHeight, 150)}px`;
                }
              }}
              rows={1}
            />
            <div
              className={
                // eslint-disable-next-line no-useless-concat
                'bo-new-object-text-bottom-border ' + 'bo-new-object-text-bottom-border-active'
              }
            />
          </div>
          <div
            className={
              'bo-new-object-input-button-container' +
              ' bo-new-object-input-button-container-active'
            }
          >
            {spinner ? (
              <div className="h-100">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              <>
                <Button
                  className="bo-new-object-input-button-icon"
                  onClick={handleSave}
                  disabled={maxLength && value.length !== maxLength}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color="#5946A4" size="1x" />
                </Button>
                <Button className="bo-new-object-input-button-icon" onClick={handleCancel}>
                  <FontAwesomeIcon icon={faTimesCircle} color="#5946A4" size="1x" />
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <Button
          className="bo-new-object-multiline-text-input-container"
          onClick={() => setEditMode(true)}
        >
          <div className="w-100">
            <Input
              innerRef={readOnlyRef}
              className="bo-new-object-multiline-text-input"
              type="textarea"
              readOnly
              value={value}
              onChange={e => setValue(e.target.value)}
              rows={1}
            />
            <div className="bo-new-object-text-bottom-border" />
          </div>
          <div className="bo-new-object-input-button-container">
            <div className="bo-new-object-input-button-icon">
              <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
            </div>
          </div>
        </Button>
      )}
    </>
  );
}

export default NewObjectMultiLineTextInput;
