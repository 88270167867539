import { states } from '../../../../../utils/states';
// components
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

function NewObjectBillingAddressForm({
  btMetadata,
  setBtMetadata,
  useProfileAddress,
  setUseProfileAddress,
}) {
  const handleDefaultInputChangeEvent = (fieldName, e) => {
    let { value } = e.target;

    if (fieldName === 'state') {
      value = value.toUpperCase().replace(/[^A-Z]/g, '');
    }

    if (fieldName === 'zip') {
      value = value.replace(/[^0-9]/g, '');
    }

    setBtMetadata(prevM => ({
      ...prevM,
      [fieldName]: { value, dirty: true },
    }));
  };

  const getDefaultInputStyle = fieldName => {
    let className = 'bo-new-object-popover-form-menu-input';

    if (
      btMetadata[fieldName].dirty &&
      !btMetadata[fieldName].value &&
      fieldName !== 'addressLine2'
    ) {
      className += ' bo-new-object-card-form-default-input-invalid';
    }

    if (
      btMetadata[fieldName].dirty &&
      fieldName === 'state' &&
      !states.includes(btMetadata[fieldName].value)
    ) {
      className += ' bo-new-object-card-form-default-input-invalid';
    }

    if (
      btMetadata[fieldName].dirty &&
      fieldName === 'zip' &&
      btMetadata[fieldName].value.length !== 5
    ) {
      className += ' bo-new-object-card-form-default-input-invalid';
    }

    if (useProfileAddress) {
      className += ' bo-new-object-card-form-default-input-readonly';
    }

    return className;
  };

  return (
    <>
      <Row className="mt-2">
        <Col>
          <Label className="bo-new-object-popover-form-menu-label">Billing Address</Label>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="bo-same-line align-items-start">
          <FormGroup switch>
            <Input
              name="useProfileAddress"
              id="useProfileAddress"
              type="switch"
              role="switch"
              className="bo-new-object-switch-scale"
              checked={useProfileAddress}
              onChange={e => setUseProfileAddress(e.target.checked)}
            />
            <Label className="bo-new-object-popover-form-menu-label ms-3">
              Use consumer&apos;s profile address
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <div className="mb-1">
            <Label className="bo-new-object-popover-form-menu-label">Address line 1</Label>
            <Input
              id="addressLine1"
              className={getDefaultInputStyle('addressLine1')}
              placeholder=""
              value={btMetadata.addressLine1.value}
              onChange={e => handleDefaultInputChangeEvent('addressLine1', e)}
              readOnly={useProfileAddress}
              disabled={useProfileAddress}
            />
          </div>
          <div className="mb-1">
            <Label className="bo-new-object-popover-form-menu-label">Address line 2</Label>
            <Input
              id="addressLine2"
              className={getDefaultInputStyle('addressLine2')}
              placeholder=""
              value={btMetadata.addressLine2.value}
              onChange={e => handleDefaultInputChangeEvent('addressLine2', e)}
              readOnly={useProfileAddress}
              disabled={useProfileAddress}
            />
          </div>
          <div className="d-flex justify-content-between mb-1">
            <div className="bo-new-object-card-form-city-input">
              <Label className="bo-new-object-popover-form-menu-label">City</Label>
              <Input
                id="city"
                className={getDefaultInputStyle('city')}
                placeholder=""
                value={btMetadata.city.value}
                onChange={e => handleDefaultInputChangeEvent('city', e)}
                readOnly={useProfileAddress}
                disabled={useProfileAddress}
              />
            </div>
            <div className="bo-new-object-card-form-state-input">
              <Label className="bo-new-object-popover-form-menu-label">State</Label>
              <Input
                id="state"
                className={getDefaultInputStyle('state')}
                placeholder=""
                value={btMetadata.state.value}
                maxLength={2}
                onChange={e => handleDefaultInputChangeEvent('state', e)}
                readOnly={useProfileAddress}
                disabled={useProfileAddress}
              />
            </div>
            <div className="bo-new-object-card-form-zip-input">
              <Label className="bo-new-object-popover-form-menu-label">Zip</Label>
              <Input
                id="state"
                className={getDefaultInputStyle('zip')}
                placeholder=""
                value={btMetadata.zip.value}
                maxLength={5}
                inputMode="numeric"
                onChange={e => handleDefaultInputChangeEvent('zip', e)}
                readOnly={useProfileAddress}
                disabled={useProfileAddress}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NewObjectBillingAddressForm;
