import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function ProspectEmplyeeIdForm({ prospectEmployeeId, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Employee ID:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="employeeId"
          id="employeeId"
          placeholder="Employee ID"
          value={prospectEmployeeId || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ProspectEmplyeeIdForm);
