import { Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react';

function ScheduledForm({ consumerIsScheduler }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Scheduled:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        {consumerIsScheduler ? (
          <span className="text-success">
            <FontAwesomeIcon className="me-2" size="lg" icon={faClock} color="green" />
            <strong>Yes</strong>
          </span>
        ) : (
          <span className="text-danger">
            <strong>No</strong>
          </span>
        )}
      </Col>
    </>
  );
}
export default memo(ScheduledForm);
