import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingCloseReasonForm({ fundingCloseReason }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Close Reason:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="closeReason"
          id="closeReason"
          value={fundingCloseReason || ''}
          readOnly
        />
      </Col>
    </>
  );
}
export default memo(FundingCloseReasonForm);
