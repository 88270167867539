import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTable } from '../../hooks/useTable';
import { Container, Row, Col } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import ProspectsFormController from './components/ProspectsFormController';
import ProspectsTableContainer from './components/ProspectsTableContainer';
import ProspectsUploadModal from './components/modals/ProspectsUploadModal';
import TablePaginationActions from '../../components/TablePaginationActions';
import Loading from '../../components/Loading';

function Prospects() {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginAggregator, apiOriginConsumer } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState('');
  const [prospectsStatus, setProspectsStatus] = useState({});
  const [showUploadProspectsModal, setShowUploadProspectsModal] = useState(false);
  const [aggregator, setAggregator] = useState(null);

  const aggregatorId = params.id;

  const {
    sortBy,
    sortOrder,
    page,
    rowsPerPage,
    totalRows,
    setTotalRows,
    rows,
    setRows,
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
  } = useTable({ initSortBy: 'createDate', initSortOrder: 'desc', initRowsPerPage: 25 });

  const getProspectsStatus = async ids => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_prospects_status`,
      params: ids,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setProspectsStatus(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAggregator = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get/${id}`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setAggregator(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getData = async () => {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/prospects/get`,
      method: 'GET',
      params: {
        sortBy,
        sortOrder,
        aggregatorId,
        searchText: search,
        page,
        perPage: rowsPerPage,
      },
    };

    const data = await makeRequest(config);
    const ids = Array.from(data, x => x.id).filter(onlyUnique);

    getProspectsStatus(ids);

    return data;
  };

  const getTotalRows = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/prospects/count`,
      method: 'GET',
      params: {
        aggregatorId,
        searchText: search,
      },
    };

    const data = await makeRequest(config);

    return data.count;
  };

  const getAll = async () => {
    setSpinner(true);
    try {
      const data = await getData();
      const total = await getTotalRows();

      if (data && total !== null) {
        setRows(data);
        setTotalRows(total);
      }
    } catch (error) {
      toast.error(`Faled getting data: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, rowsPerPage, sortBy, sortOrder]);

  useEffect(() => {
    getAggregator(aggregatorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ReactModal.setAppElement(document.getElementById('root'));

  const closeUploadProspectsModal = () => setShowUploadProspectsModal(false);
  const openUploadProspectsModal = () => setShowUploadProspectsModal(true);

  const deleteProspect = async id => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginAggregator}/prospect/delete`,
        method: 'POST',
        params: { id },
      };

      try {
        await makeRequest(config);

        await getAll();
        toast.success('Prospect deleted successfully');
      } catch (error) {
        setSpinner(false);
        toast.error(error.message);
      }
    }
  };

  if (!aggregator) {
    return <Spinner visible={spinner} text="Loading..." />;
  }

  return (
    <>
      <Spinner visible={spinner} text="">
        <Container className="mb-5">
          <Row className="mb-3">
            <Link to={`/aggregator/${aggregator.id}`}>&lt; Back</Link>
          </Row>
          <Row className="mb-4 align-items-center">
            <Col md={6}>
              <h5>{aggregator.name} prospects</h5>
            </Col>
          </Row>
          <ProspectsFormController
            aggregator={aggregator}
            openUploadProspectsModal={openUploadProspectsModal}
            setSpinner={setSpinner}
            setSearch={setSearch}
          />
          <ProspectsTableContainer
            sortOrder={sortOrder}
            sortBy={sortBy}
            sortHandler={sortHandler}
            rows={rows}
            prospectsStatus={prospectsStatus}
            deleteProspect={deleteProspect}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            TablePaginationActions={TablePaginationActions}
          />
        </Container>
      </Spinner>
      <ProspectsUploadModal
        isOpen={showUploadProspectsModal}
        close={closeUploadProspectsModal}
        aggregatorId={aggregatorId}
        getAll={getAll}
      />
    </>
  );
}

export default withAuthenticationRequired(Prospects, { onRedirecting: () => <Loading /> });
