import { memo } from 'react';
import { Col, Input } from 'reactstrap';

function ProspectAddressLine2Form({ prospectAddressLine2, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="addressLine2"
          id="addressLine2"
          placeholder="Address line 2"
          value={prospectAddressLine2 || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ProspectAddressLine2Form);
