import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useEnv } from '../../../context/env-context';
import { makeRequest } from '../../../utils/makeRequest';
import { toast } from 'react-toastify';

function AggregatorSaveButton({ aggregator, setSpinner }) {
  const navigate = useNavigate();
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const saveAggregator = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const {
      maxAllowedSignups,
      defaultFreeCreditTransfers,
      defaultFreeDebitTransfers,
      defaultTransferFee,
      defaultBpInitQuota,
      defaultBpMonthlyQuota,
      defaultBpCap,
      defaultPrePaydayClusterExtension,
      defaultPostPaydayClusterExtension,
    } = aggregator;

    const formatDefaultValue = value => (value === null ? null : value);

    const config = {
      token,
      url: `${apiOriginAggregator}/save`,
      method: 'POST',
      data: JSON.stringify({
        ...aggregator,
        maxAllowedSignups: Number(maxAllowedSignups),
        defaultFreeCreditTransfers: formatDefaultValue(defaultFreeCreditTransfers),
        defaultFreeDebitTransfers: formatDefaultValue(defaultFreeDebitTransfers),
        defaultTransferFee: formatDefaultValue(defaultTransferFee),
        defaultBpInitQuota: formatDefaultValue(defaultBpInitQuota),
        defaultBpMonthlyQuota: formatDefaultValue(defaultBpMonthlyQuota),
        defaultBpCap: formatDefaultValue(defaultBpCap),
        defaultPrePaydayClusterExtension: formatDefaultValue(defaultPrePaydayClusterExtension),
        defaultPostPaydayClusterExtension: formatDefaultValue(defaultPostPaydayClusterExtension),
      }),
    };

    try {
      await makeRequest(config);

      setSpinner(false);
      toast.success('Aggregator successfully saved!');
      navigate('/aggregators');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Row className="mb-2 align-items-center">
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Button className="mt-5 bo-button" onClick={saveAggregator}>
          Save
        </Button>
      </Col>
    </Row>
  );
}

export default AggregatorSaveButton;
