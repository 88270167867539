import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportDetailedSignupFunnel({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      estTime: {
        header: 'Time (EST)',
        width: 23,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      ip: {
        header: 'IP Address',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      ipLocation: {
        header: 'IP Location',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      aggregatorName: {
        header: 'Aggregator',
        width: 30,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer ID',
        width: 20,
        style: { alignment: { horizontal: 'right' } },
      },
      name: {
        header: 'Name',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      email: {
        header: 'Email',
        width: 32,
        style: { alignment: { horizontal: 'left' } },
      },
      openLink: {
        header: 'Open Link',
        width: 28,
        style: { alignment: { horizontal: 'left' } },
      },
      page1: {
        header: 'Details Page 1',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      page2: {
        header: 'Details Page 2',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      verification: {
        header: 'Email Verification',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      consumer: {
        header: 'Consumer Created',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      account: {
        header: 'Account Linked',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      sessionId: {
        header: 'Session ID',
        width: 40,
        style: { alignment: { horizontal: 'left' } },
      },
      timestamp: {
        header: 'timestamp',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      eventName: {
        header: 'eventName',
        headerBgColor: '#DADADA',
        width: 25,
        style: { alignment: { horizontal: 'left' } },
      },
      view_name: {
        header: 'view_name',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      institution_id: {
        header: 'institution_id',
        headerBgColor: '#DADADA',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      institution_name: {
        header: 'institution_name',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      institution_search_query: {
        header: 'institution_search_query',
        headerBgColor: '#DADADA',
        width: 28,
        style: { alignment: { horizontal: 'left' } },
      },
      error_code: {
        header: 'error_code',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      error_message: {
        header: 'error_message',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      error_type: {
        header: 'error_type',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      exit_status: {
        header: 'exit_status',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },

      request_id: {
        header: 'request_id',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      link_session_id: {
        header: 'link_session_id',
        headerBgColor: '#DADADA',
        width: 44,
        style: { alignment: { horizontal: 'left' } },
      },
      mfa_type: {
        header: 'mfa_type',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      selection: {
        header: 'selection',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      brand_name: {
        header: 'brand_name',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      match_reason: {
        header: 'match_reason',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      routing_number: {
        header: 'routing_number',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      account_number_mask: {
        header: 'account_number_mask',
        headerBgColor: '#DADADA',
        width: 26,
        style: { alignment: { horizontal: 'left' } },
      },
      view_variant: {
        header: 'view_variant',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
      is_update_mode: {
        header: 'is_update_mode',
        headerBgColor: '#DADADA',
        width: 24,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const restructuredReport = useMemo(() => {
    if (report.length > 0) {
      const nextAccountEvents = [];

      const newReport = report.map((r, idx) =>
        Object.entries(r).reduce((acc, [key, value]) => {
          let newValue = value;

          if (key === 'ipLocation') {
            newValue =
              value !== null && (value.country || value.city)
                ? `${value.country} / ${value.city}`
                : null;
          } else if (key === 'accountEvents' && value.length > 0) {
            if (value.length > 1) {
              nextAccountEvents.push({
                idx,
                value: value.slice(1).map(x => ({
                  eventName: x.eventName,
                  ...x.metadata,
                })),
              });
            }

            return {
              ...acc,
              eventName: value[0].eventName,
              ...value[0].metadata,
            };
          }

          return { ...acc, [key]: newValue };
        }, {}),
      );

      let nextAccountEventsCount = 0;

      nextAccountEvents.forEach(({ value, idx }) => {
        newReport.splice(
          idx + 1 + nextAccountEventsCount,
          0,
          ...value.map(x => ({
            ...x,
            groupParentIndex: idx + nextAccountEventsCount,
          })),
        );

        nextAccountEventsCount += value.length;
      });

      return newReport.map(r => ({ ...r, uuid: crypto.randomUUID() }));
    }

    return [];
  }, [report]);

  const groups = useMemo(() => {
    const newGroups = [];

    restructuredReport.forEach((rep, childIndex) => {
      if (rep.groupParentIndex !== null && rep.groupParentIndex !== undefined) {
        const foundIndex = newGroups.findIndex(g => rep.groupParentIndex === g.parentIndex);

        if (foundIndex !== -1) {
          newGroups[foundIndex] = {
            parentIndex: rep.groupParentIndex,
            childIndices: [...newGroups[foundIndex].childIndices, childIndex],
          };
        } else {
          newGroups.push({
            parentIndex: rep.groupParentIndex,
            childIndices: [childIndex],
          });
        }
      }
    });

    return newGroups;
  }, [restructuredReport]);

  const endRow = restructuredReport.length + 1;

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Detailed Signup Funnel', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = restructuredReport.map(r => ({
      ...r,
      estTime: r.estTime ? formatExcelDate(new Date(r.estTime)) : null,
      timestamp: r.timestamp ? formatExcelDate(new Date(r.timestamp)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };

    headerRow.eachCell((cell, colNumber) => {
      if (colNumber >= 14) {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DADADA' },
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
        };
      }
    });

    groups.forEach(group => {
      group.childIndices.forEach(idx => {
        worksheet.getRow(idx + 2).outlineLevel = 1;
      });
    });

    worksheet.properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false,
    };

    worksheet.addConditionalFormatting({
      ref: `G2:L${endRow}`,
      rules: [
        {
          type: 'expression',
          formulae: [`IF(INDEX($A$1:$L$${endRow}, ROW(), COLUMN()) = "ok", TRUE, FALSE)`],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FF008000' },
            },
            font: {
              color: { argb: 'FF008000' },
            },
          },
        },
        {
          type: 'expression',
          formulae: [
            `IF(AND(ISTEXT(INDEX($A$1:$L$${endRow}, ROW(), COLUMN())), ` +
              `INDEX($A$1:$L$${endRow}, ROW(), COLUMN()) <> "ok"), TRUE, FALSE)`,
          ],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFFF0000' },
            },
            font: {
              color: { argb: 'FFFFFFFF' },
            },
          },
        },
      ],
    });

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [restructuredReport, reportFileName, tableColumns, groups, endRow]);

  const formattedReport = useMemo(() => {
    if (restructuredReport.length > 0) {
      return restructuredReport.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [restructuredReport, tableColumns]);

  const formatCellConditionally = useCallback(({ reportItem, tableColumnKey }) => {
    if (
      (tableColumnKey === 'openLink' ||
        tableColumnKey === 'page1' ||
        tableColumnKey === 'page2' ||
        tableColumnKey === 'verification' ||
        tableColumnKey === 'consumer' ||
        tableColumnKey === 'account') &&
      reportItem[tableColumnKey] === 'ok'
    ) {
      return ' bg-success text-success';
    }

    if (
      (tableColumnKey === 'openLink' ||
        tableColumnKey === 'page1' ||
        tableColumnKey === 'page2' ||
        tableColumnKey === 'verification' ||
        tableColumnKey === 'consumer' ||
        tableColumnKey === 'account') &&
      reportItem[tableColumnKey] &&
      reportItem[tableColumnKey] !== 'ok'
    ) {
      return ' bg-danger text-white';
    }

    return '';
  }, []);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        groups={groups}
        rowKey="uuid"
        formatCellConditionally={formatCellConditionally}
      />
    </>
  );
}

export default ReportDetailedSignupFunnel;
