import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

function LetterIcon({ style }) {
  return (
    <Svg viewBox="0 0 149 149" fill="none" style={style}>
      <Rect width="148.605" height="148.605" rx={40} ry={40} fill="#350569" />
      <G clip-path="url(#clip0_119_339)">
        <Path
          // eslint-disable-next-line max-len
          d="M113.145 46.0059H37.4623C34.2471 46.0059 31.6406 48.584 31.6406 51.7643V97.8318C31.6406 101.012 34.2471 103.59 37.4623 103.59H113.145C116.36 103.59 118.966 101.012 118.966 97.8318V51.7643C118.966 48.584 116.36 46.0059 113.145 46.0059Z"
          fill="#350569"
        />
        <Path
          // eslint-disable-next-line max-len
          d="M113.145 43.127H37.463C35.147 43.127 32.9259 44.037 31.2882 45.6569C29.6505 47.2767 28.7305 49.4738 28.7305 51.7646V97.8321C28.7305 100.123 29.6505 102.32 31.2882 103.94C32.9259 105.56 35.147 106.47 37.463 106.47H113.145C115.461 106.47 117.683 105.56 119.32 103.94C120.958 102.32 121.878 100.123 121.878 97.8321V51.7646C121.878 49.4738 120.958 47.2767 119.32 45.6569C117.683 44.037 115.461 43.127 113.145 43.127ZM97.7323 78.5269C97.1755 78.0552 96.4592 77.8088 95.7266 77.8367C94.994 77.8647 94.2991 78.1651 93.7807 78.6779C93.2623 79.1907 92.9586 79.8781 92.9303 80.6027C92.902 81.3273 93.1512 82.0358 93.628 82.5866L111.937 100.711H38.671L56.9803 82.5866C57.4572 82.0358 57.7064 81.3273 57.6781 80.6027C57.6498 79.8781 57.3461 79.1907 56.8277 78.6779C56.3093 78.1651 55.6144 77.8647 54.8818 77.8367C54.1492 77.8088 53.4329 78.0552 52.876 78.5269L34.5522 96.6372V51.8798L65.7711 75.9068C68.503 77.9894 71.8551 79.1188 75.3042 79.1188C78.7533 79.1188 82.1053 77.9894 84.8372 75.9068L116.056 51.8798V96.6372L97.7323 78.5269ZM40.1265 48.8854H110.482L81.2569 71.3577C79.549 72.6535 77.4566 73.3558 75.3042 73.3558C73.1517 73.3558 71.0594 72.6535 69.3515 71.3577L40.1265 48.8854Z"
          fill="#51E5FF"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_119_339">
          <Rect
            width="93.1474"
            height="92.1349"
            fill="white"
            transform="translate(28.7305 28.7305)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default LetterIcon;
