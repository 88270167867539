import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import StyledTableCell from '../../../components/StyledTableCell';
import { Button } from 'reactstrap';

function ProspectsTableContainer({
  sortOrder,
  sortBy,
  sortHandler,
  rows,
  prospectsStatus,
  deleteProspect,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
}) {
  return (
    <>
      <TableContainer className="mt-5">
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => sortHandler('name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'email'}
                  direction={sortOrder}
                  onClick={() => sortHandler('email')}
                >
                  Email
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'createDate'}
                  direction={sortOrder}
                  onClick={() => sortHandler('createDate')}
                >
                  CreateDate
                </TableSortLabel>
              </StyledTableCell>

              <StyledTableCell>
                <TableSortLabel />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map(row => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <StyledTableCell align="left">
                    <Link to={`/prospect/${row.id}`}>{`${row.firstName} ${row.lastName}`}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">
                    {prospectsStatus[row.id] != null ? prospectsStatus[row.id] : 'Loading...'}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {moment.utc(row.createDate).local().format('MMM DD, YYYY H:mm (UTCZ)')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Button
                      className="bo-button-inline-link bo-inline-badge m-0 p-0"
                      color="link"
                      onClick={() => deleteProspect(row.id)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={5} align="center">
                  No Data
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default ProspectsTableContainer;
