import { useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function ReportsPeriodModalForm({ close, createReport }) {
  const [period, setPeriod] = useState('TODAY');

  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  const handleCreate = () => {
    let from = moment();

    let to = moment();

    if (period === 'YESTERDAY') {
      from = moment().subtract(1, 'days');
      to = moment().subtract(1, 'days');
    }

    if (period === 'THIS_WEEK') {
      from = moment().startOf('isoWeek');
    }

    if (period === 'THIS_MONTH') {
      from = moment().startOf('month');
    }

    if (period === 'THIS_YEAR') {
      from = moment().startOf('year');
    }

    if (period === 'ALWAYS') {
      from = moment('2023-01-01');
    }

    if (period === 'SELECT_DATES') {
      from = moment(dateFrom);
      to = moment(dateTo);
    }

    createReport({
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
    });
  };

  return (
    <div className="bo-consumer-economics-modal-form">
      <div>
        <Row className="align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Period:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <Input
              className="bo-filter bo-w-250"
              type="select"
              name="period"
              id="period"
              placeholder="period"
              noValidate
              value={period}
              onChange={e => setPeriod(e.target.value)}
            >
              <option value="TODAY">Today</option>
              <option value="YESTERDAY">Yesterday</option>
              <option value="THIS_WEEK">This Week</option>
              <option value="THIS_MONTH">This Month</option>
              <option value="THIS_YEAR">This Year</option>
              <option value="ALWAYS">Always</option>
              <option value="SELECT_DATES">Select Dates</option>
            </Input>
          </Col>
        </Row>
        {period === 'SELECT_DATES' && (
          <>
            <Row className="mt-2 mb-2 align-items-center">
              <Col md={4} className="bo-same-line">
                <Label>From:</Label>
              </Col>
              <Col md={8} className="bo-same-line">
                <DatePicker
                  className="bo-input bo-date-picker bo-w-250"
                  onChange={e => setDateFrom(e)}
                  selected={new Date(dateFrom)}
                  dateFormat="yyyy-MM-dd"
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2 align-items-center">
              <Col md={4} className="bo-same-line">
                <Label>To:</Label>
              </Col>
              <Col md={8} className="bo-same-line">
                <DatePicker
                  className="bo-input bo-date-picker bo-w-250"
                  onChange={e => setDateTo(e)}
                  selected={new Date(dateTo)}
                  dateFormat="yyyy-MM-dd"
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      <Row>
        <Col className="text-center mt-5">
          <Button className="bo-button" onClick={handleCreate}>
            Create
          </Button>
          <Button className="ms-3 bo-button" onClick={close}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ReportsPeriodModalForm;
