import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner } from '../../components/Spinner';
import ProfileFormController from './components/ProfileFormController';

export function Profile() {
  const [spinner, setSpinner] = useState(false);

  return (
    <Spinner visible={spinner} text="">
      <Container className="flex-grow-1 mb-5 h-100">
        <Row className="mt-1">
          <Col>
            <p className="bo-header">User Profile</p>
          </Col>
        </Row>
        <ProfileFormController setSpinner={setSpinner} />
      </Container>
    </Spinner>
  );
}

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
