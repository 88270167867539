import { Row, Col, Button } from 'reactstrap';
import { useEnv } from '../../../context/env-context';
import { useAuth } from '../../../hooks/useAuth';
import { makeRequest } from '../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';

function FundingActionRow({ fundingId, setSpinner, getFunding, openRepaymentModal }) {
  const navigate = useNavigate();
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const deletelFunding = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/delete`,
        method: 'POST',
        data: JSON.stringify({ fundingId }),
      };

      try {
        await makeRequest(config);

        toast.success('Advance successfully deleted!');
        navigate('/fundings');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const cancelFunding = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/cancel_funding`,
        method: 'POST',
        data: JSON.stringify({ fundingId }),
      };

      try {
        await makeRequest(config);

        toast.success('Advance successfully canceled!');
        getFunding(fundingId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Row className="mb-2 mt-5 align-items-center">
      <Col md={12} className="bo-same-line">
        <Button className="bo-button" onClick={() => cancelFunding()}>
          Cancel Advance
        </Button>
        <Button className="ms-3 bo-button" onClick={() => deletelFunding()}>
          Delete Advance
        </Button>
        <Button className="ms-3 bo-button" onClick={() => openRepaymentModal({ topup: false })}>
          Manual Repayment
        </Button>
        <Button className="ms-3 bo-button" onClick={() => openRepaymentModal({ topup: true })}>
          Manual Top-up
        </Button>
      </Col>
    </Row>
  );
}

export default memo(FundingActionRow);
