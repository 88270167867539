import { Row, Col, Button } from 'reactstrap';
import FundingsAllAggregatorsForm from './forms/FundingsAllAggregatorsForm';
import FundingsStatusFilterForm from './forms/FundingsStatusFilterForm';
import FundingsSearchForm from './forms/FundingsSearchForm';
import FundingsRepaymentModeForm from './forms/FundingsRepaymentModeForm';
import FundingsRedFlagsFilterForm from './forms/FundingsRedFlagsFilterForm';
import FundingsQuickAccessForm from './forms/FundingsQuickAccessForm';

function FundingsFormController({
  spinner,
  selectedAggregatorFilter,
  changeAggregatorFilter,
  selectedConsumerFilter,
  clearConsumerFilter,
  selectedFundingIdsFilter,
  clearFundingIdsFilter,
  statusFilter,
  changeStatusFilter,
  flagsFilter,
  changeFlagsFilter,
  search,
  searchKey,
  modeFilter,
  changeModeFilter,
}) {
  return (
    <Row className="align-items-center">
      {selectedFundingIdsFilter.fundingIds || selectedConsumerFilter.consumerId ? (
        <Col md={10} className="bo-same-line">
          <div className="mt-1 ms-2 d-flex align-items-center">
            <strong className="bo-text-bold">Filtered:</strong>
            <div className="bo-text ms-2">
              {selectedFundingIdsFilter.message || selectedConsumerFilter.message}
            </div>
            <Button
              className="bo-link py-0 px-2 ms-3 border-0"
              color="link"
              disabled={spinner}
              onClick={
                selectedFundingIdsFilter.fundingIds
                  ? () => clearFundingIdsFilter()
                  : () => clearConsumerFilter()
              }
            >
              Cancel Filter
            </Button>
          </div>
        </Col>
      ) : (
        <div className="d-xl-flex align-items-center">
          <div className="d-flex flex-wrap align-items-center gap-2 mb-2">
            <FundingsAllAggregatorsForm
              selectedAggregatorFilter={selectedAggregatorFilter}
              changeAggregatorFilter={changeAggregatorFilter}
              spinner={spinner}
            />
            <FundingsStatusFilterForm
              statusFilter={statusFilter}
              changeStatusFilter={changeStatusFilter}
              spinner={spinner}
            />
            <FundingsRepaymentModeForm
              modeFilter={modeFilter}
              changeModeFilter={changeModeFilter}
              spinner={spinner}
            />
            <FundingsRedFlagsFilterForm
              flagsFilter={flagsFilter}
              changeFlagsFilter={changeFlagsFilter}
              spinner={spinner}
            />
          </div>
          <div className="ms-auto d-flex gap-2 mb-2">
            <FundingsQuickAccessForm />
            <FundingsSearchForm search={search} searchKey={searchKey} spinner={spinner} />
          </div>
        </div>
      )}
    </Row>
  );
}

export default FundingsFormController;
