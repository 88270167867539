import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';

function NewObjectPopoverNameForm({ consumerFirstName, consumerLastName, updateConsumer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [fields, setFields] = useState({
    firstName: consumerFirstName,
    lastName: consumerLastName,
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setFields({
      firstName: consumerFirstName,
      lastName: consumerLastName,
    });
  };

  const handleSave = async () => {
    setSpinner(true);
    await updateConsumer(fields);
    setSpinner(false);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        id="popover_name_form"
        onClick={handleToggle}
        className="bo-new-object-popover-form-toggle pb-1 bo-new-object-popover-toggle-form-name"
      >
        <p className="bo-new-object-popover-form-toggle-title">
          {`${consumerFirstName} ${consumerLastName}`}
        </p>
        <div
          className={`bo-new-object-popover-form-toggle-icon ${
            isOpen ? 'bo-new-object-popover-form-toggle-icon-active' : ''
          }`}
        >
          <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
        </div>
      </Button>
      <Popover
        target="popover_name_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner}>
            <Label className="bo-new-object-popover-form-menu-label">First Name</Label>
            <Input
              type="text"
              className="bo-new-object-popover-form-menu-input bo-w-180"
              value={fields.firstName}
              onChange={e => setFields(prev => ({ ...prev, firstName: e.target.value }))}
            />
            <Label className="bo-new-object-popover-form-menu-label mt-2">Last Name</Label>
            <Input
              type="text"
              className="bo-new-object-popover-form-menu-input bo-w-180"
              value={fields.lastName}
              onChange={e => setFields(prev => ({ ...prev, lastName: e.target.value }))}
            />
            <div className="mt-2 d-flex justify-content-between">
              <Button className="bo-new-object-button" onClick={handleSave}>
                Save
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverNameForm;
