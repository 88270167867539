import { Col, Label, Input } from 'reactstrap';
import { memo } from 'react';

function StatusForm({ consumerStatus, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Status:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-filter"
          type="select"
          name="status"
          id="status"
          noValidate
          value={consumerStatus}
          onChange={changeValue}
        >
          <option value="PENDING">Pending</option>
          <option value="MONITOR_ONLY">Monitor Only</option>
          <option value="ACTIVE">Active</option>
          <option value="SUSPENDED">Suspended</option>
          <option value="TERMINATED">Terminated</option>
        </Input>
      </Col>
    </>
  );
}

export default memo(StatusForm);
