import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { Badge, Button } from 'reactstrap';
import StyledTableCell from '../../../components/StyledTableCell';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { REPORTS_DISPLAY_NAMES, REPORTS_STATUS_BADGE } from '../../../constants/reports';

function ReportsTableContainer({
  rows,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  deleteReport,
  showReport,
}) {
  return (
    <>
      <TableContainer className="mt-5">
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Details</StyledTableCell>
              <StyledTableCell align="left">Created at</StyledTableCell>
              <StyledTableCell align="left">Created by</StyledTableCell>
              <StyledTableCell align="left" />
              <StyledTableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map(row => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <StyledTableCell align="left" className="py-2">
                    {REPORTS_DISPLAY_NAMES[row.type]}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="py-2">
                    <Badge
                      className="bo-inline-badge"
                      color={REPORTS_STATUS_BADGE[row.status]?.color}
                    >
                      {REPORTS_STATUS_BADGE[row.status]?.text}
                    </Badge>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="py-2">
                    {row.details}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="py-2">
                    {row.createDate
                      ? moment.utc(row.createDate).local().format('MMM DD, YYYY H:mm (UTCZ)')
                      : 'N/A'}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="py-2">
                    {row.user}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="py-2">
                    <Button
                      className="bo-button-inline-link bo-inline-badge"
                      color="link"
                      onClick={() => deleteReport(row.id)}
                      disabled={row.status !== 'READY' && row.status !== 'FAILED'}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="px-2 py-2">
                    <Button
                      className="bo-button-inline-link bo-inline-badge"
                      color="link"
                      onClick={() => showReport(row.id)}
                      disabled={row.status !== 'READY'}
                    >
                      Show
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={7} align="center">
                  No Data
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => handleChangePage('reports', e, p)}
        onRowsPerPageChange={e => handleChangeRowsPerPage('reports', e)}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default ReportsTableContainer;
