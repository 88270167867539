import { useState } from 'react';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { useReportDimensions } from '../../../../hooks/useReportDimensions';
import ReportActionRow from '../ReportActionRow';
import AggregatorMonthlyDoc from '../pdf-documents/AggregatorMonthlyDoc/AggregatorMonthlyDoc';
import { Input } from 'reactstrap';
// excel
import * as ExcelJS from 'exceljs';
// export
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { string_to_slug } from '../../../../utils/slug';

function ReportAggregatorMonthly({ reportTitle, reportDetails, reportId, setSpinner, report }) {
  const [activeItem, setActiveItem] = useState(report[0]);

  const { height, width } = useReportDimensions();

  const exportExcel = async () => {
    setSpinner(true);

    try {
      const zip = new JSZip();

      const pdfList = await Promise.all(
        report.map(async item => ({
          fileName: `${string_to_slug(item.aggregatorName)}-${item.year}-${item.month}.pdf`,
          blob: await pdf(<AggregatorMonthlyDoc item={item} />).toBlob(),
        })),
      );

      pdfList.forEach(x => {
        zip.file(x.fileName, x.blob);
      });

      const excelList = await Promise.all(
        report.map(async item => {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Aggregator Monthly', {
            views: [{ state: 'frozen', ySplit: 1 }],
          });

          worksheet.columns = [
            {
              key: 'aggregatorConsumerId',
              header: 'User ID',
              width: 18,
              style: { alignment: { horizontal: 'right' } },
            },
            {
              key: 'employeeId',
              header: 'Employee ID',
              width: 18,
              style: { alignment: { horizontal: 'right' } },
            },
            {
              key: 'firstName',
              header: 'First Name',
              width: 16,
              style: { alignment: { horizontal: 'left' } },
            },
            {
              key: 'lastName',
              header: 'Last Name',
              width: 16,
              style: { alignment: { horizontal: 'left' } },
            },
            {
              key: 'billableDays',
              header: 'Billable Days',
              width: 18,
              style: { alignment: { horizontal: 'right' } },
            },
            {
              key: 'bill',
              header: 'Cost',
              width: 18,
              style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
            },
          ];

          worksheet.addRows(
            Object.values(item.consumers).map(x => ({
              ...x,
              aggregatorConsumerId: `${`0000${item.aggregatorId}`.slice(
                -4,
              )}-${`0000000${x.consumerId}`.slice(-7)}`,
            })),
          );

          const headerRow = worksheet.getRow(1);

          headerRow.alignment = { horizontal: 'left' };
          headerRow.font = { bold: true };
          headerRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' },
          };

          const dataExcel = await workbook.xlsx.writeBuffer();

          return {
            fileName: `${string_to_slug(item.aggregatorName)}-${item.year}-${
              item.month
            }-users.xlsx`,
            blob: new Blob([dataExcel], {
              // eslint-disable-next-line max-len
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            }),
          };
        }),
      );

      excelList.forEach(x => {
        zip.file(x.fileName, x.blob);
      });

      const blob = await zip.generateAsync({ type: 'blob' });

      saveAs(blob, 'aggregator-monthly.zip');
    } catch (err) {
      console.error(err);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        renderSelect={() =>
          report.length > 1 ? (
            <Input
              className="bo-filter bo-w-250"
              type="select"
              value={activeItem.aggregatorId}
              onChange={e => {
                setActiveItem(report.find(x => x?.aggregatorId === Number(e.target.value)));
              }}
            >
              {report.map(agg => (
                <option key={agg.aggregatorId} value={agg.aggregatorId}>
                  {agg.aggregatorName}
                </option>
              ))}
            </Input>
          ) : null
        }
        exportExcel={exportExcel}
      />
      <PDFViewer width={width} height={height} showToolbar={false}>
        <AggregatorMonthlyDoc item={activeItem} />
      </PDFViewer>
    </>
  );
}

export default ReportAggregatorMonthly;
