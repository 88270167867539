import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AggregatorAddressLine1Form({ aggregatorAddressLine1, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Address:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="addressLine1"
          id="addressLine1"
          placeholder="Address line 1"
          value={aggregatorAddressLine1 || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorAddressLine1Form);
