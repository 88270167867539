import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { toast } from 'react-toastify';

function DashboardFunnel({ aggregators, getAggregators }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [report, setReport] = useState(null);
  const [aggregatorId, setAggregatorId] = useState(null);

  const [spinner, setSpinner] = useState(false);

  const getFunnelReport = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_funnel_report`,
      method: 'GET',
      params: { aggregatorId },
    };

    try {
      const data = await makeRequest(config);

      setReport(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const refreshReport = () => {
    getFunnelReport();
    getAggregators();
  };

  useEffect(() => {
    refreshReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatorId]);

  const changeAggregatorFilter = e => setAggregatorId(e.target.value);

  return (
    <Card className="mb-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Signup Funnel Summary
        <div className="d-flex align-items-center">
          <Input
            className="bo-filter w-100 ms-3"
            type="select"
            name="aggregatorFilter"
            id="aggregatorFilter"
            placeholder="filter"
            noValidate
            value={aggregatorId}
            onChange={changeAggregatorFilter}
          >
            <option value="">All Standard Aggregators</option>
            {aggregators.map(agg => (
              <option key={agg.id} value={agg.id}>
                {agg.name}
              </option>
            ))}
          </Input>
          <Button className="bo-button-inline-link" color="link" onClick={() => refreshReport()}>
            Refresh
          </Button>
        </div>
      </CardHeader>
      <CardBody className="bo-min-h-100">
        <Spinner visible={spinner} text="">
          {report && (
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" />
                    <StyledTableCell align="center">Opened Link</StyledTableCell>
                    <StyledTableCell align="center">Consumer Created</StyledTableCell>
                    <StyledTableCell align="center">Bank Account Linked</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>Today</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.today.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.today.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.today.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>Yesterday</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.yesterday.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.yesterday.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.yesterday.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>This Week</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisWeek.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisWeek.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisWeek.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>This Month</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisMonth.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisMonth.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisMonth.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>This Year</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisYear.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisYear.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.thisYear.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1}>
                    <StyledTableCell align="left">
                      <strong>Total</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.total.OPEN_SIGNUP_LINK}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.total.CONSUMER_CREATED}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {report.total.ACCOUNT_LINK_COMPLETED}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Spinner>
      </CardBody>
    </Card>
  );
}

export default DashboardFunnel;
