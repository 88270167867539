import { Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react';

function ConsumerRedFlags({ consumerFlags }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Red Flags:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <div className="bo-border-container w-100">
          {consumerFlags.map(flag => (
            <div key={flag.type}>
              <FontAwesomeIcon icon={faFlag} color="red" />
              <span className="ms-3 text-danger">{flag.type}</span>
            </div>
          ))}
        </div>
      </Col>
    </>
  );
}

export default memo(ConsumerRedFlags);
