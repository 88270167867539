import { Input } from 'reactstrap';

const REPAYMENT_MODE_FILTER = {
  NEXT_PAYDAY: 'Next Payday',
  'FORCE,IMMEDIATE': 'Force/Immediate',
  CUSTOM: 'Custom',
  MANUAL: 'Manual',
  REPAID: 'Repaid',
};

function FundingsRepaymentModeForm({ modeFilter, changeModeFilter, spinner }) {
  return (
    <Input
      className="bo-filter"
      type="select"
      name="modeFilter"
      id="modeFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={modeFilter}
      onChange={e => changeModeFilter('fundings', e)}
    >
      <option value=""> All Repayments Mode</option>
      {Object.entries(REPAYMENT_MODE_FILTER).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </Input>
  );
}

export default FundingsRepaymentModeForm;
