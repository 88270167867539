import { memo, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { Badge, Button, Col, Input, Label } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function PhoneNumberForm({
  consumerId,
  consumerPhoneCc,
  consumerPhoneNdc,
  consumerPhoneSn,
  consumerIsPhoneVerified,
  setSpinner,
  phoneFields,
  setPhoneFields,
  phoneEditMode,
  setPhoneEditMode,
  openPhoneVerificationModal,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    setPhoneFields({
      phoneCc: consumerPhoneCc,
      phoneNdc: consumerPhoneNdc,
      phoneSn: consumerPhoneSn,
    });
  }, [consumerPhoneCc, consumerPhoneNdc, consumerPhoneSn, setPhoneFields]);

  const onChange = e => setPhoneFields({ ...phoneFields, [e.target.name]: e.target.value });

  const update = () => setPhoneEditMode(true);

  const cancel = () => {
    setPhoneFields({
      phoneCc: consumerPhoneCc,
      phoneNdc: consumerPhoneNdc,
      phoneSn: consumerPhoneSn,
    });
    setPhoneEditMode(false);
  };

  const save = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/init_phone_verification`,
      method: 'POST',
      data: JSON.stringify({ consumerId, ...phoneFields }),
    };

    try {
      await makeRequest(config);
      openPhoneVerificationModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Phone number:</Label>
      </Col>
      <Col md={4} className="bo-same-line justify-content-between">
        <div className="d-flex align-items-center">
          <NumericFormat
            className="bo-input bo-w-60 me-1"
            id="phoneCc"
            name="phoneCc"
            value={phoneFields.phoneCc || ''}
            customInput={Input}
            onChange={onChange}
            isAllowed={values => values.formattedValue.length <= 3}
            allowNegative={false}
            decimalScale={0}
            readOnly={!phoneEditMode}
            disabled={!phoneEditMode}
          />
          <NumericFormat
            className="bo-input bo-w-60 me-1"
            id="phoneNdc"
            name="phoneNdc"
            value={phoneFields.phoneNdc || ''}
            customInput={Input}
            onChange={onChange}
            isAllowed={values => values.formattedValue.length <= 3}
            allowNegative={false}
            decimalScale={0}
            readOnly={!phoneEditMode}
            disabled={!phoneEditMode}
          />
          <NumericFormat
            className="bo-input bo-w-150 me-1"
            id="phoneSn"
            name="phoneSn"
            value={phoneFields.phoneSn || ''}
            customInput={Input}
            onChange={onChange}
            isAllowed={values => values.formattedValue.length <= 12}
            allowNegative={false}
            decimalScale={0}
            readOnly={!phoneEditMode}
            disabled={!phoneEditMode}
          />
          {!phoneEditMode && (
            <Badge
              color={consumerIsPhoneVerified ? 'success' : 'warning'}
              className="bo-inline-badge"
            >
              {consumerIsPhoneVerified ? 'Verified' : 'Not verified'}
            </Badge>
          )}
        </div>
        {!phoneEditMode ? (
          <Button className="bo-button-inline-link p-0 m-0" color="link" onClick={update}>
            Update
          </Button>
        ) : (
          <div className="d-flex">
            <Button className="bo-button-inline-link p-0 m-0 me-2" color="link" onClick={cancel}>
              Cancel
            </Button>
            <Button
              className="bo-button-inline-link p-0 m-0"
              color="link"
              disabled={!phoneFields.phoneCc || !phoneFields.phoneNdc || !phoneFields.phoneSn}
              onClick={save}
            >
              Save
            </Button>
          </div>
        )}
      </Col>
    </>
  );
}

export default memo(PhoneNumberForm);
