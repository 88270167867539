import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function ProspectAddressLine1Form({ prospectAddressLine1, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Address:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="addressLine1"
          id="addressLine1"
          placeholder="Address line 1"
          value={prospectAddressLine1 || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ProspectAddressLine1Form);
