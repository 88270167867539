import {
  faDollarSign,
  faFileCode,
  faFileCsv,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'reactstrap';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { useState } from 'react';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const NEW_OBJECT_DOWNLOAD_BUTTONS = {
  excel: {
    tooltip: 'Download Excel Report',
    icon: faFileExcel,
    endpoint: 'reports/profile',
    fileName: consumerId => `report_${consumerId}.xlsx`,
    responseType: 'blob',
  },
  json: {
    tooltip: 'Download Profile JSON',
    icon: faFileCode,
    endpoint: 'get_profile_json',
    fileName: consumerId => `profile_${consumerId}.json`,
    stringifyResponse: true,
  },
  csv: {
    tooltip: 'Download Transactions CSV',
    icon: faFileCsv,
    endpoint: 'download_transactions',
    type: 'main',
    fileName: consumerId => `transactions_${consumerId}.csv`,
  },
  historical: {
    tooltip: 'Download Historical Balances',
    icon: faDollarSign,
    endpoint: 'reports/historical_balances',
    fileName: consumerId => `historical_balances_${consumerId}.xlsx`,
    responseType: 'blob',
  },
};

function NewObjectProfileActionSection({ consumerId }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [tooltipOpen, setTooltipOpen] = useState(
    Object.keys(NEW_OBJECT_DOWNLOAD_BUTTONS).reduce((acc, val) => ({ ...acc, [val]: false }), {}),
  );
  const [loading, setLoading] = useState(
    Object.keys(NEW_OBJECT_DOWNLOAD_BUTTONS).reduce((acc, val) => ({ ...acc, [val]: false }), {}),
  );

  const downloadFile = async ({
    key,
    endpoint,
    params,
    fileName,
    stringifyResponse,
    responseType,
  }) => {
    setLoading(prev => ({ ...prev, [key]: true }));

    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }
      const config = {
        token,
        url: `${apiOriginMonitor}/${endpoint}`,
        responseType,
        method: 'GET',
        params,
      };

      let data = await makeRequest(config);

      if (data) {
        if (stringifyResponse) {
          data = JSON.stringify(data);
        }

        const binaryData = [];

        binaryData.push(data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  return (
    <div className="mb-2 d-flex justify-content-between align-items-center">
      <div className="d-flex ms-1">
        {Object.entries(NEW_OBJECT_DOWNLOAD_BUTTONS).map(([key, value]) => (
          <div key={key} className="bo-w-30">
            <Button
              id={key}
              className="bo-new-object-inline-button"
              disabled={loading[key]}
              onClick={() =>
                downloadFile({
                  key,
                  endpoint: value.endpoint,
                  params: { consumerId, type: value.type },
                  fileName: value.fileName(consumerId),
                  responseType: value.responseType,
                  stringifyResponse: value.stringifyResponse,
                })
              }
            >
              {loading[key] ? (
                <CircularProgress size={13} color="inherit" />
              ) : (
                <FontAwesomeIcon icon={value.icon} color="#5946A4" size="lg" />
              )}
            </Button>
            <Tooltip
              offset={[0, 6]}
              target={key}
              isOpen={tooltipOpen[key]}
              toggle={() => setTooltipOpen({ ...tooltipOpen, [key]: !tooltipOpen[key] })}
            >
              {value.tooltip}
            </Tooltip>
          </div>
        ))}
      </div>
      <div className="d-flex gap-3" />
    </div>
  );
}

export default NewObjectProfileActionSection;
