import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner } from '../../components/Spinner';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { useEnv } from '../../context/env-context';
import { useEffect, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../utils/makeRequest';
import { useTableContext } from '../../context/table-context';
import ReportsTableContainer from './components/ReportsTableContainer';
import ReportsFormController from './components/ReportsFormController';
import ReportsCreationModal from './components/modals/ReportsCreationModal';

function Reports() {
  const { apiOriginReports } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);

  const [reportsCreationModal, setReportCreationModal] = useState(false);

  const { getTable, updateTable, handleChangePage, handleChangeRowsPerPage, toggleMineOnly } =
    useTableContext();

  const table = getTable({
    tableName: 'reports',
    initSortBy: 'createDate',
    initSortOrder: 'asc',
    initRowsPerPage: 25,
  });

  const { sortBy, sortOrder, page, rowsPerPage, totalRows, rows, mineOnly } = table;

  const getData = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginReports}/get`,
      method: 'GET',
      params: {
        sortBy,
        sortOrder,
        page,
        perPage: rowsPerPage,
        mineOnly,
      },
    };

    const data = await makeRequest(config);

    return data;
  };

  const getTotalRows = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginReports}/count`,
      method: 'GET',
      params: {
        mineOnly,
      },
    };

    const data = await makeRequest(config);

    return data.count;
  };

  const getAll = async () => {
    setSpinner(true);
    try {
      const data = await getData();
      const total = await getTotalRows();

      if (data && total !== null) {
        updateTable({
          tableName: 'reports',
          newTable: {
            ...table,
            rows: data,
            totalRows: total,
          },
        });
      }
    } catch (error) {
      toast.error(`Faled getting data: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, page, rowsPerPage, mineOnly]);

  const requestTimeouts = useRef([]);

  const getReport = async reportId => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginReports}/get_report`,
      method: 'GET',
      params: {
        reportId,
      },
    };

    try {
      const report = await makeRequest(config);

      updateTable({
        tableName: 'reports',
        newTable: {
          ...table,
          rows: rows.map(row => (row.id === reportId ? report : row)),
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    rows.forEach(row => {
      if (row.status === 'PENDING' || row.status === 'RUNNING') {
        const timeout = setTimeout(() => getReport(row.id), 3000);

        requestTimeouts.current = [...requestTimeouts.current, timeout];
      }
    });

    return () => {
      if (requestTimeouts.current) {
        requestTimeouts.current.forEach(timeout => {
          clearTimeout(timeout);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const openReportsCreationModal = () => setReportCreationModal(true);
  const closeReportsCreationModal = () => setReportCreationModal(false);

  const deleteReport = async reportId => {
    if (window.confirm('Are you sure?')) {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      setSpinner(true);

      const config = {
        token,
        url: `${apiOriginReports}/delete`,
        method: 'POST',
        data: { reportId },
      };

      try {
        await makeRequest(config);
        await getAll();

        toast.success('Report was successfully deleted');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const showReport = async reportId => navigate(`/report/${reportId}`);

  return (
    <Spinner visible={spinner}>
      <Container className="mb-5">
        <ReportsFormController
          getAll={getAll}
          openReportsCreationModal={openReportsCreationModal}
          mineOnly={mineOnly}
          toggleMineOnly={toggleMineOnly}
        />
        <ReportsTableContainer
          rows={rows}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          deleteReport={deleteReport}
          showReport={showReport}
        />
      </Container>
      <ReportsCreationModal
        isOpen={reportsCreationModal}
        closeModal={closeReportsCreationModal}
        getAll={getAll}
      />
    </Spinner>
  );
}

export default withAuthenticationRequired(Reports, { onRedirecting: () => <Loading /> });
