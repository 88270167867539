import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';

function NewObjectPopoverJobsForm({ popoverJobsForm, closePopoverJobsForm, getJobs, consumerId }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [fields, setFields] = useState({ last: 'NO', num: null });

  const close = () => {
    setFields({ last: 'NO', num: null });
    closePopoverJobsForm();
  };

  const launchJob = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/${popoverJobsForm}`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
        last: fields.last !== 'NO',
        num: Number(fields.num) || null,
      }),
    };

    try {
      await makeRequest(config);
      await getJobs();

      close();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Popover
      target="popover_jobs_form"
      isOpen={!!popoverJobsForm}
      placement="right"
      offset={[30, 10]}
      fade={false}
      hideArrow
    >
      <PopoverBody className="bo-new-object-popover-form-menu bo-w-200">
        <Spinner visible={spinner} size={36}>
          <p className="bo-new-object-text-bold">TYPE: {popoverJobsForm.toUpperCase()}</p>
          <Label className="bo-new-object-popover-form-menu-label">Last:</Label>
          <Input
            type="select"
            className="bo-new-object-popover-form-menu-input"
            value={fields.last}
            onChange={e => setFields({ ...fields, last: e.target.value })}
          >
            <option value="NO">No</option>
            <option value="YES">Yes</option>
          </Input>
          <Label className="bo-new-object-popover-form-menu-label">Num:</Label>
          <NumericFormat
            type="text"
            className="bo-new-object-popover-form-menu-input"
            value={fields.num}
            onChange={e => setFields({ ...fields, num: e.target.value })}
            customInput={Input}
            decimalScale={0}
          />
          <div className="mt-3 d-flex justify-content-between">
            <Button className="bo-new-object-button" onClick={launchJob}>
              Launch
            </Button>
            <Button className="bo-new-object-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </Spinner>
      </PopoverBody>
    </Popover>
  );
}

export default NewObjectPopoverJobsForm;
