import { Col, Label, Input } from 'reactstrap';
import { memo } from 'react';

function NotesForm({ consumerNotes, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Notes:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-input"
          type="textarea"
          name="notes"
          id="notes"
          value={consumerNotes || ''}
          onChange={changeValue}
          rows={3}
        />
      </Col>
    </>
  );
}

export default memo(NotesForm);
