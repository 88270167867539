import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AllowCreditCardForm({ consumerAllowCreditCard, setConsumer }) {
  return (
    <>
      <Col md={6} className="bo-same-line" />
      <Col md={3} className="bo-same-line-top">
        <Input
          className="ms-1 bo-checkbox"
          type="checkbox"
          name="allowCreditCard"
          id="allowCreditCard"
          checked={consumerAllowCreditCard}
          onChange={e =>
            setConsumer(prevConsumer => ({
              ...prevConsumer,
              allowCreditCard: e.target.checked,
            }))
          }
        />
        <Label className="p-0 mt-1 mb-0 me-0">Allow credit card</Label>
      </Col>
      <Col md={3} className="bo-same-line-top" />
    </>
  );
}

export default memo(AllowCreditCardForm);
