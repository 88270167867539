import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function ConsumerIdForm({ consumerId }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Consumer ID:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="consumerId"
          id="consumerId"
          value={consumerId || 'Loading...'}
          disabled
        />
      </Col>
    </>
  );
}

export default memo(ConsumerIdForm);
