import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function ProspectAggregatorNameForm({ prospectAggregatorName }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Aggregator name:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="aggregatorName"
          id="aggregatorName"
          value={prospectAggregatorName || ''}
          disabled
        />
      </Col>
    </>
  );
}

export default memo(ProspectAggregatorNameForm);
