import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

function FundingsAllAggregatorsForm({ selectedAggregatorFilter, changeAggregatorFilter, spinner }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [aggregators, setAggregators] = useState([]);

  const getAggregators = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const data = await makeRequest(config);

      setAggregators(data);
    } catch (error) {
      toast.error(`Faled getting aggregators: ${error.message}`);
    }
  };

  useEffect(() => {
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      className="bo-filter bo-min-w-240"
      type="select"
      name="aggregatorFilter"
      id="aggregatorFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={selectedAggregatorFilter}
      onChange={changeAggregatorFilter}
    >
      <option value="">All Standard Aggregators</option>
      {aggregators.map(agg => (
        <option key={agg.id} value={agg.id}>
          {agg.name}
        </option>
      ))}
    </Input>
  );
}

export default FundingsAllAggregatorsForm;
