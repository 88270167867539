const capitalizeFirstLetter = str => {
  const firstLetterCap = str.charAt(0).toUpperCase();
  const remainingLetters = str.slice(1);

  return firstLetterCap + remainingLetters;
};

function getTextWidthInOneLine(inputText, font = '12px Roboto Helvetica Arial sans-serif') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font;

  const { width } = context.measureText(inputText);

  return Math.ceil(width);
}

export { capitalizeFirstLetter, getTextWidthInOneLine };
