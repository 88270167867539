import { Path, Rect, Svg } from '@react-pdf/renderer';

function CheckIcon({ checkColor, style }) {
  return (
    <Svg viewBox="0 0 140 140" style={style}>
      <Rect width="140" height="140" rx={70} ry={70} fill="#350569" />
      <Path
        // eslint-disable-next-line max-len
        d="M61.0667 93.1068L39.1152 71.1553L44.6031 65.6674L61.0667 82.131L96.4008 46.7969L101.889 52.2847L61.0667 93.1068Z"
        fill={checkColor}
      />
    </Svg>
  );
}

export default CheckIcon;
