import moment from 'moment';
import { toast } from 'react-toastify';
import { memo, useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import StyledTableCell from '../../../../components/StyledTableCell';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useAuth } from '../../../../hooks/useAuth';
import { useTable } from '../../../../hooks/useTable';
import TablePaginationActions from '../../../../components/TablePaginationActions';

function ConsumerAuditTrail({ consumerId }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [auditTrail, setAuditTrail] = useState([]);
  const [selectedFieldName, setSelectedFieldName] = useState('');

  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useTable({
    initRowsPerPage: 20,
  });

  const filteredAuditTrail = useMemo(
    () =>
      auditTrail
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .filter(trial => {
          if (selectedFieldName) {
            return trial.fieldName === selectedFieldName;
          }

          return true;
        }),
    [auditTrail, selectedFieldName],
  );

  const getAuditTrail = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/audit_trail`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const res = await makeRequest(config);

      setAuditTrail(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAuditTrail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spinner visible={spinner}>
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <div className="d-flex">
            <Button className="bo-button-inline bo-button-gray" onClick={getAuditTrail}>
              <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
            </Button>
            <Input
              className="bo-filter ms-3"
              type="select"
              name="filter"
              id="filter"
              placeholder="filter"
              noValidate
              value={selectedFieldName}
              onChange={e => {
                setPage(0);
                setSelectedFieldName(e.target.value);
              }}
            >
              <option value="">All Fields</option>
              {[...new Set(auditTrail.map(trial => trial.fieldName))].map(fieldName => (
                <option key={fieldName} value={fieldName}>
                  {fieldName}
                </option>
              ))}
            </Input>
          </div>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Timestamp</StyledTableCell>
              <StyledTableCell align="left">Field Name</StyledTableCell>
              <StyledTableCell align="left">New value</StyledTableCell>
              <StyledTableCell align="left">User</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAuditTrail.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(trial => (
              <TableRow hover tabIndex={-1} key={trial.id}>
                <StyledTableCell align="left">
                  {moment.utc(trial.timestamp).local().format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">{trial.fieldName}</StyledTableCell>
                <StyledTableCell align="left">{trial.newValue}</StyledTableCell>
                <StyledTableCell align="left">{trial.user}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={filteredAuditTrail.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Spinner>
  );
}

export default memo(ConsumerAuditTrail);
