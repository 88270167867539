import { Row, Col } from 'reactstrap';
import ReportsActions from './ReportsActions';
import ReportsMineOnlyForm from './forms/ReportsMineOnlyForm';

function ReportsFormController({ getAll, openReportsCreationModal, mineOnly, toggleMineOnly }) {
  return (
    <Row className="mb-2 align-items-center">
      <Col md={12} className="bo-same-line">
        <ReportsActions getAll={getAll} openReportsCreationModal={openReportsCreationModal} />
        <ReportsMineOnlyForm mineOnly={mineOnly} toggleMineOnly={toggleMineOnly} />
      </Col>
    </Row>
  );
}

export default ReportsFormController;
