import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportGenericSignup({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      timestamp: {
        header: 'Date and Time',
        width: 25,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd hh:mm:ss' },
      },
      employerName: {
        header: 'Employer Name',
        width: 32,
        style: { alignment: { horizontal: 'left' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      email: {
        header: 'Email',
        width: 36,
        style: { alignment: { horizontal: 'left' } },
      },
      consent: {
        header: 'Consent',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Generic Signup', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = report.map(r => ({
      ...r,
      timestamp: r.timestamp ? formatExcelDate(new Date(r.timestamp)) : null,
      consent: r.consent ? 'Yes' : 'No',
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return report.map(r => ({
        ...Object.entries(r).reduce((acc, [key, value]) => {
          if (key === 'consent') {
            return {
              ...acc,
              [key]: value ? 'Yes' : 'No',
            };
          }

          return {
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          };
        }, {}),
        uuid: crypto.randomUUID(),
      }));
    }

    return [];
  }, [report, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="uuid"
      />
    </>
  );
}

export default ReportGenericSignup;
