import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedTable from '../../../../../components/VirtualizedTable';
import { capitalizeFirstLetter, getTextWidthInOneLine } from '../../../../../utils/strings';
import { Button, Dropdown, DropdownItem, DropdownMenu, Input, Label, Popover } from 'reactstrap';
import { CONSUMER_TRANSACTIONS_COLORS } from '../../../../../constants/consumers';
import { useCustomContextMenu } from '../../../../../hooks/useCustomContextMenu';

function NewObjectTransactionsTab({
  consumerId,
  profileJson,
  getProfileJson,
  refreshTab,
  setRefreshTab,
  globalRecurringStreamId,
  setGlobalRecurringStreamId,
}) {
  const [spinner, setSpinner] = useState(false);

  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [highlightStreams, setHighlightStreams] = useState(false);
  const [highlightPayroll, setHighlightPayroll] = useState(false);

  const { popoverTarget, contextMenuKey, updateContextMenuPosition, toggleContextMenu } =
    useCustomContextMenu('popover_transactions_menu');
  const [activeRow, setActiveRow] = useState(null);
  const [activeRowFilter, setActiveRowFilter] = useState({ field: null, value: null });
  const [activeRowHightlight, setActiveRowHightlight] = useState(false);

  useEffect(() => {
    if (globalRecurringStreamId) {
      setSearch('');
      setSearchValue('');
      setFilterField('');
      setFilterValue('');
      setActiveRowFilter({ field: 'recurringStreamId', value: globalRecurringStreamId });
      setGlobalRecurringStreamId(null);
    }
  }, [globalRecurringStreamId, setGlobalRecurringStreamId]);

  const formatClassification = useCallback((classification, recurringStreamId, profile) => {
    switch (classification) {
      case 'ONE_OFF':
        return 'One-off';
      case 'PAYROLL':
        const payrollFrequency = profile?.payroll?.frequency || '';

        return `Payroll-${capitalizeFirstLetter(payrollFrequency.toLowerCase())}`;
      case 'RECURRING':
        const recurringFrequency =
          profile?.recurringStreams?.find(x => x.uuid === recurringStreamId)?.frequency || '';

        return `Recurring-${capitalizeFirstLetter(recurringFrequency.toLowerCase())}`;
      case 'VARIABLE':
        return 'Variable';
      default:
        return '';
    }
  }, []);

  const formattedTransactions = useMemo(() => {
    if (profileJson?.profile?.transactions?.length > 0) {
      return [...profileJson.profile.transactions]
        .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))
        .map(x => ({
          ...x,
          transactionWeekday: moment(x.transactionDate).format('dddd'),
          pending: x.pending ? 'Yes' : '',
          classification: formatClassification(
            x.classification,
            x.recurringStreamId,
            profileJson.profile,
          ),
          category: x.category?.join('/') || '',
        }));
    }

    return [];
  }, [profileJson, formatClassification]);

  const transactions = useMemo(() => {
    if (formattedTransactions.length > 0) {
      let filteredTransactions = formattedTransactions;

      if (search) {
        const lowerCaseSearch = search.toLowerCase();

        filteredTransactions = filteredTransactions.filter(
          x =>
            x.originalDescription?.toLowerCase().includes(lowerCaseSearch) ||
            x.normalizedDescription?.toLowerCase().includes(lowerCaseSearch) ||
            x.merchentName?.toLowerCase().includes(lowerCaseSearch) ||
            x.classification?.toLowerCase().includes(lowerCaseSearch) ||
            x.plaidTransactionId?.toLowerCase().includes(lowerCaseSearch) ||
            x.category?.toLowerCase().includes(lowerCaseSearch),
        );
      }

      if (filterField && filterValue) {
        filteredTransactions = filteredTransactions.filter(x => x[filterField] === filterValue);
      }

      if (activeRowFilter.field && activeRowFilter.value) {
        filteredTransactions = filteredTransactions.filter(
          x => x[activeRowFilter.field] === activeRowFilter.value,
        );
      }

      return filteredTransactions;
    }

    return [];
  }, [formattedTransactions, search, filterField, filterValue, activeRowFilter]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'transactionDate',
        header: 'Date',
        width: 100,
      },
      {
        key: 'transactionWeekday',
        header: 'Weekday',
        width: 100,
      },
      {
        key: 'recurringPeriod',
        header: 'Period',
        width: 100,
      },
      {
        key: 'originalDescription',
        header: 'Description',
        width: 300,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
      {
        key: 'merchentName',
        header: 'Merchant Name',
        width: 180,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
      {
        key: 'direction',
        header: 'Direction',
        width: 100,
        renderValue: value =>
          typeof value === 'string' ? capitalizeFirstLetter(value.toLowerCase()) : '',
      },
      {
        key: 'amount',
        header: 'Amount',
        width: 100,
        renderValue: value =>
          value !== null ? (
            <div className="d-flex flex-column w-100 text-end me-2">
              {`${value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          ) : (
            '---'
          ),
      },
      {
        key: 'pending',
        header: 'Pending',
        width: 100,
      },
      {
        key: 'classification',
        header: 'Classification',
        width: 150,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
      {
        key: 'recurringAllocation',
        header: 'Allocation',
        width: 150,
      },
      {
        key: 'normalizedDescription',
        header: 'Normalized Description',
        width: 260,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
      {
        key: 'reconciled',
        header: 'Reconciliation',
        width: 180,
        renderValue: value => (value === 'TRANSFER' ? 'Bridgeover ACH' : '--------'),
      },
      {
        key: 'classificationReason',
        header: 'Classification Reason',
        width: 260,
        renderValue: value => value || '----------------',
      },
      {
        key: 'plaidTransactionId',
        header: 'Plaid ID',
        width: 300,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
      {
        key: 'category',
        header: 'Category',
        width: 300,
        renderValue: value => (
          <Highlighter
            searchWords={[search]}
            autoEscape
            textToHighlight={value || ''}
            highlightClassName="bo-table-highlight-text"
          />
        ),
      },
    ],
    [search],
  );

  const getTransactions = async () => {
    setSpinner(true);
    await getProfileJson(consumerId);
    setSpinner(false);
  };

  useEffect(() => {
    if (refreshTab === 'transactions') {
      getTransactions();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  useEffect(() => {
    setFilterField('');
    setFilterValue('');
    setSearch('');
  }, [consumerId]);

  const filterOptions = useMemo(() => {
    const filterFields = tableColumns
      .filter(
        x =>
          x.key !== 'originalDescription' &&
          x.key !== 'amount' &&
          x.key !== 'normalizedDescription' &&
          x.key !== 'plaidTransactionId',
      )
      .map(x => ({ key: x.key, header: x.header }));

    const options = {};

    filterFields.forEach(x => {
      const fieldValues = [...new Set(formattedTransactions.map(tr => tr[x.key]))].filter(
        v => v !== null && v !== undefined && v !== '',
      );

      if (x.key === 'transactionWeekday') {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        fieldValues.sort((a, b) => days.indexOf(a) - days.indexOf(b));
      } else if (x.key === 'transactionDate') {
        fieldValues.sort((a, b) => new Date(b) - new Date(a));
      } else {
        fieldValues.sort();
      }

      if (fieldValues.length > 0) {
        options[x.key] = { header: x.header, values: fieldValues };
      }
    });

    return options;
  }, [tableColumns, formattedTransactions]);

  const getTransactionStyles = useCallback(
    index => ({
      backgroundColor: CONSUMER_TRANSACTIONS_COLORS[index].bgColor,
      color: CONSUMER_TRANSACTIONS_COLORS[index].color,
    }),
    [],
  );

  const transactionStreamsStyles = useMemo(() => {
    const styles = {};
    const recurringStreamStyles = {};

    let count = 0;

    formattedTransactions.forEach(tr => {
      if (tr.recurringStreamId) {
        if (recurringStreamStyles[tr.recurringStreamId]) {
          styles[tr.uuid] = recurringStreamStyles[tr.recurringStreamId];
        } else {
          const newStyle = getTransactionStyles(3 + count);

          styles[tr.uuid] = newStyle;
          recurringStreamStyles[tr.recurringStreamId] = newStyle;
          count = count < 17 ? count + 1 : 0;
        }
      }
    });

    return styles;
  }, [formattedTransactions, getTransactionStyles]);

  const formatCellConditionally = useCallback(
    row => {
      if (highlightStreams) {
        if (row.classification.includes('One-off')) {
          return getTransactionStyles(0);
        }

        if (row.classification.includes('Payroll')) {
          return getTransactionStyles(1);
        }

        if (row.classification.includes('Variable')) {
          return getTransactionStyles(2);
        }

        if (row.classification.includes('Recurring')) {
          return transactionStreamsStyles[row.uuid];
        }
      }

      if (highlightPayroll) {
        if (row.classification.includes('Payroll')) {
          return getTransactionStyles(1);
        }
      }

      if (activeRow && activeRowHightlight) {
        if (
          activeRow.classification.includes('One-off') &&
          row.classification.includes('One-off')
        ) {
          return getTransactionStyles(0);
        }

        if (
          activeRow.classification.includes('Payroll') &&
          row.classification.includes('Payroll')
        ) {
          return getTransactionStyles(1);
        }

        if (
          activeRow.classification.includes('Variable') &&
          row.classification.includes('Variable')
        ) {
          return getTransactionStyles(2);
        }

        if (activeRow.recurringStreamId && activeRow.recurringStreamId === row.recurringStreamId) {
          return transactionStreamsStyles[row.uuid];
        }
      }

      return {};
    },
    [
      highlightStreams,
      transactionStreamsStyles,
      highlightPayroll,
      activeRow,
      activeRowHightlight,
      getTransactionStyles,
    ],
  );

  const handleRowRightClick = useCallback(
    (e, row) => {
      e.preventDefault();

      if (activeRowFilter.field === null && activeRowHightlight === false) {
        updateContextMenuPosition(e.pageX, e.pageY);
        setActiveRow(row);
      }
    },
    [updateContextMenuPosition, activeRowFilter, activeRowHightlight],
  );

  const getRowHeight = useCallback(
    index => {
      const originalDescWidth = getTextWidthInOneLine(transactions[index].originalDescription);
      const originalDescHeight =
        originalDescWidth > 270 ? Math.ceil((originalDescWidth / 300) * 20) + 28 : 28;

      const normalizedDescWidth = getTextWidthInOneLine(transactions[index].normalizedDescription);
      const normalizedDescHeight =
        normalizedDescWidth > 270 ? Math.ceil((normalizedDescWidth / 300) * 20) + 28 : 28;

      const classificationReasonWidth = getTextWidthInOneLine(
        transactions[index].classificationReason,
      );
      const classificationReasonHeight =
        classificationReasonWidth > 240
          ? Math.ceil((classificationReasonWidth / 260) * 20) + 28
          : 28;

      return Math.max(originalDescHeight, normalizedDescHeight, classificationReasonHeight, 28);
    },
    [transactions],
  );

  const cancelFiltersAndHighlights = () => {
    setSearch('');
    setSearchValue('');
    setFilterField('');
    setFilterValue('');
    setHighlightStreams(false);
    setHighlightPayroll(false);
    setActiveRowFilter({ field: null, value: null });
    setActiveRowHightlight(false);
  };

  return (
    <Spinner visible={spinner} wrapperStyle={{ height: '100%' }}>
      <div className="h-100">
        <div className="m-3 d-flex gap-2 overflow-hidden">
          <Input
            className="bo-new-object-popover-form-menu-search bo-w-250"
            placeholder="Search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setSearch(e.target.value);
              }
            }}
          />
          <Input
            type="select"
            className="bo-new-object-popover-form-menu-input bo-w-200"
            value={filterField}
            onChange={e => {
              setFilterValue('');
              setFilterField(e.target.value);
            }}
          >
            <option value="" disabled>
              Filter Field
            </option>
            {Object.entries(filterOptions).map(([key, value]) => (
              <option key={key} value={key}>
                {value.header}
              </option>
            ))}
          </Input>
          <Input
            type="select"
            className="bo-new-object-popover-form-menu-input bo-w-200 text-truncation pe-5"
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
            disabled={!filterField}
          >
            <option value="" disabled>
              Filter Value
            </option>
            {filterField &&
              filterOptions[filterField].values.map(x => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
          </Input>
          <div className="d-flex align-items-center">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              checked={highlightStreams}
              onChange={e => setHighlightStreams(e.target.checked)}
            />
            <Label className="bo-new-object-text-md ms-1 text-nowrap">Highlight All Streams</Label>
          </div>
          <div className="d-flex align-items-center">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              checked={highlightPayroll}
              onChange={e => setHighlightPayroll(e.target.checked)}
            />
            <Label className="bo-new-object-text-md ms-1 text-nowrap">Highlight Payroll</Label>
          </div>
          {(search ||
            filterValue ||
            highlightStreams ||
            highlightPayroll ||
            activeRowFilter.value ||
            activeRowHightlight) && (
            <Button
              className="ms-auto bo-new-object-inline-button text-nowrap"
              onClick={cancelFiltersAndHighlights}
            >
              Cancel Filters & Highlights
            </Button>
          )}
        </div>
        {popoverTarget && activeRowFilter.field === null && activeRowHightlight === false && (
          <Popover
            key={contextMenuKey}
            target={popoverTarget}
            isOpen={!!contextMenuKey}
            placement="top-start"
            trigger="legacy"
            toggle={toggleContextMenu}
            offset={[10, 10]}
            fade={false}
            hideArrow
          >
            <Dropdown isOpen toggle={toggleContextMenu}>
              <DropdownMenu className="bo-new-object-dropdown-menu">
                <DropdownItem
                  className="bo-new-object-dropdown-menu-item"
                  onClick={() => setActiveRowHightlight(true)}
                  disabled={highlightStreams || highlightPayroll}
                >
                  Highlight Stream
                </DropdownItem>
                <DropdownItem
                  className="bo-new-object-dropdown-menu-item"
                  onClick={() =>
                    setActiveRowFilter({
                      field: activeRow.recurringStreamId ? 'recurringStreamId' : 'classification',
                      value: activeRow.recurringStreamId || activeRow.classification,
                    })
                  }
                >
                  Filter Stream
                </DropdownItem>
                <DropdownItem
                  className="bo-new-object-dropdown-menu-item"
                  onClick={() =>
                    setActiveRowFilter({ field: 'merchentName', value: activeRow.merchentName })
                  }
                >
                  Filter Merchant
                </DropdownItem>
                <DropdownItem
                  className="bo-new-object-dropdown-menu-item"
                  onClick={() =>
                    setActiveRowFilter({
                      field: 'normalizedDescription',
                      value: activeRow.normalizedDescription,
                    })
                  }
                >
                  Filter Description
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Popover>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <VirtualizedTable
              tableRows={transactions}
              tableColumns={tableColumns}
              width={width}
              height={height - 62}
              headerTopBorder
              getRowHeight={getRowHeight}
              longMultilineColumnKey="originalDescription"
              rowKey="uuid"
              formatCellConditionally={formatCellConditionally}
              handleRowRightClick={handleRowRightClick}
            />
          )}
        </AutoSizer>
      </div>
    </Spinner>
  );
}

export default memo(NewObjectTransactionsTab);
