import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

function TurnoverTable({
  reportDate,
  usersQtdTurnoverAnnualized,
  usersYtdTurnoverAnnualized,
  usersL12TurnoverAnnualized,
  nonUsersQtdTurnoverAnnualized,
  nonUsersYtdTurnoverAnnualized,
  nonUsersL12TurnoverAnnualized,
}) {
  return (
    <View style={styles.turnoverTable}>
      <View style={styles.turnoverTableRow}>
        <View style={styles.turnoverTableRowTitleContainer} />
        <View
          style={[styles.turnoverTableRowValueContainer, styles.turonverTableTopRowValueContainer]}
        >
          <Text style={styles.turnoverTableRowValue}>{`QTD ${reportDate.year()}`}</Text>
        </View>
        <View
          style={[styles.turnoverTableRowValueContainer, styles.turonverTableTopRowValueContainer]}
        >
          <Text style={styles.turnoverTableRowValue}>{`YTD ${reportDate.year()}`}</Text>
        </View>
        <View
          style={[
            styles.turnoverTableRowValueContainer,
            styles.turonverTableTopRowValueContainer,
            styles.turnoverTableTopRightRowValueContainer,
          ]}
        >
          <Text style={styles.turnoverTableRowValue}>Last 12 Months</Text>
        </View>
      </View>
      <View style={[styles.turnoverTableRow, styles.turnoverTableMiddleRow]}>
        <View style={styles.turnoverTableRowTitleContainer}>
          <Text style={[styles.turnoverTableRowTitle, styles.turnoverTableRowAltColor]}>
            Bridgeover Users
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {usersQtdTurnoverAnnualized !== null && usersQtdTurnoverAnnualized !== undefined
              ? `${Number(usersQtdTurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {usersYtdTurnoverAnnualized !== null && usersYtdTurnoverAnnualized !== undefined
              ? `${Number(usersYtdTurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A'}
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {usersL12TurnoverAnnualized !== null && usersL12TurnoverAnnualized !== undefined
              ? `${Number(usersL12TurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A'}
          </Text>
        </View>
      </View>
      <View style={[styles.turnoverTableRow, styles.turnoverTableBottomRow]}>
        <View style={styles.turnoverTableRowTitleContainer}>
          <Text style={[styles.turnoverTableRowTitle, styles.turnoverTableRowAltColor]}>
            Non-Bridgeover Users
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {nonUsersQtdTurnoverAnnualized !== null && nonUsersQtdTurnoverAnnualized !== undefined
              ? `${Number(nonUsersQtdTurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A*'}
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {nonUsersYtdTurnoverAnnualized !== null && nonUsersYtdTurnoverAnnualized !== undefined
              ? `${Number(nonUsersYtdTurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A*'}
          </Text>
        </View>
        <View style={styles.turnoverTableRowValueContainer}>
          <Text style={[styles.turnoverTableRowValue, styles.turnoverTableRowAltColor]}>
            {nonUsersL12TurnoverAnnualized !== null && nonUsersL12TurnoverAnnualized !== undefined
              ? `${Number(nonUsersL12TurnoverAnnualized * 100).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}%`
              : 'N/A*'}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default TurnoverTable;
