import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function FundingIdForm({ fundingId }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Advance ID:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="id"
          id="id"
          value={fundingId.toString().padStart(6, '0')}
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingIdForm);
