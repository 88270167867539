// https://countrycode.org/

export const countryCodes = [
  {
    country: 'Afghanistan',
    countryCodes: ['93'],
    isoCode2: 'AF',
    isoCode3: 'AFG',
  },
  {
    country: 'Albania',
    countryCodes: ['355'],
    isoCode2: 'AL',
    isoCode3: 'ALB',
  },
  {
    country: 'Algeria',
    countryCodes: ['213'],
    isoCode2: 'DZ',
    isoCode3: 'DZA',
  },
  {
    country: 'American Samoa',
    countryCodes: ['1-684'],
    isoCode2: 'AS',
    isoCode3: 'ASM',
  },
  {
    country: 'Andorra',
    countryCodes: ['376'],
    isoCode2: 'AD',
    isoCode3: 'AND',
  },
  {
    country: 'Angola',
    countryCodes: ['244'],
    isoCode2: 'AO',
    isoCode3: 'AGO',
  },
  {
    country: 'Anguilla',
    countryCodes: ['1-264'],
    isoCode2: 'AI',
    isoCode3: 'AIA',
  },
  {
    country: 'Antarctica',
    countryCodes: ['672'],
    isoCode2: 'AQ',
    isoCode3: 'ATA',
  },
  {
    country: 'Antigua and Barbuda',
    countryCodes: ['1-268'],
    isoCode2: 'AG',
    isoCode3: 'ATG',
  },
  {
    country: 'Argentina',
    countryCodes: ['54'],
    isoCode2: 'AR',
    isoCode3: 'ARG',
  },
  {
    country: 'Armenia',
    countryCodes: ['374'],
    isoCode2: 'AM',
    isoCode3: 'ARM',
  },
  {
    country: 'Aruba',
    countryCodes: ['297'],
    isoCode2: 'AW',
    isoCode3: 'ABW',
  },
  {
    country: 'Australia',
    countryCodes: ['61'],
    isoCode2: 'AU',
    isoCode3: 'AUS',
  },
  {
    country: 'Austria',
    countryCodes: ['43'],
    isoCode2: 'AT',
    isoCode3: 'AUT',
  },
  {
    country: 'Azerbaijan',
    countryCodes: ['994'],
    isoCode2: 'AZ',
    isoCode3: 'AZE',
  },
  {
    country: 'Bahamas',
    countryCodes: ['1-242'],
    isoCode2: 'BS',
    isoCode3: 'BHS',
  },
  {
    country: 'Bahrain',
    countryCodes: ['973'],
    isoCode2: 'BH',
    isoCode3: 'BHR',
  },
  {
    country: 'Bangladesh',
    countryCodes: ['880'],
    isoCode2: 'BD',
    isoCode3: 'BGD',
  },
  {
    country: 'Barbados',
    countryCodes: ['1-246'],
    isoCode2: 'BB',
    isoCode3: 'BRB',
  },
  {
    country: 'Belarus',
    countryCodes: ['375'],
    isoCode2: 'BY',
    isoCode3: 'BLR',
  },
  {
    country: 'Belgium',
    countryCodes: ['32'],
    isoCode2: 'BE',
    isoCode3: 'BEL',
  },
  {
    country: 'Belize',
    countryCodes: ['501'],
    isoCode2: 'BZ',
    isoCode3: 'BLZ',
  },
  {
    country: 'Benin',
    countryCodes: ['229'],
    isoCode2: 'BJ',
    isoCode3: 'BEN',
  },
  {
    country: 'Bermuda',
    countryCodes: ['1-441'],
    isoCode2: 'BM',
    isoCode3: 'BMU',
  },
  {
    country: 'Bhutan',
    countryCodes: ['975'],
    isoCode2: 'BT',
    isoCode3: 'BTN',
  },
  {
    country: 'Bolivia',
    countryCodes: ['591'],
    isoCode2: 'BO',
    isoCode3: 'BOL',
  },
  {
    country: 'Bosnia and Herzegovina',
    countryCodes: ['387'],
    isoCode2: 'BA',
    isoCode3: 'BIH',
  },
  {
    country: 'Botswana',
    countryCodes: ['267'],
    isoCode2: 'BW',
    isoCode3: 'BWA',
  },
  {
    country: 'Brazil',
    countryCodes: ['55'],
    isoCode2: 'BR',
    isoCode3: 'BRA',
  },
  {
    country: 'British Indian Ocean Territory',
    countryCodes: ['246'],
    isoCode2: 'IO',
    isoCode3: 'IOT',
  },
  {
    country: 'British Virgin Islands',
    countryCodes: ['1-284'],
    isoCode2: 'VG',
    isoCode3: 'VGB',
  },
  {
    country: 'Brunei',
    countryCodes: ['673'],
    isoCode2: 'BN',
    isoCode3: 'BRN',
  },
  {
    country: 'Bulgaria',
    countryCodes: ['359'],
    isoCode2: 'BG',
    isoCode3: 'BGR',
  },
  {
    country: 'Burkina Faso',
    countryCodes: ['226'],
    isoCode2: 'BF',
    isoCode3: 'BFA',
  },
  {
    country: 'Burundi',
    countryCodes: ['257'],
    isoCode2: 'BI',
    isoCode3: 'BDI',
  },
  {
    country: 'Cambodia',
    countryCodes: ['855'],
    isoCode2: 'KH',
    isoCode3: 'KHM',
  },
  {
    country: 'Cameroon',
    countryCodes: ['237'],
    isoCode2: 'CM',
    isoCode3: 'CMR',
  },
  {
    country: 'Canada',
    countryCodes: ['1'],
    isoCode2: 'CA',
    isoCode3: 'CAN',
  },
  {
    country: 'Cape Verde',
    countryCodes: ['238'],
    isoCode2: 'CV',
    isoCode3: 'CPV',
  },
  {
    country: 'Cayman Islands',
    countryCodes: ['1-345'],
    isoCode2: 'KY',
    isoCode3: 'CYM',
  },
  {
    country: 'Central African Republic',
    countryCodes: ['236'],
    isoCode2: 'CF',
    isoCode3: 'CAF',
  },
  {
    country: 'Chad',
    countryCodes: ['235'],
    isoCode2: 'TD',
    isoCode3: 'TCD',
  },
  {
    country: 'Chile',
    countryCodes: ['56'],
    isoCode2: 'CL',
    isoCode3: 'CHL',
  },
  {
    country: 'China',
    countryCodes: ['86'],
    isoCode2: 'CN',
    isoCode3: 'CHN',
  },
  {
    country: 'Christmas Island',
    countryCodes: ['61'],
    isoCode2: 'CX',
    isoCode3: 'CXR',
  },
  {
    country: 'Cocos Islands',
    countryCodes: ['61'],
    isoCode2: 'CC',
    isoCode3: 'CCK',
  },
  {
    country: 'Colombia',
    countryCodes: ['57'],
    isoCode2: 'CO',
    isoCode3: 'COL',
  },
  {
    country: 'Comoros',
    countryCodes: ['269'],
    isoCode2: 'KM',
    isoCode3: 'COM',
  },
  {
    country: 'Cook Islands',
    countryCodes: ['682'],
    isoCode2: 'CK',
    isoCode3: 'COK',
  },
  {
    country: 'Costa Rica',
    countryCodes: ['506'],
    isoCode2: 'CR',
    isoCode3: 'CRI',
  },
  {
    country: 'Croatia',
    countryCodes: ['385'],
    isoCode2: 'HR',
    isoCode3: 'HRV',
  },
  {
    country: 'Cuba',
    countryCodes: ['53'],
    isoCode2: 'CU',
    isoCode3: 'CUB',
  },
  {
    country: 'Curacao',
    countryCodes: ['599'],
    isoCode2: 'CW',
    isoCode3: 'CUW',
  },
  {
    country: 'Cyprus',
    countryCodes: ['357'],
    isoCode2: 'CY',
    isoCode3: 'CYP',
  },
  {
    country: 'Czech Republic',
    countryCodes: ['420'],
    isoCode2: 'CZ',
    isoCode3: 'CZE',
  },
  {
    country: 'Democratic Republic of the Congo',
    countryCodes: ['243'],
    isoCode2: 'CD',
    isoCode3: 'COD',
  },
  {
    country: 'Denmark',
    countryCodes: ['45'],
    isoCode2: 'DK',
    isoCode3: 'DNK',
  },
  {
    country: 'Djibouti',
    countryCodes: ['253'],
    isoCode2: 'DJ',
    isoCode3: 'DJI',
  },
  {
    country: 'Dominica',
    countryCodes: ['1-767'],
    isoCode2: 'DM',
    isoCode3: 'DMA',
  },
  {
    country: 'Dominican Republic',
    countryCodes: ['1-809', '1-829', '1-849'],
    isoCode2: 'DO',
    isoCode3: 'DOM',
  },
  {
    country: 'East Timor',
    countryCodes: ['670'],
    isoCode2: 'TL',
    isoCode3: 'TLS',
  },
  {
    country: 'Ecuador',
    countryCodes: ['593'],
    isoCode2: 'EC',
    isoCode3: 'ECU',
  },
  {
    country: 'Egypt',
    countryCodes: ['20'],
    isoCode2: 'EG',
    isoCode3: 'EGY',
  },
  {
    country: 'El Salvador',
    countryCodes: ['503'],
    isoCode2: 'SV',
    isoCode3: 'SLV',
  },
  {
    country: 'Equatorial Guinea',
    countryCodes: ['240'],
    isoCode2: 'GQ',
    isoCode3: 'GNQ',
  },
  {
    country: 'Eritrea',
    countryCodes: ['291'],
    isoCode2: 'ER',
    isoCode3: 'ERI',
  },
  {
    country: 'Estonia',
    countryCodes: ['372'],
    isoCode2: 'EE',
    isoCode3: 'EST',
  },
  {
    country: 'Ethiopia',
    countryCodes: ['251'],
    isoCode2: 'ET',
    isoCode3: 'ETH',
  },
  {
    country: 'Falkland Islands',
    countryCodes: ['500'],
    isoCode2: 'FK',
    isoCode3: 'FLK',
  },
  {
    country: 'Faroe Islands',
    countryCodes: ['298'],
    isoCode2: 'FO',
    isoCode3: 'FRO',
  },
  {
    country: 'Fiji',
    countryCodes: ['679'],
    isoCode2: 'FJ',
    isoCode3: 'FJI',
  },
  {
    country: 'Finland',
    countryCodes: ['358'],
    isoCode2: 'FI',
    isoCode3: 'FIN',
  },
  {
    country: 'France',
    countryCodes: ['33'],
    isoCode2: 'FR',
    isoCode3: 'FRA',
  },
  {
    country: 'French Polynesia',
    countryCodes: ['689'],
    isoCode2: 'PF',
    isoCode3: 'PYF',
  },
  {
    country: 'Gabon',
    countryCodes: ['241'],
    isoCode2: 'GA',
    isoCode3: 'GAB',
  },
  {
    country: 'Gambia',
    countryCodes: ['220'],
    isoCode2: 'GM',
    isoCode3: 'GMB',
  },
  {
    country: 'Georgia',
    countryCodes: ['995'],
    isoCode2: 'GE',
    isoCode3: 'GEO',
  },
  {
    country: 'Germany',
    countryCodes: ['49'],
    isoCode2: 'DE',
    isoCode3: 'DEU',
  },
  {
    country: 'Ghana',
    countryCodes: ['233'],
    isoCode2: 'GH',
    isoCode3: 'GHA',
  },
  {
    country: 'Gibraltar',
    countryCodes: ['350'],
    isoCode2: 'GI',
    isoCode3: 'GIB',
  },
  {
    country: 'Greece',
    countryCodes: ['30'],
    isoCode2: 'GR',
    isoCode3: 'GRC',
  },
  {
    country: 'Greenland',
    countryCodes: ['299'],
    isoCode2: 'GL',
    isoCode3: 'GRL',
  },
  {
    country: 'Grenada',
    countryCodes: ['1-473'],
    isoCode2: 'GD',
    isoCode3: 'GRD',
  },
  {
    country: 'Guam',
    countryCodes: ['1-671'],
    isoCode2: 'GU',
    isoCode3: 'GUM',
  },
  {
    country: 'Guatemala',
    countryCodes: ['502'],
    isoCode2: 'GT',
    isoCode3: 'GTM',
  },
  {
    country: 'Guernsey',
    countryCodes: ['44-1481'],
    isoCode2: 'GG',
    isoCode3: 'GGY',
  },
  {
    country: 'Guinea',
    countryCodes: ['224'],
    isoCode2: 'GN',
    isoCode3: 'GIN',
  },
  {
    country: 'Guinea-Bissau',
    countryCodes: ['245'],
    isoCode2: 'GW',
    isoCode3: 'GNB',
  },
  {
    country: 'Guyana',
    countryCodes: ['592'],
    isoCode2: 'GY',
    isoCode3: 'GUY',
  },
  {
    country: 'Haiti',
    countryCodes: ['509'],
    isoCode2: 'HT',
    isoCode3: 'HTI',
  },
  {
    country: 'Honduras',
    countryCodes: ['504'],
    isoCode2: 'HN',
    isoCode3: 'HND',
  },
  {
    country: 'Hong Kong',
    countryCodes: ['852'],
    isoCode2: 'HK',
    isoCode3: 'HKG',
  },
  {
    country: 'Hungary',
    countryCodes: ['36'],
    isoCode2: 'HU',
    isoCode3: 'HUN',
  },
  {
    country: 'Iceland',
    countryCodes: ['354'],
    isoCode2: 'IS',
    isoCode3: 'ISL',
  },
  {
    country: 'India',
    countryCodes: ['91'],
    isoCode2: 'IN',
    isoCode3: 'IND',
  },
  {
    country: 'Indonesia',
    countryCodes: ['62'],
    isoCode2: 'ID',
    isoCode3: 'IDN',
  },
  {
    country: 'Iran',
    countryCodes: ['98'],
    isoCode2: 'IR',
    isoCode3: 'IRN',
  },
  {
    country: 'Iraq',
    countryCodes: ['964'],
    isoCode2: 'IQ',
    isoCode3: 'IRQ',
  },
  {
    country: 'Ireland',
    countryCodes: ['353'],
    isoCode2: 'IE',
    isoCode3: 'IRL',
  },
  {
    country: 'Isle of Man',
    countryCodes: ['44-1624'],
    isoCode2: 'IM',
    isoCode3: 'IMN',
  },
  {
    country: 'Israel',
    countryCodes: ['972'],
    isoCode2: 'IL',
    isoCode3: 'ISR',
  },
  {
    country: 'Italy',
    countryCodes: ['39'],
    isoCode2: 'IT',
    isoCode3: 'ITA',
  },
  {
    country: 'Ivory Coast',
    countryCodes: ['225'],
    isoCode2: 'CI',
    isoCode3: 'CIV',
  },
  {
    country: 'Jamaica',
    countryCodes: ['1-876'],
    isoCode2: 'JM',
    isoCode3: 'JAM',
  },
  {
    country: 'Japan',
    countryCodes: ['81'],
    isoCode2: 'JP',
    isoCode3: 'JPN',
  },
  {
    country: 'Jersey',
    countryCodes: ['44-1534'],
    isoCode2: 'JE',
    isoCode3: 'JEY',
  },
  {
    country: 'Jordan',
    countryCodes: ['962'],
    isoCode2: 'JO',
    isoCode3: 'JOR',
  },
  {
    country: 'Kazakhstan',
    countryCodes: ['7'],
    isoCode2: 'KZ',
    isoCode3: 'KAZ',
  },
  {
    country: 'Kenya',
    countryCodes: ['254'],
    isoCode2: 'KE',
    isoCode3: 'KEN',
  },
  {
    country: 'Kiribati',
    countryCodes: ['686'],
    isoCode2: 'KI',
    isoCode3: 'KIR',
  },
  {
    country: 'Kosovo',
    countryCodes: ['383'],
    isoCode2: 'XK',
    isoCode3: 'XKX',
  },
  {
    country: 'Kuwait',
    countryCodes: ['965'],
    isoCode2: 'KW',
    isoCode3: 'KWT',
  },
  {
    country: 'Kyrgyzstan',
    countryCodes: ['996'],
    isoCode2: 'KG',
    isoCode3: 'KGZ',
  },
  {
    country: 'Laos',
    countryCodes: ['856'],
    isoCode2: 'LA',
    isoCode3: 'LAO',
  },
  {
    country: 'Latvia',
    countryCodes: ['371'],
    isoCode2: 'LV',
    isoCode3: 'LVA',
  },
  {
    country: 'Lebanon',
    countryCodes: ['961'],
    isoCode2: 'LB',
    isoCode3: 'LBN',
  },
  {
    country: 'Lesotho',
    countryCodes: ['266'],
    isoCode2: 'LS',
    isoCode3: 'LSO',
  },
  {
    country: 'Liberia',
    countryCodes: ['231'],
    isoCode2: 'LR',
    isoCode3: 'LBR',
  },
  {
    country: 'Libya',
    countryCodes: ['218'],
    isoCode2: 'LY',
    isoCode3: 'LBY',
  },
  {
    country: 'Liechtenstein',
    countryCodes: ['423'],
    isoCode2: 'LI',
    isoCode3: 'LIE',
  },
  {
    country: 'Lithuania',
    countryCodes: ['370'],
    isoCode2: 'LT',
    isoCode3: 'LTU',
  },
  {
    country: 'Luxembourg',
    countryCodes: ['352'],
    isoCode2: 'LU',
    isoCode3: 'LUX',
  },
  {
    country: 'Macau',
    countryCodes: ['853'],
    isoCode2: 'MO',
    isoCode3: 'MAC',
  },
  {
    country: 'Macedonia',
    countryCodes: ['389'],
    isoCode2: 'MK',
    isoCode3: 'MKD',
  },
  {
    country: 'Madagascar',
    countryCodes: ['261'],
    isoCode2: 'MG',
    isoCode3: 'MDG',
  },
  {
    country: 'Malawi',
    countryCodes: ['265'],
    isoCode2: 'MW',
    isoCode3: 'MWI',
  },
  {
    country: 'Malaysia',
    countryCodes: ['60'],
    isoCode2: 'MY',
    isoCode3: 'MYS',
  },
  {
    country: 'Maldives',
    countryCodes: ['960'],
    isoCode2: 'MV',
    isoCode3: 'MDV',
  },
  {
    country: 'Mali',
    countryCodes: ['223'],
    isoCode2: 'ML',
    isoCode3: 'MLI',
  },
  {
    country: 'Malta',
    countryCodes: ['356'],
    isoCode2: 'MT',
    isoCode3: 'MLT',
  },
  {
    country: 'Marshall Islands',
    countryCodes: ['692'],
    isoCode2: 'MH',
    isoCode3: 'MHL',
  },
  {
    country: 'Mauritania',
    countryCodes: ['222'],
    isoCode2: 'MR',
    isoCode3: 'MRT',
  },
  {
    country: 'Mauritius',
    countryCodes: ['230'],
    isoCode2: 'MU',
    isoCode3: 'MUS',
  },
  {
    country: 'Mayotte',
    countryCodes: ['262'],
    isoCode2: 'YT',
    isoCode3: 'MYT',
  },
  {
    country: 'Mexico',
    countryCodes: ['52'],
    isoCode2: 'MX',
    isoCode3: 'MEX',
  },
  {
    country: 'Micronesia',
    countryCodes: ['691'],
    isoCode2: 'FM',
    isoCode3: 'FSM',
  },
  {
    country: 'Moldova',
    countryCodes: ['373'],
    isoCode2: 'MD',
    isoCode3: 'MDA',
  },
  {
    country: 'Monaco',
    countryCodes: ['377'],
    isoCode2: 'MC',
    isoCode3: 'MCO',
  },
  {
    country: 'Mongolia',
    countryCodes: ['976'],
    isoCode2: 'MN',
    isoCode3: 'MNG',
  },
  {
    country: 'Montenegro',
    countryCodes: ['382'],
    isoCode2: 'ME',
    isoCode3: 'MNE',
  },
  {
    country: 'Montserrat',
    countryCodes: ['1-664'],
    isoCode2: 'MS',
    isoCode3: 'MSR',
  },
  {
    country: 'Morocco',
    countryCodes: ['212'],
    isoCode2: 'MA',
    isoCode3: 'MAR',
  },
  {
    country: 'Mozambique',
    countryCodes: ['258'],
    isoCode2: 'MZ',
    isoCode3: 'MOZ',
  },
  {
    country: 'Myanmar',
    countryCodes: ['95'],
    isoCode2: 'MM',
    isoCode3: 'MMR',
  },
  {
    country: 'Namibia',
    countryCodes: ['264'],
    isoCode2: 'NA',
    isoCode3: 'NAM',
  },
  {
    country: 'Nauru',
    countryCodes: ['674'],
    isoCode2: 'NR',
    isoCode3: 'NRU',
  },
  {
    country: 'Nepal',
    countryCodes: ['977'],
    isoCode2: 'NP',
    isoCode3: 'NPL',
  },
  {
    country: 'Netherlands',
    countryCodes: ['31'],
    isoCode2: 'NL',
    isoCode3: 'NLD',
  },
  {
    country: 'Netherlands Antilles',
    countryCodes: ['599'],
    isoCode2: 'AN',
    isoCode3: 'ANT',
  },
  {
    country: 'New Caledonia',
    countryCodes: ['687'],
    isoCode2: 'NC',
    isoCode3: 'NCL',
  },
  {
    country: 'New Zealand',
    countryCodes: ['64'],
    isoCode2: 'NZ',
    isoCode3: 'NZL',
  },
  {
    country: 'Nicaragua',
    countryCodes: ['505'],
    isoCode2: 'NI',
    isoCode3: 'NIC',
  },
  {
    country: 'Niger',
    countryCodes: ['227'],
    isoCode2: 'NE',
    isoCode3: 'NER',
  },
  {
    country: 'Nigeria',
    countryCodes: ['234'],
    isoCode2: 'NG',
    isoCode3: 'NGA',
  },
  {
    country: 'Niue',
    countryCodes: ['683'],
    isoCode2: 'NU',
    isoCode3: 'NIU',
  },
  {
    country: 'North Korea',
    countryCodes: ['850'],
    isoCode2: 'KP',
    isoCode3: 'PRK',
  },
  {
    country: 'Northern Mariana Islands',
    countryCodes: ['1-670'],
    isoCode2: 'MP',
    isoCode3: 'MNP',
  },
  {
    country: 'Norway',
    countryCodes: ['47'],
    isoCode2: 'NO',
    isoCode3: 'NOR',
  },
  {
    country: 'Oman',
    countryCodes: ['968'],
    isoCode2: 'OM',
    isoCode3: 'OMN',
  },
  {
    country: 'Pakistan',
    countryCodes: ['92'],
    isoCode2: 'PK',
    isoCode3: 'PAK',
  },
  {
    country: 'Palau',
    countryCodes: ['680'],
    isoCode2: 'PW',
    isoCode3: 'PLW',
  },
  {
    country: 'Palestine',
    countryCodes: ['970'],
    isoCode2: 'PS',
    isoCode3: 'PSE',
  },
  {
    country: 'Panama',
    countryCodes: ['507'],
    isoCode2: 'PA',
    isoCode3: 'PAN',
  },
  {
    country: 'Papua New Guinea',
    countryCodes: ['675'],
    isoCode2: 'PG',
    isoCode3: 'PNG',
  },
  {
    country: 'Paraguay',
    countryCodes: ['595'],
    isoCode2: 'PY',
    isoCode3: 'PRY',
  },
  {
    country: 'Peru',
    countryCodes: ['51'],
    isoCode2: 'PE',
    isoCode3: 'PER',
  },
  {
    country: 'Philippines',
    countryCodes: ['63'],
    isoCode2: 'PH',
    isoCode3: 'PHL',
  },
  {
    country: 'Pitcairn',
    countryCodes: ['64'],
    isoCode2: 'PN',
    isoCode3: 'PCN',
  },
  {
    country: 'Poland',
    countryCodes: ['48'],
    isoCode2: 'PL',
    isoCode3: 'POL',
  },
  {
    country: 'Portugal',
    countryCodes: ['351'],
    isoCode2: 'PT',
    isoCode3: 'PRT',
  },
  {
    country: 'Puerto Rico',
    countryCodes: ['1-787', '1-939'],
    isoCode2: 'PR',
    isoCode3: 'PRI',
  },
  {
    country: 'Qatar',
    countryCodes: ['974'],
    isoCode2: 'QA',
    isoCode3: 'QAT',
  },
  {
    country: 'Republic of the Congo',
    countryCodes: ['242'],
    isoCode2: 'CG',
    isoCode3: 'COG',
  },
  {
    country: 'Reunion',
    countryCodes: ['262'],
    isoCode2: 'RE',
    isoCode3: 'REU',
  },
  {
    country: 'Romania',
    countryCodes: ['40'],
    isoCode2: 'RO',
    isoCode3: 'ROU',
  },
  {
    country: 'Russia',
    countryCodes: ['7'],
    isoCode2: 'RU',
    isoCode3: 'RUS',
  },
  {
    country: 'Rwanda',
    countryCodes: ['250'],
    isoCode2: 'RW',
    isoCode3: 'RWA',
  },
  {
    country: 'Saint Barthelemy',
    countryCodes: ['590'],
    isoCode2: 'BL',
    isoCode3: 'BLM',
  },
  {
    country: 'Saint Helena',
    countryCodes: ['290'],
    isoCode2: 'SH',
    isoCode3: 'SHN',
  },
  {
    country: 'Saint Kitts and Nevis',
    countryCodes: ['1-869'],
    isoCode2: 'KN',
    isoCode3: 'KNA',
  },
  {
    country: 'Saint Lucia',
    countryCodes: ['1-758'],
    isoCode2: 'LC',
    isoCode3: 'LCA',
  },
  {
    country: 'Saint Martin',
    countryCodes: ['590'],
    isoCode2: 'MF',
    isoCode3: 'MAF',
  },
  {
    country: 'Saint Pierre and Miquelon',
    countryCodes: ['508'],
    isoCode2: 'PM',
    isoCode3: 'SPM',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    countryCodes: ['1-784'],
    isoCode2: 'VC',
    isoCode3: 'VCT',
  },
  {
    country: 'Samoa',
    countryCodes: ['685'],
    isoCode2: 'WS',
    isoCode3: 'WSM',
  },
  {
    country: 'San Marino',
    countryCodes: ['378'],
    isoCode2: 'SM',
    isoCode3: 'SMR',
  },
  {
    country: 'Sao Tome and Principe',
    countryCodes: ['239'],
    isoCode2: 'ST',
    isoCode3: 'STP',
  },
  {
    country: 'Saudi Arabia',
    countryCodes: ['966'],
    isoCode2: 'SA',
    isoCode3: 'SAU',
  },
  {
    country: 'Senegal',
    countryCodes: ['221'],
    isoCode2: 'SN',
    isoCode3: 'SEN',
  },
  {
    country: 'Serbia',
    countryCodes: ['381'],
    isoCode2: 'RS',
    isoCode3: 'SRB',
  },
  {
    country: 'Seychelles',
    countryCodes: ['248'],
    isoCode2: 'SC',
    isoCode3: 'SYC',
  },
  {
    country: 'Sierra Leone',
    countryCodes: ['232'],
    isoCode2: 'SL',
    isoCode3: 'SLE',
  },
  {
    country: 'Singapore',
    countryCodes: ['65'],
    isoCode2: 'SG',
    isoCode3: 'SGP',
  },
  {
    country: 'Sint Maarten',
    countryCodes: ['1-721'],
    isoCode2: 'SX',
    isoCode3: 'SXM',
  },
  {
    country: 'Slovakia',
    countryCodes: ['421'],
    isoCode2: 'SK',
    isoCode3: 'SVK',
  },
  {
    country: 'Slovenia',
    countryCodes: ['386'],
    isoCode2: 'SI',
    isoCode3: 'SVN',
  },
  {
    country: 'Solomon Islands',
    countryCodes: ['677'],
    isoCode2: 'SB',
    isoCode3: 'SLB',
  },
  {
    country: 'Somalia',
    countryCodes: ['252'],
    isoCode2: 'SO',
    isoCode3: 'SOM',
  },
  {
    country: 'South Africa',
    countryCodes: ['27'],
    isoCode2: 'ZA',
    isoCode3: 'ZAF',
  },
  {
    country: 'South Korea',
    countryCodes: ['82'],
    isoCode2: 'KR',
    isoCode3: 'KOR',
  },
  {
    country: 'South Sudan',
    countryCodes: ['211'],
    isoCode2: 'SS',
    isoCode3: 'SSD',
  },
  {
    country: 'Spain',
    countryCodes: ['34'],
    isoCode2: 'ES',
    isoCode3: 'ESP',
  },
  {
    country: 'Sri Lanka',
    countryCodes: ['94'],
    isoCode2: 'LK',
    isoCode3: 'LKA',
  },
  {
    country: 'Sudan',
    countryCodes: ['249'],
    isoCode2: 'SD',
    isoCode3: 'SDN',
  },
  {
    country: 'Suriname',
    countryCodes: ['597'],
    isoCode2: 'SR',
    isoCode3: 'SUR',
  },
  {
    country: 'Svalbard and Jan Mayen',
    countryCodes: ['47'],
    isoCode2: 'SJ',
    isoCode3: 'SJM',
  },
  {
    country: 'Swaziland',
    countryCodes: ['268'],
    isoCode2: 'SZ',
    isoCode3: 'SWZ',
  },
  {
    country: 'Sweden',
    countryCodes: ['46'],
    isoCode2: 'SE',
    isoCode3: 'SWE',
  },
  {
    country: 'Switzerland',
    countryCodes: ['41'],
    isoCode2: 'CH',
    isoCode3: 'CHE',
  },
  {
    country: 'Syria',
    countryCodes: ['963'],
    isoCode2: 'SY',
    isoCode3: 'SYR',
  },
  {
    country: 'Taiwan',
    countryCodes: ['886'],
    isoCode2: 'TW',
    isoCode3: 'TWN',
  },
  {
    country: 'Tajikistan',
    countryCodes: ['992'],
    isoCode2: 'TJ',
    isoCode3: 'TJK',
  },
  {
    country: 'Tanzania',
    countryCodes: ['255'],
    isoCode2: 'TZ',
    isoCode3: 'TZA',
  },
  {
    country: 'Thailand',
    countryCodes: ['66'],
    isoCode2: 'TH',
    isoCode3: 'THA',
  },
  {
    country: 'Togo',
    countryCodes: ['228'],
    isoCode2: 'TG',
    isoCode3: 'TGO',
  },
  {
    country: 'Tokelau',
    countryCodes: ['690'],
    isoCode2: 'TK',
    isoCode3: 'TKL',
  },
  {
    country: 'Tonga',
    countryCodes: ['676'],
    isoCode2: 'TO',
    isoCode3: 'TON',
  },
  {
    country: 'Trinidad and Tobago',
    countryCodes: ['1-868'],
    isoCode2: 'TT',
    isoCode3: 'TTO',
  },
  {
    country: 'Tunisia',
    countryCodes: ['216'],
    isoCode2: 'TN',
    isoCode3: 'TUN',
  },
  {
    country: 'Turkey',
    countryCodes: ['90'],
    isoCode2: 'TR',
    isoCode3: 'TUR',
  },
  {
    country: 'Turkmenistan',
    countryCodes: ['993'],
    isoCode2: 'TM',
    isoCode3: 'TKM',
  },
  {
    country: 'Turks and Caicos Islands',
    countryCodes: ['1-649'],
    isoCode2: 'TC',
    isoCode3: 'TCA',
  },
  {
    country: 'Tuvalu',
    countryCodes: ['688'],
    isoCode2: 'TV',
    isoCode3: 'TUV',
  },
  {
    country: 'U.S. Virgin Islands',
    countryCodes: ['1-340'],
    isoCode2: 'VI',
    isoCode3: 'VIR',
  },
  {
    country: 'Uganda',
    countryCodes: ['256'],
    isoCode2: 'UG',
    isoCode3: 'UGA',
  },
  {
    country: 'Ukraine',
    countryCodes: ['380'],
    isoCode2: 'UA',
    isoCode3: 'UKR',
  },
  {
    country: 'United Arab Emirates',
    countryCodes: ['971'],
    isoCode2: 'AE',
    isoCode3: 'ARE',
  },
  {
    country: 'United Kingdom',
    countryCodes: ['44'],
    isoCode2: 'GB',
    isoCode3: 'GBR',
  },
  {
    country: 'United States',
    countryCodes: ['1'],
    isoCode2: 'US',
    isoCode3: 'USA',
  },
  {
    country: 'Uruguay',
    countryCodes: ['598'],
    isoCode2: 'UY',
    isoCode3: 'URY',
  },
  {
    country: 'Uzbekistan',
    countryCodes: ['998'],
    isoCode2: 'UZ',
    isoCode3: 'UZB',
  },
  {
    country: 'Vanuatu',
    countryCodes: ['678'],
    isoCode2: 'VU',
    isoCode3: 'VUT',
  },
  {
    country: 'Vatican',
    countryCodes: ['379'],
    isoCode2: 'VA',
    isoCode3: 'VAT',
  },
  {
    country: 'Venezuela',
    countryCodes: ['58'],
    isoCode2: 'VE',
    isoCode3: 'VEN',
  },
  {
    country: 'Vietnam',
    countryCodes: ['84'],
    isoCode2: 'VN',
    isoCode3: 'VNM',
  },
  {
    country: 'Wallis and Futuna',
    countryCodes: ['681'],
    isoCode2: 'WF',
    isoCode3: 'WLF',
  },
  {
    country: 'Western Sahara',
    countryCodes: ['212'],
    isoCode2: 'EH',
    isoCode3: 'ESH',
  },
  {
    country: 'Yemen',
    countryCodes: ['967'],
    isoCode2: 'YE',
    isoCode3: 'YEM',
  },
  {
    country: 'Zambia',
    countryCodes: ['260'],
    isoCode2: 'ZM',
    isoCode3: 'ZMB',
  },
  {
    country: 'Zimbabwe',
    countryCodes: ['263'],
    isoCode2: 'ZW',
    isoCode3: 'ZWE',
  },
];
