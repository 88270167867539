import { Input } from 'reactstrap';

function AggregatorsSearchForm({ search, searchKey, spinner }) {
  return (
    <Input
      className="bo-search"
      type="search"
      name="search"
      id="search"
      placeholder="Search"
      noValidate
      disabled={spinner}
      defaultValue={search}
      onKeyPress={e => searchKey('aggregators', e)}
    />
  );
}

export default AggregatorsSearchForm;
