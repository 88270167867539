import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTableContext } from '../../../context/table-context';
import { memo } from 'react';

function ConsumerOpenFunding({
  consumerId,
  consumerFirstName,
  consumerLastName,
  isOpenFunding,
  openFundingId,
  getFunding,
  openFundingModal,
}) {
  const navigate = useNavigate();

  const { getTable, updateTable, setSelectedConsumerFilter, setSelectedFundingIdsFilter } =
    useTableContext();

  const getLink = () => {
    if (isOpenFunding && openFundingId) {
      return (
        <>
          <Link className="ms-2 bo-link" to={`/funding/${openFundingId}`}>
            {openFundingId.toString().padStart(6, '0')}
          </Link>
          <Button className="ms-1 bo-link" color="link" onClick={() => openFundingModal(true)}>
            Create Top-up
          </Button>
        </>
      );
    }

    if (isOpenFunding) {
      return (
        <>
          <span>No advance is currently open for this consumer</span>
          <Button className="ms-1 bo-link" color="link" onClick={() => openFundingModal(false)}>
            Create Advance
          </Button>
        </>
      );
    }

    return null;
  };

  return (
    <Row className="mb-2">
      <Col md={2} className="bo-same-line">
        <span>Open Advance:</span>
      </Col>
      <Col className="bo-same-line">
        <Button className="me-2 bo-link" color="link" onClick={() => getFunding(consumerId)}>
          <FontAwesomeIcon icon={faArrowsRotate} />
        </Button>
        {getLink()}
        <Button
          className="ms-2 bo-link"
          color="link"
          onClick={() => {
            setSelectedConsumerFilter({
              consumerId,
              consumerFirstName,
              consumerLastName,
              message: `${consumerFirstName} ${consumerLastName} (${consumerId}) → All Advances`,
            });

            const table = getTable({
              tableName: 'fundings',
              initSortBy: 'id',
              initSortOrder: 'desc',
              initRowsPerPage: 25,
              initStatusFilter: '',
            });

            updateTable({ tableName: 'fundings', newTable: { ...table, statusFilter: '' } });

            setSelectedFundingIdsFilter({ fundingIds: null, message: null });

            navigate('/fundings');
          }}
        >
          All Advances
        </Button>
      </Col>
    </Row>
  );
}

export default memo(ConsumerOpenFunding);
