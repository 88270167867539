import { useState } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import { useEnv } from '../../../context/env-context';
import { makeRequest } from '../../../utils/makeRequest';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import LazyTabPanel from '../../../components/LazyTabPanel';
// tabs
import ConsumerStatusSummary from './tabs/ConsumerStatusSummary';
import ConsumerBankAccount from './tabs/ConsumerBankAccount';
import ConsumerCards from './tabs/ConsumerCards';
import ConsumerKyc from './tabs/ConsumerKyc';
import ConsumerUserActivity from './tabs/ConsumerUserActivity';
import ConsumerJobs from './tabs/ConsumerJobs';
import ConsumerNotifications from './tabs/ConsumerNotifications';
import ConsumerPayroll from './tabs/ConsumerPayroll';
import ConsumerBridgePoints from './tabs/ConsumerBridgePoints';
import ConsumerAlerts from './tabs/ConsumerAlerts';
import ConsumerAuditTrail from './tabs/ConsumerAuditTrail';
import ConsumerGhosting from './tabs/ConsumerGhosting';
import ConsumerTestZone from './tabs/ConsumerTestZone';
import ConsumerRiskIndicators from './tabs/ConsumerRiskIndicators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

function ConsumerTabController({
  consumer,
  getConsumer,
  predict,
  profile,
  getProfile,
  refreshProfile,
  openBridgePointsModal,
  bridgePointsModal,
  openGhostingModal,
  openNewAlertModal,
  openLinkNewCardModal,
  linkNewCardModal,
}) {
  const params = useParams();

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [activeTab, setActiveTab] = useState('status');

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const setConsumerStatus = async (statusType, status) => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_consumer_status`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, [statusType]: status }),
    };

    try {
      await makeRequest(config);

      getConsumer(consumer.id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const riskIndicatorsAboveThreshold =
    profile &&
    profile.riskIndicators &&
    Object.values(profile.riskIndicators).filter(
      x => x.threshold !== null && x.value !== null && x.value > x.threshold,
    ).length > 0;

  return (
    <>
      <Nav className="bo-pointer mt-5" tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'status' })}
            onClick={() => toggle('status')}
          >
            Status
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'bank' })}
            onClick={() => toggle('bank')}
          >
            Bank Account
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'cards' })}
            onClick={() => toggle('cards')}
          >
            Cards
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'kyc' })}
            onClick={() => toggle('kyc')}
          >
            KYC
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'user' })}
            onClick={() => toggle('user')}
          >
            Activity
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'jobs' })}
            onClick={() => toggle('jobs')}
          >
            Jobs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'notifications' })}
            onClick={() => toggle('notifications')}
          >
            Notifications
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'payroll' })}
            onClick={() => toggle('payroll')}
          >
            Payroll
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'bridgePoints' })}
            onClick={() => toggle('bridgePoints')}
          >
            BridgePoints™
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'alerts' })}
            onClick={() => toggle('alerts')}
          >
            Alerts
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'audit_trail' })}
            onClick={() => toggle('audit_trail')}
          >
            Audit Trail
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'risk_indicators' })}
            onClick={() => toggle('risk_indicators')}
          >
            {riskIndicatorsAboveThreshold && (
              <FontAwesomeIcon icon={faWarning} size="1x" color="red" className="me-2" />
            )}
            Risk Indicators
          </NavLink>
        </NavItem>
        {consumer.ghostingUser && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'ghosting' })}
              onClick={() => toggle('ghosting')}
            >
              Ghosting
            </NavLink>
          </NavItem>
        )}
        {consumer.isTest && (
          <NavItem>
            <NavLink
              className={`${classnames({ active: activeTab === 'testZone' })} bg-danger`}
              onClick={() => toggle('testZone')}
            >
              Test Zone
            </NavLink>
          </NavItem>
        )}
      </Nav>
      {params.id === String(consumer.id) && (
        <TabContent activeTab={activeTab} className="bo-tabs">
          <LazyTabPanel activeTab={activeTab} tabId="status">
            <ConsumerStatusSummary
              consumerId={consumer.id}
              consumerBankAccountStatus={consumer.bankAccountStatus}
              consumerAccountDwollaFundingSourceUrl={consumer.account?.dwollaFundingSourceUrl}
              consumerProfileStatus={consumer.profileStatus}
              consumerPayrollStatus={consumer.payrollStatus}
              getConsumer={getConsumer}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="bank">
            <ConsumerBankAccount
              consumerId={consumer.id}
              consumerDwollaCustomerUrl={consumer.dwollaCustomerUrl}
              consumerBankAccountStatus={consumer.bankAccountStatus}
              consumerBankAccountStatusMessage={consumer.bankAccountStatusMessage}
              getConsumer={getConsumer}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="cards">
            <ConsumerCards
              consumerId={consumer.id}
              openLinkNewCardModal={openLinkNewCardModal}
              linkNewCardModal={linkNewCardModal}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="kyc">
            <ConsumerKyc
              consumerId={consumer.id}
              consumerKycStatus={consumer.kycStatus}
              consumerAmlStatus={consumer.amlStatus}
              consumerFraudIndicator={consumer.fraudIndicator}
              setConsumerStatus={setConsumerStatus}
              getConsumer={getConsumer}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="user">
            <ConsumerUserActivity consumerId={consumer.id} />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="jobs">
            <ConsumerJobs consumerId={consumer.id} />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="notifications">
            <ConsumerNotifications consumerId={consumer.id} />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="payroll">
            <ConsumerPayroll
              consumerId={consumer.id}
              consumerLockPayrollDay={consumer.lockPayrollDay}
              consumerPayrollFrequency={consumer.payrollFrequency}
              consumerPayrollDay1={consumer.payrollDay1}
              consumerPayrollDay2={consumer.payrollDay2}
              consumerPayrollStatus={consumer.payrollStatus}
              getConsumer={getConsumer}
              predict={predict}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="bridgePoints">
            <ConsumerBridgePoints
              consumerId={consumer.id}
              openBridgePointsModal={openBridgePointsModal}
              bridgePointsModal={bridgePointsModal}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="alerts">
            <ConsumerAlerts
              consumerId={consumer.id}
              consumerAlerts={consumer.alerts}
              openNewAlertModal={openNewAlertModal}
              getConsumer={getConsumer}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="audit_trail">
            <ConsumerAuditTrail consumerId={consumer.id} />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="risk_indicators">
            <ConsumerRiskIndicators
              consumerId={consumer.id}
              profile={profile}
              getProfile={getProfile}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="ghosting">
            <ConsumerGhosting
              consumerId={consumer.id}
              consumerGhostedConsumerId={consumer.ghostedConsumerId}
              openGhostingModal={openGhostingModal}
              getConsumer={getConsumer}
            />
          </LazyTabPanel>
          <LazyTabPanel activeTab={activeTab} tabId="testZone">
            <ConsumerTestZone
              consumerId={consumer.id}
              consumerIsTest={consumer.isTest}
              refreshProfile={refreshProfile}
            />
          </LazyTabPanel>
        </TabContent>
      )}
    </>
  );
}

export default ConsumerTabController;
