import { version } from '../version';
import { useEnv } from '../context/env-context';

function Footer() {
  const { envName } = useEnv();

  return (
    <footer className="bg-light p-3 text-center">
      <p className="bo-footer-text">
        {`Admin v${version.major}.${version.minor}.${version.build} (${envName})`}
      </p>
    </footer>
  );
}

export default Footer;
