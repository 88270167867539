import { Row, Col, Button } from 'reactstrap';
import ConsumersActions from './ConsumersActions';
import ConsumersAllAggregatorsForm from './forms/ConsumersAllAggregatorsForm';
import ConsumersStatusFilterForm from './forms/ConsumersStatusFilterForm';
import ConsumersSearchForm from './forms/ConsumersSearchForm';
import ConsumersBankStatusFilterForm from './forms/ConsumersBankStatusFilterForm';
import ConsumersRedFlagsFilterForm from './forms/ConsumersRedFlagsFilterForm';
import ConsumersQuickAccessForm from './forms/ConsumersQuickAccessForm';

function ConsumersFormController({
  spinner,
  setSpinner,
  selectedAggregatorFilter,
  changeAggregatorFilter,
  selectedConsumerIdsFilter,
  clearConsumerIdsFilter,
  statusFilter,
  changeStatusFilter,
  bankStatusFilter,
  changeBankStatusFilter,
  flagsFilter,
  changeFlagsFilter,
  search,
  searchKey,
}) {
  return (
    <Row className="mb-2 align-items-center">
      {selectedConsumerIdsFilter.consumerIds ? (
        <Col md={10} className="bo-same-line">
          <div className="mt-1 ms-2 d-flex align-items-center">
            <strong className="bo-text-bold">Filtered:</strong>
            <div className="bo-text ms-2">{selectedConsumerIdsFilter.message}</div>
            <Button
              className="bo-link py-0 px-2 ms-3 border-0"
              color="link"
              disabled={spinner}
              onClick={() => clearConsumerIdsFilter()}
            >
              Cancel Filter
            </Button>
          </div>
        </Col>
      ) : (
        <div className="d-xl-flex align-items-center">
          <div className="me-2 mb-2">
            <ConsumersActions
              selectedAggregatorFilter={selectedAggregatorFilter}
              spinner={spinner}
              setSpinner={setSpinner}
            />
          </div>
          <div className="d-flex flex-wrap align-items-center gap-2 mb-2">
            <ConsumersAllAggregatorsForm
              selectedAggregatorFilter={selectedAggregatorFilter}
              changeAggregatorFilter={changeAggregatorFilter}
              spinner={spinner}
            />
            <ConsumersStatusFilterForm
              statusFilter={statusFilter}
              changeStatusFilter={changeStatusFilter}
              spinner={spinner}
            />
            <ConsumersBankStatusFilterForm
              bankStatusFilter={bankStatusFilter}
              changeBankStatusFilter={changeBankStatusFilter}
              spinner={spinner}
            />
            <ConsumersRedFlagsFilterForm
              flagsFilter={flagsFilter}
              changeFlagsFilter={changeFlagsFilter}
              spinner={spinner}
            />
          </div>
          <div className="ms-auto d-flex gap-2 mb-2">
            <ConsumersQuickAccessForm />
            <ConsumersSearchForm search={search} searchKey={searchKey} spinner={spinner} />
          </div>
        </div>
      )}
    </Row>
  );
}

export default ConsumersFormController;
