import { Col, Label, Row, Input, Button } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { toast } from 'react-toastify';

function GrantPermissionsForm({
  allPermissions,
  users,
  selectedUserId,
  setSelectedUserId,
  userPermissions,
  setSpinner,
  getUsers,
  openModal,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const onUserInputChange = e => setSelectedUserId(e.target.value);

  const addPermission = async permission => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    try {
      await fetch(`${apiOriginConsumer}/add_permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: selectedUserId,
          permission,
        }),
      });

      await getUsers();
      await getAccessTokenSilently({
        ignoreCache: true,
      });
    } catch (error) {
      toast.error('Failed to add permission!');
    } finally {
      setSpinner(false);
    }
  };

  const removePermission = async permission => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    try {
      await fetch(`${apiOriginConsumer}/remove_permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: selectedUserId,
          permission,
        }),
      });

      await getUsers();
      await getAccessTokenSilently({
        ignoreCache: true,
      });
    } catch (error) {
      toast.error('Failed to remove permission!');
    } finally {
      setSpinner(false);
    }
  };

  const onCheckInputChange = e => {
    if (e.target.checked) {
      addPermission(e.target.name);
    } else {
      removePermission(e.target.name);
    }
  };

  return (
    <Row className="mt-3 mb-2">
      <Col md={2}>
        <Label>User:</Label>
      </Col>
      <Col md={4}>
        <Input
          className="bo-filter w-100"
          type="select"
          noValidate
          value={selectedUserId}
          onChange={onUserInputChange}
        >
          <option value="">Select User</option>
          {users.map(user => (
            <option key={user.user_id} value={user.user_id}>
              {user.email}
            </option>
          ))}
        </Input>
        <div className="mt-4">
          {allPermissions.map(permission => (
            <div key={permission}>
              <Label check>
                <Input
                  type="checkbox"
                  name={permission}
                  disabled={!selectedUserId}
                  checked={userPermissions.includes(permission)}
                  onChange={onCheckInputChange}
                />
                {permission}
              </Label>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <Button className="bo-link m-0 p-0" color="link" onClick={openModal}>
            Create Superuser
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default GrantPermissionsForm;
