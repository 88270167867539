import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import { Button } from 'reactstrap';
import {
  showIndicatorValueUnit,
  exportRiskIndicatorsFile,
  RISK_INDICATORS_ORDER,
} from '../../../../../utils/riskIndicators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import RiskIndicatorChart from '../../../../../components/RiskIndicatorChart';
import CustomTable from '../../../../../components/CustomTable';

function NewObjectRiskIndicatorsTab({
  consumerId,
  profileJson,
  getProfileJson,
  refreshTab,
  setRefreshTab,
}) {
  const [spinner, setSpinner] = useState(false);

  const getRiskIndicators = async () => {
    setSpinner(true);
    await getProfileJson(consumerId);
    setSpinner(false);
  };

  useEffect(() => {
    if (refreshTab === 'risk_indicators') {
      getRiskIndicators();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const riskIndicators = useMemo(() => {
    if (profileJson?.profile?.riskIndicators) {
      return Object.entries(profileJson?.profile?.riskIndicators)
        .filter(([key]) => RISK_INDICATORS_ORDER.includes(key))
        .sort((a, b) => RISK_INDICATORS_ORDER.indexOf(a[0]) - RISK_INDICATORS_ORDER.indexOf(b[0]))
        .map(([key, value]) => ({
          ...value,
          key,
        }));
    }

    return [];
  }, [profileJson]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'name',
        header: 'Indicator Name',
        width: 260,
      },
      {
        key: 'to',
        header: 'Period',
        width: 120,
        renderValue: (_, row) => `${moment(row.to).diff(row.from, 'days') + 1} Days`,
      },
      {
        key: 'value',
        header: 'Value',
        width: 140,
        renderValue: (_, row) => (
          <div className="d-flex align-items-center justify-content-between">
            <p className="p-0 m-0 text-end">
              {row.value !== null ? showIndicatorValueUnit(row.key, row.value) : 'N/A'}
            </p>
            {row.value !== null && row.threshold !== null && row.value > row.threshold && (
              <FontAwesomeIcon icon={faWarning} size="1x" color="red" className="ms-1" />
            )}
          </div>
        ),
      },
      {
        key: 'threshold',
        header: 'Threshold',
        width: 140,
        renderValue: (_, row) =>
          row.threshold !== null ? showIndicatorValueUnit(row.key, row.threshold) : 'N/A',
      },
      {
        key: 'data',
        header: '',
        width: 160,
        renderValue: (_, row) =>
          row.value !== null ? (
            <RiskIndicatorChart riskIndicator={row} riskIndicatorKey={row.key} />
          ) : null,
      },
      {
        key: 'data',
        header: '',
        width: 220,
        renderValue: (_, row) =>
          row.key === 'EL_P' && [...new Set(row.data.map(x => x.provider))].join(', '),
      },
    ],
    [],
  );

  return (
    <div className="h-100 w-100">
      <div className="m-3 d-flex align-items-center">
        <Button
          className="bo-new-object-button"
          disabled={spinner || !profileJson?.profile?.riskIndicators}
          onClick={() => exportRiskIndicatorsFile(profileJson.profile.riskIndicators)}
        >
          Download Data
        </Button>
        <p className="bo-new-object-text-bold ms-2">As of:</p>
        <p className="bo-new-object-text ms-1">
          {profileJson?.profile?.dateTime &&
            moment(profileJson?.profile.dateTime).utc().local().calendar({
              lastDay: '[Yesterday]',
              sameDay: '[Today]',
              nextDay: 'MMM DD, YYYY H:mm (UTCZ)',
              nextWeek: 'MMM DD, YYYY H:mm (UTCZ)',
              lastWeek: 'MMM DD, YYYY H:mm (UTCZ)',
              sameElse: 'MMM DD, YYYY H:mm (UTCZ)',
            })}
        </p>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <CustomTable
              tableRows={riskIndicators}
              tableColumns={tableColumns}
              width={width}
              height={height - 58}
              rowHeight={42}
              headerTopBorder
              rowKey="key"
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(NewObjectRiskIndicatorsTab);
