import { memo } from 'react';
import { Badge, Col, Label } from 'reactstrap';
import { isFundingBlind } from '../../../../utils/fundings';

function FundingBlindCollectionForm({
  fundingStatus,
  fundingRepaymentMode,
  consumerBankAccountStatus,
}) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Blind Collection:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Badge
          className="bo-badge"
          color={
            isFundingBlind(fundingStatus, fundingRepaymentMode, consumerBankAccountStatus)
              ? 'danger'
              : 'success'
          }
        >
          {isFundingBlind(fundingStatus, fundingRepaymentMode, consumerBankAccountStatus)
            ? 'Yes'
            : 'No'}
        </Badge>
      </Col>
      <Col md={2} className="bo-same-line" />
    </>
  );
}

export default memo(FundingBlindCollectionForm);
