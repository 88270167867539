import { Circle, Path, Svg } from '@react-pdf/renderer';
import { styles } from './styles';

function CircleChart({
  progress = 0.25,
  progressColor = '#16BFBB',
  radius = 40,
  cx = 50,
  cy = 50,
}) {
  const createCirclePath = () => {
    if (progress >= 1) {
      return `
          M ${cx},${cy - radius}
          A ${radius},${radius} 0 1,1 ${cx},${cy + radius}
          A ${radius},${radius} 0 1,1 ${cx},${cy - radius}
        `;
    }

    const startAngle = -Math.PI / 2;
    const endAngle = startAngle + progress * 2 * Math.PI;

    const x1 = cx + radius * Math.cos(startAngle);
    const y1 = cy + radius * Math.sin(startAngle);

    const x2 = cx + radius * Math.cos(endAngle);
    const y2 = cy + radius * Math.sin(endAngle);

    const largeArcFlag = progress > 0.5 ? 1 : 0;

    return `
      M ${x1} ${y1}
      A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}
    `;
  };

  const progressPath = createCirclePath();

  return (
    <Svg style={styles.svgContainer} viewBox="0 0 100 100">
      <Circle cx={cx} cy={cy} r={radius} stroke="#F0EDF3" strokeWidth="4" fill="none" />
      <Path d={progressPath} stroke={progressColor} strokeWidth="10" fill="none" />
    </Svg>
  );
}

export default CircleChart;
