import { useLayoutEffect, useState } from 'react';
import { TabPane } from 'reactstrap';

function LazyTabPanel({ activeTab, tabId, children }) {
  const [entered, setEntered] = useState(false);

  useLayoutEffect(() => {
    if (activeTab === tabId) {
      setEntered(true);
    }
  }, [activeTab, tabId]);

  return entered ? (
    <TabPane tabId={tabId} className="h-100">
      {children}
    </TabPane>
  ) : null;
}

export default LazyTabPanel;
