import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { PatternFormat } from 'react-number-format';

function DateOfBirthForm({ consumerDateOfBirth, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Date of birth:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <PatternFormat
          name="dateOfBirth"
          id="dateOfBirth"
          format="##/##/####"
          mask="_"
          className="bo-input"
          allowEmptyFormatting
          customInput={Input}
          value={consumerDateOfBirth || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(DateOfBirthForm);
