import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateRight,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import LazyTabPanel from '../../../../components/LazyTabPanel';
import CollapsedPanel from '../../../../components/CollapsedPanel';
import NewObjectProfileActionSection from './sections/NewObjectProfleActionSection';
import NewObjectRiskIndicatorsTab from './tabs/NewObjectRiskIndicatorsTab';
import NewObjectPayrollTab from './tabs/NewObjectPayrollTab';
import NewObjectTransactionsTab from './tabs/NewObjectTransactionsTab';
import NewObjectRecurringStreamsTab from './tabs/NewObjectRecurringStreamsTab';
import NewObjectPrediction from './tabs/NewObjectPrediction';

const PROFILE_PANEL_TABS = {
  risk_indicators: 'Risk Indicators',
  payroll: 'Payroll',
  transactions: 'Transactions',
  recurring_streams: 'Recurring Streams',
  prediction: 'Prediction',
};

function NewObjectProfilePanel({
  collapsedMode,
  collapsedPanels,
  setCollapsedPanels,
  expandChildPanel,
  detailsRef,
  bankRef,
  profileRef,
  activityRef,
  childGroupDefaultSizes,
  consumer,
  getConsumer,
  profileJson,
  getProfileJson,
  profileActiveTab,
  toggleProfileActiveTab,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const [refreshTab, setRefreshTab] = useState('');

  const [globalRecurringStreamId, setGlobalRecurringStreamId] = useState(null);

  const refresh = useCallback(() => setRefreshTab(profileActiveTab), [profileActiveTab]);

  useEffect(() => {
    if (panelDataRefresh.profile) {
      refresh();
      setPanelDataRefresh(prev => ({ ...prev, profile: false }));
    }
  }, [panelDataRefresh, setPanelDataRefresh, refresh]);

  const riskIndicatorsAboveThreshold =
    profileJson?.profile?.riskIndicators &&
    Object.values(profileJson.profile.riskIndicators).filter(
      x => x.threshold !== null && x.value !== null && x.value > x.threshold,
    ).length > 0;

  const toggleProfilePanel = () => {
    if (collapsedPanels.details && collapsedPanels.activity && collapsedPanels.bank) {
      setCollapsedPanels(prev => ({ ...prev, details: false, activity: false, bank: false }));
      activityRef.current?.expand();
      detailsRef.current?.expand();
      bankRef.current?.expand();
    } else {
      setCollapsedPanels(prev => ({ ...prev, details: true, activity: true, bank: true }));
      activityRef.current?.collapse();
      detailsRef.current?.collapse();
      bankRef.current?.collapse();
    }
  };

  if (collapsedPanels.profile) {
    return (
      <CollapsedPanel
        side="down"
        onClick={() => expandChildPanel(profileRef, childGroupDefaultSizes[0])}
      />
    );
  }

  return (
    <div className="h-100 p-2 d-flex flex-column">
      <NewObjectProfileActionSection consumerId={consumer.id} />
      <div className="d-flex justify-content-between">
        <Nav className="bo-new-object-nav border-bottom-0" tabs>
          {Object.entries(PROFILE_PANEL_TABS).map(([key, value]) => (
            <NavItem key={key}>
              <NavLink
                className={`bo-new-object-nav-link ${classnames({
                  'bo-new-object-nav-link-active': profileActiveTab === key,
                })}`}
                onClick={() => toggleProfileActiveTab(key)}
              >
                {((key === 'risk_indicators' && riskIndicatorsAboveThreshold) ||
                  (key === 'payroll' && consumer.payrollStatus !== 'OK')) && (
                  <FontAwesomeIcon icon={faWarning} size="1x" color="red" className="me-1" />
                )}
                {value}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <div className="d-flex">
          <Button
            className="bo-new-object-inline-button me-2 d-flex align-items-center"
            onClick={refresh}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} size="1x" color="#5946A4" />
          </Button>
          {!collapsedMode && (
            <Button
              className="bo-new-object-inline-button me-2 d-flex align-items-center"
              onClick={toggleProfilePanel}
            >
              <FontAwesomeIcon
                icon={
                  collapsedPanels.details && collapsedPanels.activity && collapsedPanels.bank
                    ? faCompressArrowsAlt
                    : faExpandArrowsAlt
                }
                size="1x"
                color="#5946A4"
              />
            </Button>
          )}
        </div>
      </div>
      <TabContent activeTab={profileActiveTab} className="bo-new-object-tabs">
        <LazyTabPanel activeTab={profileActiveTab} tabId="risk_indicators">
          <NewObjectRiskIndicatorsTab
            consumerId={consumer?.id}
            profileJson={profileJson}
            getProfileJson={getProfileJson}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="payroll">
          <NewObjectPayrollTab
            consumer={consumer}
            getConsumer={getConsumer}
            profileJson={profileJson}
            getProfileJson={getProfileJson}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="transactions">
          <NewObjectTransactionsTab
            consumerId={consumer?.id}
            profileJson={profileJson}
            getProfileJson={getProfileJson}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
            globalRecurringStreamId={globalRecurringStreamId}
            setGlobalRecurringStreamId={setGlobalRecurringStreamId}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="recurring_streams">
          <NewObjectRecurringStreamsTab
            consumerId={consumer?.id}
            profileJson={profileJson}
            getProfileJson={getProfileJson}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
            setGlobalRecurringStreamId={setGlobalRecurringStreamId}
            toggleProfileActiveTab={toggleProfileActiveTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={profileActiveTab} tabId="prediction">
          <NewObjectPrediction
            consumerId={consumer?.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
      </TabContent>
    </div>
  );
}

export default NewObjectProfilePanel;
