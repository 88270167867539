import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Button, Row, Col, Container } from 'reactstrap';

function DashboardSettings() {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [status, setStatus] = useState(null);

  const getStatus = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/get_status`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setStatus(data);
    } catch (error) {
      setStatus('Error');
    }
  };

  const setSystemStatus = async systemStatus => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/set_status`,
      method: 'POST',
      data: JSON.stringify({ status: systemStatus }),
    };

    try {
      const data = await makeRequest(config);

      setStatus(data);
    } catch (error) {
      setStatus('Error');
    }
  };

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => getStatus();

  const renderStatus = () => {
    if (status === 'up') {
      return 'Turned Off';
    }

    if (status === 'down') {
      return 'Turned On';
    }

    return '...';
  };

  return (
    <Card className="mt-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Settings
        <Button className="bo-button-inline-link" color="link" onClick={() => refresh()}>
          Refresh
        </Button>
      </CardHeader>
      <CardBody>
        <Container>
          <Row className="align-items-center">
            <Col>
              <span>
                Maintenance Notice: &nbsp;
                <strong>{renderStatus()}</strong>
              </span>
              {status === 'up' && (
                <Button
                  className="bo-button-inline-link"
                  color="link"
                  onClick={() => setSystemStatus(1)}
                >
                  Turn On
                </Button>
              )}
              {status === 'down' && (
                <Button
                  className="bo-button-inline-link"
                  color="link"
                  onClick={() => setSystemStatus(0)}
                >
                  Turn Off
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
}

export default DashboardSettings;
