import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { BarElementPath, DefaultChartsAxisTooltipContent, SparkLineChart } from '@mui/x-charts';
import {
  getRiskIndicatorChart,
  getRiskIndicatorTooltipLabel,
  getRiskIndicatorTooltipValue,
} from '../utils/riskIndicators';

const BarElement = memo(({ containerHeight, style, ...restProps }) => (
  <>
    <BarElementPath {...restProps} style={style} />
    <BarElementPath
      {...restProps}
      style={{
        ...style,
        height: 0.5,
        y: containerHeight - 5,
      }}
    />
  </>
));

const AxisContent = memo(({ series, dataIndex, metadata, riskIndicatorKey, ...restProps }) => (
  <DefaultChartsAxisTooltipContent
    {...restProps}
    dataIndex={dataIndex}
    series={[
      {
        ...series[0],
        label: getRiskIndicatorTooltipLabel(riskIndicatorKey, dataIndex, metadata),
        valueFormatter: value =>
          value === null ? '' : getRiskIndicatorTooltipValue(riskIndicatorKey, value),
      },
    ]}
  />
));

function RiskIndicatorChart({ riskIndicatorKey, riskIndicator }) {
  const { xAxisData, metadata, data } = useMemo(
    () => getRiskIndicatorChart(riskIndicatorKey, riskIndicator),
    [riskIndicatorKey, riskIndicator],
  );

  const containerHeight = 36;
  const containerWidth = 140;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SparkLineChart
        plotType="bar"
        xAxis={{ scaleType: 'band', data: xAxisData }}
        slots={{
          // eslint-disable-next-line react/no-unstable-nested-components
          bar: props => <BarElement {...props} containerHeight={containerHeight} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          axisContent: props => (
            <AxisContent {...props} riskIndicatorKey={riskIndicatorKey} metadata={metadata} />
          ),
        }}
        data={data}
        height={containerHeight}
        width={containerWidth}
        showTooltip
        showHighlight
      />
    </Box>
  );
}

export default memo(RiskIndicatorChart);
