import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Button, Input } from 'reactstrap';

const CONSUMER_STATUS_BG_COLORS = {
  ACTIVE: 'bg-success',
  PENDING: 'bg-warning',
  MONITOR_ONLY: 'bg-info',
  SUSPENDED: 'bg-danger',
  TERMINATED: 'bg-secondary',
};

const CONSUMER_STATUS_COLORS = {
  ACTIVE: 'text-white',
  PENDING: 'text-black',
  MONITOR_ONLY: 'text-white',
  SUSPENDED: 'text-white',
  TERMINATED: 'text-white',
};

function DashboardConsumers({ aggregators, getAggregators }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [consumersByStatus, setConsumersByStatus] = useState({});
  const [billableConsumers, setBillableConsumers] = useState(null);
  const [allSubscribersToDate, setAllSubscribersToDate] = useState(null);
  const [aggregatorId, setAggregatorId] = useState();

  const getConsumers = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_counts_by_aggregator`,
      method: 'GET',
      params: {
        aggregatorId,
      },
    };

    try {
      const response = await makeRequest(config);

      setAllSubscribersToDate(response.allSubscribersToDate);
      setBillableConsumers(response.billableConsumers);
      setConsumersByStatus(response.byStatus);
    } catch (error) {
      toast.error(`Failed getting consumers: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getConsumers();
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatorId]);

  const changeAggregatorFilter = e => setAggregatorId(e.target.value);

  return (
    <Card className="mb-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Consumers
        <div className="d-flex align-items-center">
          <Input
            className="bo-filter w-100 ms-3"
            type="select"
            name="aggregatorFilter"
            id="aggregatorFilter"
            placeholder="filter"
            noValidate
            value={aggregatorId}
            onChange={changeAggregatorFilter}
          >
            <option value="">All Standard Aggregators</option>
            {aggregators.map(agg => (
              <option key={agg.id} value={agg.id}>
                {agg.name}
              </option>
            ))}
          </Input>
          <Button
            className="bo-button-inline-link"
            color="link"
            onClick={() => {
              getConsumers();
              getAggregators();
            }}
          >
            Refresh
          </Button>
        </div>
      </CardHeader>
      <Spinner visible={spinner} text="">
        {Object.entries(consumersByStatus).length > 0 && (
          <CardBody>
            <div className="d-flex justify-content-center flex-wrap">
              {Object.entries(consumersByStatus).map(([key, value]) => (
                <div
                  key={key}
                  className={`bo-border-container rounded m-1 \
                ${CONSUMER_STATUS_BG_COLORS[key]} bo-w-120`}
                >
                  <p className={`text-capitalize bo-badge ${CONSUMER_STATUS_COLORS[key]}`}>
                    {key.split('_').join(' ').toLowerCase()}
                  </p>
                  <p className={`text-center h4 ${CONSUMER_STATUS_COLORS[key]}`}>
                    {Number(value).toLocaleString('en-US', {
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
              ))}
            </div>
          </CardBody>
        )}
        <CardBody className="bo-min-h-100">
          <div className="d-flex overflow-auto justify-content-center">
            {billableConsumers !== null && (
              <div className="bo-border-container rounded mx-1 bo-w-200">
                <p className="text-capitalize bo-badge">Billable Consumers</p>
                <p className="text-center h4">
                  {Number(billableConsumers).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
            )}
            {allSubscribersToDate !== null && (
              <div className="bo-border-container rounded mx-1 bo-w-200">
                <p className="text-capitalize bo-badge">Subscribes to date</p>
                <p className="text-center h4">
                  {Number(allSubscribersToDate).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
            )}
          </div>
        </CardBody>
      </Spinner>
    </Card>
  );
}

export default DashboardConsumers;
