import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function AggregatorsActions() {
  const navigate = useNavigate();

  const onClick = () => navigate('/aggregator/new');

  return (
    <Button className="bo-button" onClick={onClick}>
      New Aggregator
    </Button>
  );
}

export default AggregatorsActions;
