import { useEffect, useRef, useState } from 'react';
import { states } from '../../../../../utils/states';
// API
import { toast } from 'react-toastify';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import {
  BasisTheoryApiError,
  BasisTheoryValidationError,
  useBasisTheory,
} from '@basis-theory/basis-theory-react';
// components
import { Button, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import CardProviderImage from '../../../../../components/CardProviderImage';
import NewObjectCardInputForm from './NewObjectCardInputForm';
import NewObjectBillingAddressForm from './NewObjectBillingAddressForm';

function NewObjectPopoverNewCardForm({
  getAstraUser,
  consumerId,
  consumerFirstName,
  consumerLastName,
  consumerAddressLine1,
  consumerAddressLine2,
  consumerCity,
  consumerState,
  consumerZip,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { bt } = useBasisTheory(process.env.REACT_APP_BT_PUBLIC_KEY, {
    elements: true,
  });

  const cardNumberRef = useRef(null);
  const cardExpirationDateRef = useRef(null);
  const cardVerificationCodeRef = useRef(null);

  const [btElementsEvents, setBtElementsEvents] = useState({
    cardExpirationDate: {
      ready: false,
      focused: false,
      dirty: false,
      changeEvent: null,
    },
    cvc: { ready: false, focused: false, dirty: false, changeEvent: null },
    cardNumber: {
      ready: false,
      focused: false,
      dirty: false,
      changeEvent: null,
    },
  });

  const [btMetadata, setBtMetadata] = useState({
    firstName: { value: '', dirty: false },
    lastName: { value: '', dirty: false },
    addressLine1: { value: '', dirty: false },
    addressLine2: { value: '', dirty: false },
    city: { value: '', dirty: false },
    state: { value: '', dirty: false },
    zip: { value: '', dirty: false },
  });

  const [useProfileAddress, setUseProfileAddress] = useState(true);

  useEffect(() => {
    if (useProfileAddress) {
      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: consumerAddressLine1, dirty: false },
        addressLine2: { value: consumerAddressLine2, dirty: false },
        city: { value: consumerCity, dirty: false },
        state: { value: consumerState, dirty: false },
        zip: { value: consumerZip, dirty: false },
      }));
    } else {
      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: '', dirty: false },
        addressLine2: { value: '', dirty: false },
        city: { value: '', dirty: false },
        state: { value: '', dirty: false },
        zip: { value: '', dirty: false },
      }));
    }
  }, [
    consumerAddressLine1,
    consumerAddressLine2,
    consumerCity,
    consumerState,
    consumerZip,
    useProfileAddress,
  ]);

  const handleToggle = () => {
    cardNumberRef.current?.clear();
    cardExpirationDateRef.current?.clear();
    cardVerificationCodeRef.current?.clear();

    setBtElementsEvents({
      cardExpirationDate: { ready: false, focused: false, dirty: false, changeEvent: null },
      cvc: { ready: false, focused: false, dirty: false, changeEvent: null },
      cardNumber: { ready: false, focused: false, dirty: false, changeEvent: null },
    });

    setBtMetadata({
      firstName: { value: consumerFirstName, dirty: false },
      lastName: { value: consumerLastName, dirty: false },
      addressLine1: { value: consumerAddressLine1, dirty: false },
      addressLine2: { value: consumerAddressLine2, dirty: false },
      city: { value: consumerCity, dirty: false },
      state: { value: consumerState, dirty: false },
      zip: { value: consumerZip, dirty: false },
    });

    setUseProfileAddress(true);

    setIsOpen(!isOpen);
  };

  const createBtToken = async () => {
    try {
      const btToken = await bt?.tokens.create({
        type: 'card',
        data: {
          number: cardNumberRef.current,
          expiration_month: cardExpirationDateRef.current.month(),
          expiration_year: cardExpirationDateRef.current.year(),
          cvc: cardVerificationCodeRef.current,
        },
        metadata: {
          firstName: btMetadata.firstName.value,
          lastName: btMetadata.lastName.value,
          addressLine1: btMetadata.addressLine1.value,
          addressLine2: btMetadata.addressLine2.value || undefined,
          city: btMetadata.city.value,
          state: btMetadata.state.value,
          zip: btMetadata.zip.value,
        },
      });

      return btToken;
    } catch (error) {
      if (error instanceof BasisTheoryValidationError) {
        console.error(error);
      } else if (error instanceof BasisTheoryApiError) {
        console.error(error);
      }

      toast.error('Unknown Error');

      return null;
    }
  };

  const linkCard = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const btToken = await createBtToken();

    try {
      const config = {
        url: `${apiOriginConsumer}/cards/link`,
        token,
        method: 'POST',
        data: JSON.stringify({
          btTokenId: btToken.id,
          isAdhoc: false,
          allowPending: true,
          consumerId,
        }),
      };

      await makeRequest(config);
      await getAstraUser(consumerId);

      handleToggle();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const formValid =
    btElementsEvents.cardNumber.ready &&
    btElementsEvents.cardNumber.changeEvent &&
    btElementsEvents.cardNumber.changeEvent.valid &&
    (btElementsEvents.cardNumber.changeEvent.cardBrand === 'mastercard' ||
      btElementsEvents.cardNumber.changeEvent.cardBrand === 'visa') &&
    btElementsEvents.cardExpirationDate.ready &&
    btElementsEvents.cardExpirationDate.changeEvent &&
    btElementsEvents.cardExpirationDate.changeEvent.valid &&
    btElementsEvents.cvc.ready &&
    btElementsEvents.cvc.changeEvent &&
    btElementsEvents.cvc.changeEvent.valid &&
    btMetadata.firstName.value.length !== 0 &&
    btMetadata.lastName.value.length !== 0 &&
    btMetadata.addressLine1.value.length !== 0 &&
    btMetadata.city.value.length !== 0 &&
    states.includes(btMetadata.state.value) &&
    btMetadata.zip.value.length === 5;

  return (
    <>
      <Button
        id="popover_new_card_form"
        className="bo-new-object-button px-2"
        onClick={handleToggle}
      >
        <p className="bo-new-object-text-sm">Link Card</p>
      </Button>
      <Popover
        target="popover_new_card_form"
        isOpen={isOpen}
        placement="bottom-end"
        toggle={handleToggle}
        offset={[0, 6]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu bo-w-300">
          <Spinner
            visible={
              spinner ||
              !btElementsEvents.cardNumber.ready ||
              !btElementsEvents.cardExpirationDate.ready ||
              !btElementsEvents.cvc.ready
            }
            size={36}
          >
            <div className="mt-2 d-flex align-items-center justify-content-center">
              <CardProviderImage company="visa" height={14} className="me-3" />
              <CardProviderImage company="mastercard" height={20} />
            </div>
            <NewObjectCardInputForm
              bt={bt}
              btElementsEvents={btElementsEvents}
              btMetadata={btMetadata}
              setBtElementsEvents={setBtElementsEvents}
              setBtMetadata={setBtMetadata}
              cardNumberRef={cardNumberRef}
              cardExpirationDateRef={cardExpirationDateRef}
              cardVerificationCodeRef={cardVerificationCodeRef}
            />
            <NewObjectBillingAddressForm
              btMetadata={btMetadata}
              setBtMetadata={setBtMetadata}
              useProfileAddress={useProfileAddress}
              setUseProfileAddress={setUseProfileAddress}
            />
            <div className="mt-2 d-flex justify-content-between">
              <Button className="bo-new-object-button" onClick={linkCard} disabled={!formValid}>
                Link
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverNewCardForm;
