import { memo } from 'react';
import { Col, Label, Badge } from 'reactstrap';

function FundingPayrollDelayedForm({ fundingIsDelayedAfterPayrollCluster }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Payroll Delayed:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Badge
          className="bo-badge"
          color={fundingIsDelayedAfterPayrollCluster ? 'danger' : 'success'}
        >
          {fundingIsDelayedAfterPayrollCluster ? 'Yes' : 'No'}
        </Badge>
      </Col>
    </>
  );
}
export default memo(FundingPayrollDelayedForm);
