import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function AggregatorsQuickAccessForm() {
  const navigate = useNavigate();

  return (
    <Input
      className="bo-input bo-w-120"
      type="quickAccess"
      name="quickAccess"
      id="quickAccess"
      placeholder="Aggregator ID"
      noValidate
      onKeyDown={e => {
        if (e.code === 'Enter' && e.target.value !== '') {
          navigate(`/aggregator/${e.target.value}`);
        }
      }}
    />
  );
}

export default AggregatorsQuickAccessForm;
