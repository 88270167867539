import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { makeRequest } from '../../../../utils/makeRequest';
import { nextYears } from '../../../../utils/dates';

function ReportsAggregatorMonthlyModalForm({ close, setSpinner, createReport }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [aggregatorId, setAggregatorId] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  const [aggregators, setAggregators] = useState([]);

  const getAggregators = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);
    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const data = await makeRequest(config);

      setAggregators(data);
    } catch (error) {
      toast.error(`Faled getting aggregators: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    if (!year) {
      toast.error('Year is required');

      return;
    }

    if (!month) {
      toast.error('Month is required');

      return;
    }

    createReport({
      aggregatorId: Number(aggregatorId),
      year: Number(year),
      month: Number(month),
    });
  };

  return (
    <>
      <Row className="align-items-center">
        <Col md={4} className="bo-same-line">
          <Label>Aggregator:</Label>
        </Col>
        <Col md={8} className="bo-same-line">
          <Input
            className="bo-filter bo-w-250"
            type="select"
            name="aggregators"
            id="aggregators"
            placeholder="aggregators"
            noValidate
            value={aggregatorId}
            onChange={e => setAggregatorId(e.target.value)}
          >
            <option value="">All Standard Aggregators</option>
            {aggregators.map(agg => (
              <option key={agg.id} value={agg.id}>
                {agg.name}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className="mt-2 mb-2 align-items-center">
        <Col md={4} className="bo-same-line">
          <Label>Year:</Label>
        </Col>
        <Col md={8} className="bo-same-line">
          <Input
            className="bo-filter bo-w-250"
            type="select"
            noValidate
            value={year}
            onChange={e => setYear(e.target.value)}
          >
            <option value="">Select Year</option>
            {nextYears(8, 2023).map(y => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className="mt-2 mb-2 align-items-center">
        <Col md={4} className="bo-same-line">
          <Label>Month:</Label>
        </Col>
        <Col md={8} className="bo-same-line">
          <Input
            className="bo-filter bo-w-250"
            type="select"
            noValidate
            value={month}
            onChange={e => setMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {[...Array(12).keys()].map(m => (
              <option key={m} value={m + 1}>
                {m + 1}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-5">
          <Button className="bo-button" onClick={handleCreate}>
            Create
          </Button>
          <Button className="ms-3 bo-button" onClick={close}>
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ReportsAggregatorMonthlyModalForm;
