import { Input } from 'reactstrap';

function AggregatorsStatusFilterForm({ statusFilter, changeStatusFilter, spinner }) {
  return (
    <Input
      className="bo-filter"
      type="select"
      name="filter"
      id="filter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={statusFilter}
      onChange={e => changeStatusFilter('aggregators', e.target.value)}
    >
      <option value="">All Statuses</option>
      <option value="ACTIVE">Active</option>
      <option value="TERMINATED">Terminated</option>
    </Input>
  );
}

export default AggregatorsStatusFilterForm;
