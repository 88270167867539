import { useState, useEffect } from 'react';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Card, CardHeader, CardBody, Badge, Button } from 'reactstrap';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

function DashboardServicesHealth() {
  const {
    apiOriginAggregator,
    apiOriginConsumer,
    apiOriginMonitor,
    apiOriginMonitorWorker,
    apiOriginScheduler,
    apiOriginFunding,
    apiOriginOrchestrator,
    apiOriginNotifications,
    apiOriginReports,
  } = useEnv();

  const origins = {
    aggregators: apiOriginAggregator,
    consumers: apiOriginConsumer,
    monitor: apiOriginMonitor,
    monitorWorker: apiOriginMonitorWorker,
    scheduler: apiOriginScheduler,
    funding: apiOriginFunding,
    orchestrator: apiOriginOrchestrator,
    notifications: apiOriginNotifications,
    reports: apiOriginReports,
  };

  const servicesInitialState = Object.keys(origins).reduce(
    (acc, value) => ({
      ...acc,
      [value]: {
        status: 'Loading',
        environment: '',
        version: '',
      },
    }),
    {},
  );

  const [services, setServices] = useState(servicesInitialState);

  const getServicesHealthCheck = () => {
    Object.entries(origins).forEach(async ([key, value]) => {
      const config = {
        url: `${value}/healthCheck`,
        method: 'GET',
      };

      setServices(prevServices => ({
        ...prevServices,
        [key]: {
          status: 'Loading',
          environment: '',
          version: '',
        },
      }));

      try {
        const hc = await makeRequest(config);

        setServices(prevServices => ({
          ...prevServices,
          [key]: {
            status: 'Ok',
            environment: hc.environment,
            version: hc.version,
          },
        }));
      } catch (error) {
        setServices(prevServices => ({
          ...prevServices,
          [key]: {
            status: 'Error',
            environment: '',
            version: '',
          },
        }));
      }
    });
  };

  useEffect(() => {
    getServicesHealthCheck();
    const interval = setInterval(getServicesHealthCheck, 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshServices = () => getServicesHealthCheck();

  const statusBadge = {
    Ok: (
      <Badge className="bo-inline-badge" color="success">
        On-line
      </Badge>
    ),
    Error: (
      <Badge className="bo-inline-badge" color="danger">
        Off-line
      </Badge>
    ),
    Loading: 'Loading...',
  };

  return (
    <Card>
      <CardHeader className="card-header d-flex justify-content-between">
        Services Health
        <Button className="bo-button-inline-link" color="link" onClick={() => refreshServices()}>
          Refresh
        </Button>
      </CardHeader>
      <CardBody>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Service Name</StyledTableCell>
                <StyledTableCell align="left">Env.</StyledTableCell>
                <StyledTableCell align="left">Origin</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Version</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Aggregators Manager</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.aggregators.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginAggregator}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.aggregators.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.aggregators.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Consumers Manager</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.consumers.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginConsumer}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.consumers.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.consumers.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Monitoring Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.monitor.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginMonitor}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.monitor.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.monitor.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Monitoring Worker Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.monitorWorker.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginMonitorWorker}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.monitorWorker.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.monitorWorker.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Funding Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.funding.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginFunding}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.funding.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.funding.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Scheduler Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.scheduler.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginScheduler}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.scheduler.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.scheduler.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Orchestrator Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.orchestrator.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginOrchestrator}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.orchestrator.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.orchestrator.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Notifications Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.notifications.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginNotifications}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.notifications.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.notifications.version}</StyledTableCell>
              </TableRow>
              <TableRow hover tabIndex={-1}>
                <StyledTableCell align="left">
                  <strong>Reports Service</strong>
                </StyledTableCell>
                <StyledTableCell align="left">{services.reports.environment}</StyledTableCell>
                <StyledTableCell align="left">{apiOriginReports}</StyledTableCell>
                <StyledTableCell align="left">
                  {statusBadge[services.reports.status]}
                </StyledTableCell>
                <StyledTableCell align="left">{services.reports.version}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default DashboardServicesHealth;
