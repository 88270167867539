import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingRepaymentsAmountForm({ fundingRepaymentsSum, fundingRepaymentsCount }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Repayments Amount:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          align="right"
          className="bo-input"
          type="text"
          name="repayment"
          id="repayments"
          value={fundingRepaymentsSum}
          readOnly
        />
      </Col>
      <Col md={2} className="bo-same-line">
        ({fundingRepaymentsCount} repayments)
      </Col>
      <Col md={1} className="bo-same-line" />
    </>
  );
}

export default memo(FundingRepaymentsAmountForm);
