import { Col, Input, Label, Row } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { memo } from 'react';
import { COLLECTION_POLICY_CODE } from '../../../../constants/fundings';

function FundingCollectionPolicy({ fundingId, fundingCollectionPolicy, getFunding, setSpinner }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const setCollectionPolicyCode = async policyCode => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/set_collection_policy`,
      method: 'POST',
      data: JSON.stringify({ fundingId, policyCode }),
    };

    try {
      await makeRequest(config);
      await getFunding(fundingId);

      toast.success('Collection policy code updated!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const setCustomCollectionPolicyField = async (fieldName, fieldValue) => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/set_custom_collection_policy_field`,
      method: 'POST',
      data: JSON.stringify({ fundingId, fieldName, fieldValue }),
    };

    try {
      await makeRequest(config);
      await getFunding(fundingId);

      toast.success('Custom collection policy fields updated!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <Row className="ms-3 mt-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label className="bo-text-bold">Policy:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            noValidate
            value={fundingCollectionPolicy.code}
            onChange={e => setCollectionPolicyCode(e.target.value)}
          >
            {Object.entries(COLLECTION_POLICY_CODE).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <hr className="mx-4" />
      <Row className="ms-3 mt-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Intra-day Collection Frequency:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            value={fundingCollectionPolicy.frequency}
            onChange={e => setCustomCollectionPolicyField('frequency', e.target.value)}
            disabled={fundingCollectionPolicy.code !== 'CUSTOM'}
          >
            <option value="ONCE">Once a day</option>
            <option value="TWICE">Twice a day</option>
            <option value="HIGH">High Frequency</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-3 mt-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Full Repayment Condition:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            value={fundingCollectionPolicy.fullRepaymentCondition}
            onChange={e => setCustomCollectionPolicyField('fullRepaymentCondition', e.target.value)}
            disabled={fundingCollectionPolicy.code !== 'CUSTOM'}
          >
            <option value="BALANCE_ONLY">Balance Only</option>
            <option value="PAYCHECK_DEPOSIT">Paycheck Deposit</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-3 mt-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Partial Repayment Condition:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            value={fundingCollectionPolicy.partialRepaymentCondition}
            onChange={e =>
              setCustomCollectionPolicyField('partialRepaymentCondition', e.target.value)
            }
            disabled={fundingCollectionPolicy.code !== 'CUSTOM'}
          >
            <option value="PAYCHECK_DEPOSIT">Paycheck Deposit or Last Cluster Trial</option>
            <option value="LAST_PAYDAY_TRIAL">Paycheck Deposit or Last Payday Trial</option>
            <option value="FIRST_CHANCE">First Time Balance Allows</option>
            <option value="WHENEVER_POSSIBLE">Whenever Balance Allows</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-3 mt-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Apply Threshold:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            value={fundingCollectionPolicy.applyThreshold}
            onChange={e => setCustomCollectionPolicyField('applyThreshold', e.target.value)}
            disabled={fundingCollectionPolicy.code !== 'CUSTOM'}
          >
            <option value="ALWAYS">Always</option>
            <option value="NPD_ONLY">NPD Only</option>
            <option value="NEVER">Never</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-3 my-3 align-items-center">
        <Col md={3} className="bo-same-line">
          <Label>Apply 3 Days Prediction:</Label>
        </Col>
        <Col md={9} className="bo-same-line">
          <Input
            className="bo-filter bo-min-w-400"
            type="select"
            value={fundingCollectionPolicy.applyPrediction}
            onChange={e => setCustomCollectionPolicyField('applyPrediction', e.target.value)}
            disabled={fundingCollectionPolicy.code !== 'CUSTOM'}
          >
            <option value="ALWAYS">Always</option>
            <option value="NPD_ONLY">NPD Only</option>
            <option value="NEVER">Never</option>
          </Input>
        </Col>
      </Row>
    </>
  );
}

export default memo(FundingCollectionPolicy);
