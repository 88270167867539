import './App.css';
import './assets/bridgeover.css';
import initFontAwesome from './utils/initFontAwesome';
// navigation
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './router/MainRouter';
// providers
import { TableProvider } from './context/table-context';
import { ClockProvider } from './context/clock-context';
// components
import Auth0ProviderWithRedirectCallback from './components/Auth0ProviderWithRedirectCallback';
// toast
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

initFontAwesome();

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback>
        <ClockProvider>
          <TableProvider>
            <MainRouter />
            <ToastContainer
              position="top-center"
              autoClose={8000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="colored"
              transition={Bounce}
              className="bo-w-500"
            />
          </TableProvider>
        </ClockProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default App;
