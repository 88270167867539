import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Button, Input } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { makeRequest } from '../../../../utils/makeRequest';
import StyledTableCell from '../../../../components/StyledTableCell';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { memo } from 'react';

function FundingCustomRepayments({
  fundingFutureRepayments,
  fundingRepaymentMode,
  fundingId,
  fundingConsumerId,
  fundingCurrentAmount,
  isConsumer,
  consumerId,
  setActiveTab,
  getFunding,
  setTotalExpectedBalance,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginFunding, apiOriginMonitor } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [tableMode, setTableMode] = useState('read');
  const [nextPaydays, setNextPaydays] = useState([]);
  const [editingRepayment, setEditingRepayment] = useState(null);
  const [editingRepaymentIndex, setEditingRepaymentIndex] = useState(null);
  const [newRepayment, setNewRepayment] = useState({ repaymentDate: '', amount: '' });
  const [futureRepayments, setFutureRepayments] = useState(fundingFutureRepayments);

  useEffect(
    () => {
      if (fundingRepaymentMode === 'CUSTOM') {
        setActiveTab('customRepayments');
      }

      return () => {
        setActiveTab(prevActiveTab =>
          prevActiveTab === 'customRepayments' ? 'details' : prevActiveTab,
        );
        setTotalExpectedBalance(null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fundingRepaymentMode],
  );

  useEffect(() => {
    setFutureRepayments(fundingFutureRepayments);
  }, [fundingFutureRepayments]);

  const getNextPaydays = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/paydays`,
      method: 'GET',
      params: {
        consumerId,
      },
    };

    try {
      const response = await makeRequest(config);

      setNextPaydays(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (isConsumer) {
      getNextPaydays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNextPayday = index => {
    const futureRepaymentsIndex = index !== null ? index : futureRepayments.length - 1;

    const nextPayday = nextPaydays
      ?.sort((a, b) => new Date(a.mostProbable).getTime() - new Date(b.mostProbable).getTime())
      ?.find(
        payday =>
          new Date(payday.mostProbable) >
          new Date(futureRepayments[futureRepaymentsIndex]?.repaymentDate),
      );

    if (tableMode === 'add') {
      if (futureRepayments.length === 0) {
        setNewRepayment({ ...newRepayment, repaymentDate: new Date(nextPaydays[0].mostProbable) });
      } else if (futureRepayments.length > 0 && nextPayday) {
        setNewRepayment({
          ...newRepayment,
          repaymentDate: new Date(nextPayday.mostProbable),
        });
      }
    } else if (tableMode === 'edit') {
      if (nextPayday) {
        setEditingRepayment({
          ...editingRepayment,
          repaymentDate: new Date(nextPayday.mostProbable),
        });
      }
    }
  };

  const resetMode = () => {
    setTableMode('read');
    setNewRepayment({ date: '', amount: '' });
    setEditingRepayment(null);
  };

  const updateFutureRepayments = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/update_funding_future_payments`,
      method: 'POST',
      data: JSON.stringify({
        consumerId: fundingConsumerId,
        fundingId,
        consent: true,
        payments: futureRepayments,
      }),
    };

    try {
      await makeRequest(config);
      await getFunding(fundingId);
      setTotalExpectedBalance(null);

      toast.success('New future repayments updated successfully!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const validateTempPlans = async (action, itemIndex) => {
    setSpinner(true);

    let payments = null;

    const formatPayment = payment => ({
      amount: Number(payment.amount),
      repaymentDate: payment.repaymentDate
        ? moment(payment.repaymentDate).format('YYYY-MM-DD')
        : null,
    });

    if (action === 'add') {
      payments = [...futureRepayments, formatPayment(newRepayment)];
    } else if (action === 'edit') {
      payments = futureRepayments.map((fR, index) =>
        itemIndex === index ? formatPayment(editingRepayment) : fR,
      );
    } else if (action === 'delete') {
      payments = futureRepayments.filter((_, index) => index !== itemIndex);
    }

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/validate_temp_plan`,
      method: 'POST',
      data: JSON.stringify({
        consumerId: fundingConsumerId,
        fundingId,
        consent: true,
        payments,
      }),
    };

    try {
      const response = await makeRequest(config);

      setTotalExpectedBalance(response.bridgePoints);

      if (response.errors.length > 0) {
        response.errors.forEach(val => toast.error(val));
      } else {
        setFutureRepayments(response.payments);
        resetMode();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const futureRepaymentsSum = useMemo(
    () => futureRepayments.reduce((acc, val) => Number(val.amount) + acc, 0),
    [futureRepayments],
  );

  return (
    <Spinner visible={spinner}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width="300">
                Repayment Date
              </StyledTableCell>
              <StyledTableCell align="left" width="150">
                Amount
              </StyledTableCell>
              <StyledTableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {futureRepayments
              .sort(
                (a, b) => new Date(a.repaymentDate).getTime() - new Date(b.repaymentDate).getTime(),
              )
              .map((repayment, index) =>
                editingRepayment && index === editingRepaymentIndex ? (
                  <TableRow hover tabIndex={-1} key={editingRepayment.repaymentDate}>
                    <TableCell align="left" height="68">
                      <div className="d-flex">
                        <DatePicker
                          className="bo-input bo-date-picker"
                          onChange={e =>
                            setEditingRepayment({
                              ...editingRepayment,
                              repaymentDate: e,
                            })
                          }
                          selected={new Date(editingRepayment.repaymentDate)}
                          dateFormat="yyyy-MM-dd"
                        />
                        <Button
                          className="bo-button-inline-link"
                          color="link"
                          onClick={() => setNextPayday(index)}
                        >
                          NPD
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell align="left" height="68">
                      <div className="d-flex align-items-center">
                        <p className="m-0 me-1">$</p>
                        <Input
                          className="bo-input"
                          type="text"
                          value={editingRepayment.amount}
                          onChange={e => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(/\D/g, '');

                            setEditingRepayment({
                              ...editingRepayment,
                              amount: numericValue,
                            });
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left" height="68">
                      <Button
                        className="bo-button-inline-link"
                        color="link"
                        onClick={() => validateTempPlans('edit', index)}
                      >
                        Set
                      </Button>
                      <Button
                        className="bo-button-inline-link py-0 m-0"
                        color="link"
                        onClick={resetMode}
                        key="cancel"
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow hover tabIndex={-1} key={repayment.repaymentDate}>
                    <TableCell align="left" height="68">
                      {repayment.repaymentDate}
                    </TableCell>
                    <TableCell align="left" height="68">
                      {`$${repayment.amount}`}
                    </TableCell>
                    {tableMode === 'read' ? (
                      <TableCell align="left" height="68">
                        <Button
                          className="bo-button-inline-link py-0 m-0"
                          color="link"
                          onClick={() => {
                            setTableMode('edit');
                            setEditingRepayment(repayment);
                            setEditingRepaymentIndex(index);
                          }}
                          key="edit"
                        >
                          Edit
                        </Button>
                        <Button
                          className="bo-button-inline-link py-0 m-0"
                          color="link"
                          onClick={() => validateTempPlans('delete', index)}
                          key="delete"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="left" />
                    )}
                  </TableRow>
                ),
              )}
            {tableMode === 'add' && (
              <TableRow tabIndex={-1}>
                <TableCell align="left" height="68">
                  <div className="d-flex">
                    <DatePicker
                      className="bo-input bo-date-picker"
                      onChange={e => setNewRepayment({ ...newRepayment, repaymentDate: e })}
                      selected={newRepayment.repaymentDate}
                      dateFormat="yyyy-MM-dd"
                    />
                    <Button
                      className="bo-button-inline-link"
                      color="link"
                      onClick={() => setNextPayday(null)}
                    >
                      NPD
                    </Button>
                  </div>
                </TableCell>
                <TableCell align="left" height="68">
                  <div className="d-flex align-items-center">
                    <p className="m-0 me-1">$</p>
                    <Input
                      className="bo-input"
                      type="text"
                      value={newRepayment.amount}
                      onChange={e => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/\D/g, '');

                        setNewRepayment({
                          ...newRepayment,
                          amount: numericValue,
                        });
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell align="left" height="68">
                  <Button
                    className="bo-button-inline-link"
                    color="link"
                    onClick={() => validateTempPlans('add')}
                  >
                    Set
                  </Button>
                  <Button className="bo-button-inline-link" color="link" onClick={resetMode}>
                    Cancel
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {fundingCurrentAmount > futureRepaymentsSum && (
        <p className="text-danger ps-3 mt-3">
          Total repayments does not match current amount debt.{' '}
          <strong>${fundingCurrentAmount - futureRepaymentsSum}</strong> are missing
        </p>
      )}
      {fundingCurrentAmount < futureRepaymentsSum && (
        <p className="text-danger ps-3 mt-3">
          Total repayments does not match current amount debt.{' '}
          <strong>${futureRepaymentsSum - fundingCurrentAmount}</strong> are over paid
        </p>
      )}
      {fundingCurrentAmount !== futureRepaymentsSum && tableMode === 'read' && (
        <Button className="bo-button-inline-link" color="link" onClick={() => setTableMode('add')}>
          Add New Repayment
        </Button>
      )}
      {fundingFutureRepayments !== futureRepayments && tableMode === 'read' && (
        <Button className="bo-button-inline-link" color="link" onClick={updateFutureRepayments}>
          Save
        </Button>
      )}
    </Spinner>
  );
}

export default memo(FundingCustomRepayments);
