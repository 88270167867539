import moment from 'moment';
import momentTimezone from 'moment-timezone';

const weekdayArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function nextYears(count, startYear) {
  const yearList = [];
  const year = startYear || new Date().getFullYear();

  for (let i = 0; i < count; i += 1) {
    yearList.push(Number(year) + i);
  }

  return yearList.sort((a, b) => a - b);
}

function previousYears(backCount, startYear) {
  const year = startYear || new Date().getFullYear();

  return Array.from({ length: backCount }, (v, i) => year - backCount + i + 1);
}

function getDaysInMonth(month, year) {
  return Array.from({ length: moment({ month, year }).daysInMonth() }, (v, i) => i + 1);
}

function findFirstNonSubsequentDate(dateList) {
  if (!Array.isArray(dateList) || dateList.length === 0) {
    return null;
  }

  dateList.sort((a, b) => moment(a) - moment(b));

  for (let i = 0; i < dateList.length - 1; i += 1) {
    const current = moment(dateList[i]);
    const next = moment(dateList[i + 1]);

    const diffDays = next.diff(current, 'days');

    if (diffDays > 1) {
      return current.add(1, 'days').format('YYYY-MM-DD');
    }
  }

  return null;
}

function getNextCutOffTime(currentCTTime, closestNonBusinessDays) {
  const cutOffTimes = ['09:00', '13:00', '15:00'];

  for (let i = 0; i < cutOffTimes.length; i += 1) {
    const cutOffTime = momentTimezone.tz(cutOffTimes[i], 'HH:mm', 'America/Chicago').set({
      year: currentCTTime.year(),
      month: currentCTTime.month(),
      date: currentCTTime.date(),
    });

    if (
      currentCTTime.isBefore(cutOffTime) &&
      !closestNonBusinessDays.includes(currentCTTime.format('YYYY-MM-DD'))
    ) {
      return cutOffTime;
    }
  }

  const firstNonSubsequentDate = findFirstNonSubsequentDate([
    momentTimezone(currentCTTime).format('YYYY-MM-DD'),
    ...closestNonBusinessDays,
  ]);

  const nextFirstBusinessDay = firstNonSubsequentDate
    ? momentTimezone.tz(cutOffTimes[0], 'HH:mm', 'America/Chicago').set({
        year: Number(firstNonSubsequentDate.split('-')[0]),
        month: Number(firstNonSubsequentDate.split('-')[1]) - 1,
        date: Number(firstNonSubsequentDate.split('-')[2]),
      })
    : momentTimezone
        .tz(cutOffTimes[0], 'HH:mm', 'America/Chicago')
        .set({
          year: currentCTTime.year(),
          month: currentCTTime.month(),
          date: currentCTTime.date(),
        })
        .add(closestNonBusinessDays.length === 0 ? 1 : closestNonBusinessDays.length, 'days');

  return nextFirstBusinessDay;
}

function formatTimeToCutOff(remainingTime) {
  const duration = momentTimezone.duration(remainingTime);

  const seconds = duration.seconds();

  let minutes = duration.minutes();

  let hours = duration.hours();

  const days = duration.days();

  if (seconds > 0 && minutes > 0) {
    minutes += 1;
  }

  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }

  if (days > 0 || hours >= 1) {
    return `${days * 24 + hours}:${minutes < 10 ? '0' : ''}${minutes} hr`;
  }

  if (minutes >= 1) {
    return `${minutes} min`;
  }

  return `${seconds} sec`;
}

function getHumanReadableDate(ts) {
  const local = moment.utc(ts).local();
  const diff = moment().startOf('day').diff(moment(local).startOf('day'), 'days');

  if (diff === 0) {
    return `Today at ${local.format('h:mm a')}`;
  }

  if (diff === 1) {
    return `Yesterday at ${local.format('h:mm a')}`;
  }

  return `${local.format('MMM DD')} at ${local.format('h:mm a')}`;
}

export {
  weekdayArray,
  nextYears,
  previousYears,
  getDaysInMonth,
  getNextCutOffTime,
  formatTimeToCutOff,
  getHumanReadableDate,
};
