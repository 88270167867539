import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingTopUpsAmountForm({ fundingTopupsSum, fundingTopupsCount }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Top-ups Amount:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          align="right"
          className="bo-input"
          type="text"
          name="topups"
          id="topups"
          value={fundingTopupsSum}
          readOnly
        />
      </Col>
      <Col md={3} className="bo-same-line">
        ({fundingTopupsCount} top-ups)
      </Col>
    </>
  );
}

export default memo(FundingTopUpsAmountForm);
