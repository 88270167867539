import { useEffect, useMemo, useRef, useState } from 'react';
import JSONEditor from 'jsoneditor/dist/jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

const defaultOptions = {
  mode: 'tree',
  history: true,
  search: true,
  navigationBar: true,
  statusBar: true,
  sortObjectKeys: false,
};

function ReactJsonEditor({ value, options, containerRef, getJsonEditor }) {
  const innerRef = useRef(null);
  const optionalContainerRef = containerRef || innerRef;

  const [jsonEditor, setJsonEditor] = useState(null);
  const [valueInitialized, setValueInitialized] = useState(false);

  const compiledOptions = useMemo(() => ({ ...defaultOptions, ...options }), [options]);

  useEffect(() => {
    let editor = null;

    if (optionalContainerRef.current) {
      editor = new JSONEditor(optionalContainerRef.current, compiledOptions);

      setJsonEditor(editor);
    }

    return () => editor?.destroy();
  }, [optionalContainerRef, setJsonEditor, compiledOptions]);

  useEffect(() => {
    if (jsonEditor && value) {
      if (!valueInitialized) {
        jsonEditor.set(value);
        setValueInitialized(true);
      } else {
        jsonEditor.update(value);
      }
    }
  }, [jsonEditor, value, valueInitialized, setValueInitialized]);

  useEffect(() => {
    if (jsonEditor && typeof getJsonEditor === 'function') {
      getJsonEditor(jsonEditor);
    }
  }, [jsonEditor, getJsonEditor]);

  return <div ref={optionalContainerRef} />;
}

export default ReactJsonEditor;
