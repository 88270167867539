import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { exportExcelFile } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportBilling({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      consumerId: {
        header: 'Id',
        width: 12,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      billableDays: {
        header: 'Billable Days',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
    }),
    [],
  );

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Billing', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    worksheet.addRows(report);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={report}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="consumerId"
      />
    </>
  );
}

export default ReportBilling;
