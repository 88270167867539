import { Link } from 'react-router-dom';
import { Col, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react';

function FundingConsumerNameForm({
  fundingConsumerId,
  fundingConsumerFirstName,
  fundingConsumerLastName,
}) {
  return (
    <>
      <Col md={2} className="bo-same-line d-flex align-items-baseline">
        <Label>Consumer Name:</Label>
        <Link className="ms-2 bo-link" to={`/consumer/${fundingConsumerId}`}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Link>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="firstName"
          id="firstName"
          value={fundingConsumerFirstName || ''}
          readOnly
        />
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="lastName"
          id="lastName"
          value={fundingConsumerLastName || ''}
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingConsumerNameForm);
