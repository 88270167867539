import { useAuth } from '../../../../hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { useEnv } from '../../../../context/env-context';
import { Col, Input, Label, Row } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

function ProfilePermissionsForm({ setSpinner }) {
  const { apiOriginConsumer, audience } = useEnv();
  const { user, getAccessTokenSilently } = useAuth();

  const [allPermissions, setAllPermissions] = useState([]);

  const getAllPermissions = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);
    try {
      const response = await axios(`${apiOriginConsumer}/get_all_permissions`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setAllPermissions(response.data);
    } catch (error) {
      toast.error('Failed to get permissions!');
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAllPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPermissions = useMemo(() => user[`${audience}/metadata`].permissions, [user, audience]);

  return (
    <Row className="mt-3 mb-2">
      <Col md={2}>
        <Label>Permissions:</Label>
      </Col>
      <Col md={4}>
        {allPermissions.map(permission => (
          <div key={permission}>
            <Label check>
              <Input type="checkbox" disabled checked={userPermissions.includes(permission)} />
              {permission}
            </Label>
          </div>
        ))}
      </Col>
    </Row>
  );
}

export default ProfilePermissionsForm;
