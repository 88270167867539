import { Input, FormGroup } from 'reactstrap';

function SuperuserProfileForm({ superuser, formErrors, handleChange, renderError }) {
  return (
    <>
      <FormGroup>
        <Input
          className={formErrors && formErrors.firstName ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First Name"
          value={superuser.firstName ? superuser.firstName : ''}
          onChange={e => handleChange(e)}
        />
        {'firstName' in formErrors ? renderError(formErrors.firstName) : ''}
      </FormGroup>
      <FormGroup>
        <Input
          className={formErrors && formErrors.lastName ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last Name"
          value={superuser.lastName ? superuser.lastName : ''}
          onChange={e => handleChange(e)}
        />
        {'lastName' in formErrors ? renderError(formErrors.lastName) : ''}
      </FormGroup>
      <FormGroup>
        <Input
          className={formErrors && formErrors.email ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="email"
          id="email"
          placeholder="Email"
          value={superuser.email ? superuser.email : ''}
          onChange={e => handleChange(e)}
        />
        {'email' in formErrors ? renderError(formErrors.email) : ''}
      </FormGroup>
    </>
  );
}

export default SuperuserProfileForm;
