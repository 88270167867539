import { memo, useState } from 'react';
import { Row, Col, Button, Label, Badge } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import {
  BANK_ACCOUNT_STATUS_BADGE,
  FUNDING_SOURCE_BADGE,
  PAYROLL_STATUS_BADGE,
  PROFILE_STATUS_BADGE,
} from '../../../../constants/consumers';

function ConsumerStatusSummary({
  consumerId,
  consumerBankAccountStatus,
  consumerAccountDwollaFundingSourceUrl,
  consumerProfileStatus,
  consumerPayrollStatus,
  getConsumer,
}) {
  const [spinner, setSpinner] = useState(false);

  const refreshStatus = async () => {
    setSpinner(true);
    await getConsumer(consumerId);
    setSpinner(false);
  };

  if (!consumerId) {
    return null;
  }

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="2">
          <Button className="bo-button-inline bo-button-gray" onClick={refreshStatus}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <Row className="mb-1 mt-3 ms-1 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Bank Account:</Label>
        </Col>
        <Col md={10} className="bo-same-line">
          <Badge
            color=""
            className={`bo-badge ${BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.color}`}
          >
            {BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.text || 'Unknown'}
          </Badge>
          <span className="bo-inline-right-comment">
            [ Connected | Refresh Required | Invalid | Not connected ]
          </span>
        </Col>
      </Row>
      <Row className="mb-1 mt-1 ms-1 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Funding Source:</Label>
        </Col>
        <Col md={10} className="bo-same-line">
          <Badge
            className="bo-badge"
            color={
              FUNDING_SOURCE_BADGE[consumerAccountDwollaFundingSourceUrl ? 'YES' : 'NO']?.color
            }
          >
            {FUNDING_SOURCE_BADGE[consumerAccountDwollaFundingSourceUrl ? 'YES' : 'NO']?.text}
          </Badge>
        </Col>
      </Row>
      <Row className="mb-1 mt-1 ms-1 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Profile:</Label>
        </Col>
        <Col md={10} className="bo-same-line">
          <Badge className="bo-badge" color={PROFILE_STATUS_BADGE[consumerProfileStatus]?.color}>
            {PROFILE_STATUS_BADGE[consumerProfileStatus]?.text || 'Unknown'}
          </Badge>
          <span className="bo-inline-right-comment">
            [ Ok | Non-sufficient history | Not initialized | Out of date ]
          </span>
        </Col>
      </Row>
      <Row className="mb-3 mt-1 ms-1 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Payroll:</Label>
        </Col>
        <Col md={10} className="bo-same-line">
          <Badge className="bo-badge" color={PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.color}>
            {PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.text || 'Unknown'}
          </Badge>
          <span className="bo-inline-right-comment">
            [ Ok | Unknown Frequency | Not recent | Not detected | Out of date | N/A ]
          </span>
        </Col>
      </Row>
    </Spinner>
  );
}

export default memo(ConsumerStatusSummary);
