export const AGGREGATOR_TYPE_BADGE = {
  STANDARD: {
    text: 'Standard',
    color: 'bg-success',
  },
  GENERIC: {
    text: 'Generic',
    color: 'bg-primary',
  },
  NO_EMPLOYER: {
    text: 'No employer',
    color: 'bg-warning',
  },
  INTERNAL: {
    text: 'Internal',
    color: 'bg-secondary',
  },
};
