import moment from 'moment';
import { useState, useEffect, useMemo, memo } from 'react';
import { Row, Col, Button, Badge, Input, Label } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { PAYROLL_STATUS_BADGE } from '../../../../constants/consumers';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';

function ConsumerPayroll({
  consumerId,
  consumerLockPayrollDay,
  consumerPayrollFrequency,
  consumerPayrollDay1,
  consumerPayrollDay2,
  consumerPayrollStatus,
  getConsumer,
  predict,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginMonitor, apiOriginConsumer } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [profile, setProfile] = useState(null);
  const [biWeeklyDay, setBiWeeklyDay] = useState(null);

  const getProfile = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_profile`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const response = await makeRequest(config);

      setProfile(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getBiweeklyDay = async date => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_biweekly_day`,
      method: 'GET',
      params: {
        date,
      },
    };

    try {
      const response = await makeRequest(config);

      setBiWeeklyDay(response?.biWeeklyDay);

      return response?.biWeeklyDay;
    } catch (error) {
      toast.error(error.message);

      return null;
    }
  };

  const getInitProfile = async () => {
    setSpinner(true);
    await getProfile(consumerId);
    setSpinner(false);
  };

  useEffect(() => {
    getInitProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (biWeeklyDay === null && consumerPayrollFrequency === 'BIWEEKLY') {
      getBiweeklyDay(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectPayroll = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/payroll/detect`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);

        toast.success('Payroll parameters detected and payroll profile updated successfully');

        await getProfile(consumerId);
        await getConsumer(consumerId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const runProfilePayroll = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/payroll/profile`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    await makeRequest(config);
  };

  const profilePayroll = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      try {
        await runProfilePayroll();

        toast.success('Payroll profile updated successfully');

        await getProfile(consumerId);
        await getConsumer(consumerId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const refreshPage = async () => {
    setSpinner(true);
    await getProfile(consumerId);
    await getConsumer(consumerId);
    setSpinner(false);
  };

  const setConsumerPayrollParams = async (
    payrollFrequency,
    payrollDay1,
    payrollDay2,
    lockPayrollDay,
    runPayrollProfiling,
  ) => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_consumer_payroll_params`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
        payrollFrequency,
        payrollDay1: payrollDay1 === null ? null : Number(payrollDay1),
        payrollDay2: payrollDay2 === null ? null : Number(payrollDay2),
        lockPayrollDay,
      }),
    };

    try {
      await makeRequest(config);

      toast.success('New consumer payroll params saved!');

      if (runPayrollProfiling) {
        await runProfilePayroll();

        if (
          window.confirm(
            'Payroll profile has been updated, do you want to run “Re-predict” ' +
              'in order to refresh consumers offer and prediction data?',
          )
        ) {
          await predict();
          await getProfile(consumerId);
        } else {
          await getProfile(consumerId);
          await getConsumer(consumerId);
        }
      } else {
        await getProfile(consumerId);
        await getConsumer(consumerId);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updatePayrollFrequency = async frequency => {
    if (frequency === 'WEEKLY') {
      await setConsumerPayrollParams(frequency, 4, null, consumerLockPayrollDay, true);
    }

    if (frequency === 'BIWEEKLY') {
      const payrollDay1 = await getBiweeklyDay(moment().startOf('isoWeek').format('YYYY-MM-DD'));

      if (payrollDay1 !== null && payrollDay1 !== undefined) {
        await setConsumerPayrollParams(frequency, payrollDay1, null, consumerLockPayrollDay, true);
      }
    }

    if (frequency === 'SEMI_MONTHLY') {
      await setConsumerPayrollParams(frequency, 1, 16, consumerLockPayrollDay, true);
    }

    if (frequency === 'MONTHLY') {
      await setConsumerPayrollParams(frequency, 1, null, consumerLockPayrollDay, true);
    }
  };

  const renderPayrollDay = () => {
    if (consumerPayrollStatus === 'NOT_DETECTED') {
      return <Badge className="bo-badge">N/A</Badge>;
    }

    if (
      consumerPayrollFrequency === 'WEEKLY' ||
      consumerPayrollFrequency === 'BIWEEKLY' ||
      consumerPayrollFrequency === 'MONTHLY' ||
      consumerPayrollFrequency === 'SEMI_MONTHLY'
    ) {
      let days = [];

      if (consumerPayrollFrequency === 'WEEKLY') {
        const weekdays = moment.weekdays();

        // [{key: 'Monday', value: 0}, {key: 'Tuesday', value: 1}, ..., {key: 'Sunday', value: 6}]
        days = [...weekdays.slice(1), weekdays[0]].map((day, index) => ({
          key: day,
          value: index,
        }));
      } else if (consumerPayrollFrequency === 'BIWEEKLY') {
        const twoWeekValues =
          biWeeklyDay === 7
            ? [7, 8, 9, 10, 11, 12, 13, 0, 1, 2, 3, 4, 5, 6]
            : [...Array(14).keys()];

        // two weeks starting from the current week monday
        days = twoWeekValues.map((num, idx) => ({
          key: moment().startOf('isoWeek').add(idx, 'days').format('dddd DD/MM/yyyy'),
          value: num,
        }));
      } else if (
        consumerPayrollFrequency === 'MONTHLY' ||
        consumerPayrollFrequency === 'SEMI_MONTHLY'
      ) {
        // [{key: 1, value: 1}, {key: 2, value: 2}, ..., {key: 28, value: 28}]
        days = [...Array(28).keys()].map(value => ({ key: value + 1, value: value + 1 }));
      }

      return (
        <div className="d-flex">
          <Input
            className="bo-select bo-w-240"
            type="select"
            name="payrollDay1"
            id="payrollDay1"
            noValidate
            value={consumerPayrollDay1}
            onChange={e =>
              setConsumerPayrollParams(
                consumerPayrollFrequency,
                e.target.value,
                consumerPayrollDay2,
                true,
                true,
              )
            }
          >
            {days.map(day => (
              <option key={day.key} value={day.value}>
                {day.key}
              </option>
            ))}
            {(consumerPayrollFrequency === 'MONTHLY' ||
              consumerPayrollFrequency === 'SEMI_MONTHLY') && (
              <option value={-1}>Last month day</option>
            )}
          </Input>
          {consumerPayrollFrequency === 'SEMI_MONTHLY' && (
            <Input
              className="bo-select bo-w-240 ms-2"
              type="select"
              name="payrollDay2"
              id="payrollDay2"
              noValidate
              value={consumerPayrollDay2}
              onChange={e =>
                setConsumerPayrollParams(
                  consumerPayrollFrequency,
                  consumerPayrollDay1,
                  e.target.value,
                  true,
                  true,
                )
              }
            >
              {days.map(day => (
                <option key={day.key} value={day.value}>
                  {day.value}
                </option>
              ))}
              <option value={-1}>Last month day</option>
            </Input>
          )}
          <div className="d-flex align-items-center ms-3">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              name="lockPayrollDay"
              id="lockPayrollDay"
              checked={consumerLockPayrollDay}
              onChange={e =>
                setConsumerPayrollParams(
                  consumerPayrollFrequency,
                  consumerPayrollDay1,
                  consumerPayrollDay2,
                  e.target.checked,
                  false,
                )
              }
            />
            <Label className="p-0 m-0 ms-1">Lock</Label>
          </div>
        </div>
      );
    }

    return <Badge className="bo-badge">N/A</Badge>;
  };

  const numberOfCycles = useMemo(() => {
    if (profile && profile.payroll && profile.payroll.cycles) {
      return Object.values(profile.payroll.cycles).filter(
        v => v.totalAmount > 0 && v.status === 'final',
      ).length;
    }

    return null;
  }, [profile]);

  const last3CyclesAvarage = useMemo(() => {
    if (profile && profile.payroll && profile.payroll.average) {
      return `$${Number(Math.round(profile.payroll.average)).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })}`;
    }

    return null;
  }, [profile]);

  const emptyCycle = {
    endDate: null,
    index: null,
    startDate: null,
    status: null,
    totalAmount: null,
    transactions: [],
  };

  const previousCycle = useMemo(() => {
    if (profile && profile.payroll && profile.payroll.cycles) {
      const cycleValues = Object.values(profile.payroll.cycles);
      const currentValueIndex = cycleValues.findIndex(
        v =>
          moment().startOf('day') <= moment(v.endDate) &&
          moment().startOf('day') >= moment(v.startDate),
      );

      if (currentValueIndex > 0) {
        return cycleValues[currentValueIndex - 1];
      }
    }

    return emptyCycle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const currentCycle = useMemo(() => {
    if (profile && profile.payroll && profile.payroll.cycles) {
      const currentValue = Object.values(profile.payroll.cycles).find(
        v =>
          moment().startOf('day') <= moment(v.endDate) &&
          moment().startOf('day') >= moment(v.startDate),
      );

      if (currentValue) {
        return currentValue;
      }
    }

    return emptyCycle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 mb-4 ms-1">
        <Col sm="8">
          <Button
            className="bo-button-inline bo-button-gray"
            onClick={() => refreshPage(consumerId)}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={detectPayroll}>
            Detect Payroll
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={profilePayroll}>
            Profile Payroll
          </Button>
        </Col>
      </Row>
      <Row className="m-0 ms-1 me-1">
        <Col sm="2">Payroll Status:</Col>
        <Col sm="10">
          <Badge className="bo-badge" color={PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.color}>
            {PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.text || 'Unknown'}
          </Badge>
        </Col>
      </Row>
      <Row className="m-0 mt-3 ms-1 me-1">
        <Col sm="2">Payroll Frequency:</Col>
        <Col sm="10">
          {consumerPayrollStatus === 'NOT_DETECTED' ? (
            <Badge className="bo-badge">N/A</Badge>
          ) : (
            <Input
              className="bo-select bo-w-240"
              type="select"
              name="payrollFrequency"
              id="payrollFrequency"
              noValidate
              value={consumerPayrollFrequency}
              onChange={e => updatePayrollFrequency(e.target.value)}
            >
              <option disabled value="UNKNOWN">
                Unknown
              </option>
              <option value="WEEKLY">Weekly</option>
              <option value="BIWEEKLY">Bi-Weekly</option>
              <option value="SEMI_MONTHLY">Semi-Monthly</option>
              <option value="MONTHLY">Monthly</option>
            </Input>
          )}
        </Col>
      </Row>
      <Row className="mt-2 ms-1 me-1">
        <Col sm="2">Payroll Day:</Col>
        <Col sm="10">{renderPayrollDay()}</Col>
      </Row>
      <Row className="mt-2 ms-1 me-1">
        <Col sm="2">Number of Cycles:</Col>
        <Col sm="10">{numberOfCycles}</Col>
      </Row>
      <Row className="mt-2 ms-1 me-1">
        <Col sm="2">Last 3 Cycles average:</Col>
        <Col sm="10">{last3CyclesAvarage}</Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="bo-w-240" align="left">
                Cycle
              </StyledTableCell>
              <StyledTableCell align="left">Index</StyledTableCell>
              <StyledTableCell align="left">Start</StyledTableCell>
              <StyledTableCell align="left">End</StyledTableCell>
              <StyledTableCell align="left">Total Amount</StyledTableCell>
              <StyledTableCell align="left">Transactions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover tabIndex={-1}>
              <StyledTableCell align="left">Previous</StyledTableCell>
              <StyledTableCell align="left">{previousCycle.index}</StyledTableCell>
              <StyledTableCell align="left">
                {previousCycle.startDate && moment(previousCycle.startDate).format('DD/MM/YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {previousCycle.endDate && moment(previousCycle.endDate).format('DD/MM/YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {previousCycle.totalAmount &&
                  `$${previousCycle.totalAmount.toLocaleString('en-US')}`}
              </StyledTableCell>
              <StyledTableCell align="left">
                {previousCycle.transactions.map(transaction => (
                  <div key={transaction.uuid}>{`${
                    transaction.transactionDate &&
                    moment(transaction.transactionDate).format('DD/MM/YYYY')
                  } - $${transaction.amount && transaction.amount.toLocaleString('en-US')}`}</div>
                ))}
              </StyledTableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <StyledTableCell align="left">Current</StyledTableCell>
              <StyledTableCell align="left">{currentCycle.index}</StyledTableCell>
              <StyledTableCell align="left">
                {currentCycle.startDate && moment(currentCycle.startDate).format('DD/MM/YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {currentCycle.endDate && moment(currentCycle.endDate).format('DD/MM/YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {currentCycle.totalAmount && `$${currentCycle.totalAmount.toLocaleString('en-US')}`}
              </StyledTableCell>
              <StyledTableCell align="left">
                {currentCycle.transactions.map(transaction => (
                  <div key={transaction.uuid}>{`${
                    transaction.transactionDate &&
                    moment(transaction.transactionDate).format('DD/MM/YYYY')
                  } - $${transaction.amount && transaction.amount.toLocaleString('en-US')}`}</div>
                ))}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
}

export default memo(ConsumerPayroll);
