import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { PatternFormat } from 'react-number-format';

function AggregatorPhoneForm({ aggregatorPhoneNumber, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Phone number:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <PatternFormat
          name="phoneNumber"
          id="phoneNumber"
          format="(###) ###-####"
          mask="_"
          className="bo-input"
          allowEmptyFormatting
          customInput={Input}
          value={aggregatorPhoneNumber || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorPhoneForm);
