import {
  Row,
  Col,
  Button,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faFileCode,
  faFileCsv,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { makeRequest } from '../../../utils/makeRequest';
import { useEnv } from '../../../context/env-context';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';

function ConsumerActionRow({
  consumer,
  setSpinner,
  getConsumer,
  refreshProfile,
  predict,
  openNotificationModal,
  openManualMfaModal,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginNotifications, apiOriginMonitor } = useEnv();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [launchOpen, setLaunchOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({
    excel: false,
    json: false,
    csv: false,
    historical: false,
  });

  const notificationsToggle = () => setNotificationsOpen(!notificationsOpen);
  const launchToggle = () => setLaunchOpen(!launchOpen);

  const saveConsumer = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/save`,
      method: 'POST',
      data: JSON.stringify(consumer),
    };

    try {
      await makeRequest(config);
      getConsumer(consumer.id);
      toast.success('Consumer successfully saved!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const downloadFile = async ({ endpoint, params, fileName, stringifyResponse, responseType }) => {
    setSpinner(true);
    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/${endpoint}`,
        responseType,
        method: 'GET',
        params,
      };

      let data = await makeRequest(config);

      if (data) {
        if (stringifyResponse) {
          data = JSON.stringify(data);
        }

        const binaryData = [];

        binaryData.push(data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const buildProfile = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/build_profile`,
        method: 'POST',
        data: JSON.stringify({ consumerId: consumer.id }),
      };

      try {
        await makeRequest(config);

        toast.success('Profile initialization job initiated successfully');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const sendNotification = async type => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginNotifications}/send_predefined_message`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, type, date: {} }),
    };

    try {
      await makeRequest(config);

      toast.success(`${type} notification has been sent`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Row className="pt-4 mb-4">
      <Col sm={4} className="bo-same-line">
        <Button className="bo-button w-75" onClick={saveConsumer}>
          Save
        </Button>
      </Col>
      <Col sm={8} className="bo-flex-1-right">
        <Button className="bo-button-inline bo-button-gray" onClick={openManualMfaModal}>
          Manual MFA
        </Button>
        <Button
          id="excel"
          className="ms-5 bo-button-inline bo-button-gray "
          onClick={() =>
            downloadFile({
              endpoint: 'reports/profile',
              params: { consumerId: consumer.id },
              fileName: `report_${consumer.id}.xlsx`,
              responseType: 'blob',
            })
          }
        >
          <FontAwesomeIcon icon={faFileExcel} color="white" size="lg" />
        </Button>
        <Tooltip
          offset={[0, 6]}
          target="excel"
          isOpen={tooltipOpen.excel}
          toggle={() => setTooltipOpen({ ...tooltipOpen, excel: !tooltipOpen.excel })}
        >
          Download Excel Report
        </Tooltip>
        <Button
          id="json"
          className="ms-1 bo-button-inline bo-button-gray"
          onClick={() =>
            downloadFile({
              endpoint: 'get_profile_json',
              params: { consumerId: consumer.id },
              fileName: `profile_${consumer.id}.json`,
              stringifyResponse: true,
            })
          }
        >
          <FontAwesomeIcon icon={faFileCode} color="white" size="lg" />
        </Button>
        <Tooltip
          offset={[0, 6]}
          target="json"
          isOpen={tooltipOpen.json}
          toggle={() => setTooltipOpen({ ...tooltipOpen, json: !tooltipOpen.json })}
        >
          Download Profile JSON
        </Tooltip>
        <Button
          id="csv"
          className="ms-1 bo-button-inline bo-button-gray"
          onClick={() =>
            downloadFile({
              endpoint: 'download_transactions',
              params: { consumerId: consumer.id, type: 'main' },
              fileName: `transactions_${consumer.id}.csv`,
            })
          }
        >
          <FontAwesomeIcon icon={faFileCsv} color="white" size="lg" />
        </Button>
        <Tooltip
          offset={[0, 6]}
          target="csv"
          isOpen={tooltipOpen.csv}
          toggle={() => setTooltipOpen({ ...tooltipOpen, csv: !tooltipOpen.csv })}
        >
          Download Transactions CSV
        </Tooltip>
        <Button
          id="historical"
          className="ms-1 me-5 bo-button-inline bo-button-gray"
          onClick={() =>
            downloadFile({
              endpoint: 'reports/historical_balances',
              params: { consumerId: consumer.id },
              fileName: `historical_balances_${consumer.id}.xlsx`,
              responseType: 'blob',
            })
          }
        >
          <FontAwesomeIcon icon={faDollarSign} color="white" size="lg" />
        </Button>
        <Tooltip
          offset={[0, 6]}
          target="historical"
          isOpen={tooltipOpen.historical}
          toggle={() => setTooltipOpen({ ...tooltipOpen, historical: !tooltipOpen.historical })}
        >
          Download Historical Balances
        </Tooltip>
        <Dropdown isOpen={launchOpen} toggle={launchToggle}>
          <DropdownToggle className="bo-button-inline bo-button-gray" caret>
            Launch&nbsp;&nbsp;&nbsp;
          </DropdownToggle>
          <DropdownMenu className="bo-dropdown-menu-gray">
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => buildProfile()}
            >
              Initialization {consumer.isTest ? '' : '(from Plaid)'}
            </DropdownItem>
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => refreshProfile()}
            >
              Incremental {consumer.isTest ? '' : '(from Plaid)'}
            </DropdownItem>
            <DropdownItem className="bo-dropdown-menu-item-inline-gray" onClick={() => predict()}>
              Re-predict
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown isOpen={notificationsOpen} toggle={notificationsToggle}>
          <DropdownToggle className="ms-3 bo-button-inline bo-button-gray" caret>
            Send Notification&nbsp;&nbsp;&nbsp;
          </DropdownToggle>
          <DropdownMenu className="bo-dropdown-menu-gray">
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => openNotificationModal()}
            >
              Free text
            </DropdownItem>
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => sendNotification('ACTIVATION')}
            >
              Activation
            </DropdownItem>
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => sendNotification('DEACTIVATION')}
            >
              Deactivation
            </DropdownItem>
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => sendNotification('SUSPENSION')}
            >
              Suspension
            </DropdownItem>
            <DropdownItem
              className="bo-dropdown-menu-item-inline-gray"
              onClick={() => sendNotification('PLAID_REFRESH_REQUIRED')}
            >
              Plaid Refresh Required
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default ConsumerActionRow;
