import moment from 'moment';
import { toast } from 'react-toastify';
import { useState, useEffect, memo, useCallback } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest, makeUploadRequest } from '../../../../../utils/makeRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AutoSizer from 'react-virtualized-auto-sizer';

function NewObjectTestZoneTab({ consumerId, refreshTab, setRefreshTab }) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginOrchestrator, apiOriginMonitor } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [consumerTime, setConsumerTime] = useState(null);
  const [artTime, setArtTime] = useState('');
  const [balance, setBalance] = useState(null);

  const changeArtlTime = e => setArtTime(e);

  const resetToRealtime = async id => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/reset`,
      method: 'POST',
      data: { consumerId: id },
    };

    try {
      const data = await makeRequest(config);

      setConsumerTime(data);
      setArtTime(new Date(data.time));
      toast.success("As-of time successfully set to 'Reat-time'");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const setArtificialTime = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/set`,
      method: 'POST',
      data: { consumerId, time: moment(artTime).format('YYYY-MM-DD 00:00:00') },
    };

    try {
      const data = await makeRequest(config);

      setConsumerTime(data);
      setArtTime(new Date(data.time));
      toast.success('Artificial As-of time successfully set');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const getConsumerTime = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginOrchestrator}/time`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const data = await makeRequest(config);

        return data;
      } catch (error) {
        toast.error(error.message);

        return null;
      }
    },
    [apiOriginOrchestrator, getAccessTokenSilently],
  );

  const getConsumerBalance = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/getTestAcctBalance`,
        method: 'GET',
        params: { consumerId: id },
      };

      try {
        const data = await makeRequest(config);

        return data;
      } catch (error) {
        toast.error(error.message);

        return null;
      }
    },
    [apiOriginMonitor, getAccessTokenSilently],
  );

  const getTimeAndBalance = useCallback(async () => {
    const cons_time = await getConsumerTime(consumerId);

    if (cons_time) {
      setConsumerTime(cons_time);
      setArtTime(new Date(cons_time.time));
      const consumerBalance = await getConsumerBalance(consumerId);

      if (consumerBalance) {
        setBalance(consumerBalance.balance);
      }
    }
  }, [consumerId, getConsumerBalance, getConsumerTime]);

  const launchMonitorJob = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/monitor`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
      }),
    };

    try {
      await makeRequest(config);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const incrementAndRun = async () => {
    const newArtTime = artTime;

    newArtTime.setDate(newArtTime.getDate() + 1);

    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/set`,
      method: 'POST',
      data: { consumerId, time: moment(newArtTime).format('YYYY-MM-DD 00:00:00') },
    };

    try {
      await makeRequest(config);

      getTimeAndBalance();
      toast.success('Artificial As-of time successfully incremented and daily monitor executed');
      launchMonitorJob();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const getInitTimeAndBalance = useCallback(async () => {
    setSpinner(true);
    await getTimeAndBalance();
    setSpinner(false);
  }, [getTimeAndBalance]);

  useEffect(() => {
    getInitTimeAndBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'test_zone') {
      getInitTimeAndBalance();
      setRefreshTab('');
    }
  }, [getInitTimeAndBalance, refreshTab, setRefreshTab]);

  const setConsumerBalance = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/setTestAcctBalance`,
      method: 'POST',
      data: { consumerId, balance },
    };

    try {
      const data = await makeRequest(config);

      setBalance(data.balance);

      toast.success('Opening balance successfully updated');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const [file, setFile] = useState(null);

  const handleFileChange = e => {
    if (e.target.files) {
      const targetFile = e.target.files[0];

      if (targetFile.type !== 'text/csv') {
        setFile('error');
      } else {
        setFile(e.target.files[0]);
      }
    }
  };

  const handleUploadClick = async () => {
    if (!file || file === 'error') {
      return;
    }
    setSpinner(true);

    const data = new FormData();

    data.append('file', file);
    data.append('consumerId', consumerId);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    try {
      await makeUploadRequest(`${apiOriginMonitor}/uploadTrx`, data, token);

      toast.success('File uploaded successfully');

      setFile(null);
      getTimeAndBalance();
    } catch (err) {
      toast.error(err.toString());
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Spinner visible={spinner} wrapperStyle={{ height: '100%' }}>
      <div className="h-100">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }} className="overflow-auto bo-table-container">
              <div className="m-3 d-flex gap-3 align-items-center">
                <Label className="bo-new-object-text-md bo-min-w-150">As-of:</Label>
                {consumerTime && consumerTime.type === 'realtime' ? (
                  <>
                    <Input
                      className="bo-new-object-popover-form-menu-input bo-w-200"
                      type="text"
                      value="Real-time"
                      disabled
                    />
                    <Button
                      className="bo-new-object-inline-button"
                      onClick={() => {
                        setArtTime(new Date());
                        setArtificialTime();
                      }}
                    >
                      Set Artificial Time
                    </Button>
                  </>
                ) : (
                  <>
                    <DatePicker
                      className="bo-new-object-popover-form-menu-input border bo-min-w-200"
                      onChange={e => changeArtlTime(e)}
                      selected={artTime}
                      dateFormat="dd/MM/yyyy"
                    />
                    <Button
                      className="bo-new-object-inline-button text-nowrap"
                      onClick={() => setArtificialTime()}
                    >
                      Save
                    </Button>
                    <Button
                      className="bo-new-object-inline-button text-nowrap"
                      onClick={() => incrementAndRun()}
                    >
                      Increment and run daily
                    </Button>
                    <Button
                      className="bo-new-object-inline-button text-nowrap"
                      onClick={() => resetToRealtime(consumerId)}
                    >
                      Real-time
                    </Button>
                    <p className="bo-new-object-text-sm bo-min-w-200">
                      Note: &ldquo;As-of date&rdquo; transactions will not be loaded if time is
                      before noon
                    </p>
                  </>
                )}
              </div>
              <div className="m-3 d-flex gap-3 align-items-center">
                <Label className="bo-new-object-text-md bo-min-w-150">Opening balance:</Label>
                <Input
                  className="bo-new-object-popover-form-menu-input bo-w-200"
                  type="text"
                  value={balance !== null ? balance : ''}
                  onChange={e => setBalance(e.target.value)}
                />
                <Button
                  className="bo-new-object-inline-button"
                  onClick={() => setConsumerBalance()}
                >
                  Save
                </Button>
              </div>
              <div className="m-3 d-flex gap-3 align-items-center">
                <Label className="bo-new-object-text-md bo-min-w-150">Transactions File:</Label>
                <Input
                  className="bo-new-object-popover-form-menu-input bo-w-300"
                  type="file"
                  onChange={handleFileChange}
                  onClick={e => {
                    e.target.value = '';
                  }}
                />
                {file === 'error' ? (
                  <p className="bo-new-object-text-sm text-danger">Only csv files are allowed!</p>
                ) : (
                  file && (
                    <Button className="bo-new-object-inline-button" onClick={handleUploadClick}>
                      Upload
                    </Button>
                  )
                )}
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    </Spinner>
  );
}

export default memo(NewObjectTestZoneTab);
