import { useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { modalStyle } from '../../../../utils/modal';
import {
  BasisTheoryApiError,
  BasisTheoryValidationError,
  useBasisTheory,
} from '@basis-theory/basis-theory-react';
import CardInputForm from '../modal-forms/CardInputForm';
import BillingAddressForm from '../modal-forms/BillingAddressForm';
import { states } from '../../../../utils/states';
import CardProviderImage from '../../../../components/CardProviderImage';

function ConsumerLinkNewCardModal({ consumer, isOpen, close }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  ReactModal.setAppElement(document.getElementById('root'));

  const { bt } = useBasisTheory(process.env.REACT_APP_BT_PUBLIC_KEY, { elements: true });

  const cardNumberRef = useRef(null);
  const cardExpirationDateRef = useRef(null);
  const cardVerificationCodeRef = useRef(null);

  const [btElementsEvents, setBtElementsEvents] = useState({
    cardExpirationDate: { ready: false, focused: false, dirty: false, changeEvent: null },
    cvc: { ready: false, focused: false, dirty: false, changeEvent: null },
    cardNumber: { ready: false, focused: false, dirty: false, changeEvent: null },
  });

  const [btMetadata, setBtMetadata] = useState({
    firstName: { value: '', dirty: false },
    lastName: { value: '', dirty: false },
    addressLine1: { value: '', dirty: false },
    addressLine2: { value: '', dirty: false },
    city: { value: '', dirty: false },
    state: { value: '', dirty: false },
    zip: { value: '', dirty: false },
  });

  const [spinner, setSpinner] = useState(false);
  const [useProfileAddress, setUseProfileAddress] = useState(true);

  useEffect(() => {
    if (useProfileAddress && consumer) {
      const { addressLine1, addressLine2, city, state, zip } = consumer;

      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: addressLine1, dirty: false },
        addressLine2: { value: addressLine2, dirty: false },
        city: { value: city, dirty: false },
        state: { value: state, dirty: false },
        zip: { value: zip, dirty: false },
      }));
    } else {
      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: '', dirty: false },
        addressLine2: { value: '', dirty: false },
        city: { value: '', dirty: false },
        state: { value: '', dirty: false },
        zip: { value: '', dirty: false },
      }));
    }
  }, [useProfileAddress, consumer]);

  const closeModal = () => {
    cardNumberRef.current?.clear();
    cardExpirationDateRef.current?.clear();
    cardVerificationCodeRef.current?.clear();

    setBtElementsEvents({
      cardExpirationDate: { ready: false, focused: false, dirty: false, changeEvent: null },
      cvc: { ready: false, focused: false, dirty: false, changeEvent: null },
      cardNumber: { ready: false, focused: false, dirty: false, changeEvent: null },
    });

    const { addressLine1, addressLine2, city, state, zip } = consumer;

    setBtMetadata({
      firstName: { value: '', dirty: false },
      lastName: { value: '', dirty: false },
      addressLine1: { value: addressLine1, dirty: false },
      addressLine2: { value: addressLine2, dirty: false },
      city: { value: city, dirty: false },
      state: { value: state, dirty: false },
      zip: { value: zip, dirty: false },
    });

    setUseProfileAddress(true);

    close();
  };

  const createBtToken = async () => {
    try {
      const btToken = await bt?.tokens.create({
        type: 'card',
        data: {
          number: cardNumberRef.current,
          expiration_month: cardExpirationDateRef.current.month(),
          expiration_year: cardExpirationDateRef.current.year(),
          cvc: cardVerificationCodeRef.current,
        },
        metadata: {
          firstName: btMetadata.firstName.value,
          lastName: btMetadata.lastName.value,
          addressLine1: btMetadata.addressLine1.value,
          addressLine2: btMetadata.addressLine2.value || undefined,
          city: btMetadata.city.value,
          state: btMetadata.state.value,
          zip: btMetadata.zip.value,
        },
      });

      return btToken;
    } catch (error) {
      if (error instanceof BasisTheoryValidationError) {
        console.error(error);
      } else if (error instanceof BasisTheoryApiError) {
        console.error(error);
      }

      toast.error('Unknown Error');

      return null;
    }
  };

  const linkCard = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const btToken = await createBtToken();

    try {
      const config = {
        url: `${apiOriginConsumer}/cards/link`,
        token,
        method: 'POST',
        data: JSON.stringify({
          btTokenId: btToken.id,
          isAdhoc: false,
          consumerId: consumer.id,
        }),
      };

      await makeRequest(config);

      closeModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const formValid =
    btElementsEvents.cardNumber.ready &&
    btElementsEvents.cardNumber.changeEvent &&
    btElementsEvents.cardNumber.changeEvent.valid &&
    (btElementsEvents.cardNumber.changeEvent.cardBrand === 'mastercard' ||
      btElementsEvents.cardNumber.changeEvent.cardBrand === 'visa') &&
    btElementsEvents.cardExpirationDate.ready &&
    btElementsEvents.cardExpirationDate.changeEvent &&
    btElementsEvents.cardExpirationDate.changeEvent.valid &&
    btElementsEvents.cvc.ready &&
    btElementsEvents.cvc.changeEvent &&
    btElementsEvents.cvc.changeEvent.valid &&
    btMetadata.firstName.value.length !== 0 &&
    btMetadata.lastName.value.length !== 0 &&
    btMetadata.addressLine1.value.length !== 0 &&
    btMetadata.city.value.length !== 0 &&
    states.includes(btMetadata.state.value) &&
    btMetadata.zip.value.length === 5;

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('400px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner
        visible={
          spinner ||
          !btElementsEvents.cardNumber.ready ||
          !btElementsEvents.cardExpirationDate.ready ||
          !btElementsEvents.cvc.ready
        }
        text=""
      >
        <h5>Link New Card</h5>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center align-items-center">
            <CardProviderImage company="visa" height={20} className="me-3" />
            <CardProviderImage company="mastercard" height={30} />
          </Col>
        </Row>
        <CardInputForm
          bt={bt}
          btElementsEvents={btElementsEvents}
          btMetadata={btMetadata}
          setBtElementsEvents={setBtElementsEvents}
          setBtMetadata={setBtMetadata}
          cardNumberRef={cardNumberRef}
          cardExpirationDateRef={cardExpirationDateRef}
          cardVerificationCodeRef={cardVerificationCodeRef}
        />
        <BillingAddressForm
          btMetadata={btMetadata}
          setBtMetadata={setBtMetadata}
          useProfileAddress={useProfileAddress}
          setUseProfileAddress={setUseProfileAddress}
        />

        <Row className="mt-3">
          <Col className="text-center">
            <Button className="bo-button" onClick={linkCard} disabled={!formValid}>
              Link
            </Button>
            <Button className="ms-3 bo-button" onClick={closeModal}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerLinkNewCardModal;
