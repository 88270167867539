import { Line, Path, Svg, Text } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { styles } from './styles';

function BarAndLineChart({
  width = 535,
  height = 200,
  barData = [],
  lineData = [],
  leftYAxisTitle,
  rightYAxisTitle,
  renderLeftYAxisValue,
  renderRightYAxisValue,
}) {
  const titleHeight = leftYAxisTitle || rightYAxisTitle ? 20 : 0;
  const totalHeight = height + titleHeight;
  const maxBarHeight = 150;
  const offsetX = 40;
  const offsetY = 20 + titleHeight;
  const barWidth = (width - offsetX * 2) / (barData.length * 1.5);
  const barSpacing = barWidth / 2;

  const maxBarValue = Math.max(...barData.map(item => item.value));
  const maxLineValue = Math.max(...lineData.map(item => item.value));

  const calculateYAxisLabels = maxValue => {
    const orderOfMagnitude = 10 ** Math.floor(Math.log10(maxValue));

    let interval = Math.ceil(maxValue / 5 / orderOfMagnitude) * orderOfMagnitude;

    if (maxValue <= 25) {
      interval = 5;
    }

    if (maxValue <= 16) {
      interval = 2;
    }

    if (maxValue <= 5) {
      interval = 1;
    }

    const labels = [];

    for (let i = 0; i <= maxValue; i += interval) {
      labels.push(i);
    }

    return labels;
  };

  const leftYAxisLabels = useMemo(() => calculateYAxisLabels(maxBarValue), [maxBarValue]);
  const rightYAxisLabels = useMemo(() => calculateYAxisLabels(maxLineValue), [maxLineValue]);

  const getRoundedBarPath = (x, y, w, h, radius) => {
    const right = x + w;
    const bottom = y + h;
    const r = Math.min(radius, w / 2, h);

    return `
      M ${x},${bottom} 
      L ${x},${y + r} 
      Q ${x},${y} ${x + r},${y} 
      L ${right - r},${y} 
      Q ${right},${y} ${right},${y + r} 
      L ${right},${bottom} 
      Z
    `;
  };

  return (
    <Svg width={width} height={totalHeight} style={styles.graphContainer}>
      <Line
        x1={offsetX}
        y1={offsetY}
        x2={offsetX}
        y2={offsetY + maxBarHeight}
        stroke="#000"
        strokeWidth="1"
      />
      {leftYAxisTitle && (
        <Text
          x={offsetX - 25}
          y={offsetY - titleHeight}
          style={styles.graphAxisTitle}
          textAnchor="start"
        >
          {leftYAxisTitle}
        </Text>
      )}
      <Line
        x1={offsetX}
        y1={offsetY + maxBarHeight}
        x2={width - offsetX}
        y2={offsetY + maxBarHeight}
        stroke="#000"
        strokeWidth="1"
      />
      {lineData.length > 0 && (
        <Line
          x1={width - offsetX}
          y1={offsetY}
          x2={width - offsetX}
          y2={offsetY + maxBarHeight}
          stroke="#000"
          strokeWidth="1"
        />
      )}
      {rightYAxisTitle && (
        <Text
          x={width - offsetX + 25}
          y={offsetY - titleHeight}
          style={styles.graphAxisTitle}
          textAnchor="end"
        >
          {rightYAxisTitle}
        </Text>
      )}
      {leftYAxisLabels.map(value => (
        <Text
          key={`left-${value}`}
          x={offsetX - 5}
          y={offsetY + maxBarHeight - (value / maxBarValue) * maxBarHeight + 5}
          style={styles.graphAxisLabels}
          textAnchor="end"
        >
          {renderLeftYAxisValue ? renderLeftYAxisValue(value) : value}
        </Text>
      ))}
      {rightYAxisLabels.map(value => (
        <Text
          key={`right-${value}`}
          x={width - offsetX + 5}
          y={offsetY + maxBarHeight - (value / maxLineValue) * maxBarHeight + 5}
          style={styles.graphAxisLabels}
          textAnchor="start"
        >
          {renderRightYAxisValue ? renderRightYAxisValue(value) : value}
        </Text>
      ))}
      {barData.map((item, index) => {
        const barHeight = (item.value / maxBarValue) * maxBarHeight;
        const x = offsetX + index * (barWidth + barSpacing) + barWidth / 2;
        const y = offsetY + maxBarHeight - barHeight;
        const radius = 5;

        return (
          <Path
            key={`bar-${item.label}`}
            d={getRoundedBarPath(x, y, barWidth, barHeight, radius)}
            fill="#350569"
          />
        );
      })}
      {lineData.map((item, index) => {
        if (index === 0 || maxLineValue === 0 || maxLineValue === null || item.value === null)
          return null;

        const prevX = offsetX + (index - 1) * (barWidth + barSpacing) + barWidth;
        const prevY =
          offsetY + maxBarHeight - (lineData[index - 1].value / maxLineValue) * maxBarHeight;

        const currX = offsetX + index * (barWidth + barSpacing) + barWidth;
        const currY = offsetY + maxBarHeight - (item.value / maxLineValue) * maxBarHeight;

        return (
          <Line
            key={`line-${item.label}`}
            x1={prevX}
            y1={prevY}
            x2={currX}
            y2={currY}
            stroke="#17BEBB"
            strokeWidth="2"
          />
        );
      })}
      {barData.map((item, index) => (
        <Text
          key={`x-axis-${item.label}`}
          x={offsetX + index * (barWidth + barSpacing) + barWidth}
          y={offsetY + maxBarHeight + 15}
          style={styles.graphAxisLabels}
          textAnchor="middle"
        >
          {item.label}
        </Text>
      ))}
    </Svg>
  );
}

export default BarAndLineChart;
