import { Input } from 'reactstrap';

function FundingsStatusFilterForm({ statusFilter, changeStatusFilter, spinner }) {
  return (
    <Input
      className="bo-filter"
      type="select"
      name="statusFilter"
      id="statusFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={statusFilter}
      onChange={e => changeStatusFilter('fundings', e.target.value)}
    >
      <option value="">All Statuses</option>
      <option value="OPEN,DUE,CLOSING">All Open</option>
      <option value="OPEN">Open</option>
      <option value="DUE">Due</option>
      <option value="DEFAULT">Default</option>
      <option value="CLOSING">Closing</option>
      <option value="CLOSED">Closed</option>
      <option value="CANCELLED">Cancelled</option>
    </Input>
  );
}

export default FundingsStatusFilterForm;
