import { Input, Label } from 'reactstrap';

function ReportsMineOnlyForm({ mineOnly, toggleMineOnly }) {
  return (
    <>
      <Input
        className="ms-3 bo-checkbox"
        type="checkbox"
        name="mineOnly"
        id="mineOnly"
        checked={mineOnly === 'TRUE'}
        onChange={() => toggleMineOnly('reports')}
      />
      <Label className="p-0 mt-1 mb-0 me-0">Show my reports only</Label>
    </>
  );
}

export default ReportsMineOnlyForm;
