import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function ProspectNameForm({ prospectFirstName, prospectLastName, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Name:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First name"
          value={prospectFirstName || ''}
          onChange={changeValue}
        />
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last name"
          value={prospectLastName || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ProspectNameForm);
