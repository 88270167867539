import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingOutstandingAmountForm({ fundingOutstandingAmount }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Outstanding Amount:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          align="right"
          className="bo-input"
          type="text"
          name="outstandingAmount"
          id="outstandingAmount"
          value={fundingOutstandingAmount}
          readOnly
        />
      </Col>
      <Col md={3} className="bo-same-line" />
    </>
  );
}
export default memo(FundingOutstandingAmountForm);
