import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingRepaymentTrialsForm({ fundingActualRepaymentTrials }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Repayment Trials:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="trials"
          id="trials"
          value={fundingActualRepaymentTrials}
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingRepaymentTrialsForm);
