import { Row, Col } from 'reactstrap';
import DashboardFunnel from './business/DashboardFunnel';
import DashboardAdvances from './business/DashboardAdvances';
import DashboardConsumers from './business/DashboardConsumers';
import DashboardPaymentMethods from './business/DashboardPaymentMethods';

function DashboardBusiness({ aggregators, getAggregators }) {
  return (
    <Row className="align-items-start">
      <Col md={6}>
        <DashboardConsumers aggregators={aggregators} getAggregators={getAggregators} />
        <DashboardPaymentMethods aggregators={aggregators} getAggregators={getAggregators} />
        <DashboardFunnel aggregators={aggregators} getAggregators={getAggregators} />
      </Col>
      <Col md={6}>
        <DashboardAdvances aggregators={aggregators} getAggregators={getAggregators} />
      </Col>
    </Row>
  );
}

export default DashboardBusiness;
