import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

const TableContext = createContext();

export const useTableContext = () => useContext(TableContext);

export function TableProvider({ children }) {
  const [tables, setTables] = useState({});

  const noPageRefreshRef = useRef(false);

  const [selectedAggregatorFilter, setSelectedAggregatorFilter] = useState('');
  const [selectedConsumerFilter, setSelectedConsumerFilter] = useState({
    consumerId: null,
    consumerFirstName: null,
    consumerLastName: null,
    message: null,
  });
  const [selectedFundingIdsFilter, setSelectedFundingIdsFilter] = useState({
    fundingIds: null,
    message: null,
  });
  const [selectedConsumerIdsFilter, setSelectedConsumerIdsFilter] = useState({
    consumerIds: null,
    message: null,
  });

  const getTable = useCallback(
    ({ tableName, initSortBy, initSortOrder, initRowsPerPage, initStatusFilter = '' }) => {
      if (tables[tableName]) {
        return tables[tableName];
      }

      return {
        sortBy: initSortBy,
        sortOrder: initSortOrder,
        page: 0,
        rowsPerPage: initRowsPerPage,
        totalRows: 0,
        rows: [],
        statusFilter: initStatusFilter,
        bankStatusFilter: '',
        flagsFilter: '',
        mineOnly: 'TRUE',
        search: '',
        modeFilter: '',
      };
    },
    [tables],
  );

  const updateTable = ({ tableName, newTable }) => {
    setTables(prevTables => ({
      ...prevTables,
      [tableName]: newTable,
    }));
  };

  const sortHandler = useCallback(
    (tableName, field, invertSortOrder) => {
      const table = tables[tableName];

      setTables(prevTables => {
        const prevTable = prevTables[tableName];

        if (prevTable.sortBy === field) {
          return {
            ...prevTables,
            [tableName]: {
              ...prevTable,
              sortOrder: table.sortOrder === 'asc' ? 'desc' : 'asc',
            },
          };
        }

        return {
          ...prevTables,
          [tableName]: {
            ...prevTable,
            sortBy: field,
            sortOrder: invertSortOrder ? 'desc' : 'asc',
          },
        };
      });
    },
    [tables],
  );

  const handleChangePage = useCallback(
    (tableName, _, newPage) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          page: newPage,
        },
      });
    },
    [tables],
  );

  const handleChangeRowsPerPage = useCallback(
    (tableName, e) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          rowsPerPage: parseInt(e.target.value, 10),
          page: 0,
        },
      });
    },
    [tables],
  );

  const changeStatusFilter = useCallback(
    (tableName, value) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          statusFilter: value,
          page: 0,
        },
      });
    },
    [tables],
  );

  const changeBankStatusFilter = useCallback(
    (tableName, value) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          bankStatusFilter: value,
          page: 0,
        },
      });
    },
    [tables],
  );

  const changeFlagsFilter = useCallback(
    (tableName, value) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          flagsFilter: value,
          page: 0,
        },
      });
    },
    [tables],
  );

  const toggleMineOnly = useCallback(
    tableName => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          mineOnly: table.mineOnly === 'TRUE' ? 'FALSE' : 'TRUE',
          page: 0,
        },
      });
    },
    [tables],
  );

  const searchKey = useCallback(
    (tableName, e) => {
      if (e.charCode === 13) {
        const table = tables[tableName];

        updateTable({
          tableName,
          newTable: {
            ...table,
            search: e.target.value,
            page: 0,
          },
        });
      }
    },
    [tables],
  );

  const changeModeFilter = useCallback(
    (tableName, e) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          modeFilter: e.target.value,
          page: 0,
        },
      });
    },
    [tables],
  );

  const clearTableFilters = useCallback(
    ({ tableName, initSortBy, initSortOrder, initRowsPerPage, initStatusFilter = '' }) => {
      const table = tables[tableName];

      updateTable({
        tableName,
        newTable: {
          ...table,
          sortBy: initSortBy,
          sortOrder: initSortOrder,
          page: 0,
          rowsPerPage: initRowsPerPage,
          totalRows: 0,
          rows: [],
          statusFilter: initStatusFilter,
          flaggedOnly: false,
          mineOnly: 'TRUE',
          search: '',
          modeFilter: '',
        },
      });

      setSelectedAggregatorFilter('');
      setSelectedConsumerFilter({
        consumerId: null,
        consumerFirstName: null,
        consumerLastName: null,
        message: null,
      });
    },
    [tables],
  );

  const value = useMemo(
    () => ({
      noPageRefreshRef,
      selectedAggregatorFilter,
      setSelectedAggregatorFilter,
      selectedConsumerFilter,
      setSelectedConsumerFilter,
      selectedFundingIdsFilter,
      setSelectedFundingIdsFilter,
      selectedConsumerIdsFilter,
      setSelectedConsumerIdsFilter,
      clearTableFilters,
      getTable,
      updateTable,
      sortHandler,
      handleChangePage,
      handleChangeRowsPerPage,
      changeStatusFilter,
      changeBankStatusFilter,
      changeFlagsFilter,
      toggleMineOnly,
      searchKey,
      changeModeFilter,
    }),
    [
      selectedAggregatorFilter,
      setSelectedAggregatorFilter,
      selectedConsumerFilter,
      setSelectedConsumerFilter,
      selectedFundingIdsFilter,
      setSelectedFundingIdsFilter,
      selectedConsumerIdsFilter,
      setSelectedConsumerIdsFilter,
      clearTableFilters,
      getTable,
      sortHandler,
      handleChangePage,
      handleChangeRowsPerPage,
      changeStatusFilter,
      changeBankStatusFilter,
      changeFlagsFilter,
      toggleMineOnly,
      searchKey,
      changeModeFilter,
    ],
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}
