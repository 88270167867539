import { toast } from 'react-toastify';
import { useEffect, useState, memo } from 'react';
import { Col, Label, Input } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

function AggregatorForm({ consumerAggregatorId, changeValue }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [aggregators, setAggregators] = useState([]);

  const getAggregators = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const response = await makeRequest(config);

      setAggregators(response);
    } catch (error) {
      toast.error(`Failed getting aggregators: ${error.message}`);
    }
  };

  useEffect(() => {
    if (consumerAggregatorId) {
      getAggregators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Aggregator:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        {aggregators.length > 0 && consumerAggregatorId ? (
          <Input
            className="bo-filter w-100"
            type="select"
            name="aggregatorId"
            id="aggregatorId"
            value={
              aggregators.find(aggregator => aggregator.id === Number(consumerAggregatorId))?.id
            }
            noValidate
            onChange={changeValue}
          >
            {aggregators.map(aggregator => (
              <option key={aggregator.id} value={aggregator.id}>
                {aggregator.name}
              </option>
            ))}
          </Input>
        ) : (
          <Input
            className="bo-input w-100"
            type="text"
            name="aggregatorId"
            id="aggregatorId"
            placeholder="Employee ID"
            value="Loading..."
            readOnly
          />
        )}
      </Col>
    </>
  );
}

export default memo(AggregatorForm);
