import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useAuth = () => {
  const { getAccessTokenSilently: getAccessTokenSilentlyAuth0, ...props } = useAuth0();

  const getAccessTokenSilently = useCallback(
    async tokenOpts => {
      let token;

      try {
        token = await getAccessTokenSilentlyAuth0(tokenOpts);
      } catch (e) {
        if (e.error === 'login_required') {
          console.error(e);
        } else {
          throw e;
        }
      }

      return token;
    },
    [getAccessTokenSilentlyAuth0],
  );

  return {
    getAccessTokenSilently,
    ...props,
  };
};
