import moment from 'moment';
import { saveAs } from 'file-saver';

export const exportExcelFile = async (workbook, fileName) => {
  const data = await workbook.xlsx.writeBuffer();
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  saveAs(blob, fileName);
};

export const applyExcelFormats = (value, numberFormat) => {
  if (!numberFormat) {
    return value;
  }

  if (numberFormat === '"$"#,##0.00' || numberFormat === '"$"#,##0.00;[Red]-"$"#,##0.00') {
    return `${value >= 0 ? '' : '-'}$${Math.abs(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  if (numberFormat === '"$"#') {
    return `${value >= 0 ? '' : '-'}$${Math.abs(value).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}`;
  }

  if (numberFormat === '#,##0') {
    return value.toLocaleString('en-US', { maximumFractionDigits: 0 });
  }

  if (numberFormat === '0.00%') {
    return `${(value * 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  }

  if (numberFormat === 'yyyy-mm-dd hh:mm:ss') {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }

  return value;
};

export const parseExcelFormats = value => {
  const parseCurrency = currencyStr => parseFloat(currencyStr.replace(/[$,]/g, ''));

  const parsePercentage = percentageStr => parseFloat(percentageStr.replace(/[%,]/, '')) / 100;

  if (String(value).includes('$')) return parseCurrency(value);

  if (String(value).includes('%')) return parsePercentage(value);

  return parseFloat(value);
};

export const formatExcelDate = date =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
