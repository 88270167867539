import ReactModal from 'react-modal';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Row, Col, Button, Container } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { modalStyle } from '../../../../utils/modal';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { useState, useEffect } from 'react';

function FundingRepaymentDateModal({ repaymentDateModal, funding, getFunding, setSpinner, close }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [selectedRepaymentDate, setSelectedRepaymentDate] = useState(null);

  useEffect(() => {
    if (repaymentDateModal.open) {
      if (repaymentDateModal.latestDate) {
        setSelectedRepaymentDate(funding.maxRepaymentDate);
      } else {
        setSelectedRepaymentDate(funding.nextRepaymentDate || null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repaymentDateModal.open]);

  ReactModal.setAppElement(document.getElementById('root'));

  const setRapaymentDate = async reset => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = repaymentDateModal.latestDate
      ? {
          token,
          url: `${apiOriginFunding}/set_latest_date`,
          method: 'POST',
          data: JSON.stringify({
            fundingId: funding.id,
            latestDate: moment(selectedRepaymentDate).format('YYYY-MM-DD'),
          }),
        }
      : {
          token,
          url: `${apiOriginFunding}/set_repayment_date`,
          method: 'POST',
          data: JSON.stringify({
            fundingId: funding.id,
            repaymentDate: reset ? null : moment(selectedRepaymentDate).format('YYYY-MM-DD'),
          }),
        };

    try {
      await makeRequest(config);
      await getFunding(funding.id);
      close();
      toast.success(
        repaymentDateModal.latestDate
          ? 'Latest repayment date updated successfully'
          : 'Next repayment date updated successfully',
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <ReactModal
      isOpen={repaymentDateModal.open}
      style={modalStyle('320px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Container className="bo-date-picker-modal-container">
        <div>
          <h5>{repaymentDateModal.latestDate && 'Latest'} Repayment Date</h5>
          <Row className="mb-2 mt-4 ms-0">
            <DatePicker
              open
              openToDate={new Date(selectedRepaymentDate)}
              className="bo-input bo-date-picker"
              onChange={e => setSelectedRepaymentDate(e)}
              selected={selectedRepaymentDate ? new Date(selectedRepaymentDate) : null}
              dateFormat="MMM dd, yyyy"
            />
          </Row>
        </div>
        <Row className="mt-5">
          <Col className="text-center">
            <Button className="mt-5 bo-button" onClick={() => setRapaymentDate()}>
              Set
            </Button>
            {repaymentDateModal.reset && (
              <Button className="ms-3 mt-5 bo-button" onClick={() => setRapaymentDate(true)}>
                Reset
              </Button>
            )}
            <Button className="ms-3 mt-5 bo-button" onClick={close}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  );
}

export default FundingRepaymentDateModal;
