import moment from 'moment';
import { useState, memo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import RiskIndicatorChart from '../../../../components/RiskIndicatorChart';
import { RISK_INDICATORS_ORDER, exportRiskIndicatorsFile } from '../../../../utils/riskIndicators';

function ConsumerRiskIndicators({ consumerId, profile, getProfile }) {
  const [spinner, setSpinner] = useState(false);

  const refreshRiskIndicators = async () => {
    setSpinner(true);
    await getProfile(consumerId);
    setSpinner(false);
  };

  const showValueUnit = (key, value) => {
    if (key === 'EL_S') return `$${value}`;

    if (
      key === 'PD_EOD' ||
      key === 'PD_PLUS1_EOD' ||
      key === 'PD_DEBIT' ||
      key === 'PD_PLUS1_DEBIT' ||
      key === 'NBD'
    )
      return `${Number(value * 100).toLocaleString('en-US', {
        maximumFractionDigits: 1,
      })}%`;

    return value;
  };

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 mb-1 ms-1">
        <Col sm="8">
          <Button className="bo-button-inline bo-button-gray" onClick={refreshRiskIndicators}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button
            className="bo-button-inline bo-button-gray ms-3"
            onClick={() => exportRiskIndicatorsFile(profile.riskIndicators)}
            disabled={!profile || !profile.riskIndicators}
          >
            Download Data
          </Button>
        </Col>
      </Row>
      <Row className="pt-2 mb-1 ms-1">
        <Col sm="3" className="d-flex">
          <p className="m-0 p-0 fw-bold">As of:</p>
          <p className="m-0 p-0 ms-1">
            {profile.dateTime !== null &&
              profile.dateTime !== undefined &&
              moment(profile.dateTime).utc().local().calendar({
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: 'MMM DD, YYYY H:mm (UTCZ)',
                nextWeek: 'MMM DD, YYYY H:mm (UTCZ)',
                lastWeek: 'MMM DD, YYYY H:mm (UTCZ)',
                sameElse: 'MMM DD, YYYY H:mm (UTCZ)',
              })}
          </p>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="bo-w-240" align="left">
                Indicator Name
              </StyledTableCell>
              <StyledTableCell className="bo-w-80" align="left">
                Period
              </StyledTableCell>
              <StyledTableCell className="bo-w-100" align="left">
                Value
              </StyledTableCell>
              <StyledTableCell className="bo-w-80" align="left">
                Threshold
              </StyledTableCell>
              <StyledTableCell className="bo-w-80" align="left" />
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {profile.riskIndicators !== null &&
              profile.riskIndicators !== undefined &&
              Object.entries(profile.riskIndicators)
                .filter(([key]) => RISK_INDICATORS_ORDER.includes(key))
                .sort(
                  (a, b) =>
                    RISK_INDICATORS_ORDER.indexOf(a[0]) - RISK_INDICATORS_ORDER.indexOf(b[0]),
                )
                .map(([key, indicator]) => (
                  <TableRow hover tabIndex={-1} key={key}>
                    <StyledTableCell align="left">{indicator.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {`${moment(indicator.to).diff(indicator.from, 'days') + 1} Days`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="p-0 m-0 text-end">
                          {indicator.value !== null ? showValueUnit(key, indicator.value) : 'N/A'}
                        </p>
                        {indicator.value !== null &&
                          indicator.threshold !== null &&
                          indicator.value > indicator.threshold && (
                            <FontAwesomeIcon
                              icon={faWarning}
                              size="1x"
                              color="red"
                              className="ms-1"
                            />
                          )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {indicator.threshold !== null
                        ? showValueUnit(key, indicator.threshold)
                        : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {indicator.value !== null && (
                        <RiskIndicatorChart riskIndicator={indicator} riskIndicatorKey={key} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {key === 'EL_P' &&
                        [...new Set(profile.riskIndicators.EL_P.data.map(x => x.provider))].join(
                          ', ',
                        )}
                    </StyledTableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
}

export default memo(ConsumerRiskIndicators);
