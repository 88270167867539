import { Row } from 'reactstrap';
import ProspectAggregatorNameForm from './forms/ProspectAggregatorNameForm';
import ProspectNameForm from './forms/ProspectNameForm';
import ProspectAddressLine1Form from './forms/ProspectAddressLine1Form';
import ProspectEmailAddressForm from './forms/ProspectEmailAddressForm';
import ProspectAddressLine2Form from './forms/ProspectAddressLine2Form';
import ProspectPhoneNumberForm from './forms/ProspectPhoneNumberForm';
import ProspectAddressLine3Form from './forms/ProspectAddressLine3Form';
import ProspectEmplyeeIdForm from './forms/ProspectEmployeeIdForm';

function ProspectFormController({ prospect, changeValue }) {
  return (
    <>
      <Row className="mb-2 align-items-center">
        <ProspectAggregatorNameForm prospectAggregatorName={prospect.aggregatorName} />
      </Row>
      <Row className="mb-2 align-items-center">
        <ProspectNameForm
          prospectFirstName={prospect.firstName}
          prospectLastName={prospect.lastName}
          changeValue={changeValue}
        />
        <ProspectAddressLine1Form
          prospectAddressLine1={prospect.addressLine1}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <ProspectEmailAddressForm prospectEmail={prospect.email} changeValue={changeValue} />
        <ProspectAddressLine2Form
          prospectAddressLine2={prospect.addressLine2}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <ProspectPhoneNumberForm prospectPhone={prospect.phone} changeValue={changeValue} />
        <ProspectAddressLine3Form
          prospectCity={prospect.city}
          prospectState={prospect.state}
          prospectZip={prospect.zip}
          changeValue={changeValue}
        />
      </Row>
      {prospect.useEmployeeIdProspects && (
        <Row className="mb-2 align-items-center">
          <ProspectEmplyeeIdForm
            prospectEmployeeId={prospect.employeeId}
            changeValue={changeValue}
          />
        </Row>
      )}
    </>
  );
}

export default ProspectFormController;
