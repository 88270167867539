import { Button, Col, Row } from 'reactstrap';

function ReportsNoInputModalForm({ close, createReport }) {
  const handleCreate = () => createReport({});

  return (
    <Row>
      <Col className="text-center mt-5">
        <Button className="bo-button" onClick={handleCreate}>
          Create
        </Button>
        <Button className="ms-3 bo-button" onClick={close}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export default ReportsNoInputModalForm;
