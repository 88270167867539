import { Button, Input, Label } from 'reactstrap';

function DefaultSettingsInput({
  fieldName,
  fieldValue,
  containerClass,
  labelText,
  labelClass,
  changeValue,
  onButtonClick,
}) {
  return (
    <div className={`d-flex align-items-center ${containerClass}`}>
      <div className={`bo-same-line bo-w-240 ${labelClass}`}>
        <Label>{labelText}</Label>
      </div>
      <div className="bo-same-line">
        <Input
          min={0}
          onKeyDown={e => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
          className="bo-input"
          type={fieldValue === null ? 'text' : 'number'}
          name={fieldName}
          id={fieldName}
          readOnly={fieldValue === null}
          value={fieldValue !== null ? fieldValue : 'Default'}
          onChange={changeValue}
        />
      </div>
      <div className="bo-same-line px-0">
        <Button
          className="bo-button-inline-link"
          color="link"
          onClick={() => onButtonClick(fieldName, fieldValue)}
        >
          {fieldValue !== null ? 'Reset to Default' : 'Override Default'}
        </Button>
      </div>
    </div>
  );
}

export default DefaultSettingsInput;
