import { useAuth } from '../hooks/useAuth';
// pages
import Rules from '../pages/Rules/Rules';
import Funding from '../pages/Funding/Funding';
import Profile from '../pages/Profile/Profile';
import Fundings from '../pages/Fundings/Fundings';
import Consumer from '../pages/Consumer/Consumer';
import Prospect from '../pages/Prospect/Prospect';
import Consumers from '../pages/Consumers/Consumers';
import Prospects from '../pages/Prospects/Prospects';
import Dashboard from '../pages/Dashboard/Dashboard';
import Aggregator from '../pages/Aggregator/Aggregator';
import Aggregators from '../pages/Aggregators/Aggregators';
import Permissions from '../pages/Permissions/Permissions';
import Reports from '../pages/Reports/Reports';
import Report from '../pages/Report/Report';
// TODO: rename
import NewObject from '../pages/NewObject/NewObject';
// components
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Spinner } from '../components/Spinner';
import { Container, Row, Col } from 'reactstrap';
// navigation
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

function RoutesWrapperComponent({ location, children }) {
  return location.pathname.includes('/consumer/') ? (
    <div className="w-100 h-100">{children}</div>
  ) : (
    <Container className="flex-grow-1 mt-5">{children}</Container>
  );
}

function MainRouter() {
  const location = useLocation();
  const { isLoading, error } = useAuth();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Row className="align-items-center text-center">
        <Col>
          <Spinner visible text="Loading..." />
        </Col>
      </Row>
    );
  }

  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar />
      <RoutesWrapperComponent location={location}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard/business" replace />} />
          <Route path="dashboard" element={<Navigate to="/dashboard/business" replace />} />
          <Route path="dashboard/:option" element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="aggregators" element={<Aggregators />} />
          <Route path="aggregator/:id" element={<Aggregator />} />
          <Route path="prospects/:id" element={<Prospects />} />
          <Route path="prospect/:id" element={<Prospect />} />
          <Route path="consumers" element={<Consumers />} />
          <Route path="consumer/:id" element={<NewObject />} />
          <Route path="old-consumer/:id" element={<Consumer />} />
          <Route path="fundings" element={<Fundings />} />
          <Route path="funding/:id" element={<Funding />} />
          <Route path="report/:id" element={<Report />} />
          <Route path="reports" element={<Reports />} />
          <Route path="rules" element={<Rules />} />
          <Route path="permissions" element={<Permissions />} />
        </Routes>
      </RoutesWrapperComponent>
      <Footer />
    </div>
  );
}

export default MainRouter;
