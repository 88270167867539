import { Col, Input, Label } from 'reactstrap';
import moment from 'moment';
import { memo } from 'react';

function FundingOpenTimestampForm({ fundingCreateTimestamp }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Open Timestamp:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="createTimestamp"
          id="createTimestamp"
          value={moment.utc(fundingCreateTimestamp).local().format('MMM DD, YYYY H:mm (UTCZ)')}
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingOpenTimestampForm);
