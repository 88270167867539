import moment from 'moment';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useState } from 'react';
import { getDaysInMonth, previousYears } from '../../../../../utils/dates';

function NewObjectPopoverDateOfBirthForm({ consumerDateOfBirth, updateConsumer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState({
    year: String(moment(consumerDateOfBirth).year()),
    month: String(moment(consumerDateOfBirth).month()),
    date: String(moment(consumerDateOfBirth).date()),
  });

  const handleSave = async () => {
    setSpinner(true);
    await updateConsumer({
      dateOfBirth: moment({
        year: dateOfBirth.year,
        month: dateOfBirth.month,
        date: dateOfBirth.date,
      }).format('MM/DD/YYYY'),
    });
    setSpinner(false);
    setIsOpen(false);
  };

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Label className="bo-new-object-input-label mt-3">Date of Birth</Label>
      <Button
        id="popover_dob_form"
        className="bo-new-object-text-input-container"
        onClick={handleToggle}
      >
        <div className="me-auto">
          <Input
            className="bo-new-object-text-input bo-w-150"
            type="text"
            readOnly
            value={moment(consumerDateOfBirth).format('MMM DD, YYYY')}
          />
        </div>
        <div className="bo-new-object-input-button-container">
          <div className="bo-new-object-input-button-icon">
            <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
          </div>
        </div>
      </Button>
      <Popover
        target="popover_dob_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <Label className="bo-new-object-popover-form-menu-label">Year</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={dateOfBirth.year}
              onChange={e => {
                if (
                  !moment({
                    year: e.target.value,
                    month: dateOfBirth.month,
                    date: dateOfBirth.date,
                  }).isValid()
                ) {
                  setDateOfBirth({
                    ...dateOfBirth,
                    year: e.target.value,
                    date: moment({ year: e.target.value, month: dateOfBirth.month })
                      .endOf('month')
                      .date(),
                  });
                } else {
                  setDateOfBirth({ ...dateOfBirth, year: e.target.value });
                }
              }}
            >
              {previousYears(100, moment().subtract(18, 'years').year()).map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Input>
            <Label className="bo-new-object-popover-form-menu-label mt-2">Month</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={dateOfBirth.month}
              onChange={e => {
                if (
                  !moment({
                    year: dateOfBirth.year,
                    month: e.target.value,
                    date: dateOfBirth.date,
                  }).isValid()
                ) {
                  setDateOfBirth({
                    ...dateOfBirth,
                    month: e.target.value,
                    date: moment({ year: dateOfBirth.year, month: e.target.value })
                      .endOf('month')
                      .date(),
                  });
                } else {
                  setDateOfBirth({ ...dateOfBirth, month: e.target.value });
                }
              }}
            >
              {[...Array(12)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option value={i} key={i}>
                  {moment().month(i).format('MMMM')}
                </option>
              ))}
            </Input>
            <Label className="bo-new-object-popover-form-menu-label mt-2">Day</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={dateOfBirth.date}
              onChange={e => setDateOfBirth({ ...dateOfBirth, date: e.target.value })}
            >
              {getDaysInMonth(dateOfBirth.month, dateOfBirth.year).map(x => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
            </Input>
            <div className="mt-2 d-flex justify-content-between">
              <Button className="bo-new-object-button" onClick={handleSave}>
                Save
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverDateOfBirthForm;
