import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function FundingTotalFeesForm({ fundingFeesSum }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Advance Total Fees:</Label>
      </Col>
      <Col md={1} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="reason"
          id="reason"
          value={fundingFeesSum || ''}
          readOnly
        />
      </Col>
      <Col md={3} className="bo-same-line" />
    </>
  );
}

export default memo(FundingTotalFeesForm);
