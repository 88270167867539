import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function MfaChannelForm({ consumerMfaChannel }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>MFA Channel:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          type="text"
          className="bo-input"
          id="mfaChannel"
          name="mfaChannel"
          value={consumerMfaChannel || ''}
          readOnly
          disabled
        />
      </Col>
      <Col md={2} className="bo-same-line" />
    </>
  );
}

export default memo(MfaChannelForm);
