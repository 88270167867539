import { useState } from 'react';
import ReactModal from 'react-modal';
import { modalStyle } from '../../../../utils/modal';
import { Spinner } from '../../../../components/Spinner';
import { Badge, Button, Col, Input, Label, Row } from 'reactstrap';
import { PatternFormat } from 'react-number-format';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ConsumerPhoneVerificationModal({
  isOpen,
  close,
  consumer,
  phoneFields,
  setPhoneEditMode,
  getConsumer,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [isVerified, setIsVerified] = useState(null);

  const closeModal = () => {
    close();
    setMfaCode('');
    setIsVerified(null);
  };

  const resend = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/init_phone_verification`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, ...phoneFields }),
    };

    try {
      await makeRequest(config);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updatePhone = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/update_phone`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, ...phoneFields }),
    };

    try {
      await makeRequest(config);
      await getConsumer(consumer.id);
      setPhoneEditMode(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      closeModal();
      setSpinner(false);
    }
  };

  const save = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/update_phone_verification`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, ...phoneFields, code: mfaCode }),
    };

    try {
      const response = await makeRequest(config);

      setIsVerified(response.isVerified);

      if (response.isVerified) {
        await updatePhone();
      } else {
        setSpinner(false);
      }
    } catch (error) {
      toast.error(error.message);
      setSpinner(false);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('440px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner visible={spinner} text="">
        <h5>Phone Verification</h5>
        <Row className="mt-3">
          <Col md={5} className="bo-same-line">
            <Label>MFA code:</Label>
          </Col>
          <Col md={7} className="bo-same-line">
            <PatternFormat
              className="bo-input"
              type="text"
              name="mfaCode"
              id="mfCode"
              value={mfaCode || ''}
              customInput={Input}
              format="######"
              mask="_"
              onChange={e => setMfaCode(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button className="bo-link m-0 p-0" color="link" onClick={resend}>
              Re-send verification code
            </Button>
          </Col>
        </Row>
        <Row className="bo-h-30 mt-3">
          {isVerified !== null && (
            <Col className="d-flex justify-content-center align-items-center">
              <Badge color={isVerified ? 'success' : 'danger'} className="bo-badge">
                {isVerified ? 'Verification Succeeded' : 'Verification Failed'}
              </Badge>
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <Button className="bo-button" onClick={save} disabled={spinner}>
              Save
            </Button>
            <Button className="ms-3 bo-button" onClick={closeModal}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerPhoneVerificationModal;
