import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function CardRequiredForm({ consumerCardRequired, consumerDefaultPaymentMethod, setConsumer }) {
  const handleChange = e => {
    if (
      consumerDefaultPaymentMethod === 'ACH' &&
      e.target.checked &&
      window.confirm('Would like to change the default payment method to CARD?')
    ) {
      setConsumer(prevConsumer => ({
        ...prevConsumer,
        defaultPaymentMethod: 'CARD',
      }));
    }

    if (
      consumerDefaultPaymentMethod === 'CARD' &&
      !e.target.checked &&
      window.confirm('Would like to change the default payment method to ACH?')
    ) {
      setConsumer(prevConsumer => ({
        ...prevConsumer,
        defaultPaymentMethod: 'ACH',
      }));
    }

    setConsumer(prevConsumer => ({
      ...prevConsumer,
      cardRequired: e.target.checked,
    }));
  };

  return (
    <>
      <Col md={3} className="bo-same-line-top">
        <Input
          className="ms-1 bo-checkbox"
          type="checkbox"
          name="cardRequired"
          id="cardRequired"
          checked={consumerCardRequired}
          onChange={handleChange}
        />
        <Label className="p-0 mt-1 mb-0 me-0">Card required for taking an advance</Label>
      </Col>
      <Col md={3} className="bo-same-line-top" />
    </>
  );
}

export default memo(CardRequiredForm);
