import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function ConsumersQuickAccessForm() {
  const navigate = useNavigate();

  return (
    <Input
      className="bo-input bo-w-120"
      type="quickAccess"
      name="quickAccess"
      id="quickAccess"
      placeholder="Consumer ID"
      noValidate
      onKeyDown={e => {
        if (e.code === 'Enter' && e.target.value !== '') {
          navigate(`/consumer/${e.target.value}`);
        }
      }}
    />
  );
}

export default ConsumersQuickAccessForm;
