import americanExpressImage from '../assets/card-providers/americanexpress.png';
import discoverImage from '../assets/card-providers/discover.png';
import masterCardImage from '../assets/card-providers/mastercard.png';
import visaImage from '../assets/card-providers/visa.png';

function CardProviderImage({ company, height, className }) {
  if (company.toLowerCase() === 'visa') {
    return (
      <img
        alt="Card Provider"
        src={visaImage}
        width="auto"
        height={height || '14'}
        className={className}
      />
    );
  }

  if (company.toLowerCase() === 'mastercard') {
    return (
      <img
        alt="Card Provider"
        src={masterCardImage}
        width="auto"
        height={height || '24'}
        className={className}
      />
    );
  }

  if (company.toLowerCase() === 'discover') {
    return (
      <img
        alt="Card Provider"
        src={discoverImage}
        width="auto"
        height={height || '24'}
        className={className}
      />
    );
  }

  if (company.toLowerCase() === 'americanexpress') {
    return (
      <img
        alt="Card Provider"
        src={americanExpressImage}
        width="auto"
        height={height || '28'}
        className={className}
      />
    );
  }

  return <p className="bo-text mb-0">{company.toUpperCase()}</p>;
}

export default CardProviderImage;
