import { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import DashboardOperational from './components/DashboradOperational';
import DashboardBusiness from './components/DashboardBusiness';

function Dashboard() {
  const params = useParams();

  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [aggregators, setAggregators] = useState([]);

  const getAggregators = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const response = await makeRequest(config);

      setAggregators(response);
    } catch (error) {
      toast.error(`Faled getting aggregators: ${error.message}`);
    }
  }, [apiOriginAggregator, getAccessTokenSilently]);

  useEffect(() => {
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboardOption = () => {
    if (params.option === 'operational') {
      return <DashboardOperational />;
    }

    if (params.option === 'business') {
      return <DashboardBusiness aggregators={aggregators} getAggregators={getAggregators} />;
    }

    return null;
  };

  return <Container className="mb-5">{getDashboardOption()}</Container>;
}

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
