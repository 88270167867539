import { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@mui/material';
import { toast } from 'react-toastify';
import {
  CARD_ASTRA_STATUS,
  CARD_STATUS,
  CARD_VERIFICATION_STATUS,
} from '../../../../constants/consumers';
import CardProviderImage from '../../../../components/CardProviderImage';
import { capitalizeFirstLetter } from '../../../../utils/strings';

function ConsumerCards({ consumerId, openLinkNewCardModal, linkNewCardModal }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [astraUser, setAstraUser] = useState(null);

  const getAstraUser = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/astra/user`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setAstraUser(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (linkNewCardModal === false) {
      getAstraUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkNewCardModal]);

  const createAstraUser = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/astra/create_user`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    try {
      await makeRequest(config);
      await getAstraUser();
      toast.success('Astra user has been created');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const deleteAstraUser = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/astra/delete_user`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        const response = await makeRequest(config);

        window.alert(
          `Astra user data has been removed from consumer ${consumerId}. ` +
            'Please delete the user manually in Astra dashboard. ' +
            `Astra user id: ${response.astraUserId}`,
        );
        await getAstraUser();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const setMainCard = async cardId => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/cards/set_main`,
      method: 'POST',
      data: JSON.stringify({ consumerId, cardId }),
    };

    try {
      await makeRequest(config);
      await getAstraUser();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const deleteCard = async cardId => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/cards/remove`,
        method: 'POST',
        data: JSON.stringify({ consumerId, cardId }),
      };

      try {
        await makeRequest(config);
        await getAstraUser();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <Button className="bo-button-inline bo-button-gray" onClick={getAstraUser}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={createAstraUser}>
            Create Astra User
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={deleteAstraUser}>
            Delete Astra User
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={openLinkNewCardModal}>
            Link new Card
          </Button>
        </Col>
      </Row>
      <div className="mb-2 mt-3">
        <Row className="mt-2 ms-1 me-1">
          <Col sm="1">User ID:</Col>
          <Col sm="9" className="bo-same-line">
            {astraUser && astraUser.userId && astraUser.userId.includes('user-intent-') && (
              <Badge className="bo-badge me-2" color="warning">
                User Intent
              </Badge>
            )}
            <p className="mb-0">{astraUser && astraUser.userId ? astraUser.userId : 'N/A'}</p>
          </Col>
        </Row>
        <Row className="mt-2 ms-1 me-1">
          <Col sm="1">Status:</Col>
          <Col sm="9">
            <Badge
              className="bo-badge"
              color={astraUser?.status === 'approved' ? 'success' : 'danger'}
            >
              {astraUser && astraUser.status ? capitalizeFirstLetter(astraUser.status) : 'N/A'}
            </Badge>
          </Col>
        </Row>
      </div>
      <div className="d-flex flex-wrap ms-3 mb-3 mt-3">
        {astraUser &&
          astraUser.cards.map(card => (
            <Card
              className={`mb-3 me-3 card bo-w-400 p-3 ${
                card.isAdhoc ? 'bo-card-adhoc-bg-color' : ''
              }`}
              key={`${card.lastFourDigits}_${card.expirationDate}`}
            >
              <div className="d-flex justify-content-between">
                <CardProviderImage company={card.cardCompany} />
                <div className="d-flex">
                  <p className="bo-text me-1">{card.cardType?.toUpperCase()}</p>
                  {card.isMain && <FontAwesomeIcon icon={faStar} size="lg" color="orange" />}
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Card Number:</p>
                  <p className="bo-text mb-1">{`**** ***** **** ${card.lastFourDigits}`}</p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">EXP:</p>
                  <p className={`bo-text mb-1 ${card.isExpired ? 'bo-text-red' : ''}`}>
                    {`${card.expirationDate} ${card.isExpired ? 'Expired!' : ''}`}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Card Holder:</p>
                  <p className="bo-text mb-1">{`${card.firstName} ${card.lastName}`}</p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Astra Status:</p>
                  <p className="bo-text mb-1">
                    <Badge
                      className="bo-badge"
                      color={CARD_ASTRA_STATUS[card.astraStatus]?.color || 'danger'}
                    >
                      {CARD_ASTRA_STATUS[card.astraStatus]?.text}
                    </Badge>
                  </p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Push enabled:</p>
                  <p className="bo-text mb-1">{card.pushEnabled ? 'Yes' : 'No'}</p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Pull enabled:</p>
                  <p className="bo-text mb-1">{card.pullEnabled ? 'Yes' : 'No'}</p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Verification Status:</p>
                  <p className="bo-text mb-1">
                    <Badge
                      className="bo-badge"
                      color={CARD_VERIFICATION_STATUS[card.verificationStatus]?.color}
                    >
                      {CARD_VERIFICATION_STATUS[card.verificationStatus]?.text}
                    </Badge>
                  </p>
                </div>
                <div className="d-flex">
                  <p className="bo-text mb-1 bo-w-200">Status:</p>
                  <p className="bo-text mb-1">
                    <Badge className="bo-badge" color={CARD_STATUS[card.status]?.color}>
                      {CARD_STATUS[card.status]?.text}
                    </Badge>
                  </p>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between">
                {!card.isAdhoc && (
                  <Button
                    className="bo-button-inline-link p-0 m-0"
                    color="link"
                    onClick={() => setMainCard(card.id)}
                    disabled={card.isMain}
                  >
                    Set as main card
                  </Button>
                )}
                <Button
                  className="bo-button-inline-link p-0 m-0 ms-auto"
                  color="link"
                  onClick={() => deleteCard(card.id)}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
      </div>
    </Spinner>
  );
}

export default memo(ConsumerCards);
