import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { Spinner } from '../../components/Spinner';
import { useEnv } from '../../context/env-context';
import { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import SuperuserModal from './components/modals/SuperuserModal';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import GrantPermissionsForm from './components/forms/GrantPermissionsForm';

export function Permissions() {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const getAllPermissions = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    try {
      const response = await axios(`${apiOriginConsumer}/get_all_permissions`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setAllPermissions(response.data);
    } catch (error) {
      toast.error('Failed to get permissions!');
    } finally {
      setSpinner(false);
    }
  };

  const getUsers = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    try {
      const response = await axios(`${apiOriginConsumer}/get_users`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setUsers(response.data);
    } catch (error) {
      toast.error('Failed to get users!');
    } finally {
      setSpinner(false);
    }
  };

  const getData = async () => {
    setSpinner(true);
    await getAllPermissions();
    await getUsers();
    setSpinner(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPermissions = useMemo(() => {
    const foundUser = users.find(user => user.user_id === selectedUserId);

    if (foundUser && foundUser.app_metadata.permissions) {
      return foundUser.app_metadata.permissions;
    }

    return [];
  }, [selectedUserId, users]);

  return (
    <>
      <Spinner visible={spinner} text="">
        <Container className="flex-grow-1 mb-5 h-100">
          <Row className="mt-1">
            <Col>
              <p className="bo-header">Permissions</p>
            </Col>
          </Row>
          <Form className="form">
            <GrantPermissionsForm
              allPermissions={allPermissions}
              users={users}
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              userPermissions={userPermissions}
              setSpinner={setSpinner}
              getUsers={getUsers}
              openModal={openModal}
            />
          </Form>
        </Container>
      </Spinner>
      <SuperuserModal showModal={showModal} closeModal={closeModal} getUsers={getUsers} />
    </>
  );
}

export default withAuthenticationRequired(Permissions, {
  onRedirecting: () => <Loading />,
});
