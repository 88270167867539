import { useAuth } from '../../../../hooks/useAuth';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Row } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import WrongPassword from '../../../../components/WrongPassword';

function ProfileChangePasswordForm({ setSpinner }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [passwordChangeMode, setPasswordChangeMode] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const setPasswordChange = () => setPasswordChangeMode(true);
  const cancelPasswordChange = () => setPasswordChangeMode(false);
  const handlePwdChange = e => setPassword(e.target.value);
  const handlePwdConfirmChange = e => setConfirmPassword(e.target.value);

  const savePassword = async () => {
    if (password !== confirmPassword) {
      toast.error('Password and Password confirmation does not match!');

      return;
    }
    if (!password) {
      toast.error('Empty password is not allowed');

      return;
    }
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${apiOriginAggregator}/user/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newPass: password }),
      });

      if (response.ok) {
        setPasswordChangeMode(false);
        toast.success('Password updated successfully');
      } else {
        const err = await response.text();

        toast.error(`Failed to change password: ${JSON.parse(err).message}`);
      }
    } catch (error) {
      toast.error('Failed to change password: Communication error!');
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Row className="mt-3 mb-2 align-items-center">
      <Col md={2} />
      <Col md={4} className="h-100">
        {passwordChangeMode ? (
          <>
            <Input
              className="bo-input mb-2"
              type="password"
              name="password"
              id="password"
              placeholder="New password"
              noValidate
              onChange={e => handlePwdChange(e)}
              value={password}
            />
            <Input
              className="bo-input mt-3"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm new password"
              noValidate
              onChange={e => handlePwdConfirmChange(e)}
              value={confirmPassword}
            />
            {password !== confirmPassword ? (
              <div className="bo-inline-error mt-1">Passwords does not match</div>
            ) : (
              ''
            )}
            <Button className="mt-3 bo-button-inline" onClick={savePassword}>
              Change
            </Button>{' '}
            &nbsp;
            <Button className="mt-3 bo-button-inline" onClick={cancelPasswordChange}>
              Cancel
            </Button>
            <div className="bo-password-policy mt-2">
              <WrongPassword />
            </div>
          </>
        ) : (
          <Button className="bo-button-inline-link p-0" color="link" onClick={setPasswordChange}>
            Change Password
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default ProfileChangePasswordForm;
