import { useAuth } from '../../../../hooks/useAuth';
import { Col, Input, Label, Row } from 'reactstrap';

function ProfileEmailForm() {
  const { user } = useAuth();

  return (
    <Row className="mt-3 mb-2 align-items-center">
      <Col md={2}>
        <Label>Email Address:</Label>
      </Col>
      <Col md={4}>
        <Input
          className="bo-input"
          type="text"
          name="email"
          id="email"
          placeholder="Email address"
          value={user.email}
          disabled
          noValidate
        />
      </Col>
    </Row>
  );
}

export default ProfileEmailForm;
