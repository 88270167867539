import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTableContext } from '../../../../context/table-context';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableContainer, Table, TableHead, TableRow } from '@mui/material';

function DashboardAdvances({ aggregators, getAggregators }) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginMonitor, apiOriginFunding } = useEnv();

  const { clearTableFilters, setSelectedFundingIdsFilter } = useTableContext();

  const [spinner, setSpinner] = useState(false);
  const [activeOffers, setActiveOffers] = useState();
  const [fundingSummary, setFundingSummary] = useState();
  const [dwollaBalance, setDwollaBalance] = useState();
  const [aggregatorId, setAggregatorId] = useState();

  const formatAdvances = obj => {
    const newObj = { ...obj };

    Object.keys(newObj).forEach(item => {
      if (Array.isArray(newObj[item]) && item !== 'agingData') {
        newObj[item] = newObj[item].map(v => Number(v));
      } else if (
        item !== 'CurrentMonth' &&
        item !== 'PreviousMonth' &&
        item !== 'CurrentMonthCardFailures' &&
        item !== 'CurrentMonthCardTransfers' &&
        item !== 'TransferFailuresCurrentMonth' &&
        item !== 'TransferFailuresPreviousMonth' &&
        item !== 'TransferFees_sum' &&
        item !== 'collectionForecast' &&
        item !== 'agingData'
      ) {
        newObj[item] = Number(newObj[item]).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        });
      } else if (item === 'CurrentMonth' || item === 'PreviousMonth') {
        newObj[item] = String(newObj[item]);
      } else if (item === 'CurrentMonthCardFailures') {
        newObj[item] = { ...newObj[item] };
      } else if (item === 'CurrentMonthCardTransfers') {
        newObj[item] = Number(newObj[item]);
      } else if (
        item === 'TransferFailuresCurrentMonth' ||
        item === 'TransferFailuresPreviousMonth'
      ) {
        if (newObj[item] === null) {
          newObj[item] = 'N/A';
        } else {
          newObj[item] = `${(Number(newObj[item]) * 100).toLocaleString('en-US', {
            maximumFractionDigits: 1,
          })}%`;
        }
      } else if (item === 'TransferFees_sum') {
        if (newObj[item] === null) {
          newObj[item] = 'N/A';
        } else {
          newObj[item] = `${Number(newObj[item]).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
      } else {
        Object.keys(obj.collectionForecast).forEach(i => {
          formatAdvances(obj.collectionForecast[i]);
        });
      }
    });

    return newObj;
  };

  const getActiveOffers = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_active_offers_by_aggregator`,
      method: 'GET',
      params: {
        aggregatorId,
      },
    };

    try {
      const response = await makeRequest(config);
      const formattedOffers = formatAdvances(response);

      setActiveOffers(formattedOffers);
    } catch (error) {
      toast.error(`Failed getting active offers: ${error.message}`);
    }
  };

  const getFundingSummary = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/get_funding_summary_by_aggregator`,
      method: 'GET',
      params: {
        aggregatorId,
      },
    };

    try {
      const response = await makeRequest(config);
      const formattedSummary = formatAdvances(response);

      setFundingSummary(formattedSummary);
    } catch (error) {
      toast.error(`Failed getting advance summary: ${error.message}`);
    }
  };

  const getDwollaBalance = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/get_dwolla_balance`,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      setDwollaBalance(response);
    } catch (error) {
      toast.error(`Failed getting dwolla balance: ${error.message}`);
    }
  };

  const getAllData = async () => {
    setSpinner(true);
    await Promise.all([
      getDwollaBalance(),
      getActiveOffers(),
      getFundingSummary(),
      getAggregators(),
    ]);
    setSpinner(false);
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatorId]);

  const changeAggregatorFilter = e => setAggregatorId(e.target.value);

  const onItemCountClick = (fundingIds, message) => {
    clearTableFilters({
      tableName: 'fundings',
      initSortBy: 'id',
      initSortOrder: 'desc',
      initRowsPerPage: 25,
    });
    setSelectedFundingIdsFilter({
      fundingIds: fundingIds.join(','),
      message,
    });
    navigate('/fundings');
  };

  // const calculateAgingData = (dividend, divisor) =>
  //   `${Number((dividend / parseFloat(divisor.replace(',', ''))) * 100).toLocaleString('en-US', {
  //     maximumFractionDigits: 0,
  //   })}% `;

  const getEarlyLateList = (prePayday, postPayday) => {
    if (prePayday && postPayday) {
      return [...prePayday.list, ...postPayday.list];
    }

    if (prePayday) {
      return [...prePayday.list];
    }

    if (postPayday) {
      return [...postPayday.list];
    }

    return [];
  };

  const renderEarlyLateCell = (value, messageOnClick) => (
    <div className="d-flex align-items-center justify-content-end">
      <div className="d-flex flex-column">
        {value.prePayday && (
          <div>{`Early: $${value.prePayday.sum} (${value.prePayday.count})`}</div>
        )}
        {value.postPayday && (
          <div>{`Late: $${value.postPayday.sum} (${value.postPayday.count})`}</div>
        )}
      </div>
      {(value.prePayday || value.postPayday) && (
        <Button
          className="bo-button-inline-link bo-inline-badge p-0"
          color="link"
          onClick={() =>
            onItemCountClick(getEarlyLateList(value.prePayday, value.postPayday), messageOnClick)
          }
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-2" />
        </Button>
      )}
    </div>
  );

  return (
    <Card className="mb-0">
      <CardHeader className="card-header d-flex justify-content-between">
        Advances
        <div className="d-flex align-items-center">
          <Input
            className="bo-filter w-100 ms-3"
            type="select"
            name="aggregatorFilter"
            id="aggregatorFilter"
            placeholder="filter"
            noValidate
            value={aggregatorId}
            onChange={changeAggregatorFilter}
          >
            <option value="">All Standard Aggregators</option>
            {aggregators.map(agg => (
              <option key={agg.id} value={agg.id}>
                {agg.name}
              </option>
            ))}
          </Input>
          <Button className="bo-button-inline-link" color="link" onClick={getAllData}>
            Refresh
          </Button>
        </div>
      </CardHeader>
      <Spinner visible={spinner} text="">
        {fundingSummary && activeOffers ? (
          <>
            <CardBody>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Advances to date</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${fundingSummary.AdvancesToDate_sum}`}</p>
                    <p className="text-center h6">{`(${fundingSummary.AdvancesToDate_count})`}</p>
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Open Advances</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${fundingSummary.AdvancesOpen_sum}`}</p>
                    {fundingSummary.AdvancesOpen_list.length > 0 ? (
                      <Button
                        className="bo-button-inline-link p-0 d-flex"
                        color="link"
                        onClick={() =>
                          onItemCountClick(
                            fundingSummary.AdvancesOpen_list,
                            'Dashboard → Advances → Open Advances',
                          )
                        }
                      >
                        <p className="text-center h6">{`(${fundingSummary.AdvancesOpen_count})`}</p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-1" />
                      </Button>
                    ) : (
                      <p className="text-center h6">{`(${fundingSummary.AdvancesOpen_count})`}</p>
                    )}
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Default Advances</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${fundingSummary.AdvancesDefault_sum}`}</p>
                    {fundingSummary.AdvancesDefault_list.length > 0 ? (
                      <Button
                        className="bo-button-inline-link p-0 d-flex"
                        color="link"
                        onClick={() =>
                          onItemCountClick(
                            fundingSummary.AdvancesDefault_list,
                            'Dashboard → Advances → Default Advances',
                          )
                        }
                      >
                        <p className="text-center h6">
                          {`(${fundingSummary.AdvancesDefault_count})`}
                        </p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-1" />
                      </Button>
                    ) : (
                      <p className="text-center h6">
                        {`(${fundingSummary.AdvancesDefault_count})`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Fees to Date</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="text-center h5">{`$${fundingSummary.TransferFees_sum}`}</p>
                  </div>
                </div>
              </div>
            </CardBody>
            <p className="card-header border-0">Advances Aging</p>
            <CardBody>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="bo-border-container rounded m-1 bo-w-500">
                  {fundingSummary.agingData?.map(x => (
                    <div
                      key={x.description}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p className="text-start h6 me-3">{x.description}</p>
                      {x.count !== null &&
                      x.count !== undefined &&
                      fundingSummary.AdvancesOpen_count !== null &&
                      fundingSummary.AdvancesOpen_count !== undefined &&
                      fundingSummary.AdvancesOpen_count !== 0 ? (
                        <div className="d-flex align-items-center">
                          {x.amount > 0 ? (
                            <Button
                              className="bo-button-inline-link p-0 me-1 d-flex bo-w-100 text-start"
                              color="link"
                              onClick={() =>
                                onItemCountClick(x.list, `Dashboard → Aging → ${x.description}`)
                              }
                            >
                              <p className="h6">{`$${x.amount}`}</p>
                            </Button>
                          ) : (
                            <p className="h6 me-1 bo-w-100 text-start">{`$${x.amount}`}</p>
                          )}
                          <span className="bo-text-sm mb-2 bo-w-100">
                            ({x.count}/{fundingSummary.AdvancesOpen_count})
                          </span>
                        </div>
                      ) : (
                        <p className="h6">N/A</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </CardBody>
            <p className="card-header border-0">ACH Payments</p>
            <CardBody>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Pending - Out</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${fundingSummary.TransferPendingOut_sum}`}</p>
                    {fundingSummary.TransferPendingOut_list.length > 0 ? (
                      <Button
                        className="bo-button-inline-link p-0 d-flex"
                        color="link"
                        onClick={() =>
                          onItemCountClick(
                            fundingSummary.TransferPendingOut_list,
                            'Dashboard → ACH → Pending Out',
                          )
                        }
                      >
                        <p className="text-center h6">
                          {`(${fundingSummary.TransferPendingOut_count})`}
                        </p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-1" />
                      </Button>
                    ) : (
                      <p className="text-center h6">
                        {`(${fundingSummary.TransferPendingOut_count})`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Pending - In</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${fundingSummary.TransferPendingIn_sum}`}</p>
                    {fundingSummary.TransferPendingIn_list.length > 0 ? (
                      <Button
                        className="bo-button-inline-link p-0 d-flex"
                        color="link"
                        onClick={() =>
                          onItemCountClick(
                            fundingSummary.TransferPendingIn_list,
                            'Dashboard → ACH → Pending In',
                          )
                        }
                      >
                        <p className="text-center h6">
                          {`(${fundingSummary.TransferPendingIn_count})`}
                        </p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-1" />
                      </Button>
                    ) : (
                      <p className="text-center h6">
                        {`(${fundingSummary.TransferPendingIn_count})`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Debit Return Rate</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h6 me-3">{fundingSummary.CurrentMonth}</p>
                    <p className="text-center h6">
                      {fundingSummary.TransferFailuresCurrentMonth}{' '}
                      <span className="bo-text-sm mb-2">
                        ({fundingSummary.TransferCurrentMonth_failed_cnt}/
                        {fundingSummary.TransferCurrentMonth_all_count})
                      </span>
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center m-0 me-3">
                      <small>{fundingSummary.PreviousMonth}</small>
                    </p>
                    <p className="text-center m-0">
                      <small>
                        {fundingSummary.TransferFailuresPreviousMonth}{' '}
                        <span className="bo-text-sm mb-2">
                          ({fundingSummary.TransferPreviousMonth_failed_cnt}/
                          {fundingSummary.TransferPreviousMonth_all_count})
                        </span>
                      </small>
                    </p>
                  </div>
                </div>
                {dwollaBalance && (
                  <div className="bo-border-container rounded m-1 bo-min-w-200">
                    <p className="text-capitalize bo-badge">Dwolla Balance</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="text-center h5">{`$${Number(
                        dwollaBalance.balance,
                      ).toLocaleString('en-US')}`}</p>
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
            <p className="card-header border-0">CARD Payments</p>
            <CardBody>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Pending - In</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">
                      {`$${fundingSummary.CardTransfersPendingIn_sum}`}
                    </p>
                    {fundingSummary.CardTransfersPendingIn_list.length > 0 ? (
                      <Button
                        className="bo-button-inline-link p-0 d-flex"
                        color="link"
                        onClick={() =>
                          onItemCountClick(
                            fundingSummary.CardTransfersPendingIn_list,
                            'Dashboard → CARD → Pending In',
                          )
                        }
                      >
                        <p className="text-center h6">
                          {`(${fundingSummary.CardTransfersPendingIn_count})`}
                        </p>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mt-1 ms-1" />
                      </Button>
                    ) : (
                      <p className="text-center h6">
                        {`(${fundingSummary.CardTransfersPendingIn_count})`}
                      </p>
                    )}
                  </div>
                </div>
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">
                    {`Failures - ${fundingSummary.CurrentMonth}`}
                  </p>
                  {fundingSummary.CurrentMonthCardFailures &&
                  Object.entries(fundingSummary.CurrentMonthCardFailures).length > 0 ? (
                    Object.entries(fundingSummary.CurrentMonthCardFailures).map(([key, value]) => (
                      <div key={key} className="d-flex align-items-center justify-content-between">
                        <p className="text-center h6 me-3">{key}</p>
                        <p className="text-center h6">
                          {`${Number(
                            (value / fundingSummary.CurrentMonthCardTransfers) * 100,
                          ).toLocaleString('en-US', {
                            maximumFractionDigits: 1,
                          })}% `}
                          <span className="bo-text-sm mb-2">
                            ({value}/{fundingSummary.CurrentMonthCardTransfers})
                          </span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="h6">N/A</p>
                  )}
                </div>
              </div>
            </CardBody>
            <p className="card-header border-0">Offers</p>
            <CardBody>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="bo-border-container rounded m-1 bo-min-w-200">
                  <p className="text-capitalize bo-badge">Active Offers</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-center h5">{`$${activeOffers.sum}`}</p>
                    <p className="text-center h6">{`(${activeOffers.count})`}</p>
                  </div>
                </div>
              </div>
            </CardBody>
            <p className="card-header border-0">Repayment Forecast</p>
            <CardBody>
              <TableContainer>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Date</StyledTableCell>
                      <StyledTableCell align="right">Payday</StyledTableCell>
                      <StyledTableCell align="right">Early/Late</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fundingSummary.collectionForecast.MANUAL && (
                      <TableRow hover tabIndex={-1}>
                        <StyledTableCell align="left">
                          <strong>Manual with no date</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {`$${Number(fundingSummary.collectionForecast.MANUAL.sum).toLocaleString(
                            'en-US',
                          )} `}
                          {`(${fundingSummary.collectionForecast.MANUAL.count})`}
                          {fundingSummary.collectionForecast.MANUAL.list.length > 0 && (
                            <Button
                              className="bo-button-inline-link bo-inline-badge p-0 mb-1"
                              color="link"
                              onClick={() =>
                                onItemCountClick(
                                  fundingSummary.collectionForecast.MANUAL.list,
                                  'Dashboard → Repayment Forecast → Manual',
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="mb-0 ms-1"
                              />
                            </Button>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {renderEarlyLateCell(
                            fundingSummary.collectionForecast.MANUAL,
                            'Dashboard → Repayment Forecast → Manual → Early/Late',
                          )}
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {fundingSummary.collectionForecast.FORCE && (
                      <TableRow hover tabIndex={-1}>
                        <StyledTableCell align="left">
                          <strong>Force</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {`$${Number(fundingSummary.collectionForecast.FORCE.sum).toLocaleString(
                            'en-US',
                          )} `}
                          {`(${fundingSummary.collectionForecast.FORCE.count})`}
                          {fundingSummary.collectionForecast.FORCE.list.length > 0 && (
                            <Button
                              className="bo-button-inline-link bo-inline-badge p-0 mb-1"
                              color="link"
                              onClick={() =>
                                onItemCountClick(
                                  fundingSummary.collectionForecast.FORCE.list,
                                  'Dashboard → Repayment Forecast → Force',
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="mt-1 ms-1"
                              />
                            </Button>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {renderEarlyLateCell(
                            fundingSummary.collectionForecast.FORCE,
                            'Dashboard → Repayment Forecast → Force → Early/Late',
                          )}
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {fundingSummary.collectionForecast.IMMEDIATE && (
                      <TableRow hover tabIndex={-1}>
                        <StyledTableCell align="left">
                          <strong>Immediate</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {`$${Number(
                            fundingSummary.collectionForecast.IMMEDIATE.sum,
                          ).toLocaleString('en-US')} `}
                          {`(${fundingSummary.collectionForecast.IMMEDIATE.count})`}
                          {fundingSummary.collectionForecast.IMMEDIATE.list.length > 0 && (
                            <Button
                              className="bo-button-inline-link bo-inline-badge p-0 mb-1"
                              color="link"
                              onClick={() =>
                                onItemCountClick(
                                  fundingSummary.collectionForecast.IMMEDIATE.list,
                                  'Dashboard → Repayment Forecast → Immediate',
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="mt-1 ms-1"
                              />
                            </Button>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {renderEarlyLateCell(
                            fundingSummary.collectionForecast.IMMEDIATE,
                            'Dashboard → Repayment Forecast → Immediate → Early/Late',
                          )}
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {Object.entries(fundingSummary.collectionForecast)
                      .filter(([key]) => key !== 'FORCE' && key !== 'IMMEDIATE' && key !== 'MANUAL')
                      .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
                      .map(([key, value]) => (
                        <TableRow key={key} hover tabIndex={-1}>
                          <StyledTableCell align="left">
                            <strong>{key}</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {`$${Number(value.sum).toLocaleString('en-US')} `}
                            {`(${value.count})`}
                            {value.list.length > 0 && (
                              <Button
                                className="bo-button-inline-link bo-inline-badge p-0 mb-1"
                                color="link"
                                onClick={() =>
                                  onItemCountClick(
                                    value.list,
                                    `Dashboard → Repayment Forecast → ${key}`,
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faArrowUpRightFromSquare}
                                  className="mt-1 ms-1"
                                />
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {renderEarlyLateCell(
                              value,
                              `Dashboard → Repayment Forecast → ${key} → Early/Late`,
                            )}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </>
        ) : (
          <CardBody className="bo-min-h-100" />
        )}
      </Spinner>
    </Card>
  );
}

export default DashboardAdvances;
