import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function ContactNameForm({ aggregatorContactName, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Contact person:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="contactName"
          id="contactName"
          placeholder="Full name"
          value={aggregatorContactName || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ContactNameForm);
