import moment from 'moment';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Spinner } from '../../../../components/Spinner';
import { Row, Col, Button } from 'reactstrap';
import { memo, useState } from 'react';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';

function ConsumerAlerts({ consumerId, consumerAlerts, openNewAlertModal, getConsumer }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);

  const deleteAlert = async alertId => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/alerts/delete`,
        method: 'POST',
        data: JSON.stringify({ alertId }),
      };

      try {
        await makeRequest(config);
        await getConsumer(consumerId);
        toast.success('Alert has been successfully deleted');
      } catch (error) {
        toast.error(error);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Spinner visible={spinner}>
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <Button className="bo-button-inline bo-button-gray" onClick={openNewAlertModal}>
            Create New Alert
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Data</StyledTableCell>
              <StyledTableCell align="left">Create Date</StyledTableCell>
              <StyledTableCell align="left">Created By</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {consumerAlerts.map(cAlert => (
              <TableRow hover tabIndex={-1} key={cAlert.id}>
                <StyledTableCell align="left">{cAlert.type}</StyledTableCell>
                <StyledTableCell align="left">
                  {cAlert.data.threshold !== null &&
                    cAlert.data.threshold !== undefined &&
                    `Threshold=${cAlert.data.threshold}`}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {moment.utc(cAlert.createTimestamp).local().format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">{cAlert.user}</StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    className="bo-button-inline-link py-0 m-0"
                    color="link"
                    onClick={() => deleteAlert(cAlert.id)}
                    key="delete"
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
}

export default memo(ConsumerAlerts);
