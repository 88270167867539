function mandatoryValidation(text) {
  return !text;
}

function emailValidation(email) {
  const emailRegEx =
    // eslint-disable-next-line no-useless-escape, max-len
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email && email.match(emailRegEx)) {
    return false;
  }

  return true;
}

function passwordValidation(password, firstName, lastName, email) {
  const password1 = /^(?=.*[A-Z])(?=.*[%~^\-_+.!@#$&*])(?=.*[0-9]).{8,}$/;
  const password2 = /^(?=.*[A-Z])(?=.*[%~^\-_+.!@#$&*])(?=.*[a-z]).{8,}$/;
  const password3 = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
  const password4 = /^(?=.*[%~^\-_+.!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

  if (
    password &&
    (password.match(password1) ||
      password.match(password2) ||
      password.match(password3) ||
      password.match(password4)) &&
    !(
      password.toLocaleLowerCase().includes(firstName.toLocaleLowerCase()) ||
      password.toLocaleLowerCase().includes(lastName.toLocaleLowerCase()) ||
      password.toLocaleLowerCase().includes(email.toLocaleLowerCase().split('@')[0])
    )
  ) {
    return false;
  }

  return true;
}

function confirmPasswordValidation(confirmPassword, password) {
  if ((confirmPassword && confirmPassword === password) || (!confirmPassword && !password)) {
    return false;
  }

  return true;
}

export { mandatoryValidation, emailValidation, passwordValidation, confirmPasswordValidation };
