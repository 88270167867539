import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

function ConsumersRedFlagsFilterForm({ flagsFilter, changeFlagsFilter, spinner }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [flags, setFlags] = useState([]);

  const getFlags = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_all_flags`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setFlags(Object.entries(data));
    } catch (error) {
      toast.error(`Faled getting consumer flags: ${error.message}`);
    }
  };

  useEffect(() => {
    getFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      className="bo-filter bo-min-w-240"
      type="select"
      name="flagsFilter"
      id="flagsFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={flagsFilter}
      onChange={e => changeFlagsFilter('consumers', e.target.value)}
    >
      <option value="">Ignore Flags</option>
      <option value={flags.map(([key]) => key).join(',')}>Any Flag</option>
      {flags.map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </Input>
  );
}

export default ConsumersRedFlagsFilterForm;
