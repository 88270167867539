import { useAuth } from '../../../../hooks/useAuth';
import { Col, Input, Label, Row } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';

function ProfileRoleForm() {
  const { user } = useAuth();
  const { audience } = useEnv();

  return (
    <Row className="mt-3 mb-2 align-items-center">
      <Col md={2}>
        <Label>Role:</Label>
      </Col>
      <Col md={4}>
        <Input
          className="bo-input"
          type="text"
          name="role"
          id="role"
          placeholder="Role"
          value={user[`${audience}/metadata`].role}
          disabled
          noValidate
        />
      </Col>
    </Row>
  );
}

export default ProfileRoleForm;
