import DefaultSettingsInput from './DefaultSettingsInput';

function DefaultTransferSettings({
  defaultFreeCreditTransfers,
  defaultFreeDebitTransfers,
  defaultTransferFee,
  changeValue,
  labelClass,
}) {
  const onButtonClick = (fieldName, value) =>
    changeValue({ target: { name: fieldName, value: value ? null : '0' } });

  return (
    <>
      <DefaultSettingsInput
        fieldName="defaultFreeCreditTransfers"
        fieldValue={defaultFreeCreditTransfers}
        containerClass="mt-4"
        labelText="Default Free Credit Transfers:"
        labelClass={labelClass}
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
      <DefaultSettingsInput
        fieldName="defaultFreeDebitTransfers"
        fieldValue={defaultFreeDebitTransfers}
        containerClass="mt-2"
        labelText="Default Free Debit Transfers:"
        labelClass={labelClass}
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
      <DefaultSettingsInput
        fieldName="defaultTransferFee"
        fieldValue={defaultTransferFee}
        containerClass="my-2"
        labelText="Default Transfer Fee ($):"
        labelClass={labelClass}
        changeValue={changeValue}
        onButtonClick={onButtonClick}
      />
    </>
  );
}

export default DefaultTransferSettings;
