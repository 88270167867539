import { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../components/Spinner';
import { modalStyle } from '../../../../utils/modal';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

function FundingRepaymentModal({
  isOpen,
  topup,
  consumer,
  astraUser,
  prediction,
  close,
  funding,
  getFunding,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginFunding, apiOriginConsumer } = useEnv();

  const [amount, setAmount] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const achEnabled = consumer && consumer.account && consumer.account.dwollaFundingSourceUrl;
  const cardEnabled = astraUser && astraUser.cards.find(c => c.isMain && c.status === 'OK');

  useEffect(() => {
    if (achEnabled || cardEnabled) {
      if (achEnabled && cardEnabled) {
        setPaymentMethod(consumer.defaultPaymentMethod);
      } else if (achEnabled) {
        setPaymentMethod('ACH');
      } else {
        setPaymentMethod('CARD');
      }
    }
  }, [achEnabled, cardEnabled, consumer]);

  const closeModal = () => {
    close();
    setAmount('');
    setMfaCode('');
  };

  ReactModal.setAppElement(document.getElementById('root'));

  const repayFunding = async repayFundingId => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/manual_repay`,
      method: 'POST',
      data: JSON.stringify({
        fundingId: repayFundingId,
        amount: parseInt(amount, 10),
        paymentMethod,
      }),
    };

    try {
      await makeRequest(config);

      toast.success('Repayment completed successfully');
      getFunding(repayFundingId);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createManualTopup = async fundingId => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/create_manual`,
      method: 'POST',
      data: JSON.stringify({
        cosnumerId: consumer.id,
        offerId: prediction.activeOffer ? prediction.activeOffer.offerId : null,
        requestedAmount: parseInt(amount, 10),
        textualReason: '',
        type: 'TOPUP',
        topupFundingId: null,
        mfaCode,
        consent: true,
      }),
    };

    try {
      await makeRequest(config);

      toast.success('Top-up created successfully');
      getFunding(fundingId);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const sendMfa = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/mfa/send`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, mfaUseCase: 'ADVANCE' }),
    };

    try {
      const response = await makeRequest(config);

      toast.success(`MFA code has been sent by ${response.mfaChannel} to ${response.mfaMask}`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleTopup = async () => {
    if (!mfaCode) {
      toast.error('MFA code is required');

      return;
    }

    if (!amount) {
      toast.error('Amount is required');

      return;
    }

    setSpinner(true);
    await createManualTopup(funding.id);
    setSpinner(false);
    closeModal();
  };

  const handleRepay = async () => {
    if (!amount) {
      toast.error('Amount is required');

      return;
    }

    if (amount > funding.currentAmount) {
      toast.error('Amount is too high');

      return;
    }

    setSpinner(true);
    await repayFunding(funding.id);
    setSpinner(false);
    closeModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('440px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner visible={spinner} text="">
        <h5>{topup ? 'Manual Top-up' : 'Repay Advance'}</h5>
        <Row className="mb-2 mt-4 mb-2 align-items-center">
          <Col md={topup ? 4 : 5} className="bo-same-line">
            <Label>Amount:</Label>
          </Col>
          <Col md={4} className="bo-same-line">
            {topup && prediction && prediction.activeOffer ? (
              <Input
                className="bo-filter"
                type="select"
                name="amount"
                id="amount"
                noValidate
                value={amount}
                onChange={e => setAmount(e.target.value)}
              >
                {prediction.activeOffer.allAmounts.map(amt => (
                  <option key={amt} value={amt}>
                    {amt}
                  </option>
                ))}
              </Input>
            ) : (
              <Input
                className="bo-input"
                type="text"
                name="amount"
                id="amount"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            )}
          </Col>
        </Row>
        {!topup && (
          <Row className="mb-2 mt-3 align-items-start">
            <Col md={5} className="bo-same-line">
              <Label>Payment Method:</Label>
            </Col>
            <Col md={7} className="bo-same-line justify-content-between">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <Input
                    name="radio"
                    type="radio"
                    value="ACH"
                    checked={paymentMethod === 'ACH'}
                    disabled={!achEnabled}
                    onChange={() => setPaymentMethod('ACH')}
                  />
                  <Label check>ACH</Label>
                </div>
                <div className="d-flex">
                  <Input
                    name="radio"
                    type="radio"
                    value="CARD"
                    checked={paymentMethod === 'CARD'}
                    disabled={!cardEnabled}
                    onChange={() => setPaymentMethod('CARD')}
                  />
                  <Label check>CARD</Label>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {topup && (
          <Row className="mb-2 mb-2 align-items-center">
            <Col md={4} className="bo-same-line">
              <Label>MFA Code:</Label>
            </Col>
            <Col md={4} className="bo-same-line">
              <Input
                className="bo-input"
                type="text"
                name="mfaCode"
                id="mfaCode"
                value={mfaCode}
                onChange={e => setMfaCode(e.target.value)}
              />
            </Col>
            <Col md={4} className="bo-same-line">
              <Button className="bo-link" color="link" onClick={() => sendMfa()}>
                Send MFA
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-center">
            <Button
              className="mt-5 bo-button"
              onClick={topup ? handleTopup : handleRepay}
              disabled={!topup && !cardEnabled && !achEnabled}
            >
              {topup ? 'Create ' : 'Repay'}
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={closeModal}>
              Close
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default FundingRepaymentModal;
