import ReactModal from 'react-modal';
import { modalStyle } from '../../../../utils/modal';
import SuperuserProfileForm from '../forms/SuperuserProfileForm';
import { useState } from 'react';
import {
  confirmPasswordValidation,
  emailValidation,
  mandatoryValidation,
  passwordValidation,
} from '../../../../utils/validation';
import SuperuserPasswordForm from '../forms/SuperuserPasswordForm';
import WrongPassword from '../../../../components/WrongPassword';
import SuperuserModalButtons from './SuperuserModalButtons';
import { Spinner } from '../../../../components/Spinner';

const INIT_SUPERUSER = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

function SuperuserModal({ showModal, closeModal, getUsers }) {
  const [spinner, setSpinner] = useState(false);
  const [superuser, setSuperuser] = useState(INIT_SUPERUSER);
  const [formErrors, setFormErrors] = useState({});

  const onModalClose = () => {
    setFormErrors({});
    setSuperuser(INIT_SUPERUSER);
    closeModal();
  };

  const renderError = text => <div className="bo-validation-error">{text}</div>;

  const validate = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        if (mandatoryValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Mandatory field',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'email':
        if (emailValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Email is missing or illegal',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'password':
        if (passwordValidation(value, superuser.firstName, superuser.lastName, superuser.email)) {
          setFormErrors({
            ...formErrors,
            [name]: <WrongPassword />,
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'confirmPassword':
        if (confirmPasswordValidation(value, superuser.password)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Password confirmation does not match password',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      default:
        break;
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setSuperuser({
      ...superuser,
      [name]: value,
    });

    validate(name, value);
  };

  return (
    <ReactModal
      isOpen={showModal}
      style={modalStyle('400px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={onModalClose}
    >
      <Spinner visible={spinner} text="">
        <h5 className="mb-4">New Superuser</h5>
        <SuperuserProfileForm
          superuser={superuser}
          formErrors={formErrors}
          renderError={renderError}
          handleChange={handleChange}
        />
        <SuperuserPasswordForm
          superuser={superuser}
          formErrors={formErrors}
          renderError={renderError}
          handleChange={handleChange}
        />
        <SuperuserModalButtons
          superuser={superuser}
          formErrors={formErrors}
          setSpinner={setSpinner}
          getUsers={getUsers}
          onModalClose={onModalClose}
        />
      </Spinner>
    </ReactModal>
  );
}

export default SuperuserModal;
