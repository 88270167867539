import { Input } from 'reactstrap';

function FundingsSearchForm({ search, searchKey, spinner }) {
  return (
    <Input
      className="bo-search"
      type="search"
      name="search"
      id="search"
      placeholder="Search"
      noValidate
      disabled={spinner}
      defaultValue={search}
      onKeyPress={e => searchKey('fundings', e)}
    />
  );
}

export default FundingsSearchForm;
