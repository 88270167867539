import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  turnoverContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  turnoverItem: {
    overflow: 'hidden',
    width: '32%',
    backgroundColor: 'white',
    borderRadius: 14,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  turnoverItemIcon: {
    alignSelf: 'center',
    width: 25,
    height: 25,
    marginTop: 12,
  },
  turnoverLeftItem: {
    backgroundColor: '#50E5FF',
  },
  turnoverMiddleItem: {
    backgroundColor: '#16BFBB',
  },
  turnoverRightItem: {
    backgroundColor: '#EB7AF5',
  },
  turnoverItemTitle: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    marginTop: 12,
    alignSelf: 'center',
  },
  turnoverItemText: {
    paddingHorizontal: 12,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
    marginTop: 6,
    textAlign: 'center',
    alignSelf: 'center',
  },
  turnoverItemTextBold: {
    fontWeight: 600,
  },
  turnoverItemValue: {
    alignSelf: 'center',
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 600,
    marginTop: 6,
    marginBottom: 12,
  },
});
