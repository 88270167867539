function WrongPassword() {
  return (
    <>
      <strong>Password must meet the following criteria:</strong>
      <ol>
        <li>At least 8 characters in length</li>
        <li>Contains at least 3 of the following 4 types:</li>
        <ul>
          <li>Lower case letters (a-z)</li>
          <li>Upper case letters (A-Z)</li>
          <li>Numbers (0-9)</li>
          <li>Special characters (!@#$%^&*)</li>
        </ul>
        <li>Does not include name or email</li>
      </ol>
    </>
  );
}

export default WrongPassword;
