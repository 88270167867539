import { Col, Label, Input } from 'reactstrap';
import { AGGREGATOR_TYPE_BADGE } from '../../../../constants/aggregators';
import { memo } from 'react';

function AggregatorTypeForm({ aggregatorType, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Type:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-filter"
          type="select"
          name="type"
          id="type"
          noValidate
          value={aggregatorType}
          onChange={changeValue}
        >
          {Object.entries(AGGREGATOR_TYPE_BADGE).map(([key, value]) => (
            <option key={key} value={key}>
              {value.text}
            </option>
          ))}
        </Input>
      </Col>
    </>
  );
}
export default memo(AggregatorTypeForm);
