import { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import moment from 'moment';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import { toast } from 'react-toastify';
import { useTable } from '../../../../hooks/useTable';
import TablePaginationActions from '../../../../components/TablePaginationActions';

function ConsumerNotifications({ consumerId }) {
  const { apiOriginNotifications } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useTable({
    initRowsPerPage: 20,
  });

  const getNotifications = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    const config = {
      token,
      url: `${apiOriginNotifications}/get_notifications`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setNotifications(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="2">
          <Button className="bo-button-inline bo-button-gray" onClick={getNotifications}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="bo-w-240" align="left">
                Sent Timestamp
              </StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Title</StyledTableCell>
              <StyledTableCell align="left">Message</StyledTableCell>
              <StyledTableCell align="left">Body</StyledTableCell>
              <StyledTableCell className="bo-w-60" align="left" />
              <StyledTableCell className="bo-w-80" align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(notification => (
              <TableRow hover tabIndex={-1} key={notification.id}>
                <StyledTableCell align="left">
                  {moment.utc(notification.timestamp).local().format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">{notification.type}</StyledTableCell>
                <StyledTableCell align="left">{notification.title}</StyledTableCell>
                <StyledTableCell align="left">{notification.message}</StyledTableCell>
                <StyledTableCell align="left">{notification.body}</StyledTableCell>
                <StyledTableCell align="left">
                  {notification.isRead && (
                    <Badge className="bo-badge" color="success">
                      Read
                    </Badge>
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {notification.isDeleted && (
                    <Badge className="bo-badge" color="warning">
                      Deleted
                    </Badge>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={notifications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Spinner>
  );
}

export default memo(ConsumerNotifications);
