import { Input } from 'reactstrap';

function ConsumersBankStatusFilterForm({ bankStatusFilter, changeBankStatusFilter, spinner }) {
  return (
    <Input
      className="bo-filter"
      type="select"
      name="bankStatusFilter"
      id="bankStatusFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={bankStatusFilter}
      onChange={e => changeBankStatusFilter('consumers', e.target.value)}
    >
      <option value="">All Bank Statuses</option>
      <option value="NOT_CONNECTED">Not Connected</option>
      <option value="CONNECTED">Connected</option>
      <option value="REFRESH_REQUIRED">Refresh Required</option>
      <option value="INVALID">Invalid</option>
    </Input>
  );
}

export default ConsumersBankStatusFilterForm;
