import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    paddingVertical: 20,
    paddingHorizontal: 10,
    alignItems: 'flex-start',
  },
  topContainer: {
    paddingHorizontal: 16,
    alignItems: 'flex-start',
  },
  logo: {
    height: 24,
    objectFit: 'contain',
  },
  title: {
    marginTop: 12,
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 600,
  },
  monthYear: {
    fontFamily: 'Poppins',
    fontWeight: 700,
  },
  customerTitle: {
    marginTop: 4,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 600,
  },
  customerText: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
  },
  sectionContainer: {
    marginTop: 16,
    width: '100%',
    backgroundColor: '#f0edf3',
    borderRadius: 16,
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  sectionTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    color: '#340468',
  },
  setctionTitleMarginTop: {
    marginTop: 20,
  },
  sectionSubTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  noteText: {
    marginTop: 16,
    color: '#8C8692',
    fontFamily: 'Poppins',
    fontSize: 6,
    fontWeight: 500,
  },
  turnoverNoteContainer: {
    marginTop: 16,
  },
  turnoverNoteRow: {
    flexDirection: 'row',
    gap: 4,
  },
  turnoverNoteAsterix: {
    width: 10,
    textAlign: 'right',
    color: '#8C8692',
    fontFamily: 'Poppins',
    fontSize: 6,
    fontWeight: 500,
  },
  turnoverNoteText: {
    color: '#8C8692',
    fontFamily: 'Poppins',
    fontSize: 6,
    fontWeight: 500,
  },
  bottomContainer: {
    marginTop: 60,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  bottomItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  bottomItemIcon: {
    width: 24,
    height: 24,
  },
  bottomItemText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 9,
  },
});
