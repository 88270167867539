import { useState, useEffect, memo } from 'react';
import { Row, Col, Badge, Button } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  BANK_ACCOUNT_STATUS_BADGE,
  INSTITUTION_CATEGORY_BADGE,
} from '../../../../constants/consumers';

function ConsumerBankAccount({
  consumerId,
  consumerBankAccountStatus,
  consumerDwollaCustomerUrl,
  consumerBankAccountStatusMessage,
  getConsumer,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginFunding } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [fundingSource, setFundingSource] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [owners, setOwners] = useState([]);

  const getInstitution = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/getInstitution`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const inst = await makeRequest(config);

      setInstitution(inst);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAccounts = async id => {
    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/get_all_accounts`,
        method: 'GET',
        params: { consumerId: id },
      };

      const accs = await makeRequest(config);

      setAccounts(accs);
    } catch (error) {
      toast.error(error.message);
      setAccounts([]);
    }
  };

  const getFundingSource = async () => {
    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get_funding_source`,
        method: 'GET',
        params: { consumerId },
      };

      const fs = await makeRequest(config);

      setFundingSource(fs);
    } catch (error) {
      toast.error(error.message);
      setFundingSource({});
    }
  };

  const getOwners = async id => {
    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/getOwners`,
        method: 'GET',
        params: { consumerId: id },
      };

      const owns = await makeRequest(config);

      setOwners(owns);
    } catch (error) {
      toast.error(error.message);
      setOwners([]);
    }
  };

  const refreshAll = async () => {
    setSpinner(true);
    getInstitution(consumerId);
    getAccounts(consumerId);
    getOwners(consumerId);
    await getConsumer(consumerId);
    getFundingSource(consumerDwollaCustomerUrl);
    setSpinner(false);
  };

  const getAll = async () => {
    setSpinner(true);
    await Promise.allSettled([
      getInstitution(consumerId),
      getAccounts(consumerId),
      getOwners(consumerId),
      getFundingSource(consumerDwollaCustomerUrl),
    ]);
    setSpinner(false);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fsRemoved = removed => {
    switch (removed) {
      case true:
        return (
          <Badge className="bo-badge" color="warning">
            Yes
          </Badge>
        );
      case false:
        return (
          <Badge className="bo-badge" color="success">
            No
          </Badge>
        );

      default:
        return 'Unknown';
    }
  };

  const fsStatus = status => {
    switch (status) {
      case 'verified':
        return (
          <Badge className="bo-badge" color="success">
            Verified
          </Badge>
        );
      case 'unverified':
        return (
          <Badge className="bo-badge" color="warning">
            Unverified
          </Badge>
        );

      default:
        return 'Unknown';
    }
  };

  const disconnect = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      try {
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/link/disconnect`,
          method: 'POST',
          data: JSON.stringify({ consumerId }),
        };

        await makeRequest(config);

        toast.success('Consumer successfully disconnected');
        refreshAll();
      } catch (error) {
        toast.error(error.message);
        setSpinner(false);
      }
    }
  };

  const setBankStatus = async bankStatus => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_bank_account_status`,
      method: 'POST',
      data: JSON.stringify({ consumerId, status: bankStatus }),
    };

    try {
      await makeRequest(config);

      setSpinner(false);
      refreshAll();
      toast.success(`Status set to ${bankStatus}`);
    } catch (error) {
      setSpinner(false);
      toast.error(error.message);
    }
  };

  const setAsMain = async plaidAccountId => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      try {
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/set_main_account`,
          method: 'POST',
          data: JSON.stringify({ consumerId, plaidAccountId }),
        };
        const fs = await makeRequest(config);

        if (fs === 'ok') {
          toast.success('Main account succesfuly set.');
        } else {
          toast.success(`Main account set, but funding source not created. Error: ${fs}`);
        }

        refreshAll();
      } catch (error) {
        toast.error(error.message);
        setSpinner(false);
      }
    }
  };

  const getInstitutionCategory = () => {
    if (consumerBankAccountStatus === 'CONNECTED' || consumerBankAccountStatus === 'INVALID') {
      return (
        <Badge
          color=""
          className={`bo-badge ${
            INSTITUTION_CATEGORY_BADGE[institution?.category]?.color || 'bo-badge-bg-border-red'
          }`}
        >
          {INSTITUTION_CATEGORY_BADGE[institution?.category]?.text || '?'}
        </Badge>
      );
    }

    if (consumerBankAccountStatus === 'NOT_CONNECTED') {
      return null;
    }

    return (
      <Badge color="" className="bo-badge bo-badge-bg-border-red">
        ?
      </Badge>
    );
  };

  const showSimpleList = lst => (
    <table>
      <tbody>
        {lst.map(item => (
          <tr key={item}>
            <td>{item}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const showDtpList = lst => (
    <table>
      <tbody>
        {lst.map(item => (
          <tr key={`${item.type}_${item.data}`}>
            <td className="bo-w-200">{item.type}</td>
            <td className="bo-td-w-500">{item.data}</td>
            <td className="bo-w-150">{item.primary ? 'Primary' : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const showAddressList = lst => (
    <table>
      <tbody>
        {lst.map(item => (
          <tr
            key={`${item.data.street}, ${item.data.city} ${item.data.region}, \
                  ${item.data.postal_code}, ${item.data.country}`}
          >
            <td className="bo-td-w-500">
              {`${item.data.street}, ${item.data.city} ${item.data.region}, \
                ${item.data.postal_code}, ${item.data.country}`}
            </td>
            <td className="bo-w-150">{item.primary ? 'Primary' : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <Button className="bo-button-inline bo-button-gray" onClick={refreshAll}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button
            className="bo-button-inline bo-button-gray ms-3"
            disabled={consumerBankAccountStatus === 'NOT_CONNECTED'}
            onClick={disconnect}
          >
            Disconnect
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 ms-1">
        <Col sm="2">
          <strong>Status:</strong>
        </Col>
        <Col sm="10">
          <strong>
            <Badge
              color=""
              className={`bo-badge ${BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.color}`}
            >
              {BANK_ACCOUNT_STATUS_BADGE[consumerBankAccountStatus]?.text || 'Unknown'}
            </Badge>
            {consumerBankAccountStatus !== 'NOT_CONNECTED' && (
              <>
                {Object.entries(BANK_ACCOUNT_STATUS_BADGE)
                  .filter(
                    ([key]) =>
                      key !== 'NOT_CONNECTED' &&
                      key !== 'NO_TRANSFERS' &&
                      key !== consumerBankAccountStatus,
                  )
                  .map(([key, value]) => (
                    <Button
                      key={key}
                      className="ms-2 bo-link p-0"
                      color="link"
                      onClick={() => setBankStatus(key)}
                    >
                      {value.text}
                    </Button>
                  ))}
              </>
            )}
          </strong>
        </Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Status Message:</strong>
        </Col>
        <Col sm="10">
          <strong>{consumerBankAccountStatusMessage}</strong>
        </Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Institution Category:</strong>
        </Col>
        <Col sm="10">{getInstitutionCategory()}</Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Institution ID:</strong>
        </Col>
        <Col sm="10">{institution ? <strong>{institution.id}</strong> : 'Loading...'}</Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Institution Name:</strong>
        </Col>
        <Col sm="10">{institution ? <strong>{institution.name}</strong> : 'Loading...'}</Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Accounts:</strong>
        </Col>
        <Col sm="10">
          {accounts.map(account => (
            <table key={`${account.name}_${account.mask}`}>
              <tbody>
                <tr>
                  <td className="bo-w-200">{account.name}</td>
                  <td className="bo-w-120">{account.subType}</td>
                  <td className="bo-w-120">*****{account.mask}</td>
                  <td className="bo-w-120">
                    {account.isMain ? (
                      <Badge className="bo-badge" color="success">
                        Main
                      </Badge>
                    ) : (
                      <Button
                        className="m-0 p-0 mb-1 bo-link"
                        color="link"
                        onClick={() => setAsMain(account.plaidAccountId)}
                      >
                        Set as Main
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Account Owners:</strong>
        </Col>
        <Col sm="10">
          <table>
            <tbody>
              {owners.map((owner, index) => (
                <tr key={index}>
                  <td>
                    <div className="w-100">
                      <table>
                        <tbody>
                          <tr>
                            <td className="bo-w-150 align-top">
                              <strong>Names:</strong>
                            </td>
                            <td>{showSimpleList(owner.names)}</td>
                          </tr>
                          <tr>
                            <td className="bo-w-150 align-top">
                              <strong>Addresses:</strong>
                            </td>
                            <td>{showAddressList(owner.addresses)}</td>
                          </tr>
                          <tr>
                            <td className="bo-w-150 align-top">
                              <strong>Emails:</strong>
                            </td>
                            <td>{showDtpList(owner.emails)}</td>
                          </tr>
                          <tr>
                            <td className="bo-w-150 align-top">
                              <strong>Phone Numbers:</strong>
                            </td>
                            <td>{showDtpList(owner.phone_numbers)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className="mt-2 ms-1">
        <Col sm="2">
          <strong>Dwolla Customer ID:</strong>
        </Col>
        <Col sm="10">
          {consumerDwollaCustomerUrl && consumerDwollaCustomerUrl.split('/').slice(-1)}
        </Col>
      </Row>
      {fundingSource && (
        <Row className="mt-2 ms-1 mb-4">
          <Col sm="2">
            <strong>Funding Source:</strong>
          </Col>
          <Col sm="10">
            {fundingSource.id ? (
              <table>
                <tbody>
                  <tr>
                    <td className="bo-w-120">
                      <strong>ID:</strong>
                    </td>
                    <td>{fundingSource.id}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Name:</strong>
                    </td>
                    <td className="d-flex align-items-center">
                      <strong>&ldquo;{fundingSource.name}&rdquo;</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Type:</strong>
                    </td>
                    <td>{fundingSource.type}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Status:</strong>
                    </td>
                    <td>{fsStatus(fundingSource.status)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Removed:</strong>
                    </td>
                    <td>{fsRemoved(fundingSource.removed)}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <strong>N/A</strong>
            )}
          </Col>
        </Row>
      )}
    </Spinner>
  );
}

export default memo(ConsumerBankAccount);
