import { Row, Col } from 'reactstrap';
import ProspectsActions from './ProspectsActions';
import ProspectsSearchForm from './forms/ProspectsSearchForm';

function ProspectsFormController({ aggregator, openUploadProspectsModal, setSpinner, setSearch }) {
  return (
    <Row className="mb-2 align-items-center">
      <Col md={6} className="bo-same-line">
        <ProspectsActions
          aggregator={aggregator}
          openUploadProspectsModal={openUploadProspectsModal}
          setSpinner={setSpinner}
        />
      </Col>
      <Col md={6} className="bo-search-column">
        <ProspectsSearchForm setSearch={setSearch} />
      </Col>
    </Row>
  );
}

export default ProspectsFormController;
