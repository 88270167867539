import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { modalStyle } from '../../../../utils/modal';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

function ConsumerNotificationModal({ isOpen, close, setSpinner, consumerId }) {
  const { apiOriginNotifications } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [body, setBody] = useState('');
  const [icon, setIcon] = useState('GREEN_FREE_TEXT');

  ReactModal.setAppElement(document.getElementById('root'));

  const sendFreeTextNotification = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginNotifications}/send_free_text_message`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
        title,
        message,
        body,
        icon,
      }),
    };

    try {
      await makeRequest(config);

      toast.success('Free text notification has been sent');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleSend = () => {
    if (title.length > 50) {
      toast.error('Title should be 50 characters or less');
    } else if (message.length > 150) {
      toast.error('Message should be 150 characters or less');
    } else if (title && message && body && icon) {
      sendFreeTextNotification(title, message, body, icon);
      close();
    } else {
      toast.error('All fields must be provided');
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('700px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <h5>Send Free-text notification</h5>
      <Row className="mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Title:</Label>
        </Col>
        <Col className="bo-same-line">
          <Input
            className="bo-input"
            type="text"
            name="title"
            id="title"
            placeholder="Title (20-50 characters)"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Message:</Label>
        </Col>
        <Col className="bo-same-line">
          <Input
            className="bo-input"
            type="text"
            name="message"
            id="message"
            placeholder="Message (up to 150 characters)"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Body:</Label>
        </Col>
        <Col className="bo-same-line">
          <Input
            className="bo-input"
            type="textarea"
            rows={10}
            name="body"
            id="body"
            placeholder="Body"
            value={body}
            onChange={e => setBody(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Icon Color:</Label>
        </Col>
        <Col className="bo-same-line">
          <Input
            className="bo-select"
            type="select"
            name="icon"
            id="icon"
            value={icon}
            onChange={e => setIcon(e.target.value)}
          >
            <option value="GREEN_FREE_TEXT">Green</option>
            <option value="ORANGE_FREE_TEXT">Orange</option>
            <option value="RED_FREE_TEXT">Red</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Button className="mt-5 bo-button" onClick={handleSend}>
            Send
          </Button>
          <Button className="ms-3 mt-5 bo-button" onClick={close}>
            Close
          </Button>
        </Col>
      </Row>
    </ReactModal>
  );
}

export default ConsumerNotificationModal;
