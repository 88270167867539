import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  engagementContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  engagementItem: {
    width: '32%',
    height: 170,
    backgroundColor: 'white',
    borderRadius: 14,
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  engagementItemTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    marginTop: 8,
  },
  engagementItemValue: {
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 600,
    marginTop: 6,
  },
  engagementItemText: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
    marginTop: 6,
    marginBottom: 12,
    textAlign: 'center',
  },
});
