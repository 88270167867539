import { memo } from 'react';
import { Col, Input } from 'reactstrap';

function AggregatorAddressLine4Form({ aggregatorState, aggregatorZip, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="state"
          id="state"
          placeholder="State"
          value={aggregatorState || ''}
          onChange={changeValue}
          maxLength={2}
        />
        <Input
          className="ms-3 bo-input"
          type="text"
          name="zip"
          id="zip"
          placeholder="Zip"
          value={aggregatorZip || ''}
          onChange={changeValue}
          maxLength={5}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorAddressLine4Form);
