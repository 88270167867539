import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import AggregatorNameForm from './forms/AggregatorNameForm';
import AggregatorAddressLine1Form from './forms/AggregatorAddressLine1Form';
import AggregatorAddressLine2Form from './forms/AggregatorAddressLine2Form';
import ContactNameForm from './forms/ContactNameForm';
import ContactPhoneForm from './forms/ContactPhoneForm';
import ContactEmailForm from './forms/ContactEmailForm';
import AggregatorAddressLine3Form from './forms/AggregatorAddressLine3Form';
import AggregatorAddressLine4Form from './forms/AggregatorAddressLine4Form';
import AggregatorPhoneForm from './forms/AggregatorPhoneForm';
import AggregatorHubSpotRecordIdForm from './forms/AggregatorHubSpotRecordIdForm';
import AggregatorLookupKeywords from './forms/AggregatorLookupKeywordsForm';
import AggregatorTypeForm from './forms/AggregatorTypeForm';
import AggregatorReferralCampaignForm from './forms/AggregatorReferralCampaignForm';

function AggregatorFormController({ aggregator, changeValue }) {
  return (
    <>
      <Row className="  mb-2 align-items-center">
        <AggregatorNameForm aggregatorName={aggregator.name} changeValue={changeValue} />
        <ContactNameForm aggregatorContactName={aggregator.contactName} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-center">
        <AggregatorAddressLine1Form
          aggregatorAddressLine1={aggregator.addressLine1}
          changeValue={changeValue}
        />
        <ContactPhoneForm
          aggregatorContactPhone={aggregator.contactPhone}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <AggregatorAddressLine2Form
          aggregatorAddressLine2={aggregator.addressLine2}
          changeValue={changeValue}
        />
        <ContactEmailForm
          aggregatorContactEmail={aggregator.contactEmail}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <AggregatorAddressLine3Form aggregatorCity={aggregator.city} changeValue={changeValue} />
        <AggregatorTypeForm aggregatorType={aggregator.type} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-center">
        <AggregatorAddressLine4Form
          aggregatorState={aggregator.state}
          aggregatorZip={aggregator.zip}
          changeValue={changeValue}
        />
        {aggregator.type === 'STANDARD' && (
          <AggregatorLookupKeywords
            aggregatorLookupKeywords={aggregator.lookupKeywords}
            changeValue={changeValue}
          />
        )}
      </Row>
      <Row className="mb-2 align-items-center">
        <AggregatorPhoneForm
          aggregatorPhoneNumber={aggregator.phoneNumber}
          changeValue={changeValue}
        />
        <AggregatorHubSpotRecordIdForm
          aggregatorHubspotCompanyRecordId={aggregator.hubspotCompanyRecordId}
          changeValue={changeValue}
        />
      </Row>
      {aggregator.id !== 'new' ? (
        <Row className="mb-2 mt-1 align-items-center">
          <Col md={2} className="bo-same-line" />
          <Col md={4} className="bo-same-line">
            <Link to={`/prospects/${aggregator.id}`}>Show Prospects</Link>
          </Col>
          <AggregatorReferralCampaignForm
            aggregatorReferralCampaignId={aggregator.referralCampaignId}
            changeValue={changeValue}
          />
        </Row>
      ) : (
        <Row className="mb-2 mt-1 align-items-center">
          <Col md={6} className="bo-same-line" />
          <AggregatorReferralCampaignForm
            aggregatorReferralCampaignId={aggregator.referralCampaignId}
            changeValue={changeValue}
          />
        </Row>
      )}
    </>
  );
}

export default AggregatorFormController;
