import { memo, useEffect, useState } from 'react';
import { Col, Label, Input } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function AggregatorReferralCampaignForm({ aggregatorReferralCampaignId, changeValue }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [referralCampaigns, setReferralCampaigns] = useState([]);

  const getReferralCampaigns = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/referral_campaigns`,
      method: 'GET',
      params: {},
    };

    try {
      const response = await makeRequest(config);

      setReferralCampaigns(response);
    } catch (error) {
      toast.error(`Failed getting referral campaigns: ${error.message}`);
    }
  };

  useEffect(() => {
    getReferralCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = () => {
    if (aggregatorReferralCampaignId === null && referralCampaigns.length > 0) {
      const defaultCampaign = referralCampaigns.find(campaign => campaign.isDefault);

      return JSON.stringify({ id: defaultCampaign.id, isDefault: true });
    }

    const campaignId = referralCampaigns.find(
      campaign => campaign.id === aggregatorReferralCampaignId,
    )?.id;

    return JSON.stringify({ id: campaignId, isDefault: false });
  };

  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Referral Campaign:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-filter w-100"
          type="select"
          name="referralCampaignId"
          id="referralCampaignId"
          value={getValue()}
          noValidate
          onChange={e => {
            const parsedValue = JSON.parse(e.target.value);

            changeValue({
              ...e,
              target: {
                ...e.target,
                name: e.target.name,
                value: parsedValue.isDefault ? null : parsedValue.id,
              },
            });
          }}
        >
          {referralCampaigns.map(campaign => (
            <option
              key={campaign.id}
              value={JSON.stringify({ id: campaign.id, isDefault: campaign.isDefault })}
            >
              {campaign.name}
            </option>
          ))}
        </Input>
      </Col>
    </>
  );
}

export default memo(AggregatorReferralCampaignForm);
