import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import {
  FUNDING_SOURCE_BADGE,
  INSTITUTION_CATEGORY_BADGE,
  PAYROLL_STATUS_BADGE,
  PROFILE_STATUS_BADGE,
  STATUS_BADGE,
} from '../../../constants/consumers';
import { Link } from 'react-router-dom';
import {
  faFlag,
  faFlaskVial,
  faClock,
  faMask,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Badge, Tooltip, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledTableCell from '../../../components/StyledTableCell';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { useState } from 'react';

function ConsumersTableContainer({
  sortOrder,
  sortBy,
  sortHandler,
  rows,
  aggregatorNames,
  rerunJobs,
  deleteConsumer,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);

  const redFlag = flags =>
    flags.filter(
      flag =>
        !(
          flag.type.startsWith('KYC Status:') ||
          flag.type.startsWith('Watchlist Screening Status:') ||
          flag.type.startsWith('IDV Risk Status:')
        ),
    ).length > 0;

  const kycFlag = flags =>
    flags.filter(
      flag =>
        flag.type.startsWith('KYC Status:') ||
        flag.type.startsWith('Watchlist Screening Status:') ||
        flag.type.startsWith('IDV Risk Status:'),
    ).length > 0;

  const shortInstitutionName = name => {
    if (name.length > 18) {
      return `${name.substring(0, 15)}...`;
    }

    return name;
  };

  const bankAccountStatusBadge = (
    status,
    institutionName,
    institutionCategory,
    isTest,
    isMain,
    id,
  ) => {
    switch (status) {
      case 'CONNECTED':
        return (
          <div className="d-flex align-items-center">
            {isTest ? (
              <Badge id={`bg_${id}`} className="bo-inline-badge" color="success">
                TEST BANK
              </Badge>
            ) : (
              <Badge
                id={`bg_${id}`}
                className="bo-inline-badge"
                color={isMain ? 'success' : 'info'}
              >
                {shortInstitutionName(institutionName)}
              </Badge>
            )}
            <Tooltip
              offset={[0, 6]}
              isOpen={tooltipOpen && tooltipId === id}
              placement="right"
              target={`bg_${id}`}
              toggle={() => {
                setTooltipOpen(!tooltipOpen);
                setTooltipId(id);
              }}
            >
              {isTest ? 'TEST BANK' : institutionName}
            </Tooltip>
            <Badge
              color=""
              className={`${institutionName || isTest ? 'ms-2' : ''} bo-inline-badge ${
                INSTITUTION_CATEGORY_BADGE[institutionCategory]?.color || 'bo-badge-bg-border-red'
              }`}
            >
              {INSTITUTION_CATEGORY_BADGE[institutionCategory]?.text || '?'}
            </Badge>
          </div>
        );
      case 'NOT_CONNECTED':
        return (
          <Badge className="bo-inline-badge" color="warning">
            Not Connected
          </Badge>
        );
      case 'REFRESH_REQUIRED':
      case 'INVALID':
        return (
          <div className="d-flex align-items-center">
            <Badge
              id={`bg_${id}`}
              color=""
              className={`bo-inline-badge ${
                status === 'INVALID' ? 'bg-danger' : 'bo-badge-bg-border-red'
              }`}
            >
              {shortInstitutionName(institutionName)}
            </Badge>
            <Tooltip
              offset={[0, 6]}
              isOpen={tooltipOpen && tooltipId === id}
              placement="right"
              target={`bg_${id}`}
              toggle={() => {
                setTooltipOpen(!tooltipOpen);
                setTooltipId(id);
              }}
            >
              {institutionName}
            </Tooltip>
            <Badge
              color=""
              className={`${institutionName ? 'ms-2' : ''} bo-inline-badge ${
                INSTITUTION_CATEGORY_BADGE[institutionCategory]?.color || 'bo-badge-bg-border-red'
              }`}
            >
              {INSTITUTION_CATEGORY_BADGE[institutionCategory]?.text || '?'}
            </Badge>
          </div>
        );
      default:
        return 'Unknown';
    }
  };

  const getFundingSource = row => {
    if (row.bankAccountStatus === 'NOT_CONNECTED') {
      return 'NA';
    }
    if (row.account.dwollaFundingSourceUrl) {
      return 'YES';
    }

    return 'NO';
  };

  return (
    <>
      <TableContainer className="mt-5">
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="130px" align="left" />
              <StyledTableCell align="left">Aggregator</StyledTableCell>
              <StyledTableCell align="left">Id</StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => sortHandler('consumers', 'name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'email'}
                  direction={sortOrder}
                  onClick={() => sortHandler('consumers', 'email')}
                >
                  Email
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'bpCurrentBalance'}
                  direction={sortOrder}
                  onClick={() => sortHandler('consumers', 'bpCurrentBalance')}
                >
                  BridgePoints™ Current Balance
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'createDate'}
                  direction={sortOrder}
                  onClick={() => sortHandler('consumers', 'createDate')}
                >
                  CreateDate
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'status'}
                  direction={sortOrder}
                  onClick={() => sortHandler('consumers', 'status')}
                >
                  Status
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Bank Account</StyledTableCell>
              <StyledTableCell align="left">Funding Source</StyledTableCell>
              <StyledTableCell align="left">Profile</StyledTableCell>
              <StyledTableCell align="left">Payroll</StyledTableCell>
              <StyledTableCell align="left">Job Failures</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map(row => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <StyledTableCell width="130px" align="left">
                    <div>
                      {redFlag(row.flags) && (
                        <FontAwesomeIcon icon={faFlag} size="lg" color="red" />
                      )}
                      {kycFlag(row.flags) && (
                        <FontAwesomeIcon className="ms-2" icon={faMask} size="lg" color="#FFC107" />
                      )}
                      {row.isScheduler && (
                        <FontAwesomeIcon className="ms-2" size="lg" icon={faClock} color="green" />
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {aggregatorNames[row.aggregatorId] || 'Loading...'}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Link to={`/consumer/${row.id}`}>
                      {row.isTest && (
                        <>
                          <FontAwesomeIcon icon={faFlaskVial} color="blue" />
                          &nbsp;&nbsp;
                        </>
                      )}
                      {`${row.firstName} ${row.lastName}`}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.bpCurrentBalance !== null && row.bpCurrentBalance !== undefined
                      ? row.bpCurrentBalance.toLocaleString('en-US')
                      : 'N/A'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createDate
                      ? moment.utc(row.createDate).local().format('MMM DD, YYYY H:mm (UTCZ)')
                      : 'N/A'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {STATUS_BADGE[row.status] && (
                      <Badge className="bo-inline-badge" color={STATUS_BADGE[row.status]?.color}>
                        {STATUS_BADGE[row.status]?.text}
                      </Badge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {bankAccountStatusBadge(
                      row.bankAccountStatus,
                      row.institutionName,
                      row.institutionCategory,
                      row.isTest,
                      row.isMain,
                      row.id,
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Badge
                      className="bo-inline-badge"
                      color={FUNDING_SOURCE_BADGE[getFundingSource(row)]?.color}
                    >
                      {FUNDING_SOURCE_BADGE[getFundingSource(row)]?.text}
                    </Badge>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Badge
                      className="bo-inline-badge"
                      color={PROFILE_STATUS_BADGE[row.profileStatus]?.color}
                    >
                      {PROFILE_STATUS_BADGE[row.profileStatus]?.text}
                    </Badge>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {PAYROLL_STATUS_BADGE[row.payrollStatus] && (
                      <Badge
                        className="bo-inline-badge"
                        color={PAYROLL_STATUS_BADGE[row.payrollStatus]?.color}
                      >
                        {PAYROLL_STATUS_BADGE[row.payrollStatus]?.text}
                      </Badge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-nowrap">
                    {row.recentMonitorErrorsCount > 0 && (
                      <Badge className="bo-inline-badge" color="danger">
                        {row.recentMonitorErrorsCount} ({row.recentMonitorErrorsDaysCount}
                        {row.recentMonitorErrorsDaysCount === 1 ? ' day' : ' days'})
                      </Badge>
                    )}
                    {rerunJobs.map(job => job.consumerId).includes(row.id) && (
                      <FontAwesomeIcon
                        className="ms-2"
                        size="lg"
                        icon={faRotateRight}
                        color="blue"
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Button
                      className="bo-button-inline-link bo-inline-badge"
                      color="link"
                      onClick={() => deleteConsumer(row.id)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={14} align="center">
                  No Data
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => handleChangePage('consumers', e, p)}
        onRowsPerPageChange={e => handleChangeRowsPerPage('consumers', e)}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default ConsumersTableContainer;
