import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import { memo } from 'react';
import { NumericFormat } from 'react-number-format';

function AggregatorSignup({
  aggregatorId,
  aggregatorUseEmployeeIdProspects,
  aggregatorHideEmployeeIdProspects,
  aggregatorAllowAnonymousSignups,
  aggregatorUseEmployeeIdAnonymous,
  aggregatorMaxAllowedSignups,
  setSpinner,
  changeChecked,
  changeValue,
  uniformUrl,
  openModal,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const getExcelReport = async () => {
    setSpinner(true);
    try {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const response = await axios({
        method: 'get',
        headers: { Authorization: `Bearer ${token}` },
        url: `${apiOriginConsumer}/get_funnel_report2`,
        responseType: 'blob',
        params: { aggregatorId },
      });

      setSpinner(false);

      return response.data;
    } catch (error) {
      console.error(JSON.parse(await error.response.data.text()).message);
      setSpinner(false);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.data) {
          throw new Error(JSON.parse(await error.response.data.text()).message);
        } else {
          throw new Error(`${error.message}. Error data: ${JSON.stringify(error.response.data)}`);
        }
      } else {
        throw new Error(error.message);
      }
    }
  };

  const downloadExcelReport = async () => {
    const binaryData = [];

    try {
      const data = await getExcelReport();

      if (data) {
        binaryData.push(data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `signup_funnel_${aggregatorId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const copyUniformUrl = () => {
    navigator.clipboard.writeText(uniformUrl);
    toast.success('URL copied to clipboard');
  };

  return (
    <>
      <Row className="ms-3 mt-4 align-items-center">
        <Col md={6} className="bo-same-line">
          <Button className="bo-link p-0" color="link" onClick={() => downloadExcelReport()}>
            Signup Funnel Report
          </Button>
        </Col>
      </Row>
      <Row className="ms-3 mt-4 align-items-center">
        <Col md={6} className="bo-same-line">
          <div>
            <Input
              type="checkbox"
              name="useEmployeeIdProspects"
              id="useEmployeeIdProspects"
              checked={aggregatorUseEmployeeIdProspects || false}
              onChange={changeChecked}
            />
            <Label className="p-0 m-0">
              Use <strong>Employee ID</strong> for prospects
            </Label>
          </div>
        </Col>
      </Row>
      <Row className="ms-3 mb-3 mt-3 align-items-center">
        <Col md={1} className="bo-same-line" />
        <Col md={6} className="bo-same-line">
          <div>
            <Input
              type="checkbox"
              name="hideEmployeeIdProspects"
              id="hideEmployeeIdProspects"
              checked={aggregatorHideEmployeeIdProspects || false}
              disabled={!aggregatorUseEmployeeIdProspects}
              onChange={changeChecked}
            />
            <Label
              className="p-0 m-0"
              style={!aggregatorUseEmployeeIdProspects ? { color: 'gray' } : {}}
            >
              Hide Employee ID from prospects in signup
            </Label>
          </div>
        </Col>
      </Row>

      <Row className="ms-3 mb-3 mt-3 align-items-center">
        <Col md={6} className="bo-same-line">
          <div>
            <Input
              type="checkbox"
              name="allowAnonymousSignups"
              id="allowAnonymousSignups"
              checked={aggregatorAllowAnonymousSignups || false}
              onChange={changeChecked}
            />
            <Label className="p-0 m-0">Allow anonymous signups</Label>
          </div>
        </Col>
      </Row>
      {aggregatorAllowAnonymousSignups && (
        <>
          <Row className="mb-3 align-items-center">
            <Col md={1} className="bo-same-line" />
            <Col md={6} className="bo-same-line">
              <div>
                <Input
                  type="checkbox"
                  name="useEmployeeIdAnonymous"
                  id="useEmployeeIdAnonymous"
                  checked={aggregatorUseEmployeeIdAnonymous || false}
                  onChange={changeChecked}
                />
                <Label className="p-0 m-0">
                  Use <strong>Employee ID</strong> for anonymous signups
                </Label>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 align-items-center">
            <Col md={1} className="bo-same-line" />
            <Col md={2} className="bo-same-line">
              <Label>Max allowed signups:</Label>
            </Col>
            <Col md={2} className="bo-same-line">
              <NumericFormat
                className="ms-3 bo-input"
                id="maxAllowedSignups"
                name="maxAllowedSignups"
                placeholder="Max Signups"
                value={aggregatorMaxAllowedSignups || ''}
                customInput={Input}
                onChange={changeValue}
                allowNegative={false}
                decimalScale={0}
              />
            </Col>
          </Row>
          <Row className="mb-2 align-items-center">
            <Col md={1} className="bo-same-line" />
            <Col md={2} className="bo-same-line">
              <Label>Uniform signup URL:</Label>
            </Col>
            <Col md={8} className="bo-same-line">
              <Input
                className="ms-3 bo-input"
                type="text"
                name="uniformUrl"
                id="uniformUrl"
                placeholder="uniformUrl"
                value={uniformUrl || 'Save to generate...'}
                disabled
              />
            </Col>
            <Col md={1} className="bo-same-line">
              <Button
                className="ms-0 ps-0 bo-button-inline-link"
                color="link"
                onClick={copyUniformUrl}
              >
                Copy
              </Button>
            </Col>
          </Row>
          <Row className="mb-2 align-items-center">
            <Col md={1} className="bo-same-line" />
            <Col md={2} className="bo-same-line">
              <Label>Unique signup URLs:</Label>
            </Col>

            <Col md={2} className="bo-same-line">
              <Button className="bo-button-inline-link" color="link" onClick={openModal}>
                Download file
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default memo(AggregatorSignup);
