import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AggregatorHubSpotRecordIdForm({ aggregatorHubspotCompanyRecordId, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>HubSpot company record ID:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="hubspotCompanyRecordId"
          id="hubspotCompanyRecordId"
          placeholder="Record ID"
          value={aggregatorHubspotCompanyRecordId || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorHubSpotRecordIdForm);
