import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import CircleChart from '../../charts/CircleChart/CircleChart';

function EngagementCards({ employees, engagedUsers, engagedMonitored, engagedFunded }) {
  return (
    <View style={styles.engagementContainer}>
      <View style={styles.engagementItem}>
        <CircleChart
          progress={employees ? Number(engagedUsers / employees) : 0}
          progressColor="#16BFBB"
        />
        <Text style={styles.engagementItemTitle}>Engagement Rate</Text>
        <Text style={styles.engagementItemValue}>
          {employees
            ? `${Number((engagedUsers / employees) * 100).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}%`
            : 'N/A'}
        </Text>
        <Text style={styles.engagementItemText}>
          {employees ? `${engagedUsers} out of ${employees} employees*` : '---'}
        </Text>
      </View>
      <View style={styles.engagementItem}>
        <CircleChart
          progress={engagedUsers ? Number(engagedMonitored / engagedUsers) : 0}
          progressColor="#EB7AF5"
        />
        <Text style={styles.engagementItemTitle}>Monitoring</Text>
        <Text style={styles.engagementItemValue}>
          {engagedUsers
            ? `${Number((engagedMonitored / engagedUsers) * 100).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}%`
            : 'N/A'}
        </Text>
        <Text style={styles.engagementItemText}>
          {engagedUsers ? `${engagedMonitored} out of ${engagedUsers} users` : '---'}
        </Text>
      </View>
      <View style={styles.engagementItem}>
        <CircleChart
          progress={engagedMonitored ? Number(engagedFunded / engagedMonitored) : 0}
          progressColor="#50E5FF"
        />
        <Text style={styles.engagementItemTitle}>Funding</Text>
        <Text style={styles.engagementItemValue}>
          {engagedMonitored
            ? `${Number((engagedFunded / engagedMonitored) * 100).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}%`
            : 'N/A'}
        </Text>
        <Text style={styles.engagementItemText}>
          {engagedMonitored ? `${engagedFunded} out of ${engagedMonitored} monitored users` : '---'}
        </Text>
      </View>
    </View>
  );
}

export default EngagementCards;
