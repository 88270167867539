import { memo } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

function AggregatorBillingKPIs({
  aggregatorMonthlySubscriptionFee,
  aggregatorNumberOfEmployees,
  aggregatorNumberOfAddressableEmployees,
  aggregatorTurnoverBenchmerk,
  aggregatorTurnoverBenchmerkSource,
  aggregatorEmployeeReplacementCost,
  aggregatorEmployeeReplacementCostSource,
  aggregatorTurnoverCalculation,
  changeValue,
  changeChecked,
}) {
  return (
    <div className="overflow-auto">
      <div className="mx-3 mt-4 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Monthly Subscription Fee ($):</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="monthlySubscriptionFee"
            name="monthlySubscriptionFee"
            value={aggregatorMonthlySubscriptionFee || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="mx-3 mt-2 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Number of Employees:</Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="numberOfEmployees"
            name="numberOfEmployees"
            value={aggregatorNumberOfEmployees || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={0}
          />
        </div>
      </div>
      <div className="mx-3 mt-2 d-flex align-items-center">
        <Label className="bo-min-w-280 mb-0">Number of Addressable Employees:</Label>
      </div>
      {Object.entries(aggregatorNumberOfAddressableEmployees).map(([key, value]) => (
        <div key={key} className="mx-5 my-2 d-flex align-items-center">
          <Label className="me-3 mb-0">From Date:</Label>
          <div>
            <DatePicker
              className="bo-input bo-date-picker"
              onChange={date => {
                const keys = Object.keys(aggregatorNumberOfAddressableEmployees);
                const newObj = keys.reduce((acc, val) => {
                  if (val === key) {
                    acc[moment(date).format('YYYY-MM-DD')] =
                      aggregatorNumberOfAddressableEmployees[key];
                  } else {
                    acc[val] = aggregatorNumberOfAddressableEmployees[val];
                  }

                  return acc;
                }, {});

                changeValue({
                  target: {
                    name: 'numberOfAddressableEmployees',
                    value: newObj,
                  },
                });
              }}
              selected={new Date(key)}
              customInput={<Input />}
              excludeDates={Object.keys(aggregatorNumberOfAddressableEmployees).map(
                x => new Date(x),
              )}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <Label className="mx-3 bo-w-80 mb-0">Employees:</Label>
          <div>
            <NumericFormat
              className="bo-input bo-w-100"
              value={value}
              customInput={Input}
              onChange={e =>
                changeValue({
                  target: {
                    name: 'numberOfAddressableEmployees',
                    value: {
                      ...aggregatorNumberOfAddressableEmployees,
                      [key]: Number(e.target.value),
                    },
                  },
                })
              }
              min={0}
              allowNegative={false}
              decimalScale={0}
            />
          </div>
          <Button
            className="bo-link p-0 mx-3"
            color="link"
            onClick={() =>
              changeValue({
                target: {
                  name: 'numberOfAddressableEmployees',
                  value: Object.fromEntries(
                    Object.entries(aggregatorNumberOfAddressableEmployees).filter(
                      ([k]) => k !== key,
                    ),
                  ),
                },
              })
            }
          >
            Delete
          </Button>
        </div>
      ))}
      <Button
        className="bo-link p-0 mx-5"
        color="link"
        onClick={() => {
          const keys = Object.keys(aggregatorNumberOfAddressableEmployees);

          if (keys.length > 0) {
            const lastDate = new Date(
              Math.max(
                ...Object.keys(aggregatorNumberOfAddressableEmployees).map(k => new Date(k)),
              ),
            );

            changeValue({
              target: {
                name: 'numberOfAddressableEmployees',
                value: {
                  ...aggregatorNumberOfAddressableEmployees,
                  [moment(lastDate).add(1, 'day').format('YYYY-MM-DD')]: 0,
                },
              },
            });
          } else {
            changeValue({
              target: {
                name: 'numberOfAddressableEmployees',
                value: {
                  ...aggregatorNumberOfAddressableEmployees,
                  [moment().format('YYYY-MM-DD')]: 0,
                },
              },
            });
          }
        }}
      >
        Add
      </Button>
      <div className="mx-3 mt-2 d-flex align-items-center">
        <div className="bo-w-32 d-flex justify-content-center align-items-top mb-1">
          <Input
            type="checkbox"
            name="turnoverCalculation"
            id="turnoverCalculation"
            checked={aggregatorTurnoverCalculation || false}
            onChange={changeChecked}
          />
        </div>
        <Label className="p-0 m-0">Calculate Turnover</Label>
      </div>
      <div className="mx-5 mt-2 d-flex align-items-center">
        <Label
          className={`bo-min-w-280 mb-0 ${
            !aggregatorTurnoverCalculation ? 'bo-text-light-gray' : ''
          }`}
        >
          Annual Turnover Benchmark:
        </Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="turnoverBenchmerk"
            name="turnoverBenchmerk"
            value={aggregatorTurnoverBenchmerk || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
            disabled={!aggregatorTurnoverCalculation}
          />
        </div>
        <Label
          className={`mx-3 bo-w-60 mb-0 ${
            !aggregatorTurnoverCalculation ? 'bo-text-light-gray' : ''
          }`}
        >
          Source:
        </Label>
        <div>
          <Input
            className="bo-input"
            type="text"
            name="turnoverBenchmerkSource"
            id="turnoverBenchmerkSource"
            value={aggregatorTurnoverBenchmerkSource || ''}
            onChange={changeValue}
            disabled={!aggregatorTurnoverCalculation}
          />
        </div>
      </div>
      <div className="mx-5 my-2 d-flex align-items-center">
        <Label
          className={`bo-min-w-280 mb-0 ${
            !aggregatorTurnoverCalculation ? 'bo-text-light-gray' : ''
          }`}
        >
          Employee Replacement Cost ($):
        </Label>
        <div>
          <NumericFormat
            className="bo-input bo-w-100"
            id="employeeReplacementCost"
            name="employeeReplacementCost"
            value={aggregatorEmployeeReplacementCost || ''}
            customInput={Input}
            onChange={changeValue}
            allowNegative={false}
            decimalScale={2}
            disabled={!aggregatorTurnoverCalculation}
          />
        </div>
        <Label
          className={`mx-3 bo-w-60 mb-0 ${
            !aggregatorTurnoverCalculation ? 'bo-text-light-gray' : ''
          }`}
        >
          Source:
        </Label>
        <div>
          <Input
            className="bo-input"
            type="text"
            name="employeeReplacementCostSource"
            id="employeeReplacementCostSource"
            value={aggregatorEmployeeReplacementCostSource || ''}
            onChange={changeValue}
            disabled={!aggregatorTurnoverCalculation}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(AggregatorBillingKPIs);
