import { memo } from 'react';
import { Col, Input } from 'reactstrap';

function ContactEmailForm({ aggregatorContactEmail, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="contactEmail"
          id="contactEmail"
          placeholder="Email"
          value={aggregatorContactEmail || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ContactEmailForm);
