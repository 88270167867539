import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid, areEqual } from 'react-window';
import { useReportDimensions } from '../../../hooks/useReportDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const SCROLLBAR_SIZE = 10;
const COLUMN_WIDTH_MULTIPLIER = 6;
const GROUP_WIDTH = 24;

const HeaderCell = memo(
  ({ data: { tableColumns, addWidthToLastRow }, columnIndex, style }) => (
    <div
      key={columnIndex}
      style={{
        ...style,
        width: addWidthToLastRow ? style.width + SCROLLBAR_SIZE : style.width,
        backgroundColor: Object.values(tableColumns)[columnIndex].headerBgColor,
      }}
      className="bo-table-border bo-table-cell bo-table-row-bg-yellow fw-bold px-1 overflow-hidden"
    >
      {Object.values(tableColumns)[columnIndex].header}
    </div>
  ),
  areEqual,
);

const GroupCell = memo(
  ({ data: { transformedCollapsibleRows, toggleCollapsible }, columnIndex, rowIndex, style }) => (
    <div key={columnIndex} style={{ ...style }} className="bo-table-group-cell">
      {transformedCollapsibleRows[rowIndex] && (
        <button
          type="button"
          className="h-100 w-100 border-0 bg-transparent"
          onClick={() => toggleCollapsible(rowIndex)}
        >
          <FontAwesomeIcon
            icon={transformedCollapsibleRows[rowIndex].collapsed ? faAngleRight : faAngleDown}
            color="#000"
            size="xs"
          />
        </button>
      )}
    </div>
  ),
);

const Cell = memo(
  ({
    data: {
      transformedReport,
      tableColumns,
      hoveredRowIndex,
      setHoveredRowIndex,
      boldTotalRow,
      formatCellConditionally,
    },
    columnIndex,
    rowIndex,
    style,
  }) => {
    if (!transformedReport || !tableColumns) {
      return null;
    }

    const tableColumnKey = Object.keys(tableColumns)[columnIndex];
    const tableElement = tableColumns[tableColumnKey];
    const {
      numFmt,
      alignment: { horizontal: horizontalAlignment },
    } = tableElement.style;
    const value = transformedReport[rowIndex][tableColumnKey];

    let className = 'bo-table-border bo-table-cell px-1 overflow-hidden';

    className +=
      horizontalAlignment === 'left'
        ? ' text-start justify-content-start'
        : ' text-end justify-content-end';
    className +=
      numFmt === '"$"#,##0.00;[Red]-"$"#,##0.00' && Array.from(value)[0] === '-'
        ? ' text-danger'
        : '';
    className += boldTotalRow && rowIndex === transformedReport.length - 1 ? ' fw-bold' : '';
    className += hoveredRowIndex === rowIndex ? ' bo-table-hover-bg' : '';

    if (formatCellConditionally) {
      className += formatCellConditionally({
        reportItem: transformedReport[rowIndex],
        tableColumnKey,
      });
    }

    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div
        key={columnIndex}
        style={style}
        onMouseOver={() => setHoveredRowIndex(rowIndex)}
        className={className}
      >
        {value}
      </div>
    );
  },
  areEqual,
);

function ReportTable({
  report,
  tableColumns,
  rowHeight = 36,
  headerHeight = 52,
  boldTotalRow,
  overscanColumnCount = 5,
  groups,
  rowKey,
  formatCellConditionally,
}) {
  const { height, width } = useReportDimensions();

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const [transformedReport, setTransformedReport] = useState([]);
  const [collapsibleRows, setCollapsibleRows] = useState([]);

  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const groupGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);

  useEffect(() => {
    if (groups) {
      setCollapsibleRows(
        groups.map(g => ({
          parentIndex: g.parentIndex,
          childIndices: g.childIndices,
          collapsed: true,
        })),
      );
    } else {
      setTransformedReport(report);
    }
  }, [report, groups]);

  useEffect(() => {
    setTransformedReport(
      report.filter(
        (_, idx) => !collapsibleRows.find(g => g.childIndices.includes(idx))?.collapsed,
      ),
    );
  }, [collapsibleRows, report]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: grid } = mainGridRef;
        const { current: header } = headerGridRef;
        const { current: group } = groupGridRef;
        const { current: gridDiv } = mainGridContainerRef;

        const hiddenContainer = gridDiv.firstElementChild;

        const maxVScroll = hiddenContainer.clientHeight - gridDiv.clientHeight;
        const maxHScroll = hiddenContainer.clientWidth - gridDiv.clientWidth;

        if (gridDiv && grid && header) {
          let { scrollLeft, scrollTop } = gridDiv;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          header.scrollTo({ scrollLeft });
          grid.scrollTo({ scrollLeft, scrollTop });

          if (group) {
            group.scrollTo({ scrollTop });
          }
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  const onScroll = useCallback(({ scrollLeft, scrollTop, scrollUpdateWasRequested }) => {
    if (!scrollUpdateWasRequested && headerGridRef.current) {
      headerGridRef.current.scrollTo({ scrollLeft, scrollTop: 0 });

      if (groupGridRef.current) {
        groupGridRef.current.scrollTo({ scrollLeft: 0, scrollTop });
      }
    }
  }, []);

  const toggleCollapsible = useCallback(
    transformedReportIndex => {
      let count = 0;

      const collapsible = collapsibleRows.find(c => {
        if (c.parentIndex - count === transformedReportIndex) {
          return true;
        }

        if (c.collapsed) {
          count += c.childIndices.length;
        }

        return false;
      });

      if (collapsible) {
        setCollapsibleRows(prevRows =>
          prevRows.map(row => {
            if (row.parentIndex === collapsible.parentIndex) {
              return { ...collapsible, collapsed: !collapsible.collapsed };
            }

            return row;
          }),
        );
      }
    },
    [collapsibleRows],
  );

  const contentHeight = useMemo(
    () => transformedReport.length * rowHeight,
    [transformedReport, rowHeight],
  );

  const contentWidth = useMemo(
    () =>
      Object.values(tableColumns).reduce(
        (acc, val) => acc + val.width * COLUMN_WIDTH_MULTIPLIER,
        0,
      ),
    [tableColumns],
  );

  const transformedCollapsibleRows = useMemo(
    () =>
      transformedReport.map((_, transformedReportIndex) => {
        let count = 0;

        const collapsible = collapsibleRows.find(c => {
          if (c.parentIndex - count === transformedReportIndex) {
            return true;
          }

          if (c.collapsed) {
            count += c.childIndices.length;
          }

          return false;
        });

        if (collapsible) {
          return { collapsed: collapsible.collapsed };
        }

        return null;
      }),
    [transformedReport, collapsibleRows],
  );

  const headerGridData = useMemo(
    () => ({
      tableColumns,
      addWidthToLastRow: contentHeight > height - headerHeight,
    }),
    [tableColumns, contentHeight, height, headerHeight],
  );

  const groupGridData = useMemo(
    () => ({
      transformedCollapsibleRows,
      toggleCollapsible,
    }),
    [transformedCollapsibleRows, toggleCollapsible],
  );

  const mainGridData = useMemo(
    () => ({
      transformedReport,
      tableColumns,
      hoveredRowIndex,
      setHoveredRowIndex,
      boldTotalRow,
      formatCellConditionally,
    }),
    [transformedReport, tableColumns, hoveredRowIndex, boldTotalRow, formatCellConditionally],
  );

  const estimatedColumnWidth = useMemo(
    () => Math.round(contentWidth / Object.values(tableColumns).length),
    [contentWidth, tableColumns],
  );

  return (
    <div ref={outerContainerRef} onMouseLeave={() => setHoveredRowIndex(null)}>
      <div className="d-flex">
        {groups && <div style={{ height: headerHeight, width: GROUP_WIDTH }} />}
        <VariableSizeGrid
          itemData={headerGridData}
          ref={headerGridRef}
          className="bo-table-header-container"
          columnCount={Object.keys(tableColumns).length}
          columnWidth={index => Object.values(tableColumns)[index].width * COLUMN_WIDTH_MULTIPLIER}
          height={headerHeight}
          rowCount={1}
          rowHeight={() => headerHeight}
          width={contentWidth + SCROLLBAR_SIZE > width ? width : contentWidth + SCROLLBAR_SIZE}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={headerHeight}
          overscanColumnCount={overscanColumnCount}
        >
          {HeaderCell}
        </VariableSizeGrid>
      </div>
      <div className="d-flex">
        {groups && (
          <VariableSizeGrid
            className="bo-table-group-container"
            ref={groupGridRef}
            itemData={groupGridData}
            columnCount={1}
            columnWidth={() => GROUP_WIDTH}
            height={
              contentHeight > height - headerHeight
                ? height - headerHeight - SCROLLBAR_SIZE
                : contentHeight
            }
            rowCount={transformedReport.length}
            rowHeight={() => rowHeight}
            width={GROUP_WIDTH}
            estimatedRowHeight={rowHeight}
          >
            {GroupCell}
          </VariableSizeGrid>
        )}
        <VariableSizeGrid
          itemKey={({ columnIndex, data, rowIndex }) =>
            `${data.transformedReport[rowIndex][rowKey]}-${columnIndex}`
          }
          className="bo-table-container"
          ref={mainGridRef}
          outerRef={mainGridContainerRef}
          itemData={mainGridData}
          onScroll={onScroll}
          columnCount={Object.keys(tableColumns).length}
          columnWidth={index => Object.values(tableColumns)[index].width * COLUMN_WIDTH_MULTIPLIER}
          height={contentHeight > height - headerHeight ? height - headerHeight : contentHeight}
          rowCount={transformedReport.length}
          rowHeight={() => rowHeight}
          width={contentWidth + SCROLLBAR_SIZE > width ? width : contentWidth + SCROLLBAR_SIZE}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={rowHeight}
          overscanColumnCount={overscanColumnCount}
        >
          {Cell}
        </VariableSizeGrid>
      </div>
    </div>
  );
}

export default ReportTable;
