import moment from 'moment';
import StyledTableCell from '../../../../components/StyledTableCell';
import { TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { Badge, Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { PAYMENT_LINK_BADGE } from '../../../../constants/fundings';
import { memo } from 'react';

function FundingPaymentLinks({
  fundingId,
  fundingPaymentLinks,
  getFunding,
  setSpinner,
  openPaymentLinksModal,
}) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const refreshLinks = async () => {
    setSpinner(true);
    await getFunding(fundingId);
    setSpinner(false);
  };

  const copyLink = url => {
    navigator.clipboard.writeText(url);
    toast.success('URL copied to clipboard');
  };

  const expireLink = async linkId => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/ad_hoc_payment/expire_link`,
      method: 'POST',
      data: JSON.stringify({
        fundingId,
        linkId,
      }),
    };

    try {
      await makeRequest(config);
      await getFunding(fundingId);

      toast.success('Link expired successfully');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <Row className="pt-4 mb-4 ms-1">
        <Col sm="8">
          <Button className="bo-button-inline bo-button-gray" onClick={refreshLinks}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={openPaymentLinksModal}>
            Create New Link
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Created at</StyledTableCell>
              <StyledTableCell align="left">Created by</StyledTableCell>
              <StyledTableCell align="left">Amount</StyledTableCell>
              <StyledTableCell align="left">Allow amount change</StyledTableCell>
              <StyledTableCell align="left">Minimal Amount</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left" />
              <StyledTableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fundingPaymentLinks
              ?.sort((a, b) => b.id - a.id)
              ?.map(paymentLink => (
                <TableRow hover tabIndex={-1} key={paymentLink.id}>
                  <StyledTableCell align="left">
                    {moment
                      .utc(paymentLink.createTimestamp)
                      .local()
                      .format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                  </StyledTableCell>
                  <StyledTableCell align="left">{paymentLink.user}</StyledTableCell>
                  <StyledTableCell align="left">
                    {paymentLink.amount !== null &&
                      paymentLink.amount !== undefined &&
                      `$${paymentLink.amount.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {paymentLink.allowAmountChange ? 'Yes' : 'No'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {paymentLink.minimalAmount !== null &&
                      paymentLink.minimalAmount !== undefined &&
                      `$${paymentLink.minimalAmount.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Badge
                      className="bo-badge"
                      color={PAYMENT_LINK_BADGE[paymentLink.status]?.color}
                    >
                      {PAYMENT_LINK_BADGE[paymentLink.status]?.text}
                    </Badge>
                    {paymentLink.paymentByAdmin && (
                      <Badge className="bo-badge ms-1" color="danger">
                        Payment By Admin
                      </Badge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Button
                      className="bo-button-inline-link"
                      color="link"
                      onClick={() => copyLink(paymentLink.url)}
                    >
                      Copy Link
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Button
                      className="bo-button-inline-link"
                      color="link"
                      onClick={() => expireLink(paymentLink.id)}
                    >
                      Expire
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default memo(FundingPaymentLinks);
