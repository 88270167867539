import { Row, Col } from 'reactstrap';
import DashboardSettings from './operational/DashboardSettings';
import DashboardDwollaWebhooks from './operational/DashboardDwollaWebhooks';
import DashboardServicesHealth from './operational/DashboardServicesHealth';
import DashboardFailedJobs from './operational/DashboardFailedJobs';
import DashboardJobs from './operational/DashboardJobs';

function DashboardOperational() {
  return (
    <Row className="align-items-start">
      <Col md={6}>
        <DashboardServicesHealth />
        <DashboardSettings />
        <DashboardDwollaWebhooks />
      </Col>
      <Col md={6}>
        <DashboardFailedJobs />
        <DashboardJobs />
      </Col>
    </Row>
  );
}

export default DashboardOperational;
