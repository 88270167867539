import { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Badge } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import moment from 'moment';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import { JOB_STATUS_BADGE } from '../../../../constants/consumers';
import { toast } from 'react-toastify';
import TablePaginationActions from '../../../../components/TablePaginationActions';
import { useTable } from '../../../../hooks/useTable';

function ConsumerJobs({ consumerId }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [jobs, setJobs] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useTable({
    initRowsPerPage: 20,
  });

  const getJobs = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/jobs/get`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const log = await makeRequest(config);

      setJobs(log.jobs);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLocalizedTime = time => {
    if (time) {
      return moment.utc(time).local().format('MMM DD, YYYY HH:mm:ss (UTCZ)');
    }

    return '-';
  };

  const renderTimeDiff = (a, b) => {
    if (a && b) {
      return moment(moment(a).diff(b, '')).format('mm:ss');
    }

    return '-';
  };

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="2">
          <Button className="bo-button-inline bo-button-gray" onClick={getJobs}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Job Id</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Init</StyledTableCell>
              <StyledTableCell align="left">Report Wait Time</StyledTableCell>
              <StyledTableCell align="left">Time in Queue</StyledTableCell>
              <StyledTableCell align="left">Process Time</StyledTableCell>
              <StyledTableCell className="bo-w-600" align="left">
                Error
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(job => (
              <TableRow hover tabIndex={-1} key={job.jobId}>
                <StyledTableCell align="left">{`****${job.jobId.slice(-5)}`}</StyledTableCell>
                <StyledTableCell align="left">{job.type}</StyledTableCell>
                <StyledTableCell align="left">
                  <Badge className="bo-inline-badge" color={JOB_STATUS_BADGE[job.status]?.color}>
                    {JOB_STATUS_BADGE[job.status]?.text}
                  </Badge>
                </StyledTableCell>
                <StyledTableCell align="left">{renderLocalizedTime(job.initTime)}</StyledTableCell>
                <StyledTableCell align="left">
                  {renderTimeDiff(job.queueTime, job.reportRequestTime)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {renderTimeDiff(job.startTime, job.queueTime)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {renderTimeDiff(job.endTime, job.startTime)}
                </StyledTableCell>
                <StyledTableCell align="left">{job.error}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={jobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Spinner>
  );
}

export default memo(ConsumerJobs);
