import { Button } from 'reactstrap';

function ReportsActions({ getAll, openReportsCreationModal }) {
  return (
    <>
      <Button className="bo-button bo-w-120" onClick={getAll}>
        Refresh
      </Button>
      <Button className="ms-3 bo-button bo-w-120" onClick={openReportsCreationModal}>
        Create New
      </Button>
    </>
  );
}

export default ReportsActions;
