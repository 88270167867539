import { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Input, Container } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import moment from 'moment';
import { makeRequest, makeUploadRequest } from '../../../../utils/makeRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function ConsumerTestZone({ consumerId, refreshProfile }) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginOrchestrator, apiOriginMonitor } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [consumerTime, setConsumerTime] = useState(null);
  const [artTime, setArtTime] = useState('');
  const [balance, setBalance] = useState(null);

  const changeArtlTime = e => setArtTime(e);

  const resetToRealtime = async id => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/reset`,
      method: 'POST',
      data: { consumerId: id },
    };

    try {
      const data = await makeRequest(config);

      setConsumerTime(data);
      setArtTime(new Date(data.time));
      toast.success("As-of time successfully set to 'Reat-time'");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const setArtificialTime = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/set`,
      method: 'POST',
      data: { consumerId, time: moment(artTime).format('YYYY-MM-DD 00:00:00') },
    };

    try {
      const data = await makeRequest(config);

      setConsumerTime(data);
      setArtTime(new Date(data.time));
      toast.success('Artificial As-of time successfully set');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const getConsumerTime = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/time`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    }
  };

  const getConsumerBalance = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/getTestAcctBalance`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    }
  };

  const getTimeAndBalance = async () => {
    const cons_time = await getConsumerTime(consumerId);

    if (cons_time) {
      setConsumerTime(cons_time);
      setArtTime(new Date(cons_time.time));
      const consumerBalance = await getConsumerBalance(consumerId);

      if (consumerBalance) {
        setBalance(consumerBalance.balance);
      }
    }
  };

  const incrementAndRun = async () => {
    const newArtTime = artTime;

    newArtTime.setDate(newArtTime.getDate() + 1);

    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/set`,
      method: 'POST',
      data: { consumerId, time: moment(newArtTime).format('YYYY-MM-DD 00:00:00') },
    };

    try {
      await makeRequest(config);

      getTimeAndBalance();
      toast.success('Artificial As-of time successfully incremented and daily monitor executed');
      refreshProfile(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const getInitTimeAndBalance = async () => {
    setSpinner(true);
    await getTimeAndBalance();
    setSpinner(false);
  };

  useEffect(() => {
    getInitTimeAndBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setConsumerBalance = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/setTestAcctBalance`,
      method: 'POST',
      data: { consumerId, balance },
    };

    try {
      const data = await makeRequest(config);

      setBalance(data.balance);

      toast.success('Opening balance successfully updated');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const [file, setFile] = useState(null);

  const handleFileChange = e => {
    if (e.target.files) {
      const targetFile = e.target.files[0];

      if (targetFile.type !== 'text/csv') {
        setFile('error');
      } else {
        setFile(e.target.files[0]);
      }
    }
  };

  const handleUploadClick = async () => {
    if (!file || file === 'error') {
      return;
    }
    setSpinner(true);

    const data = new FormData();

    data.append('file', file);
    data.append('consumerId', consumerId);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    try {
      await makeUploadRequest(`${apiOriginMonitor}/uploadTrx`, data, token);

      toast.success('File uploaded successfully');

      setFile(null);
      getTimeAndBalance();
    } catch (err) {
      toast.error(err.toString());
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Container className="pb-2">
      <Spinner visible={spinner} text="">
        <Row className="mb-4 mt-5 ms-1">
          <Col sm={2} className="bo-same-line">
            <span>As-of:</span>
          </Col>
          <Col className="bo-same-line">
            {consumerTime && consumerTime.type === 'realtime' ? (
              <>
                <Input
                  className="bo-input bo-w-200"
                  type="text"
                  name="rt"
                  id="rt"
                  value="Real-time"
                  disabled
                />
                <Button
                  className="ms-3 bo-link p-0"
                  color="link"
                  onClick={() => {
                    setArtTime(new Date());
                    setArtificialTime();
                  }}
                >
                  Set Artificial Time
                </Button>
              </>
            ) : (
              <>
                <span className="bo-w-200">
                  <DatePicker
                    className="bo-input bo-date-picker"
                    onChange={e => changeArtlTime(e)}
                    selected={artTime}
                    dateFormat="dd/MM/yyyy"
                  />
                </span>
                <Button
                  className="ms-3 bo-link p-0"
                  color="link"
                  onClick={() => setArtificialTime()}
                >
                  Save
                </Button>
                <Button className="ms-3 bo-link p-0" color="link" onClick={() => incrementAndRun()}>
                  Increment and run daily
                </Button>
                <Button
                  className="ms-3 bo-link p-0"
                  color="link"
                  onClick={() => resetToRealtime(consumerId)}
                >
                  Real-time
                </Button>
                <span className="ms-3">
                  <small>
                    Note: &ldquo;As-of date&rdquo; transactions will not be loaded if time is before
                    noon
                  </small>
                </span>
              </>
            )}
          </Col>
        </Row>
        <Row className="mb-4 ms-1">
          <Col sm={2} className="bo-same-line">
            <span>Opening balance:</span>
          </Col>
          <Col className="bo-same-line">
            <Input
              className="bo-input bo-w-200"
              type="text"
              name="balance"
              id="balance"
              value={balance !== null ? balance : ''}
              onChange={e => setBalance(e.target.value)}
            />
            <Button className="ms-3 bo-link p-0" color="link" onClick={() => setConsumerBalance()}>
              Save
            </Button>
          </Col>
        </Row>
        <Row className="pb-4 ms-1">
          <Col sm={2} className="bo-same-line">
            <span>Transactions File:</span>
          </Col>
          <Col className="bo-same-line">
            <Input
              className="bo-input bo-w-500"
              type="file"
              onChange={handleFileChange}
              onClick={e => {
                e.target.value = '';
              }}
            />
            {file === 'error' ? (
              <span className="ms-3 text-danger">Only csv files are allowed!</span>
            ) : (
              file && (
                <Button
                  className="ms-3 bo-button-inline bo-button-gray"
                  onClick={handleUploadClick}
                >
                  Upload
                </Button>
              )
            )}
          </Col>
        </Row>
      </Spinner>
    </Container>
  );
}

export default memo(ConsumerTestZone);
