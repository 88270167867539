import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function FundingExpectedBalanceForm({
  fundingStatus,
  expectedBalanceOnClose,
  totalExpectedBalance,
}) {
  const getValue = () => {
    if (fundingStatus === 'CLOSED' || fundingStatus === 'CANCELLED') {
      return '';
    }

    if (totalExpectedBalance) {
      return `${expectedBalanceOnClose} (Expected)`;
    }

    return expectedBalanceOnClose || '';
  };

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Expected Balance on Close:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className={`bo-input ${expectedBalanceOnClose < 0 ? 'bo-text-red' : ''}`}
          type="text"
          name="openFundingRemainingBalance"
          id="openFundingRemainingBalance"
          value={getValue()}
          readOnly
        />
      </Col>
      <Col md={2} className="bo-same-line" />
    </>
  );
}

export default memo(FundingExpectedBalanceForm);
