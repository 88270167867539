import moment from 'moment';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import StyledTableCell from '../../../../components/StyledTableCell';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

function DashboardFailedJobs() {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [rerunJobs, setRerunJobs] = useState([]);

  const getAllRerunJobs = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_all_rerun_jobs`,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setRerunJobs(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAllRerunJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRerunJob = async id => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/delete_job_rerun`,
        method: 'POST',
        data: JSON.stringify({ id }),
      };

      try {
        await makeRequest(config);
        await getAllRerunJobs();

        toast.success('Re-run job has been removed!');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Card className="mb-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Failed Jobs Pending Re-run
        <Button className="bo-button-inline-link" color="link" onClick={() => getAllRerunJobs()}>
          Refresh
        </Button>
      </CardHeader>
      <CardBody>
        <Spinner visible={spinner} text="">
          <TableContainer>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Consumer ID</StyledTableCell>
                  <StyledTableCell align="left">Job Type</StyledTableCell>
                  <StyledTableCell align="left">Failed at</StyledTableCell>
                  <StyledTableCell align="left">Failure Reason</StyledTableCell>
                  <StyledTableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {rerunJobs
                  .sort(
                    (a, b) =>
                      moment(a.createTimestamp).valueOf() - moment(b.createTimestamp).valueOf(),
                  )
                  .map(job => (
                    <TableRow key={job.id} hover tabIndex={-1}>
                      <StyledTableCell align="left">{job.consumerId}</StyledTableCell>
                      <StyledTableCell align="left">{job.jobType}</StyledTableCell>
                      <StyledTableCell align="left">
                        {moment.utc(job.createTimestamp).local().format('YYYY-MM-DD HH:mm:ss')}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="pe-0">
                        {job.rerunReason}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="pe-0">
                        <Button
                          className="bo-button-inline-link bo-inline-badge"
                          color="link"
                          onClick={() => deleteRerunJob(job.id)}
                        >
                          Remove
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Spinner>
      </CardBody>
    </Card>
  );
}

export default DashboardFailedJobs;
