import { Col, Row } from 'reactstrap';
// forms
import AggregatorForm from './forms/AggregatorForm';
import ConsumerIdForm from './forms/ConsumerIdForm';
import ReferringConsumer from './forms/ReferringConsumer';
import FullNameForm from './forms/FullNameForm';
import AddressLine1Form from './forms/AddressLine1Form';
import EmailForm from './forms/EmailForm';
import AddressLine2Form from './forms/AddressLine2Form';
import PhoneNumberForm from './forms/PhoneNumberForm';
import AddressLine3Form from './forms/AddressLine3Form';
import EmployeeIdForm from './forms/EmployeeIdForm';
import DateOfBirthForm from './forms/DateOfBirthForm';
import ScheduledForm from './forms/ScheduledForm';
import StatusForm from './forms/StatusForm';
import NotesForm from './forms/NotesForm';
import MfaChannelForm from './forms/MfaChannelForm';
import LanguageForm from './forms/LanguageForm';
import HighRiskForm from './forms/HighRiskForm';
import LastFourSsnForm from './forms/LastFourSsnForm';
import CardRequiredForm from './forms/CardRequiredForm';
import AllowCreditCardForm from './forms/AllowCreditCardForm';
import DefaultPaymentMethodForm from './forms/DefaultPaymentMethodForm';
// components
import ConsumerRedFlags from './ConsumerRedFlags';
import ConsumerOpenFunding from './ConsumerOpenFunding';
import ConsumerSettings from './ConsumerSettings';
import ConsumerActionRow from './ConsumerActionRow';

function ConsumerFormController({
  consumer,
  changeValue,
  setConsumer,
  getConsumer,
  openFunding,
  getFunding,
  openFundingModal,
  setSpinner,
  refreshProfile,
  predict,
  openNotificationModal,
  openManualMfaModal,
  phoneFields,
  setPhoneFields,
  phoneEditMode,
  setPhoneEditMode,
  openPhoneVerificationModal,
}) {
  return (
    <>
      <Row className="mt-3 mb-2 align-items-center">
        <AggregatorForm consumerAggregatorId={consumer.aggregatorId} changeValue={changeValue} />
        <ConsumerIdForm consumerId={consumer.id} />
        <ReferringConsumer
          referringConsumerId={consumer.referringConsumerId}
          consumerId={consumer.id}
          setSpinner={setSpinner}
          setConsumer={setConsumer}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <FullNameForm
          consumerFirstName={consumer.firstName}
          consumerLastName={consumer.lastName}
          changeValue={changeValue}
        />
        <AddressLine1Form consumerAddressLine1={consumer.addressLine1} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-center">
        <EmailForm consumerEmail={consumer.email} />
        <AddressLine2Form consumerAddressLine2={consumer.addressLine2} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-center">
        <PhoneNumberForm
          consumerId={consumer.id}
          consumerPhoneCc={consumer.phoneCc}
          consumerPhoneNdc={consumer.phoneNdc}
          consumerPhoneSn={consumer.phoneSn}
          consumerIsPhoneVerified={consumer.isPhoneVerified}
          setSpinner={setSpinner}
          phoneFields={phoneFields}
          setPhoneFields={setPhoneFields}
          phoneEditMode={phoneEditMode}
          setPhoneEditMode={setPhoneEditMode}
          openPhoneVerificationModal={openPhoneVerificationModal}
        />
        <AddressLine3Form
          consumerCity={consumer.city}
          consumerState={consumer.state}
          consumerZip={consumer.zip}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-center">
        <EmployeeIdForm consumerEmployeeId={consumer.employeeId} changeValue={changeValue} />
        <DateOfBirthForm consumerDateOfBirth={consumer.dateOfBirth} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-center">
        <LanguageForm consumerLanguage={consumer.language} changeValue={changeValue} />
        <LastFourSsnForm last4ssn={consumer.last4ssn} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-top">
        <StatusForm consumerStatus={consumer.status} changeValue={changeValue} />
        <ScheduledForm consumerIsScheduler={consumer.isScheduler} />
      </Row>
      <Row className="mb-2 align-items-top">
        <MfaChannelForm consumerMfaChannel={consumer.mfaChannel} />
        <NotesForm consumerNotes={consumer.notes} changeValue={changeValue} />
      </Row>
      <Row className="mb-2 align-items-top">
        <DefaultPaymentMethodForm
          consumerDefaultPaymentMethod={consumer.defaultPaymentMethod}
          changeValue={changeValue}
        />
        <HighRiskForm
          consumerHighRisk={consumer.highRisk}
          consumerHighRiskReason={consumer.highRiskReason}
          setConsumer={setConsumer}
          changeValue={changeValue}
        />
      </Row>
      <Row className="mb-2 align-items-top">
        {consumer.flags.length > 0 ? (
          <ConsumerRedFlags consumerFlags={consumer.flags} />
        ) : (
          <Col md={6} className="bo-same-line" />
        )}
        <CardRequiredForm
          consumerCardRequired={consumer.cardRequired}
          consumerDefaultPaymentMethod={consumer.defaultPaymentMethod}
          setConsumer={setConsumer}
        />
      </Row>
      <Row className="mb-2 align-items-top">
        <AllowCreditCardForm
          consumerAllowCreditCard={consumer.allowCreditCard}
          setConsumer={setConsumer}
        />
      </Row>
      <ConsumerOpenFunding
        consumerId={consumer.id}
        consumerFirstName={consumer.firstName}
        consumerLastName={consumer.lastName}
        isOpenFunding={!!openFunding}
        openFundingId={openFunding?.id}
        getFunding={getFunding}
        openFundingModal={openFundingModal}
      />
      <ConsumerSettings
        consumerDefaultFreeCreditTransfers={consumer.defaultFreeCreditTransfers}
        consumerDefaultFreeDebitTransfers={consumer.defaultFreeDebitTransfers}
        consumerDefaultTransferFee={consumer.defaultTransferFee}
        consumerBpMonthlyQuota={consumer.bpMonthlyQuota}
        consumerBpCap={consumer.bpCap}
        consumerAdvanceLimit={consumer.advanceLimit}
        consumerMinPayrollCycles={consumer.minPayrollCycles}
        consumerMinPayrollWeeks={consumer.minPayrollWeeks}
        consumerMinAccountDays={consumer.minAccountDays}
        changeValue={changeValue}
      />
      <ConsumerActionRow
        consumer={consumer}
        setSpinner={setSpinner}
        getConsumer={getConsumer}
        refreshProfile={refreshProfile}
        predict={predict}
        openNotificationModal={openNotificationModal}
        openManualMfaModal={openManualMfaModal}
      />
    </>
  );
}

export default ConsumerFormController;
