export const STATUS_BADGE = {
  OPEN: {
    text: 'Open',
    color: 'success',
  },
  DUE: {
    text: 'Due',
    color: 'warning',
  },
  REPAYED: {
    text: 'Repayed',
    color: 'info',
  },
  CLOSING: {
    text: 'Closing...',
    color: 'info',
  },
  CLOSED: {
    text: 'Closed',
    color: 'secondary',
  },
  CANCELLED: {
    text: 'Cancelled',
    color: 'secondary',
  },
  DEFAULT: {
    text: 'Default',
    color: 'danger',
  },
};

export const REPAYMENT_MODE_BADGE = {
  NEXT_PAYDAY: {
    text: 'Next Payday',
    color: 'bg-success',
  },
  IMMEDIATE: {
    text: 'Immediate',
    color: 'bg-warning',
  },
  FORCE: {
    text: 'Force',
    color: 'bg-danger',
  },
  REPAID: {
    text: 'Repaid',
    color: 'bg-secondary',
  },
  MANUAL: {
    text: 'Manual',
    color: 'bg-danger',
  },
  CUSTOM: {
    text: 'Custom',
    color: 'bo-badge-bg-light-green',
  },
};

export const REPAYMENT_MODE_TEXT = {
  NEXT_PAYDAY: {
    text: 'Next Payday',
    color: 'text-success',
  },
  IMMEDIATE: {
    text: 'Immediate',
    color: 'text-warning',
  },
  FORCE: {
    text: 'Force',
    color: 'text-danger',
  },
  REPAID: {
    text: 'Repaid',
    color: 'text-secondary',
  },
  MANUAL: {
    text: 'Manual',
    color: 'text-danger',
  },
  CUSTOM: {
    text: 'Custom',
    color: 'bo-light-green',
  },
};

export const TRANSFER_STATUS_BADGE = {
  CREATED: {
    text: 'Created',
    color: 'warning',
  },
  FAILED: {
    text: 'Failed',
    color: 'danger',
  },
  COMPLETED: {
    text: 'Completed',
    color: 'success',
  },
  CANCELLED: {
    text: 'Cancelled',
    color: 'secondary',
  },
};

export const TRANSFER_RECONCILE_BADGE = {
  YES: {
    text: 'Yes',
    color: 'success',
  },
  NO: {
    text: 'No',
    color: 'warning',
  },
  FORCE: {
    text: 'Force',
    color: 'success',
  },
  YES_FORCE: {
    text: 'Yes & Force',
    color: 'success',
  },
};

export const EVENT_LOG_TYPE_COLOR = {
  CREATE_FUNDING: 'text-dark',
  CREATE_TOPUP: 'text-dark',
  CREATE_REPAYMENT: 'text-dark',
  CREATE_TRANSFER: 'text-dark',
  CHECK_REPAYMENT: 'text-secondary',
  CANCEL_REPAYMENT: 'text-danger',
  STATUS_CHANGE: 'text-primary',
  REPAYMENT_MODE_CHANGE: 'text-primary',
  REPAYMENT_DATE_CHANGE: 'text-primary',
  CANCEL_FUNDING: 'text-danger',
  TRANSFER_FAILED: 'text-danger',
  TRANSFER_COMPLETED: 'text-success',
  FUNDING_CLOSED: 'text-success',
  TRANSFER_WEBHOOK: 'text-dark',
};

export const PAYMENT_LINK_BADGE = {
  ACTIVE: {
    text: 'Active',
    color: 'success',
  },
  USED: {
    text: 'Used',
    colr: 'secondary',
  },
  EXPIRED: {
    text: 'Expired',
    color: 'warning',
  },
};

export const REPAYMENT_TRIALS_TYPE = {
  AUTO: 'Auto',
  MANUAL: 'Manual',
};

export const REPAYMENT_TRIALS_ACTION = {
  NO_REPAYMENT: 'No Repayment',
  PARTIAL_REPAYMENT: 'Partial Repayment',
  FULL_REPAYMENT: 'Full Repayment',
};

export const REPAYMENT_TRIALS_STATUS_COLOR = {
  FAILED: 'text-danger',
  CREATED: 'text-success',
  COMPLETED: 'text-success',
};

export const COLLECTION_POLICY_CODE = {
  CONSERVATIVE: 'Conservative',
  CONSERVATIVE_D: 'Conservative D',
  MODERATE: 'Moderate',
  HIGH_RISK: 'High Risk',
  FORCE: 'Force',
  AGGRESIVE_FORCE: 'Aggressive Force',
  CUSTOM: 'Custom',
};

export const COLLECTION_POLICY_CODE_BADGE = {
  CONSERVATIVE: {
    text: 'C',
    border: 'border-success',
    textColor: 'text-success',
  },
  CONSERVATIVE_D: {
    text: 'CD',
    border: 'border-success',
    textColor: 'text-success',
  },
  MODERATE: {
    text: 'M',
    border: 'border-warning',
    textColor: 'text-warning',
  },
  HIGH_RISK: {
    text: 'HR',
    border: 'border-danger',
    textColor: 'text-danger',
  },
  FORCE: {
    text: 'F',
    border: 'border-danger',
    textColor: 'text-danger',
  },
  AGGRESIVE_FORCE: {
    text: 'AF',
    border: 'border-danger',
    textColor: 'text-danger',
  },
  CUSTOM: {
    text: '?',
    border: 'border-info',
    textColor: 'text-info',
  },
};
