const isFundingBlind = (status, repaymentMode, bankAccountStatus) => {
  if (
    (status === 'OPEN' || status === 'DUE' || status === 'DEFAULT') &&
    (repaymentMode === 'NEXT_PAYDAY' || repaymentMode === 'CUSTOM' || repaymentMode === 'FORCE') &&
    bankAccountStatus !== 'CONNECTED'
  ) {
    return true;
  }

  return false;
};

export { isFundingBlind };
