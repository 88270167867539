import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, parseExcelFormats } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';

function ReportRiskIndicators({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      aggregatorName: {
        header: 'Aggregator Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer ID',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      el_c_value: {
        header: 'External loans count',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      el_s_value: {
        header: 'External loans sum',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
      },
      el_p_value: {
        header: 'External loan providers',
        width: 25,
        style: { alignment: { horizontal: 'right' } },
      },
      pd_eod_value: {
        header: 'Payday EOD balance',
        width: 23,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      pd_plus1_eod_value: {
        header: 'Payday+1 EOD balance',
        width: 25,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      pd_debit_value: {
        header: 'Payday Debit transactions',
        width: 28,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      pd_plus1_debit_value: {
        header: 'Payday+1 Debit transactions',
        width: 30,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      od_nsf_value: {
        header: 'OD/NSF transactions',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
      },
      nbd_value: {
        header: 'Negative balance days',
        width: 24,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
      },
      nbd_c_value: {
        header: 'Consecutive negative balance days',
        width: 36,
        style: { alignment: { horizontal: 'right' } },
      },
      el_c_threshold: {
        header: 'ELC Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
        hidden: true,
      },
      el_s_threshold: {
        header: 'ELS Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '"$"#,##0.00' },
        hidden: true,
      },
      el_p_threshold: {
        header: 'ELP Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
        hidden: true,
      },
      pd_eod_threshold: {
        header: 'PD EOD Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
        hidden: true,
      },
      pd_plus1_eod_threshold: {
        header: 'PD+1 EOD Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
        hidden: true,
      },
      pd_debit_threshold: {
        header: 'PD Debit Threshold',
        width: 23,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
        hidden: true,
      },
      pd_plus1_debit_threshold: {
        header: 'PD+1 Debit Threshold',
        width: 23,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
        hidden: true,
      },
      od_nsf_threshold: {
        header: 'OD/NSF Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
        hidden: true,
      },
      nbd_threshold: {
        header: 'NBD Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' }, numFmt: '0.00%' },
        hidden: true,
      },
      nbd_c_threshold: {
        header: 'NBDC Threshold',
        width: 22,
        style: { alignment: { horizontal: 'right' } },
        hidden: true,
      },
    }),
    [],
  );

  const endRow = report.length + 1;

  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Risk Indicators', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    worksheet.addRows(report);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    worksheet.addConditionalFormatting({
      ref: `E2:N${endRow}`,
      rules: [
        {
          type: 'expression',
          formulae: [
            `IF(ISBLANK(INDEX($A$1:$X$${endRow}, ROW(), COLUMN() + 10)), FALSE,` +
              ` INDEX($A$1:$X$${endRow}, ROW(), COLUMN()) >` +
              ` INDEX($A$1:$X$${endRow}, ROW(), COLUMN() + 10))`,
          ],
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFFF0000' },
            },
          },
        },
      ],
    });

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns, endRow]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return report.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [report, tableColumns]);

  const formattedTableColumns = useMemo(
    () =>
      Object.entries(tableColumns).reduce((acc, [key, value]) => {
        if (!value.hidden) {
          return {
            ...acc,
            [key]: { ...value },
          };
        }

        return acc;
      }, {}),
    [tableColumns],
  );

  const formatCellConditionally = useCallback(({ reportItem, tableColumnKey }) => {
    if (
      tableColumnKey.includes('_value') &&
      reportItem[tableColumnKey] !== null &&
      reportItem[tableColumnKey] !== undefined &&
      reportItem[tableColumnKey.replace('_value', '_threshold')] !== null &&
      reportItem[tableColumnKey.replace('_value', '_threshold')] !== undefined &&
      parseExcelFormats(reportItem[tableColumnKey]) >
        parseExcelFormats(reportItem[tableColumnKey.replace('_value', '_threshold')])
    ) {
      return ' bg-danger';
    }

    return '';
  }, []);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={formattedTableColumns}
        headerHeight={36}
        rowKey="consumerId"
        formatCellConditionally={formatCellConditionally}
      />
    </>
  );
}

export default ReportRiskIndicators;
