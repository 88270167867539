import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';

function NewObjectPopoverSearchAggregatorForm({
  consumerAggregatorId,
  aggregators,
  updateConsumer,
  showInInputFormat,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [search, setSearch] = useState('');

  const filteredAggregators = useMemo(() => {
    if (search === '') return aggregators;

    return aggregators.filter(aggregator =>
      aggregator.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, aggregators]);

  const handleAggregatorClick = async aggregatorId => {
    setSpinner(true);
    await updateConsumer(aggregatorId);
    setSpinner(false);

    setIsOpen(false);
    setSearch('');
  };

  const handleToggle = () => {
    setSearch('');
    setIsOpen(!isOpen);
  };

  return (
    <>
      {showInInputFormat ? (
        <>
          <Label className="bo-new-object-input-label">Employer</Label>
          <Button
            id="popover_search_aggregator_input_form"
            className="bo-new-object-text-input-container"
            onClick={handleToggle}
            disabled={aggregators.length === 0}
          >
            <div className="d-flex align-items-center w-100">
              <Input
                className="bo-new-object-text-input bo-w-150"
                type="text"
                readOnly
                value={
                  aggregators.find(aggregator => aggregator.id === Number(consumerAggregatorId))
                    ?.name || 'Loading...'
                }
              />
            </div>
            <div className="bo-new-object-input-button-container">
              <div className="bo-new-object-input-button-icon">
                <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
              </div>
            </div>
          </Button>
        </>
      ) : (
        <Button
          id="popover_search_aggregator_form"
          onClick={handleToggle}
          className="bo-new-object-popover-form-toggle w-auto"
          disabled={aggregators.length === 0}
        >
          <p className="bo-new-object-text p-0 m-0">
            {aggregators.find(aggregator => aggregator.id === Number(consumerAggregatorId))?.name ||
              'Loading...'}
          </p>
          <div
            className={`pb-1 bo-new-object-popover-form-toggle-icon ${
              isOpen ? 'bo-new-object-popover-form-toggle-icon-active' : ''
            }`}
          >
            <FontAwesomeIcon icon={faPencil} color="#5946A4" size="xs" />
          </div>
        </Button>
      )}
      <Popover
        target={
          showInInputFormat
            ? 'popover_search_aggregator_input_form'
            : 'popover_search_aggregator_form'
        }
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner}>
            <Label className="bo-new-object-popover-form-menu-label">Search</Label>
            <Input
              type="text"
              className="bo-new-object-popover-form-menu-input bo-w-200"
              value={search}
              onChange={e => setSearch(e.target.value)}
              disabled={aggregators.length === 0}
            />
            <div className="bo-new-object-popover-form-content-box">
              {filteredAggregators.map(aggregator => (
                <Button
                  className="bo-new-object-popover-form-content-button"
                  key={aggregator.id}
                  onClick={() => handleAggregatorClick(aggregator.id)}
                >
                  <p className="bo-new-object-text text-start">{aggregator.name}</p>
                </Button>
              ))}
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}
export default NewObjectPopoverSearchAggregatorForm;
