import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { states } from '../../../../utils/states';

function BillingAddressForm({
  btMetadata,
  setBtMetadata,
  useProfileAddress,
  setUseProfileAddress,
}) {
  const handleDefaultInputChangeEvent = (fieldName, e) => {
    let { value } = e.target;

    if (fieldName === 'state') {
      value = value.toUpperCase().replace(/[^A-Z]/g, '');
    }

    if (fieldName === 'zip') {
      value = value.replace(/[^0-9]/g, '');
    }

    setBtMetadata(prevM => ({
      ...prevM,
      [fieldName]: { value, dirty: true },
    }));
  };

  const getDefaultInputStyle = fieldName => {
    let className = 'bo-card-form-default-input';

    if (fieldName === 'city') {
      className += ' bo-card-form-city-input';
    }

    if (fieldName === 'state') {
      className += ' bo-card-form-state-input';
    }

    if (fieldName === 'zip') {
      className += ' bo-card-form-zip-input';
    }

    if (
      btMetadata[fieldName].dirty &&
      !btMetadata[fieldName].value &&
      fieldName !== 'addressLine2'
    ) {
      className += ' bo-card-form-default-input-invalid';
    }

    if (
      btMetadata[fieldName].dirty &&
      fieldName === 'state' &&
      !states.includes(btMetadata[fieldName].value)
    ) {
      className += ' bo-card-form-default-input-invalid';
    }

    if (
      btMetadata[fieldName].dirty &&
      fieldName === 'zip' &&
      btMetadata[fieldName].value.length !== 5
    ) {
      className += ' bo-card-form-default-input-invalid';
    }

    if (useProfileAddress) {
      className += ' bo-card-form-default-input-readonly';
    }

    return className;
  };

  return (
    <>
      <Row className="mt-2">
        <Col>
          <p className="bo-text mb-0">Billing Address</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="bo-same-line align-items-start">
          <FormGroup switch>
            <Input
              name="useProfileAddress"
              id="useProfileAddress"
              type="switch"
              role="switch"
              className="bo-switch-scale"
              checked={useProfileAddress}
              onChange={e => setUseProfileAddress(e.target.checked)}
            />
            <Label className="bo-text ms-4 mb-0">Use my profile address</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <input
            id="addressLine1"
            className={getDefaultInputStyle('addressLine1')}
            placeholder="Address line 1"
            value={btMetadata.addressLine1.value}
            onChange={e => handleDefaultInputChangeEvent('addressLine1', e)}
            readOnly={useProfileAddress}
          />
          <input
            id="addressLine2"
            className={getDefaultInputStyle('addressLine2')}
            placeholder="Address line 2"
            value={btMetadata.addressLine2.value}
            onChange={e => handleDefaultInputChangeEvent('addressLine2', e)}
            readOnly={useProfileAddress}
          />
          <div className="d-flex justify-content-between">
            <input
              id="city"
              className={getDefaultInputStyle('city')}
              placeholder="City"
              value={btMetadata.city.value}
              onChange={e => handleDefaultInputChangeEvent('city', e)}
              readOnly={useProfileAddress}
            />
            <input
              id="state"
              className={getDefaultInputStyle('state')}
              placeholder="State"
              value={btMetadata.state.value}
              maxLength={2}
              onChange={e => handleDefaultInputChangeEvent('state', e)}
              readOnly={useProfileAddress}
            />
            <input
              id="state"
              className={getDefaultInputStyle('zip')}
              placeholder="Zip"
              value={btMetadata.zip.value}
              maxLength={5}
              inputMode="numeric"
              onChange={e => handleDefaultInputChangeEvent('zip', e)}
              readOnly={useProfileAddress}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default BillingAddressForm;
