import { toast } from 'react-toastify';
import { STATUS_BADGE } from '../../../../constants/fundings';
import { Col, Badge, Label, Button } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { memo } from 'react';

function FundingStatusForm({ fundingId, fundingStatus, setSpinner, getFunding }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const toggleDefault = async defaultStatus => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/${defaultStatus ? 'reopen' : 'set_default'}`,
        method: 'POST',
        data: JSON.stringify({ fundingId }),
      };

      try {
        await makeRequest(config);
        await getFunding(fundingId);

        toast.success(
          defaultStatus
            ? 'Repayment status successfully reopened'
            : 'Repayment status successfully set to DEFAULT',
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Status:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        {STATUS_BADGE[fundingStatus] && (
          <Badge className="bo-badge" color={STATUS_BADGE[fundingStatus]?.color}>
            {STATUS_BADGE[fundingStatus]?.text}
          </Badge>
        )}
        <Button
          className="bo-link mb-0 ms-3 p-0"
          color="link"
          onClick={() => toggleDefault(fundingStatus === 'DEFAULT')}
        >
          {fundingStatus === 'DEFAULT' ? 'Re-open' : 'Set as Default'}
        </Button>
      </Col>
    </>
  );
}

export default memo(FundingStatusForm);
