import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faEyeSlash, faFlag, faWarning } from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledTableCell from '../../../components/StyledTableCell';
import {
  COLLECTION_POLICY_CODE_BADGE,
  REPAYMENT_MODE_BADGE,
  REPAYMENT_TRIALS_ACTION,
  REPAYMENT_TRIALS_STATUS_COLOR,
  STATUS_BADGE,
} from '../../../constants/fundings';
import { isFundingBlind } from '../../../utils/fundings';

function FundingsTableContainer({
  consumersShortStatus,
  sortOrder,
  sortBy,
  sortHandler,
  rows,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
}) {
  const getRepaymentLineColor = repaymentTrial => {
    if (REPAYMENT_TRIALS_STATUS_COLOR[repaymentTrial.transferStatus]) {
      return REPAYMENT_TRIALS_STATUS_COLOR[repaymentTrial.transferStatus];
    }

    if (
      repaymentTrial.trialAction === 'NO_REPAYMENT' &&
      repaymentTrial.trialActionReason !== 'Not a repayment date' &&
      repaymentTrial.trialActionReason !== 'Not a business day' &&
      repaymentTrial.trialActionReason !== 'Advance status is CLOSING' &&
      repaymentTrial.trialActionReason !== 'Advance repayment mode is MANUAL'
    ) {
      return 'text-danger';
    }

    return '';
  };

  const showTodayRepaymentTrials = todayRepaymentTrials => {
    if (Array.isArray(todayRepaymentTrials)) {
      return todayRepaymentTrials.map((x, idx) => {
        const timestamp = moment(x.timestamp);
        const localized = moment.utc(x.timestamp).local();
        const diff = localized.startOf('day').diff(timestamp.startOf('day'), 'day');

        return (
          <div
            className={`d-flex ${
              idx === todayRepaymentTrials.length - 1 ? '' : 'mb-2'
            } ${getRepaymentLineColor(x)}`}
            key={x.timestamp}
          >
            <p className="bo-w-50 text-nowrap p-0 mb-0 me-2 position-relative">
              <strong>{moment.utc(x.timestamp).local().format('HH:mm')}</strong>
              {diff !== 0 && <p className="bo-timestamp-day-indicator">{diff > 0 ? '+1' : '-1'}</p>}
              {x.isBlind && <FontAwesomeIcon icon={faEyeSlash} size="sm" className="ms-1" />}
            </p>
            <p className="bo-w-120 text-nowrap p-0 mb-0 me-2">
              {REPAYMENT_TRIALS_ACTION[x.trialAction] || x.trialAction}
            </p>
            <p className="text-nowrap p-0 mb-0 me-2">{x.trialActionReason}</p>
          </div>
        );
      });
    }

    return <p className="bo-w-600 mb-0 p-0">{todayRepaymentTrials || null}</p>;
  };

  const getCollectionPolicyStyles = row => {
    let className = 'bo-inline-badge ms-1 border bg-white';

    const badge = COLLECTION_POLICY_CODE_BADGE[row.collectionPolicy];

    if (badge) {
      className += ` ${badge.border} ${badge.textColor}`;
    } else {
      className += ' border-dark text-dark';
    }

    if (
      consumersShortStatus[row.consumerId] &&
      isFundingBlind(
        row.status,
        row.repaymentMode,
        consumersShortStatus[row.consumerId]?.bankAccountStatus,
      )
    ) {
      className += ' text-decoration-line-through';
    }

    return className;
  };

  const renderBlindIndication = row => {
    if (!consumersShortStatus[row.consumerId]) {
      return <FontAwesomeIcon icon={faWarning} size="1x" className="ms-1 text-danger" />;
    }

    if (
      isFundingBlind(
        row.status,
        row.repaymentMode,
        consumersShortStatus[row.consumerId]?.bankAccountStatus,
      )
    ) {
      return (
        <FontAwesomeIcon
          icon={faEyeSlash}
          size="sm"
          className={`${COLLECTION_POLICY_CODE_BADGE[row.collectionPolicy]?.textColor} ms-1`}
        />
      );
    }

    return null;
  };

  return (
    <>
      <TableContainer className="mt-5">
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" />
              <StyledTableCell align="left">Aggregator</StyledTableCell>
              <StyledTableCell align="center" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'id'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'id')}
                >
                  Advance ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'consumerId'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'consumerId')}
                >
                  Consumer ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" className="bo-w-30">
                Total Amount
              </StyledTableCell>
              <StyledTableCell align="center" className="bo-w-30">
                Outstanding Amount
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'createTimestamp'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'createTimestamp')}
                >
                  Created at
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder} className="bo-min-w-240">
                <TableSortLabel
                  active={sortBy === 'repaymentMode'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'repaymentMode')}
                >
                  Repayment Mode/Policy
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'nextRepaymentDate'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'nextRepaymentDate')}
                >
                  Next Repayment Date
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'status'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'status')}
                >
                  Status
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'actualRepaymentTrials'}
                  direction={sortOrder}
                  onClick={() => sortHandler('fundings', 'actualRepaymentTrials', true)}
                >
                  Repayment Trials
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Today&apos;s Repayment Activity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map(row => (
                <TableRow hover tabIndex={-1} key={row.id} className="align-top">
                  <StyledTableCell align="left">
                    {row.flags.length > 0 && <FontAwesomeIcon icon={faFlag} color="red" />}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-nowrap">
                    {row.consumerAggregatorName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/funding/${row.id}`}>{row.id.toString().padStart(6, '0')}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/consumer/${row.consumerId}`}>{row.consumerId}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-nowrap">
                    <Link to={`/consumer/${row.consumerId}`}>
                      {`${row.consumerFirstName} ${row.consumerLastName}`}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    $
                    {Number(row.totalAmount)
                      .toLocaleString()
                      .replace(/\D\d\d$/, '')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    $
                    {Number(row.outstandingAmount)
                      .toLocaleString()
                      .replace(/\D\d\d$/, '')}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-nowrap">
                    {moment.utc(row.createTimestamp).local().format('MMM DD, YYYY H:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {REPAYMENT_MODE_BADGE[row.repaymentMode] && (
                      <Badge
                        color=""
                        className={`bo-inline-badge ${
                          REPAYMENT_MODE_BADGE[row.repaymentMode].color
                        }`}
                      >
                        {REPAYMENT_MODE_BADGE[row.repaymentMode].text}
                      </Badge>
                    )}
                    {row.repaymentMode !== 'MANUAL' && consumersShortStatus && (
                      <>
                        <Badge className={getCollectionPolicyStyles(row)}>
                          {COLLECTION_POLICY_CODE_BADGE[row.collectionPolicy]?.text || 'N/A'}
                        </Badge>
                        {row.repaymentMode !== 'MANUAL' &&
                          consumersShortStatus &&
                          renderBlindIndication(row)}
                      </>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.nextRepaymentDate &&
                      moment.utc(row.nextRepaymentDate).format('MMM DD, YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {STATUS_BADGE[row.status] && (
                      <Badge className="bo-inline-badge" color={STATUS_BADGE[row.status]?.color}>
                        {STATUS_BADGE[row.status]?.text}
                      </Badge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.actualRepaymentTrials}</StyledTableCell>
                  <StyledTableCell align="left">
                    {showTodayRepaymentTrials(row.todayRepaymentTrials)}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={13} align="center">
                  No Data
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => handleChangePage('fundings', e, p)}
        onRowsPerPageChange={e => handleChangeRowsPerPage('fundings', e)}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default FundingsTableContainer;
