import { Col, Label, Input } from 'reactstrap';
import moment from 'moment';
import { memo } from 'react';

function FundingCloseDateForm({ fundingCloseTimestamp }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Close Date:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="closeDate"
          id="closeDate"
          value={
            fundingCloseTimestamp
              ? moment.utc(fundingCloseTimestamp).local().format('MMM DD, YYYY H:mm (UTCZ)')
              : ''
          }
          readOnly
        />
      </Col>
    </>
  );
}

export default memo(FundingCloseDateForm);
