import { useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { faCheckCircle, faPencil, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import { CircularProgress } from '@mui/material';

function NewObjectDefaultTextInput({
  label,
  initialValue,
  decimalScale,
  labelClassName,
  maxLength,
  onSave,
}) {
  const [spinner, setSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleSave = async v => {
    setSpinner(true);
    await onSave(v);
    setValue(v);
    setEditMode(false);
    setSpinner(false);
  };

  const handleCancel = () => {
    setEditMode(!editMode);
    setValue(initialValue);
  };

  return (
    <>
      <Label className={`bo-new-object-input-label ${labelClassName}`}>{label}</Label>
      {editMode ? (
        <div className="bo-new-object-text-input-container">
          <div className="w-100">
            <NumericFormat
              type="text"
              className="bo-new-object-text-input"
              value={value}
              onValueChange={({ floatValue }) => setValue(floatValue)}
              maxLength={maxLength}
              customInput={Input}
              allowNegative={false}
              decimalScale={decimalScale || 0}
              allowLeadingZeros={false}
              readOnly={spinner}
            />
            <div
              className={
                // eslint-disable-next-line no-useless-concat
                'bo-new-object-text-bottom-border ' + 'bo-new-object-text-bottom-border-active'
              }
            />
          </div>
          <div
            className={
              'bo-new-object-input-button-container' +
              ' bo-new-object-input-button-container-active'
            }
          >
            {spinner ? (
              <div className="h-100">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              <>
                <Button
                  className="bo-new-object-input-button-icon"
                  onClick={() => handleSave(value)}
                  disabled={(maxLength && value && String(value).length !== maxLength) || !value}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color="#5946A4" size="1x" />
                </Button>
                <Button className="bo-new-object-input-button-icon" onClick={handleCancel}>
                  <FontAwesomeIcon icon={faTimesCircle} color="#5946A4" size="1x" />
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <Button
            className="bo-new-object-text-input-container"
            onClick={() => setEditMode(true)}
            disabled={spinner}
          >
            <div className="w-100">
              <Input
                className="bo-new-object-text-input"
                type="text"
                readOnly
                value={value === null ? 'Default' : value}
                onChange={e => setValue(e.target.value)}
              />
              <div className="bo-new-object-text-bottom-border" />
            </div>
            <div
              className={`bo-new-object-input-button-container ${value !== null && 'invisible'}`}
            >
              <div className="bo-new-object-input-button-icon">
                <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
              </div>
            </div>
          </Button>
          {value !== null &&
            (spinner ? (
              <div className="h-100">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              <Button
                className="bo-new-object-inline-button text-nowrap ms-1"
                onClick={() => handleSave(null)}
              >
                Reset to default
              </Button>
            ))}
        </div>
      )}
    </>
  );
}

export default NewObjectDefaultTextInput;
