import { TableContainer, Table, TableHead, TableBody, TableRow } from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { useState } from 'react';
import { makeRequest } from '../../../../utils/makeRequest';
import { TRANSFER_RECONCILE_BADGE, TRANSFER_STATUS_BADGE } from '../../../../constants/fundings';
import { Row, Badge, Button, Col } from 'reactstrap';
import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

function FundingDetails({
  fundingId,
  fundingEvents,
  getFunding,
  isConsumer,
  consumerId,
  consumerIsTest,
  setSpinner,
}) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [showTransferEvents, setShowTransferEvents] = useState({});

  const toggleTransferEvent = transferId => {
    setShowTransferEvents(current => {
      if (transferId in current) {
        const copy = { ...current };

        delete copy[transferId];

        return copy;
      }

      return { ...current, [transferId]: true };
    });
  };

  const sendTransferSuccess = async transfer => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/ach_test_webhook`,
      method: 'POST',
      data: JSON.stringify({ uuid: transfer.uuid, code: 'OK', description: '' }),
    };

    try {
      await makeRequest(config);

      toast.success('Transfer Success event sent');
      getFunding(fundingId);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const sendTransferFailure = async transfer => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/ach_test_webhook`,
      method: 'POST',
      data: JSON.stringify({
        uuid: transfer.uuid,
        code: 'ERROR',
        description: 'Test Transfer failue event',
      }),
    };

    try {
      await makeRequest(config);

      toast.success('Transfer failure event sent');
      getFunding(fundingId);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const manualAch = async event => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/manual_transfer`,
        method: 'POST',
        data: JSON.stringify({
          fundingId,
          consumerId,
          amount: event.amount,
          topupId: event.topupId,
          repaymentId: event.repaymentId,
        }),
      };

      try {
        await makeRequest(config);

        toast.success('Manual transfer successfully created!');
        getFunding(fundingId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const getReconcilled = transfer => {
    if (transfer.forceReconcile && transfer.reconTransactionId) {
      return 'YES_FORCE';
    }
    if (transfer.forceReconcile) {
      return 'FORCE';
    }
    if (transfer.reconTransactionId) {
      return 'YES';
    }

    return 'NO';
  };

  const forceTransferRecon = async (transferId, force) => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/force_transfer_reconcile`,
        method: 'POST',
        data: JSON.stringify({ transferId, force }),
      };

      try {
        await makeRequest(config);

        toast.success('Transfer force reconcile updated successfully!');
        getFunding(fundingId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const refresh = async () => {
    setSpinner(true);
    await getFunding(fundingId);
    setSpinner(false);
  };

  return (
    <>
      <Row className="pt-4 mb-4 ms-1">
        <Col sm="8">
          <Button className="bo-button-inline bo-button-gray" onClick={refresh}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="bo-w-220" align="left">
                Timestamp
              </StyledTableCell>
              <StyledTableCell className="bo-w-120" align="left">
                Description
              </StyledTableCell>
              <StyledTableCell className="bo-w-80" align="left">
                Amount
              </StyledTableCell>
              <StyledTableCell align="left">Reason</StyledTableCell>
              <StyledTableCell className="bo-w-600" align="left">
                Transfers
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fundingEvents.map(event => (
              <TableRow hover tabIndex={-1} key={event.timestamp}>
                <StyledTableCell align="left">
                  {moment.utc(event.timestamp).local().format('MMM DD, YYYY H:mm (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">{event.description}</StyledTableCell>
                <StyledTableCell align="right">
                  {event.amount &&
                    `$${Number(event.amount)
                      .toLocaleString()
                      .replace(/\D\d\d$/, '')}`}
                </StyledTableCell>
                <StyledTableCell align="left">{event.reason || ''}</StyledTableCell>
                <StyledTableCell align="left">
                  <table>
                    <tbody>
                      {event.transfers.map(transfer => (
                        <tr key={transfer.id}>
                          <td>
                            <div className="bo-border-container w-100">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="bo-w-150">Transfer ID:</td>
                                    <td className="bo-w-250">{transfer.uuid}</td>
                                    <td className="bo-w-150">
                                      {isConsumer &&
                                        consumerIsTest &&
                                        transfer.status === 'CREATED' && (
                                          <Button
                                            className={
                                              'bo-button-inline-link' +
                                              ' ' +
                                              'bo-inline-badge m-0 p-0'
                                            }
                                            color="link"
                                            onClick={() => sendTransferSuccess(transfer)}
                                          >
                                            Send Transfer success
                                          </Button>
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Timestamp:</td>
                                    <td>
                                      {moment
                                        .utc(transfer.createTimestamp)
                                        .local()
                                        .format('MMM DD, YYYY H:mm (UTCZ)')}
                                    </td>
                                    <td>
                                      {isConsumer &&
                                        consumerIsTest &&
                                        transfer.status === 'CREATED' && (
                                          <Button
                                            className={
                                              'bo-button-inline-link' +
                                              ' ' +
                                              'bo-inline-badge m-0 p-0'
                                            }
                                            color="link"
                                            onClick={() => sendTransferFailure(transfer)}
                                          >
                                            Send Transfer failure
                                          </Button>
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Provider:</td>
                                    <td>{transfer.provider}</td>
                                  </tr>
                                  <tr>
                                    <td>Type:</td>
                                    <td>{transfer.type}</td>
                                  </tr>
                                  <tr>
                                    <td>Status:</td>
                                    <td>
                                      {TRANSFER_STATUS_BADGE[transfer.status] && (
                                        <Badge
                                          className="bo-badge"
                                          color={TRANSFER_STATUS_BADGE[transfer.status]?.color}
                                        >
                                          {TRANSFER_STATUS_BADGE[transfer.status]?.text}
                                        </Badge>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Fee:</td>
                                    <td>{transfer.fee || 'No Fee'}</td>
                                  </tr>
                                  <tr>
                                    <td>Failure Reason:</td>
                                    <td>{transfer.failureReason}</td>
                                  </tr>
                                  <tr>
                                    <td>Reconcilled:</td>
                                    <td>
                                      <Badge
                                        className="bo-badge"
                                        color={
                                          TRANSFER_RECONCILE_BADGE[getReconcilled(transfer)]?.color
                                        }
                                      >
                                        {TRANSFER_RECONCILE_BADGE[getReconcilled(transfer)]?.text}
                                      </Badge>
                                    </td>
                                    <td>
                                      {transfer.forceReconcile ? (
                                        <Button
                                          className="bo-button-inline-link bo-inline-badge m-0 p-0"
                                          color="link"
                                          onClick={() => forceTransferRecon(transfer.id, false)}
                                        >
                                          Cancel Force
                                        </Button>
                                      ) : (
                                        <Button
                                          className="bo-button-inline-link bo-inline-badge m-0 p-0"
                                          color="link"
                                          onClick={() => forceTransferRecon(transfer.id, true)}
                                        >
                                          Force Reconcile
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <Row className="ms-0 mt-1">
                                <Button
                                  className={
                                    'text-start bo-button-inline-link' +
                                    ' ' +
                                    'bo-inline-badge m-0 p-0'
                                  }
                                  color="link"
                                  onClick={() => toggleTransferEvent(transfer.id)}
                                >
                                  {transfer.id in showTransferEvents
                                    ? 'Hide events'
                                    : 'Show events'}
                                </Button>
                              </Row>
                              {transfer.id in showTransferEvents && (
                                <Row className="ms-1 mt-1">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th className="bo-w-200">Timestamp</th>
                                        <th>Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {transfer.events.map(tEvent => (
                                        <tr key={tEvent.id}>
                                          <td>
                                            {moment
                                              .utc(tEvent.timestamp)
                                              .local()
                                              .format('MMM DD, YYYY H:mm (UTCZ)')}
                                          </td>
                                          <td>{tEvent.description}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </Row>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {((event.transfers.length > 0 &&
                    event.transfers[event.transfers.length - 1].status === 'FAILED') ||
                    (event.description === 'Repayment' && event.transfers.length === 0)) && (
                    <Button
                      className="bo-button-inline-link bo-inline-badge m-0 p-0"
                      color="link"
                      onClick={() => manualAch(event)}
                    >
                      Manual ACH
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default memo(FundingDetails);
