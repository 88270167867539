import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import TableSortLabel from '@mui/material/TableSortLabel';
import StyledTableCell from '../../../components/StyledTableCell';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { AGGREGATOR_TYPE_BADGE } from '../../../constants/aggregators';

function AggregatorsTableContainer({
  sortOrder,
  sortBy,
  sortHandler,
  rows,
  consumersCount,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <>
      <TableContainer className="mt-5">
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'id'}
                  direction={sortOrder}
                  onClick={() => sortHandler('aggregators', 'id')}
                >
                  Aggregator ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => sortHandler('aggregators', 'name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'type'}
                  direction={sortOrder}
                  onClick={() => sortHandler('aggregators', 'type')}
                >
                  Type
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Prospects</StyledTableCell>
              <StyledTableCell align="left">Consumers</StyledTableCell>
              <StyledTableCell align="left" sortDirection={sortOrder}>
                <TableSortLabel
                  active={sortBy === 'createDate'}
                  direction={sortOrder}
                  onClick={() => sortHandler('aggregators', 'createDate')}
                >
                  CreateDate
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map(row => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Link to={`/aggregator/${row.id}`}>{row.name}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {AGGREGATOR_TYPE_BADGE[row.type] && (
                      <Badge
                        color=""
                        className={`bo-inline-badge ${AGGREGATOR_TYPE_BADGE[row.type]?.color}`}
                      >
                        {AGGREGATOR_TYPE_BADGE[row.type]?.text}
                      </Badge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.prospects}</StyledTableCell>
                  <StyledTableCell align="left">
                    {consumersCount[row.id] != null ? consumersCount[row.id] : 'Loading...'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createDate
                      ? moment.utc(row.createDate).local().format('MMM DD, YYYY H:mm (UTCZ)')
                      : 'N/A'}
                  </StyledTableCell>
                  <StyledTableCell align="left" />
                </TableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={7} align="center">
                  No Data
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => handleChangePage('aggregators', e, p)}
        onRowsPerPageChange={e => handleChangeRowsPerPage('aggregators', e)}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default AggregatorsTableContainer;
