import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function LanguageForm({ consumerLanguage, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Label>Language:</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-filter"
          type="select"
          name="language"
          id="language"
          noValidate
          value={consumerLanguage}
          onChange={changeValue}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
        </Input>
      </Col>
    </>
  );
}

export default memo(LanguageForm);
