import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useNewObjectDimensions } from '../../../hooks/useNewObjectDimensions';
// panels
import NewObjectDetailsPanel from './details/NewObjectDetailsPanel';
import NewObjectProfileActivityPanel from './profile-activity/NewObjectProfileActivityPanel';
import NewObjectBankPanel from './bank/NewObjectBankPanel';

function NewObjectLayout({
  loading,
  setJobId,
  jobStatus,
  refreshConsumer,
  consumer,
  setConsumer,
  getConsumer,
  aggregators,
  bridgePointsBalance,
  openFunding,
  closedFundings,
  profileJson,
  getProfileJson,
  mobileDevice,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const {
    height,
    parentPanelGroupRef,
    parentGroupCollapsedSize,
    parentGroupMinSizes,
    parentGroupDefaultSizes,
    collapsedPanels,
    setCollapsedPanels,
    expandParentPanel,
    collapsedMode,
    detailsRef,
    bankRef,
    profileRef,
    activityRef,
    childGroupCollapsedSize,
    childGroupMinSizes,
    childGroupDefaultSizes,
    expandChildPanel,
  } = useNewObjectDimensions();

  return (
    <div className="h-100">
      <PanelGroup direction="horizontal" ref={parentPanelGroupRef}>
        <Panel
          ref={detailsRef}
          collapsible
          collapsedSize={parentGroupCollapsedSize}
          minSize={parentGroupMinSizes[0]}
          defaultSize={parentGroupDefaultSizes[0]}
          onCollapse={() => setCollapsedPanels(prev => ({ ...prev, details: true }))}
          onExpand={() => setCollapsedPanels(prev => ({ ...prev, details: false }))}
          style={{ height, overflowY: 'auto' }}
        >
          <NewObjectDetailsPanel
            collapsedPanels={collapsedPanels}
            expandParentPanel={expandParentPanel}
            loading={loading}
            setJobId={setJobId}
            jobStatus={jobStatus}
            refreshConsumer={refreshConsumer}
            consumer={consumer}
            setConsumer={setConsumer}
            aggregators={aggregators}
            bridgePointsBalance={bridgePointsBalance}
            openFunding={openFunding}
            closedFundings={closedFundings}
            profileJson={profileJson}
            mobileDevice={mobileDevice}
            panelDataRefresh={panelDataRefresh}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </Panel>
        <PanelResizeHandle
          className="bo-new-object-resize-handle-vertical"
          disabled={collapsedMode}
        />
        <Panel
          collapsible
          collapsedSize={parentGroupCollapsedSize}
          minSize={parentGroupMinSizes[1]}
          defaultSize={parentGroupDefaultSizes[1]}
          onCollapse={() => setCollapsedPanels(prev => ({ ...prev, profile_activity: true }))}
          onExpand={() => setCollapsedPanels(prev => ({ ...prev, profile_activity: false }))}
          style={{ height }}
        >
          <NewObjectProfileActivityPanel
            collapsedMode={collapsedMode}
            collapsedPanels={collapsedPanels}
            setCollapsedPanels={setCollapsedPanels}
            expandParentPanel={expandParentPanel}
            expandChildPanel={expandChildPanel}
            detailsRef={detailsRef}
            bankRef={bankRef}
            profileRef={profileRef}
            activityRef={activityRef}
            childGroupCollapsedSize={childGroupCollapsedSize}
            childGroupMinSizes={childGroupMinSizes}
            childGroupDefaultSizes={childGroupDefaultSizes}
            consumer={consumer}
            getConsumer={getConsumer}
            profileJson={profileJson}
            getProfileJson={getProfileJson}
            panelDataRefresh={panelDataRefresh}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </Panel>
        <PanelResizeHandle
          className="bo-new-object-resize-handle-vertical"
          disabled={collapsedMode}
        />
        <Panel
          ref={bankRef}
          collapsible
          collapsedSize={parentGroupCollapsedSize}
          minSize={parentGroupMinSizes[2]}
          defaultSize={parentGroupDefaultSizes[2]}
          onCollapse={() => setCollapsedPanels(prev => ({ ...prev, bank: true }))}
          onExpand={() => setCollapsedPanels(prev => ({ ...prev, bank: false }))}
          style={{ height, overflowY: 'auto' }}
        >
          <NewObjectBankPanel
            collapsedPanels={collapsedPanels}
            expandParentPanel={expandParentPanel}
            consumer={consumer}
            setConsumer={setConsumer}
            getConsumer={getConsumer}
            refreshConsumer={refreshConsumer}
            loading={loading}
            profileJson={profileJson}
            panelDataRefresh={panelDataRefresh}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default NewObjectLayout;
