import { CircularProgress } from '@mui/material';
import { useClockContext } from '../context/clock-context';

function Clock() {
  const { mainTime, consumerTime, consumerTimeLoading, timeToCutOff } = useClockContext();

  return (
    <>
      <div className="nav-clock-separator me-3" />
      <div className="bo-min-w-220 d-flex flex-column justify-content-start">
        <div className="nav-main-clock mb-1">
          <p className="bo-text-md p-0 m-0">{mainTime}</p>
        </div>
        <p className="bo-text-md p-0 m-0 mb-1">
          {timeToCutOff ? `${timeToCutOff} to next ACH cut-off` : 'Loading...'}
        </p>
        <div className="d-flex gap-1">
          <p className="bo-text-md p-0 m-0">Consumer time is:</p>
          <div className="nav-consumer-clock">
            {consumerTimeLoading ? (
              <CircularProgress color="inherit" size={14} />
            ) : (
              <p className="bo-text-sm-bold p-0 m-0">{consumerTime}</p>
            )}
          </div>
        </div>
      </div>
      <div className="nav-clock-separator ms-3" />
    </>
  );
}

export default Clock;
