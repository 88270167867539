import { toast } from 'react-toastify';
import { Row, Col, Input, Label, Button } from 'reactstrap';

function ProspectSinupUrl({ signupUrl }) {
  const copySignupUrl = () => {
    navigator.clipboard.writeText(signupUrl);
    toast.success('URL copied to clipboard');
  };

  return (
    <Row className="mt-5 mb-2 align-items-center">
      <Col md={2} className="bo-same-line">
        <Label>Signup URL:</Label>
      </Col>
      <Col md={9} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="signupUrl"
          id="signupUrl"
          placeholder="signupUrl"
          value={signupUrl || 'Save to generate...'}
          disabled
        />
      </Col>
      <Col md={1} className="bo-same-line">
        <Button className="ms-0 ps-0 bo-button-inline-link" color="link" onClick={copySignupUrl}>
          Copy
        </Button>
      </Col>
    </Row>
  );
}

export default ProspectSinupUrl;
