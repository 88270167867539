import { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { Row, Col, Button, Input } from 'reactstrap';
import { modalStyle } from '../../../../utils/modal';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeUploadRequest } from '../../../../utils/makeRequest';

function RulesUploadModal({ isOpen, close, getRules }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [file, setFile] = useState(null);
  const [spinnerModal, setSpinnerModal] = useState(false);

  const handleFileChange = e => {
    if (e.target.files) {
      const targetFile = e.target.files[0];

      if (targetFile.type !== 'application/json') {
        setFile('error');
      } else {
        setFile(targetFile);
      }
    }
  };

  const handleUploadClick = async () => {
    if (!file || file === 'error') {
      return;
    }

    const formData = new FormData();

    formData.append('file', file);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinnerModal(true);
    try {
      await makeUploadRequest(`${apiOriginMonitor}/upload_rules`, formData, token);

      toast.success('File uploaded successfully');
      close();
      getRules();
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message.toString());
    } finally {
      setSpinnerModal(false);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle()}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinnerModal} text="">
        <h5>Upload rules file:</h5>
        <Row className="mt-4">
          <Col>
            <Input className="bo-file-chooser" type="file" onChange={handleFileChange} />
          </Col>
        </Row>
        {file === 'error' ? (
          <Row className="mt-3">
            <Col>
              <div className="bo-inline-error-large">
                <strong>Only json files are allowed!</strong>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="mt-3">
              <Col>
                <div>
                  File name: <strong>{file && `${file.name}`}</strong>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  File type: <strong>{file && `${file.type}`}</strong>
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col className="text-center">
            <Button
              className="mt-5 bo-button"
              disabled={!file || file === 'error'}
              onClick={handleUploadClick}
            >
              Upload
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={close}>
              Close
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default RulesUploadModal;
