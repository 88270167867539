import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { areEqual, VariableSizeGrid } from 'react-window';

const SCROLLBAR_SIZE = 10;

const HeaderCell = memo(({ data, columnIndex, rowIndex, style }) => {
  const {
    headerData,
    cellPaddingLeftSize = 3,
    headerTopBorder,
    headerLeftBorder,
    extendLastColumn,
    addWidthToLastColumn,
  } = data;

  const cellData = headerData[rowIndex][columnIndex];

  return (
    <div
      key={columnIndex}
      style={{
        ...style,
        width:
          headerData[rowIndex].length - 1 === columnIndex && addWidthToLastColumn
            ? style.width + SCROLLBAR_SIZE
            : style.width,
      }}
      className={classNames(
        `bo-table-cell fw-bold ps-${cellPaddingLeftSize} pe-1 overflow-hidden border-bottom`,
        headerLeftBorder &&
          columnIndex !== 0 &&
          cellData &&
          !cellData.isPlaceholder &&
          'border-start',
        headerTopBorder && 'border-top',
        !extendLastColumn && headerData[rowIndex].length - 1 === columnIndex && 'border-end',
        cellData && cellData.className,
      )}
    >
      {cellData && !cellData.isPlaceholder ? cellData.header : ''}
    </div>
  );
}, areEqual);

const Cell = memo(
  ({
    data: {
      tableRows,
      tableColumns,
      cellPaddingLeftSize = 3,
      rowClickable,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
      addHeightToLastRow,
    },
    columnIndex,
    rowIndex,
    style,
  }) => {
    let newStyle = style;

    const { renderValue, key } = tableColumns[columnIndex];

    const value = tableRows[rowIndex][key];

    let className = `bo-table-cell ps-${cellPaddingLeftSize} pe-1 overflow-hidden`;

    className +=
      hoovering && hoveredRowIndex === rowIndex
        ? ` ${customHoverClass || 'bo-table-hover-bg'}`
        : '';
    className += activeRowIndex === rowIndex ? ' bo-table-active-bg' : '';

    if (formatCellConditionally) {
      newStyle = {
        ...newStyle,
        ...formatCellConditionally(tableRows[rowIndex], tableColumns[columnIndex].key, rowIndex),
      };
    }

    if (addHeightToLastRow && rowIndex === tableRows.length - 1) {
      newStyle = {
        ...newStyle,
        height: newStyle.height + SCROLLBAR_SIZE,
        paddingBottom: SCROLLBAR_SIZE,
      };
    }

    return (
      /* eslint-disable */
      <div
        key={columnIndex}
        style={newStyle}
        onClick={
          rowClickable
            ? () => setActiveRowIndex(prevIndex => (prevIndex === rowIndex ? null : rowIndex))
            : undefined
        }
        onMouseOver={hoovering ? () => setHoveredRowIndex(rowIndex) : undefined}
        onContextMenu={e => {
          if (handleRowRightClick) {
            handleRowRightClick(e, tableRows[rowIndex]);
          }
        }}
        className={className}
      >
        {renderValue ? renderValue(value, tableRows[rowIndex]) : value}
      </div>
      /* eslint-enable */
    );
  },
  areEqual,
);

function VirtualizedTable({
  tableRows,
  frozenColumns = [],
  frozenHeaderConfig,
  tableColumns,
  headerConfig,
  width,
  height,
  rowKey,
  rowHeight = 36,
  longMultilineColumnKey,
  previewColumn,
  getRowHeight,
  headerHeight = 36,
  headerTopBorder,
  headerLeftBorder,
  tableContainerStyle,
  cellPaddingLeftSize,
  extendLastColumn = true,
  overscanColumnCount = 5,
  overscanRowCount = 10,
  hoovering = true,
  customHoverClass,
  formatCellConditionally,
  handleRowRightClick,
}) {
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);
  const frozenGridRef = useRef(null);

  const contentHeight = useMemo(
    () =>
      getRowHeight
        ? tableRows.reduce((acc, _, idx) => getRowHeight(idx) + acc, 0)
        : tableRows.length * rowHeight,
    [tableRows, getRowHeight, rowHeight],
  );
  const contentWidth = useMemo(
    () => tableColumns.reduce((acc, value) => acc + value.width, 0),
    [tableColumns],
  );

  const frozenContentWidth = useMemo(
    () => frozenColumns.reduce((acc, value) => acc + value.width, 0),
    [frozenColumns],
  );

  const headerRowCount = headerConfig ? headerConfig.length : 1;

  const isVerticalScrollVisable = contentHeight + headerHeight * headerRowCount > height;
  const isHorizontalScrollVisable = contentWidth + frozenContentWidth > width;

  const getHeaderData = useCallback((cols, config) => {
    if (!config) {
      return [
        cols.map(col => ({
          header: col.header,
          className: col.className,
        })),
      ];
    }

    const data = [];

    for (let rowIndex = 0; rowIndex < config.length; rowIndex += 1) {
      const rowConfig = config[rowIndex];
      const rowData = [];

      let colIndex = 0;

      rowConfig.forEach(cellConfig => {
        const { header, className, colspan = 1 } = cellConfig;

        rowData[colIndex] = {
          header,
          className,
        };

        for (let i = 1; i < colspan; i += 1) {
          colIndex += 1;
          rowData[colIndex] = {
            header: '',
            className,
            isPlaceholder: true,
          };
        }

        colIndex += 1;
      });

      data.push(rowData);
    }

    return data;
  }, []);

  const headerGridData = useMemo(
    () => ({
      headerData: getHeaderData(tableColumns, headerConfig),
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      extendLastColumn,
      addWidthToLastColumn: isVerticalScrollVisable,
    }),
    [
      getHeaderData,
      headerConfig,
      tableColumns,
      extendLastColumn,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      isVerticalScrollVisable,
    ],
  );

  const frozenHeaderGridData = useMemo(
    () => ({
      headerData: getHeaderData(frozenColumns, frozenHeaderConfig),
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      extendLastColumn,
    }),
    [
      getHeaderData,
      frozenColumns,
      frozenHeaderConfig,
      extendLastColumn,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
    ],
  );

  const mainGridData = useMemo(
    () => ({
      tableRows,
      tableColumns,
      cellPaddingLeftSize,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
    }),
    [
      tableRows,
      tableColumns,
      cellPaddingLeftSize,
      previewColumn,
      activeRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
    ],
  );

  const frozenGridData = useMemo(
    () => ({
      tableRows,
      tableColumns: frozenColumns,
      cellPaddingLeftSize,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
      addHeightToLastRow: isHorizontalScrollVisable && isVerticalScrollVisable,
    }),
    [
      tableRows,
      frozenColumns,
      cellPaddingLeftSize,
      previewColumn,
      activeRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
      isHorizontalScrollVisable,
      isVerticalScrollVisable,
    ],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: grid } = mainGridRef;
        const { current: header } = headerGridRef;
        const { current: gridDiv } = mainGridContainerRef;
        const { current: frozen } = frozenGridRef;

        const hiddenContainer = gridDiv.firstElementChild;

        const maxVScroll = hiddenContainer.clientHeight - gridDiv.clientHeight;
        const maxHScroll = hiddenContainer.clientWidth - gridDiv.clientWidth;

        if (gridDiv && grid) {
          let { scrollLeft, scrollTop } = gridDiv;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          if (header) {
            header.scrollTo({ scrollLeft });
          }

          if (frozen) {
            frozen.scrollTo({ scrollTop });
          }

          grid.scrollTo({ scrollLeft, scrollTop });
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  const onScroll = useCallback(({ scrollLeft, scrollUpdateWasRequested }) => {
    if (!scrollUpdateWasRequested && headerGridRef.current) {
      headerGridRef.current.scrollTo({ scrollLeft, scrollTop: 0 });
    }
  }, []);

  useEffect(() => {
    if (longMultilineColumnKey) {
      const idx = tableColumns.findIndex(x => x.key === longMultilineColumnKey);

      headerGridRef.current?.resetAfterColumnIndex(idx || 0);
      mainGridRef.current?.resetAfterColumnIndex(idx || 0);
    } else {
      headerGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
      mainGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
    }
  }, [width, height, tableColumns, activeRowIndex, longMultilineColumnKey]);

  useEffect(() => {
    mainGridRef.current?.resetAfterRowIndex(0);
    frozenGridRef.current?.resetAfterRowIndex(0);
  }, [tableRows]);

  const getColumnWidth = useCallback(
    index => {
      if (
        width >
        (activeRowIndex !== null && !!previewColumn
          ? contentWidth + previewColumn.width
          : contentWidth)
      ) {
        if (
          longMultilineColumnKey &&
          index === tableColumns.findIndex(x => x.key === longMultilineColumnKey)
        ) {
          return (
            width -
            (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
            contentWidth -
            (isVerticalScrollVisable ? SCROLLBAR_SIZE : 0) +
            tableColumns.find(x => x.key === longMultilineColumnKey).width
          );
        }

        if (!longMultilineColumnKey && extendLastColumn && index === tableColumns.length - 1) {
          return (
            width -
            (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
            contentWidth -
            (isVerticalScrollVisable ? SCROLLBAR_SIZE : 0) +
            tableColumns[tableColumns.length - 1].width
          );
        }
      }

      return tableColumns[index].width;
    },
    [
      width,
      extendLastColumn,
      tableColumns,
      activeRowIndex,
      previewColumn,
      contentWidth,
      isVerticalScrollVisable,
      longMultilineColumnKey,
    ],
  );

  const estimatedColumnWidth = useMemo(
    () => Math.round(contentWidth / Object.values(tableColumns).length),
    [contentWidth, tableColumns],
  );

  const estimatedRowHeight = useMemo(
    () => Math.round(contentHeight / tableRows.length),
    [contentHeight, tableRows],
  );

  const mainGridWidth = useMemo(() => {
    if (!extendLastColumn && contentWidth + frozenContentWidth + SCROLLBAR_SIZE < width) {
      return contentWidth + SCROLLBAR_SIZE;
    }

    let w = width;

    if (activeRowIndex !== null && !!previewColumn) {
      w -= previewColumn.width;
    }

    if (frozenColumns.length > 0) {
      w -= frozenContentWidth;
    }

    return w;
  }, [
    extendLastColumn,
    width,
    contentWidth,
    activeRowIndex,
    previewColumn,
    frozenColumns,
    frozenContentWidth,
  ]);

  return (
    <div className="d-flex">
      <div ref={outerContainerRef} onMouseLeave={() => setHoveredRowIndex(null)}>
        <div className="d-flex">
          {frozenColumns.length > 0 && (
            <VariableSizeGrid
              itemData={frozenHeaderGridData}
              className="bo-table-header-container"
              columnCount={frozenColumns.length}
              columnWidth={index => frozenColumns[index].width}
              height={headerHeight * headerRowCount}
              rowCount={headerRowCount}
              rowHeight={() => headerHeight}
              width={frozenContentWidth}
            >
              {HeaderCell}
            </VariableSizeGrid>
          )}
          <VariableSizeGrid
            itemData={headerGridData}
            ref={headerGridRef}
            className="bo-table-header-container"
            columnCount={tableColumns.length}
            columnWidth={index => getColumnWidth(index)}
            height={headerHeight * headerRowCount}
            rowCount={headerRowCount}
            rowHeight={() => headerHeight}
            width={mainGridWidth}
            estimatedColumnWidth={estimatedColumnWidth}
            estimatedRowHeight={headerHeight}
            overscanColumnCount={overscanColumnCount}
          >
            {HeaderCell}
          </VariableSizeGrid>
        </div>
        <div className="d-flex">
          {frozenColumns.length > 0 && (
            <VariableSizeGrid
              itemKey={({ columnIndex, data, rowIndex }) =>
                `${data.tableRows[rowIndex][rowKey]}-${columnIndex}`
              }
              className="bo-table-group-container"
              style={tableContainerStyle}
              ref={frozenGridRef}
              itemData={frozenGridData}
              columnCount={frozenColumns.length}
              columnWidth={index => frozenColumns[index].width}
              height={height - headerHeight * headerRowCount}
              rowCount={tableRows.length}
              rowHeight={getRowHeight || (() => rowHeight)}
              width={frozenContentWidth}
              estimatedRowHeight={estimatedRowHeight}
              overscanRowCount={overscanRowCount}
            >
              {Cell}
            </VariableSizeGrid>
          )}
          <VariableSizeGrid
            itemKey={({ columnIndex, data, rowIndex }) =>
              `${data.tableRows[rowIndex][rowKey]}-${columnIndex}`
            }
            className="bo-table-container"
            style={tableContainerStyle}
            ref={mainGridRef}
            outerRef={mainGridContainerRef}
            itemData={mainGridData}
            onScroll={onScroll}
            columnCount={tableColumns.length}
            columnWidth={index => getColumnWidth(index)}
            height={height - headerHeight * headerRowCount}
            rowCount={tableRows.length}
            rowHeight={getRowHeight || (() => rowHeight)}
            width={mainGridWidth}
            estimatedColumnWidth={estimatedColumnWidth}
            estimatedRowHeight={estimatedRowHeight}
            overscanColumnCount={overscanColumnCount}
            overscanRowCount={overscanRowCount}
          >
            {Cell}
          </VariableSizeGrid>
        </div>
      </div>
      {activeRowIndex !== null && !!previewColumn && (
        <div style={{ width: previewColumn.width, height }}>
          <div
            style={{
              width: previewColumn.width,
              height: headerHeight,
            }}
            className={`bo-table-cell fw-bold px-1 overflow-hidden border-bottom border-start ${
              headerTopBorder ? 'border-top' : ''
            }`}
          >
            {previewColumn.header}
          </div>
          <div
            style={{ width: previewColumn.width, height: height - headerHeight }}
            // eslint-disable-next-line max-len
            className="bo-table-cell bo-table-container align-items-start overflow-auto border-start"
          >
            {previewColumn.renderValue
              ? previewColumn.renderValue(
                  tableRows[activeRowIndex][previewColumn.key],
                  tableRows[activeRowIndex],
                  height - headerHeight,
                )
              : tableRows[activeRowIndex][previewColumn.key]}
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualizedTable;
