import { Form, FormGroup } from 'reactstrap';
import ProfileRoleForm from './forms/ProfileRoleForm';
import ProfileEmailForm from './forms/ProfileEmailForm';
import ProfileFullNameForm from './forms/ProfileFullNameForm';
import ProfilePermissionsForm from './forms/ProfilePermissionsForm';
import ProfileChangePasswordForm from './forms/ProfileChangePasswordForm';

function ProfileFormController({ setSpinner }) {
  return (
    <Form className="form">
      <FormGroup className="d-flex flex-column">
        <ProfileFullNameForm setSpinner={setSpinner} />
        <ProfileEmailForm />
        <ProfileRoleForm />
        <ProfilePermissionsForm setSpinner={setSpinner} />
        <ProfileChangePasswordForm setSpinner={setSpinner} />
      </FormGroup>
    </Form>
  );
}

export default ProfileFormController;
