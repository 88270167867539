import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../components/Spinner';
import { modalStyle } from '../../../../utils/modal';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';

const AMOUNT_RANGE_MIN_VALUE = 10;

function FundingPaymentLinkModal({ isOpen, close, funding, getFunding }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [paymentAmount, setPaymentAmount] = useState(funding.outstandingAmount);
  const [paymentByAdmin, setPaymentByAdmin] = useState(false);
  const [allowAmountChange, setAllowAmountChange] = useState(false);
  const [minimalAmount, setMinimalAmount] = useState(null);

  const [spinner, setSpinner] = useState(false);

  const closeModal = () => {
    close();
    setPaymentAmount(funding.outstandingAmount);
    setPaymentByAdmin(false);
    setAllowAmountChange(false);
    setMinimalAmount(null);
  };

  ReactModal.setAppElement(document.getElementById('root'));

  const createLink = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/ad_hoc_payment/create_link`,
      method: 'POST',
      data: JSON.stringify({
        fundingId: funding.id,
        amount: parseInt(paymentAmount, 10),
        paymentByAdmin,
        allowAmountChange,
        minimalAmount: minimalAmount ? parseInt(minimalAmount, 10) : null,
      }),
    };

    try {
      await makeRequest(config);
      await getFunding(funding.id);

      toast.success('Link created successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreate = async () => {
    if (!paymentAmount) {
      toast.error('Payment amount is required');

      return;
    }

    if (
      Number(paymentAmount) < AMOUNT_RANGE_MIN_VALUE ||
      Number(paymentAmount) > Number(funding.outstandingAmount)
    ) {
      toast.error(
        `Payment amount is too ${
          paymentAmount > funding.outstandingAmount ? 'high' : 'low'
        }, it should be in the range of $${AMOUNT_RANGE_MIN_VALUE}
         to $${funding.outstandingAmount}`,
      );

      return;
    }

    if (
      allowAmountChange &&
      (Number(minimalAmount) > Number(paymentAmount) ||
        Number(minimalAmount) < AMOUNT_RANGE_MIN_VALUE)
    ) {
      toast.error(
        `Minimal amount is too ${
          Number(minimalAmount) > Number(paymentAmount) ? 'high' : 'low'
        }, it should be in the range of $${AMOUNT_RANGE_MIN_VALUE}
        to $${paymentAmount}`,
      );

      return;
    }

    setSpinner(true);
    await createLink();
    setSpinner(false);
    closeModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('460px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner visible={spinner} text="">
        <h5>Create New Link</h5>
        <Row className="mt-4 mb-2 align-items-center">
          <Col md={6} className="bo-same-line">
            <Label>Payment Amount:</Label>
          </Col>
          <Col md={6} className="bo-same-line">
            <Input
              className="bo-input"
              min={AMOUNT_RANGE_MIN_VALUE}
              max={funding.outstandingAmount}
              type="number"
              name="paymentAmount"
              id="paymentAmount"
              value={paymentAmount}
              onChange={e => setPaymentAmount(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-2 align-items-center">
          <Col md={6} className="bo-same-line">
            <Label className="p-0 m-0">Payment By admin on behalf of consumer:</Label>
          </Col>
          <Col md={6} className="bo-same-line">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              name="paymentByAdmin"
              id="paymentByAdmin"
              checked={paymentByAdmin}
              onChange={e => setPaymentByAdmin(e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-2 align-items-center">
          <Col md={6} className="bo-same-line">
            <Label className="p-0 m-0">User can change amount:</Label>
          </Col>
          <Col md={6} className="bo-same-line">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              name="allowAmountChange"
              id="allowAmountChange"
              checked={allowAmountChange}
              onChange={e => setAllowAmountChange(e.target.checked)}
            />
          </Col>
        </Row>
        {allowAmountChange && (
          <Row className="mt-2 mb-2 align-items-center">
            <Col md={6} className="bo-same-line">
              <Label className="p-0 m-0">Minimal Amount:</Label>
            </Col>
            <Col md={6} className="bo-same-line">
              <Input
                className="bo-input"
                min={AMOUNT_RANGE_MIN_VALUE}
                max={paymentAmount}
                type="number"
                name="minimalAmount"
                id="minimalAmount"
                value={minimalAmount}
                onChange={e => setMinimalAmount(e.target.value)}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-center">
            <Button className="mt-5 bo-button" onClick={handleCreate}>
              Create
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={closeModal}>
              Close
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default FundingPaymentLinkModal;
