import { useState } from 'react';
import { Row, Col, Input, Button, Label } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { modalStyle } from '../../../../utils/modal';

function ConsumerNewAlertModal({ consumer, isOpen, close, getConsumer }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [alertType, setAlertType] = useState('');
  const [alertData, setAlertData] = useState({});
  const [spinner, setSpinner] = useState(false);

  ReactModal.setAppElement(document.getElementById('root'));

  const createNewAlert = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/alerts/add`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, type: alertType, data: alertData }),
    };

    try {
      await makeRequest(config);
      await getConsumer(consumer.id);

      setAlertType('');
      setAlertData({});

      toast.success('New alert has been created');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
      close();
    }
  };

  const handleNewAlert = async () => {
    if (!alertType) {
      toast.error('Alert type is required');

      return;
    }

    if (
      !alertData.threshold &&
      (alertType === 'AVAILABLE_BALANCE' ||
        alertType === 'CURRENT_BALANCE' ||
        alertType === 'ALL_BALANCES')
    ) {
      toast.error('Threshold is required');

      return;
    }

    await createNewAlert();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('440px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinner} text="">
        <h5>New Alert</h5>
        <Row className="mb-2 mt-3 align-items-center">
          <Col md={3} className="bo-same-line">
            <Label>Type:</Label>
          </Col>
          <Col md={4} className="bo-same-line">
            <Input
              className="bo-filter bo-w-200"
              type="select"
              name="alertType"
              id="alertType"
              noValidate
              value={alertType}
              onChange={e => setAlertType(e.target.value)}
            >
              <option value="">Choose type</option>
              <option value="AVAILABLE_BALANCE">Available Balance</option>
              <option value="CURRENT_BALANCE">Current Balance</option>
              <option value="ALL_BALANCES">All Balances</option>
            </Input>
          </Col>
        </Row>
        {(alertType === 'AVAILABLE_BALANCE' ||
          alertType === 'CURRENT_BALANCE' ||
          alertType === 'ALL_BALANCES') && (
          <Row className="mb-2 mt-3 align-items-center">
            <Col md={3} className="bo-same-line">
              <Label>Threshold:</Label>
            </Col>
            <Col md={6} className="bo-same-line">
              <Input
                className="bo-input"
                type="number"
                name="alertData"
                id="alertData"
                value={alertData.threshold}
                onChange={e =>
                  setAlertData({
                    ...alertData,
                    threshold: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-center mt-3">
            <Button className="bo-button" onClick={handleNewAlert}>
              Save
            </Button>
            <Button className="ms-3 bo-button" onClick={close}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default ConsumerNewAlertModal;
