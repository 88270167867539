import { Input } from 'reactstrap';
import { useEffect, useState } from 'react';

function ConsumersSearchForm({ search, searchKey, spinner }) {
  const [value, setValue] = useState(search);

  useEffect(() => {
    setValue(search);
  }, [search]);

  return (
    <Input
      className="bo-search"
      type="search"
      name="search"
      id="search"
      placeholder="Search"
      noValidate
      disabled={spinner}
      value={value}
      onChange={e => setValue(e.target.value)}
      onKeyPress={e => searchKey('consumers', e)}
    />
  );
}

export default ConsumersSearchForm;
