import { memo } from 'react';
import { Col, Input } from 'reactstrap';

function ContactPhoneForm({ aggregatorContactPhone, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line" />
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="contactPhone"
          id="contactPhone"
          placeholder="Phone number"
          value={aggregatorContactPhone || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(ContactPhoneForm);
