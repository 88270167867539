import { memo, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import DefaultTransferSettings from '../../../components/DefaultTransferSettings';
import DefaultSettingsInput from '../../../components/DefaultSettingsInput';

function ConsumerSettings({
  consumerDefaultFreeCreditTransfers,
  consumerDefaultFreeDebitTransfers,
  consumerDefaultTransferFee,
  consumerBpMonthlyQuota,
  consumerBpCap,
  consumerAdvanceLimit,
  consumerMinPayrollCycles,
  consumerMinPayrollWeeks,
  consumerMinAccountDays,
  changeValue,
}) {
  const [showSettings, setShowSettings] = useState(false);

  const onButtonClick = (fieldName, value) =>
    changeValue({ target: { name: fieldName, value: value ? null : '0' } });

  return (
    <>
      <Button
        className="bo-button-inline-link p-0 mt-3"
        color="link"
        onClick={() => setShowSettings(!showSettings)}
      >
        {showSettings ? 'Hide Settings' : 'Show Settings'}
      </Button>
      {showSettings && (
        <>
          <DefaultTransferSettings
            defaultFreeCreditTransfers={consumerDefaultFreeCreditTransfers}
            defaultFreeDebitTransfers={consumerDefaultFreeDebitTransfers}
            defaultTransferFee={consumerDefaultTransferFee}
            changeValue={changeValue}
            labelClass="bo-w-250"
          />
          <DefaultSettingsInput
            fieldName="bpMonthlyQuota"
            fieldValue={consumerBpMonthlyQuota}
            labelText="BridgePoints™ Monthly Quota:"
            labelClass="bo-w-250"
            changeValue={changeValue}
            onButtonClick={() => onButtonClick('bpMonthlyQuota', consumerBpMonthlyQuota)}
          />
          <DefaultSettingsInput
            fieldName="bpCap"
            fieldValue={consumerBpCap}
            containerClass="mt-2"
            labelText="BridgePoints™ Cap:"
            labelClass="bo-w-250"
            changeValue={changeValue}
            onButtonClick={() => onButtonClick('bpCap', consumerBpCap)}
          />
          <div className="mt-2 d-flex align-items-center">
            <div className="bo-same-line bo-w-250">
              <Label>Max Advance:</Label>
            </div>
            <div className="bo-same-line">
              <Input
                min={0}
                onKeyDown={e => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                }}
                className="bo-input"
                type="number"
                name="advanceLimit"
                id="advanceLimit"
                value={consumerAdvanceLimit}
                onChange={changeValue}
              />
            </div>
          </div>
          <DefaultSettingsInput
            fieldName="minPayrollCycles"
            fieldValue={consumerMinPayrollCycles}
            containerClass="mt-2"
            labelText="Min Payroll Cycles:"
            labelClass="bo-w-250"
            changeValue={changeValue}
            onButtonClick={() => onButtonClick('minPayrollCycles', consumerMinPayrollCycles)}
          />
          <DefaultSettingsInput
            fieldName="minPayrollWeeks"
            fieldValue={consumerMinPayrollWeeks}
            containerClass="mt-2"
            labelText="Min Employment Period (weeks):"
            labelClass="bo-w-250"
            changeValue={changeValue}
            onButtonClick={() => onButtonClick('minPayrollWeeks', consumerMinPayrollWeeks)}
          />
          <DefaultSettingsInput
            fieldName="minAccountDays"
            fieldValue={consumerMinAccountDays}
            containerClass="mt-2"
            labelText="Min Account History (days):"
            labelClass="bo-w-250"
            changeValue={changeValue}
            onButtonClick={() => onButtonClick('minAccountDays', consumerMinAccountDays)}
          />
        </>
      )}
    </>
  );
}

export default memo(ConsumerSettings);
