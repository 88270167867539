import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  turnoverTable: {
    marginTop: 12,
    width: '100%',
    borderRadius: 14,
    backgroundColor: '#ffffff',
  },
  turnoverTableRow: {
    width: '100%',
    flexDirection: 'row',
  },
  turnoverTableRowAltColor: {
    color: 'white',
  },
  turnoverTableMiddleRow: {
    backgroundColor: '#350569',
    borderTopColor: 'white',
    borderTopWidth: 1,
    borderBottomColor: 'white',
    borderBottomWidth: 1,
  },
  turnoverTableBottomRow: {
    backgroundColor: '#A19CA5',
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  turnoverTableRowTitleContainer: {
    width: '34%',
    paddingVertical: 8,
    paddingLeft: 16,
  },
  turnoverTableRowTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  turnoverTableRowValueContainer: {
    width: '22%',
    paddingVertical: 8,
    paddingLeft: 16,
    borderLeftColor: 'white',
    borderLeftWidth: 1,
  },
  turonverTableTopRowValueContainer: {
    backgroundColor: '#F8F7FA',
  },
  turnoverTableTopRightRowValueContainer: {
    borderTopRightRadius: 14,
  },
  turnoverTableRowValue: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
});
