import {
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CollapsedPanel({ side, onClick }) {
  return (
    <div className="h-100 d-flex justify-content-center aling-items-center p-0">
      <button type="button" className="h-100 w-100 border-0 bg-transparent" onClick={onClick}>
        {side === 'right' && <FontAwesomeIcon icon={faAngleDoubleRight} size="sm" color="grey" />}
        {side === 'left' && <FontAwesomeIcon icon={faAngleDoubleLeft} size="sm" color="grey" />}
        {side === 'down' && <FontAwesomeIcon icon={faAngleDoubleDown} size="sm" color="grey" />}
        {side === 'up' && <FontAwesomeIcon icon={faAngleDoubleUp} size="sm" color="grey" />}
      </button>
    </div>
  );
}

export default CollapsedPanel;
