import moment from 'moment';
import { useState, useEffect, memo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { Spinner } from '../../../../components/Spinner';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledTableCell from '../../../../components/StyledTableCell';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTable } from '../../../../hooks/useTable';
import TablePaginationActions from '../../../../components/TablePaginationActions';
import { toast } from 'react-toastify';

function ConsumerBridgePoints({ consumerId, openBridgePointsModal, bridgePointsModal }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [bridgePointsBalance, setBridgePointsBalance] = useState(null);
  const [bridgePointsTransactions, setBridgePointsTransactions] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useTable({
    initRowsPerPage: 20,
  });

  const getBridgePointsBalance = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/bridgepoints/balance`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const response = await makeRequest(config);

      setBridgePointsBalance(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getBridgePointsTransactions = async id => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/bridgepoints/transactions`,
      method: 'GET',
      params: { consumerId: id },
    };

    try {
      const response = await makeRequest(config);

      setBridgePointsTransactions(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const refreshBridgePoints = async () => {
    setSpinner(true);
    await getBridgePointsBalance(consumerId);
    await getBridgePointsTransactions(consumerId);
    setSpinner(false);
  };

  useEffect(() => {
    if (bridgePointsModal === false) {
      refreshBridgePoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bridgePointsModal]);

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <Button className="bo-button-inline bo-button-gray" onClick={refreshBridgePoints}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
          <Button className="bo-button-inline bo-button-gray ms-3" onClick={openBridgePointsModal}>
            Manual Transaction
          </Button>
        </Col>
      </Row>
      <div className="mb-2 mt-3">
        <Row className="mt-2 ms-1 me-1">
          <Col sm="3">Current Balance:</Col>
          <Col sm="8">
            {bridgePointsBalance?.currentBalance !== null
              ? bridgePointsBalance?.currentBalance.toLocaleString('en-US')
              : 'Unknown'}
          </Col>
        </Row>
        <Row className="mt-2 ms-1 me-1">
          <Col sm="3">Available Balance:</Col>
          <Col sm="8">
            {bridgePointsBalance?.availableBalance !== null
              ? bridgePointsBalance?.availableBalance.toLocaleString('en-US')
              : 'Unknown'}
          </Col>
        </Row>
        <Row className="mt-2 ms-1 me-1">
          <Col sm="3">Open Advance Balance So Far:</Col>
          <Col sm="8">
            {bridgePointsBalance?.openFundingBalance !== null
              ? bridgePointsBalance?.openFundingBalance.toLocaleString('en-US')
              : 'Unknown'}
          </Col>
        </Row>
        <Row className="mt-2 ms-1 me-1">
          <Col sm="3">Open Advance Remaining Balance:</Col>
          <Col sm="8">
            {bridgePointsBalance?.openFundingRemainingBalance !== null
              ? bridgePointsBalance?.openFundingRemainingBalance.toLocaleString('en-US')
              : 'Unknown'}
          </Col>
        </Row>
      </div>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="bo-w-240" align="left">
                Transaction Id
              </StyledTableCell>
              <StyledTableCell align="left">Timestamp</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
              <StyledTableCell align="left">Points</StyledTableCell>
              <StyledTableCell align="left">Reference</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bridgePointsTransactions
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map(transaction => (
                <TableRow hover tabIndex={-1} key={transaction.id}>
                  <StyledTableCell align="left">{transaction.id}</StyledTableCell>
                  <StyledTableCell align="left">
                    {moment
                      .utc(transaction.timestamp)
                      .local()
                      .format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                  </StyledTableCell>
                  <StyledTableCell align="left">{transaction.type}</StyledTableCell>
                  <StyledTableCell align="left">{transaction.description}</StyledTableCell>
                  <StyledTableCell align="left">
                    {transaction.amount.toLocaleString('en-US')}
                  </StyledTableCell>
                  <StyledTableCell align="left">{transaction.reference}</StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={bridgePointsTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Spinner>
  );
}

export default memo(ConsumerBridgePoints);
