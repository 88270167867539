// API
import {
  BasisTheoryProvider,
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-react';
// components
import { Col, Input, Label, Row } from 'reactstrap';

function NewObjectCardInputForm({
  bt,
  btElementsEvents,
  btMetadata,
  setBtElementsEvents,
  setBtMetadata,
  cardNumberRef,
  cardExpirationDateRef,
  cardVerificationCodeRef,
}) {
  const getBtElementContainerStyle = fieldName => {
    let className = 'bo-new-object-bt-element-container';

    if (fieldName === 'cvc' || fieldName === 'cardExpirationDate') {
      className += ' bo-w-100';
    }

    if (btElementsEvents[fieldName] && btElementsEvents[fieldName].focused) {
      className += ' bo-new-object-bt-element-container-focused';
    }

    if (
      btElementsEvents[fieldName] &&
      btElementsEvents[fieldName].dirty &&
      btElementsEvents[fieldName].changeEvent &&
      (btElementsEvents[fieldName].changeEvent.empty ||
        !btElementsEvents[fieldName].changeEvent.valid)
    ) {
      className += ' bo-new-object-bt-element-container-invalid';
    }

    if (
      fieldName === 'cardNumber' &&
      btElementsEvents.cardNumber.dirty &&
      btElementsEvents.cardNumber.changeEvent &&
      btElementsEvents.cardNumber.changeEvent.cardBrand !== 'mastercard' &&
      btElementsEvents.cardNumber.changeEvent.cardBrand !== 'visa'
    ) {
      className += ' bo-new-object-bt-element-container-invalid';
    }

    return className;
  };

  const btElementStyle = {
    base: {
      padding: 5,
      color: '#000000',
      fontSize: '13px',
      fontWeight: 500,
      '::placeholder': {
        color: '#979797',
      },
    },
    invalid: {
      color: '#000000',
    },
    complete: {
      color: '#000000',
    },
  };

  const getDefaultInputStyle = fieldName => {
    let className = 'bo-new-object-popover-form-menu-input';

    if (btMetadata[fieldName].dirty && !btMetadata[fieldName].value) {
      className += ' bo-new-object-card-form-default-input-invalid';
    }

    return className;
  };

  const handleBtElementReadyEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        ready: true,
      },
    }));

  const handleBtElementFocusEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        focused: true,
        dirty: true,
        changeEvent: prevEvents[fieldName].changeEvent,
      },
    }));

  const handleBtElementBlurEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        focused: false,
      },
    }));

  const handleBtElementChangeEvent = (fieldName, e) =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        changeEvent: prevEvents[fieldName].dirty ? e : null,
      },
    }));

  const handleDefaultInputChangeEvent = (fieldName, e) =>
    setBtMetadata(prevM => ({
      ...prevM,
      [fieldName]: { value: e.target.value, dirty: true },
    }));

  return (
    <Row className="mt-2">
      <Col>
        <BasisTheoryProvider bt={bt}>
          <div>
            <Label className="bo-new-object-popover-form-menu-label">Debit Card Number</Label>
            <div className={getBtElementContainerStyle('cardNumber')}>
              <CardNumberElement
                id="cardNumber"
                ref={cardNumberRef}
                iconPosition="none"
                placeholder=""
                style={btElementStyle}
                onReady={() => handleBtElementReadyEvent('cardNumber')}
                onFocus={() => handleBtElementFocusEvent('cardNumber')}
                onChange={e => handleBtElementChangeEvent('cardNumber', e)}
                onBlur={() => handleBtElementBlurEvent('cardNumber')}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <Label className="bo-new-object-popover-form-menu-label">MM/YY</Label>
              <div className={getBtElementContainerStyle('cardExpirationDate')}>
                <CardExpirationDateElement
                  id="cardExpirationDate"
                  ref={cardExpirationDateRef}
                  placeholder=""
                  style={btElementStyle}
                  onReady={() => handleBtElementReadyEvent('cardExpirationDate')}
                  onFocus={() => handleBtElementFocusEvent('cardExpirationDate')}
                  onChange={e => handleBtElementChangeEvent('cardExpirationDate', e)}
                  onBlur={() => handleBtElementBlurEvent('cardExpirationDate')}
                />
              </div>
            </div>
            <div>
              <Label className="bo-new-object-popover-form-menu-label">CVV</Label>
              <div className={getBtElementContainerStyle('cvc')}>
                <CardVerificationCodeElement
                  id="cardVerificationCode"
                  ref={cardVerificationCodeRef}
                  placeholder=""
                  style={btElementStyle}
                  onReady={() => handleBtElementReadyEvent('cvc')}
                  onFocus={() => handleBtElementFocusEvent('cvc')}
                  onChange={e => handleBtElementChangeEvent('cvc', e)}
                  onBlur={() => handleBtElementBlurEvent('cvc')}
                />
              </div>
            </div>
          </div>
        </BasisTheoryProvider>
        <div className="d-flex justify-content-between gap-2">
          <div className="w-50">
            <Label className="bo-new-object-popover-form-menu-label">First Name</Label>
            <Input
              id="firstName"
              className={getDefaultInputStyle('firstName')}
              placeholder=""
              value={btMetadata.firstName.value}
              onChange={e => handleDefaultInputChangeEvent('firstName', e)}
            />
          </div>
          <div className="w-50">
            <Label className="bo-new-object-popover-form-menu-label">Last Name</Label>
            <Input
              id="lastName"
              className={getDefaultInputStyle('lastName')}
              placeholder=""
              value={btMetadata.lastName.value}
              onChange={e => handleDefaultInputChangeEvent('lastName', e)}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default NewObjectCardInputForm;
