import { Input } from 'reactstrap';

function ProspectsSearchForm({ setSearch }) {
  const searchKey = e => {
    if (e.charCode === 13) {
      setSearch(e.target.value);
    }
  };

  return (
    <Input
      className="bo-search"
      type="search"
      name="search"
      id="search"
      placeholder="Search"
      noValidate
      onKeyPress={searchKey}
    />
  );
}

export default ProspectsSearchForm;
