import { Col, Badge, Label, Button } from 'reactstrap';
import { REPAYMENT_MODE_BADGE } from '../../../../constants/fundings';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { memo } from 'react';

function FundingRepaymentModeForm({ fundingId, fundingRepaymentMode, setSpinner, getFunding }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const changeRepaymentMode = async (mode, message) => {
    if (window.confirm(message)) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/change_repayment_mode`,
        method: 'POST',
        data: JSON.stringify({ fundingId, mode }),
      };

      try {
        await makeRequest(config);
        await getFunding(fundingId);

        toast.success(`Repayment mode successfully modified to ${mode}`);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const changeRepaymentModeButtons = repaymentMode => {
    const filteredEntries = Object.entries(REPAYMENT_MODE_BADGE).filter(
      ([key]) => key !== 'REPAID' && key !== 'IMMEDIATE',
    );

    const renderButton = (key, value) => (
      <Button
        key={key}
        className={`bo-link mb-0 ms-3 p-0 ${key === 'NEXT_PAYDAY' ? 'bo-w-100' : ''}`}
        color="link"
        onClick={() => changeRepaymentMode(key, 'Are you sure?')}
      >
        {value.text}
      </Button>
    );

    if (filteredEntries.find(([key]) => key === repaymentMode)) {
      return filteredEntries
        .filter(([key]) => key !== repaymentMode)
        .map(([key, value]) => renderButton(key, value));
    }

    return null;
  };

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Repayment Mode:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        {REPAYMENT_MODE_BADGE[fundingRepaymentMode] && (
          <Badge
            color=""
            className={`bo-badge ${REPAYMENT_MODE_BADGE[fundingRepaymentMode]?.color}`}
          >
            {REPAYMENT_MODE_BADGE[fundingRepaymentMode]?.text}
          </Badge>
        )}
        <div className="d-flex">{changeRepaymentModeButtons(fundingRepaymentMode)}</div>
      </Col>
    </>
  );
}

export default memo(FundingRepaymentModeForm);
