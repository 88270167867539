import { memo, useState } from 'react';
import { Col, Label, Input, Button } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

function ReferringConsumer({ referringConsumerId, consumerId, setSpinner, setConsumer }) {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(referringConsumerId);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const reset = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/reset_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: '' }));
      setValue('');
      setEditMode(false);
      toast.success('Referring consumer has been reset!');
    } catch (error) {
      toast.error(error.message);
      setValue(referringConsumerId);
    } finally {
      setSpinner(false);
    }
  };

  const set = () => setEditMode(true);

  const cancel = () => {
    setValue(referringConsumerId || '');
    setEditMode(false);
  };

  const save = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId, referringConsumerId: Number(value) }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: value }));
      setEditMode(false);
      toast.success('Referring consumer has been updated!');
    } catch (error) {
      toast.error(error.message);
      setValue(referringConsumerId || '');
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Col md={3}>
      <div className="d-flex align-items-center">
        <div className="d-flex">
          <Label className="me-3">Referred By:</Label>
          <NumericFormat
            customInput={Input}
            className="bo-input bo-max-w-100 me-4"
            type="text"
            name="referringConsumerId"
            id="referringConsumerId"
            value={value}
            onChange={e => setValue(e.target.value)}
            allowNegative={false}
            decimalScale={0}
            disabled={!editMode}
          />
        </div>
        {editMode ? (
          <div className="d-flex gap-2">
            <Button className="bo-button-inline-link p-0 m-0" color="link" onClick={cancel}>
              Cancel
            </Button>
            <Button className="bo-button-inline-link p-0 m-0" color="link" onClick={save}>
              Save
            </Button>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <Button className="bo-button-inline-link p-0 m-0" color="link" onClick={set}>
              Set
            </Button>
            <Button className="bo-button-inline-link p-0 m-0" color="link" onClick={reset}>
              Reset
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
}

export default memo(ReferringConsumer);
