import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  graphContainer: {
    backgroundColor: 'white',
    marginTop: 12,
    borderRadius: 14,
  },
  graphAxisTitle: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 400,
  },
  graphAxisLabels: {
    fontSize: 10,
    fontFamily: 'Poppins',
    fontWeight: 400,
  },
});
