import { Text, View } from '@react-pdf/renderer';
import CheckIcon from '../../svgs/CheckIcon';
import { styles } from './styles';

function TurnoverCards({
  turnoverAsterisks,
  annualTurnoverBenchmark,
  replacementCost,
  estimatedAnnualSavings,
}) {
  return (
    <View style={styles.turnoverContainer}>
      <View style={[styles.turnoverItem, styles.turnoverLeftItem]}>
        <View>
          <CheckIcon checkColor="#50E5FF" style={styles.turnoverItemIcon} />
          <Text
            style={styles.turnoverItemTitle}
          >{`Annual Turnover \n Benchmark${turnoverAsterisks.asterisks[1]}`}</Text>
        </View>
        <Text style={styles.turnoverItemValue}>
          {annualTurnoverBenchmark !== null && annualTurnoverBenchmark !== undefined
            ? `${Number(annualTurnoverBenchmark * 100).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}%`
            : 'N/A'}
        </Text>
      </View>
      <View style={[styles.turnoverItem, styles.turnoverMiddleItem]}>
        <View>
          <CheckIcon checkColor="#16BFBB" style={styles.turnoverItemIcon} />
          <Text
            style={styles.turnoverItemTitle}
          >{`Employee \n Replacement Cost${turnoverAsterisks.asterisks[2]}`}</Text>
        </View>
        <Text style={styles.turnoverItemValue}>
          {replacementCost !== null && replacementCost !== undefined
            ? `$${Number(replacementCost).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}`
            : 'N/A'}
        </Text>
      </View>
      <View style={[styles.turnoverItem, styles.turnoverRightItem]}>
        <View>
          <CheckIcon checkColor="#EB7AF5" style={styles.turnoverItemIcon} />
          <Text
            style={styles.turnoverItemTitle}
          >{`Estimated Annual \n Savings${turnoverAsterisks.asterisks[3]}`}</Text>
        </View>
        <Text style={styles.turnoverItemValue}>
          {estimatedAnnualSavings !== null &&
          estimatedAnnualSavings !== undefined &&
          estimatedAnnualSavings >= 0
            ? `$${Math.abs(estimatedAnnualSavings).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}`
            : 'N/A'}
        </Text>
      </View>
    </View>
  );
}

export default TurnoverCards;
