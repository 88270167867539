import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import { useEnv } from '../../../context/env-context';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../../utils/makeRequest';

function ReportActionRow({
  reportTitle,
  reportDetails,
  reportId,
  setSpinner,
  exportExcel,
  renderSelect,
}) {
  const { apiOriginReports } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const navigate = useNavigate();

  const deleteReport = async () => {
    if (window.confirm('Are you sure?')) {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      setSpinner(true);

      const config = {
        token,
        url: `${apiOriginReports}/delete`,
        method: 'POST',
        data: { reportId },
      };

      try {
        await makeRequest(config);
        navigate('/reports');

        toast.success('Report was successfully deleted');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <Row className="mb-2 align-items-center justify-content-between">
      <div className="w-auto">
        <h2>{reportTitle} Report</h2>
        <p>{reportDetails}</p>
      </div>
      <div className="w-auto">{renderSelect && renderSelect()}</div>
      <div className="w-auto d-flex gap-3 align-items-center">
        <Button className="bo-button bo-w-120" onClick={deleteReport}>
          Delete
        </Button>
        <Button className="bo-button bo-w-120" onClick={exportExcel} disabled={!exportExcel}>
          Export
        </Button>
      </div>
    </Row>
  );
}

export default ReportActionRow;
