import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function AggregatorNameForm({ aggregatorName, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Name:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="name"
          id="name"
          placeholder="Aggregator name"
          value={aggregatorName || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(AggregatorNameForm);
