import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { Col, Label, Row } from 'reactstrap';
import { memo } from 'react';

function FundingRedFlags({ fundingFlags }) {
  return (
    <Row className="mb-2 mb-2 align-items-center">
      <Col md={2} className="bo-same-line">
        <Label>Red Flags:</Label>
      </Col>
      <Col md={4} className="bo-same-line">
        <div className="bo-border-container w-100">
          {fundingFlags.map(flag => (
            <div key={`${flag.type}_${flag.data}`}>
              <FontAwesomeIcon icon={faFlag} color="red" />
              <span className="ms-3 text-danger">{flag.type}</span>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default memo(FundingRedFlags);
