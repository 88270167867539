import { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import {
  faCheckCircle,
  faPencil,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import { CircularProgress } from '@mui/material';

function NewObjectTextInput({
  label,
  initialValue,
  labelClassName,
  type,
  maxLength,
  readOnly,
  onSave,
  onDelete,
  allowLeadingZeros,
  allowEmpty = true,
}) {
  const [spinner, setSpinner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleDelete = async () => {
    try {
      setSpinner(true);
      await onDelete();
    } catch (error) {
      setValue(initialValue);
    } finally {
      setSpinner(false);
    }
  };

  const handleSave = async () => {
    try {
      setSpinner(true);
      await onSave(value);
    } catch (error) {
      setValue(initialValue);
    } finally {
      setEditMode(false);
      setSpinner(false);
    }
  };

  const handleCancel = () => {
    setEditMode(!editMode);
    setValue(initialValue);
  };

  if (readOnly) {
    return (
      <>
        <Label className={`bo-new-object-input-label ${labelClassName}`}>{label}</Label>
        <div className="w-100">
          <Input
            className="bo-new-object-text-input"
            type="text"
            readOnly
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <div className="bo-new-object-text-bottom-border" />
        </div>
      </>
    );
  }

  return (
    <>
      <Label className={`bo-new-object-input-label ${labelClassName}`}>{label}</Label>
      {editMode ? (
        <div className="bo-new-object-text-input-container">
          <div className="w-100">
            {type === 'numeric' ? (
              <NumericFormat
                type="text"
                className="bo-new-object-text-input"
                value={value}
                onValueChange={({ floatValue }) => setValue(floatValue)}
                maxLength={maxLength}
                customInput={Input}
                allowNegative={false}
                decimalScale={0}
                allowLeadingZeros={allowLeadingZeros}
                readOnly={spinner}
              />
            ) : (
              <Input
                className="bo-new-object-text-input"
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
                maxLength={maxLength}
                readOnly={spinner}
              />
            )}
            <div
              className={
                // eslint-disable-next-line no-useless-concat
                'bo-new-object-text-bottom-border ' + 'bo-new-object-text-bottom-border-active'
              }
            />
          </div>
          <div
            className={
              'bo-new-object-input-button-container' +
              ' bo-new-object-input-button-container-active'
            }
          >
            {spinner ? (
              <div className="h-100">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              <>
                <Button
                  className="bo-new-object-input-button-icon"
                  onClick={handleSave}
                  disabled={
                    (maxLength && value && String(value).length !== maxLength) ||
                    (maxLength && !value) ||
                    (!allowEmpty && !value)
                  }
                  key="save"
                >
                  <FontAwesomeIcon icon={faCheckCircle} color="#5946A4" size="1x" />
                </Button>
                <Button
                  className="bo-new-object-input-button-icon"
                  onClick={handleCancel}
                  key="cancel"
                >
                  <FontAwesomeIcon icon={faTimesCircle} color="#5946A4" size="1x" />
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="bo-new-object-text-input-container">
          <Button
            className="border-0 bg-transparent d-flex m-0 p-0 w-100 d-flex align-items-center"
            onClick={() => setEditMode(true)}
          >
            <div className="w-100">
              <Input
                className="bo-new-object-text-input"
                type="text"
                readOnly
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <div className="bo-new-object-text-bottom-border" />
            </div>
            <div
              className={`bo-new-object-input-button-container${
                spinner ? ' bo-new-object-input-button-container-active' : ''
              }`}
            >
              {spinner ? (
                <div className="h-100 text-dark">
                  <CircularProgress color="inherit" size={16} />
                </div>
              ) : (
                <div className="bo-new-object-input-button-icon">
                  <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
                </div>
              )}
            </div>
          </Button>
          {onDelete && !spinner && (
            <div className="bo-new-object-trash-button-container">
              <Button
                className="bo-new-object-input-button-icon"
                onClick={handleDelete}
                key="delete"
              >
                <FontAwesomeIcon icon={faTrashAlt} color="#5946A4" size="sm" />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default NewObjectTextInput;
