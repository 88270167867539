import { memo } from 'react';
import { Col, Button } from 'reactstrap';

function FundingChangeRepaymentDateForm({
  fundingRepaymentMode,
  openRepaymentDateModal,
  latestDate,
}) {
  if (latestDate) {
    return (
      <Col md={2} className="bo-same-line">
        <Button
          className="bo-link mb-0 p-0"
          color="link"
          onClick={() => openRepaymentDateModal({ reset: false, latestDate: true })}
        >
          Change
        </Button>
      </Col>
    );
  }

  return fundingRepaymentMode === 'NEXT_PAYDAY' || fundingRepaymentMode === 'MANUAL' ? (
    <Col md={2} className="bo-same-line">
      <Button
        className="bo-link mb-0 p-0"
        color="link"
        onClick={
          fundingRepaymentMode === 'MANUAL'
            ? () => openRepaymentDateModal({ reset: true, latestDate: false })
            : () => openRepaymentDateModal({ reset: false, latestDate: false })
        }
      >
        Change
      </Button>
    </Col>
  ) : (
    <Col md={2} className="bo-same-line" />
  );
}

export default memo(FundingChangeRepaymentDateForm);
