import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile, formatExcelDate } from '../../../../utils/excel';
import ReportActionRow from '../ReportActionRow';
import ReportTable from '../ReportTable';

function ReportReferrals({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      aggregatorName: {
        header: 'Aggregator Name',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      referralId: {
        header: 'Referral Id',
        width: 18,
        style: { alignment: { horizontal: 'right' } },
      },
      referralFirstName: {
        header: 'Referral First Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      referralLastName: {
        header: 'Referral Last Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      refereeId: {
        header: 'Referee ID',
        width: 18,
        style: { alignment: { horizontal: 'right' } },
      },
      refereeFirstName: {
        header: 'Referee First Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      refereeLastName: {
        header: 'Referee Last Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      signupDate: {
        header: 'Referee Signup Date',
        width: 23,
        style: { alignment: { horizontal: 'left' }, numFmt: 'yyyy-mm-dd' },
      },
    }),
    [],
  );

  const restructuredReport = useMemo(
    () =>
      report
        .sort((a, b) => a.refereeId - b.refereeId)
        .sort((a, b) => a.referralId - b.referralId)
        .reduce((acc, val) => {
          if (acc.find(x => x.referralId === val.referralId)) {
            return [
              ...acc,
              {
                refereeId: val.refereeId,
                refereeFirstName: val.refereeFirstName,
                refereeLastName: val.refereeLastName,
                signupDate: val.signupDate,
              },
            ];
          }

          return [...acc, val];
        }, [])
        .map(r => ({ ...r, uuid: crypto.randomUUID() })),
    [report],
  );
  const exportExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Referrals', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = restructuredReport.map(r => ({
      ...r,
      signupDate: r.signupDate ? formatExcelDate(new Date(r.signupDate)) : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [restructuredReport, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (restructuredReport.length > 0) {
      return restructuredReport.map(r =>
        Object.entries(r).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          }),
          {},
        ),
      );
    }

    return [];
  }, [restructuredReport, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportExcel={exportExcel}
      />
      <ReportTable report={formattedReport} tableColumns={tableColumns} rowKey="uuid" />
    </>
  );
}

export default ReportReferrals;
