import { useAuth } from '../../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { toast } from 'react-toastify';

function ProfileFullNameForm({ setSpinner }) {
  const { apiOriginAggregator } = useEnv();
  const { user, getAccessTokenSilently } = useAuth();

  const [nameEdit, setNameEdit] = useState({ edit: false, name: null });
  const [updateName, setUpdateName] = useState(false);

  const editName = () => setNameEdit({ edit: true, name: user.nickname });
  const cancelEditName = () => setNameEdit({ edit: false, name: null });

  const saveName = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);
    try {
      const response = await fetch(`${apiOriginAggregator}/user/updatename`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newName: nameEdit.name }),
      });

      if (response.ok) {
        setUpdateName(true);
        toast.success('Name updated successfully');
      } else {
        const err = await response.text();

        setNameEdit({ edit: false, name: null });
        toast.error(`Failed to update name: ${JSON.parse(err).message}`);
      }
    } catch (error) {
      setNameEdit({ edit: false, name: null });
      toast.error('Failed to update name: Communication error!');
    } finally {
      setSpinner(false);
    }
  };

  const handleNameChange = e => setNameEdit({ ...nameEdit, name: e.target.value });

  useEffect(() => {
    if (updateName) {
      // Update user object for current instance
      user.nickname = nameEdit.name;
      // Update tokens in localStorage
      getAccessTokenSilently({
        ignoreCache: true,
      });
      setNameEdit({ edit: false, name: null });
      setUpdateName(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getAccessTokenSilently, updateName]);

  let inputValue = '';

  if (nameEdit.edit) {
    inputValue = nameEdit.name;
  } else if (user.nickname) {
    inputValue = user.nickname;
  }

  return (
    <Row className="mb-2 align-items-center">
      <Col md={2}>
        <Label>Full Name:</Label>
      </Col>
      <Col md={4}>
        <Input
          className="bo-input"
          type="text"
          name="fullName"
          id="fullName"
          placeholder="Full name"
          value={inputValue}
          disabled={!nameEdit.edit}
          noValidate
          onChange={e => handleNameChange(e)}
        />
      </Col>
      <Col md={4}>
        {nameEdit.edit ? (
          <>
            <Button className="bo-button-inline" onClick={saveName}>
              Save
            </Button>
            &nbsp;
            <Button className="bo-button-inline" onClick={cancelEditName}>
              Cancel
            </Button>
          </>
        ) : (
          <Button className="bo-button-inline" onClick={editName}>
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default ProfileFullNameForm;
