import { memo } from 'react';
import { Col, Label, Input } from 'reactstrap';

function HighRiskForm({ consumerHighRisk, consumerHighRiskReason, setConsumer, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line-top">
        <Input
          className="ms-1 bo-checkbox"
          type="checkbox"
          name="highRisk"
          id="highRisk"
          checked={consumerHighRisk}
          onChange={e =>
            setConsumer(prevConsumer => ({
              ...prevConsumer,
              highRisk: e.target.checked,
              highRiskReason: !e.target.checked ? '' : prevConsumer.highRiskReason,
            }))
          }
        />
        <Label className="p-0 mt-1 mb-0 me-0">High Risk</Label>
      </Col>
      <Col md={4} className="bo-same-line-top">
        <Input
          className="bo-input"
          type="text"
          name="highRiskReason"
          id="highRiskReason"
          placeholder="High Risk Reason"
          disabled={!consumerHighRisk}
          value={consumerHighRiskReason || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(HighRiskForm);
