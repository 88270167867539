import moment from 'moment';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Spinner } from '../../../../components/Spinner';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { Card, CardHeader, CardBody, Badge, Button } from 'reactstrap';

function DashboardDwollaWebhooks() {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [subscriptions, setSubscriptions] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const getSubscriptions = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/get_dwolla_webhook_subscriptions`,
      method: 'GET',
      params: {},
    };

    try {
      const data = await makeRequest(config);

      setSubscriptions(data);
    } catch (error) {
      toast.error(`Failed getting webhook subscriptions: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  const createSubscription = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/create_dwolla_webhook_subscription`,
      method: 'POST',
    };

    try {
      await makeRequest(config);

      getSubscriptions();
    } catch (error) {
      toast.error(`Failed creating webhook subscription: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  const processAllEvents = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/process_all_transfer_events`,
        method: 'POST',
      };

      try {
        await makeRequest(config);
      } catch (error) {
        toast.error(`Failed processing events: ${error.message}`);
      } finally {
        setSpinner(false);
      }
    }
  };
  const deleteSubscription = async subscriptionUrl => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/delete_dwolla_webhook_subscription`,
        method: 'POST',
        data: JSON.stringify({ subscriptionUrl }),
      };

      try {
        await makeRequest(config);

        getSubscriptions();
      } catch (error) {
        toast.error(`Failed deleting webhook subscription: ${error.message}`);
      } finally {
        setSpinner(false);
      }
    }
  };
  const updateSubscription = async (subscriptionUrl, paused) => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginFunding}/update_dwolla_webhook_subscription`,
      method: 'POST',
      data: JSON.stringify({ subscriptionUrl, pause: paused }),
    };

    try {
      await makeRequest(config);

      getSubscriptions();
    } catch (error) {
      toast.error(`Failed updating webhook subscription: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusBadge = paused => {
    if (paused) {
      return (
        <Badge className="bo-inline-badge" color="warning">
          Paused
        </Badge>
      );
    }

    return (
      <Badge className="bo-inline-badge" color="success">
        Active
      </Badge>
    );
  };

  return (
    <Card className="my-3">
      <CardHeader className="card-header d-flex justify-content-between">
        Dwolla Webhooks
        <Button className="bo-button-inline-link" color="link" onClick={() => getSubscriptions()}>
          Refresh
        </Button>
      </CardHeader>
      <CardBody>
        <Spinner visible={spinner} text="">
          <table>
            <tbody>
              {subscriptions.map(sub => (
                <tr key={sub.subscriptionUrl}>
                  <td>
                    <div className="bo-inline-badge bo-border-container w-100">
                      <table>
                        <tbody>
                          <tr>
                            <td className="bo-w-120">
                              <strong>Subscription URL:</strong>
                            </td>
                            <td>{sub.subscriptionUrl}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Webhook URL</strong>
                            </td>
                            <td>{sub.url}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Created at:</strong>
                            </td>
                            <td>
                              {moment.utc(sub.created).local().format('MMM DD, YYYY H:mm (UTCZ)')}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Status:</strong>
                            </td>
                            <td className="d-flex align-items-center">
                              {statusBadge(sub.paused)}
                              <Button
                                className="bo-button-inline-link"
                                color="link"
                                onClick={() => updateSubscription(sub.subscriptionUrl, !sub.paused)}
                              >
                                {sub.paused ? 'Un-pause' : 'Pause'}
                              </Button>

                              <Button
                                className="bo-button-inline-link"
                                color="link"
                                onClick={() => deleteSubscription(sub.subscriptionUrl)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <Button
              className="bo-button-inline-link mt-3"
              color="link"
              onClick={() => createSubscription()}
            >
              Create New Subscription
            </Button>
            <Button
              className="bo-button-inline-link mt-3 me-3"
              color="link"
              onClick={() => processAllEvents()}
            >
              Process All Events
            </Button>
          </div>
        </Spinner>
      </CardBody>
    </Card>
  );
}

export default DashboardDwollaWebhooks;
