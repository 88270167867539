import moment from 'moment';
import { faPencil, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useState } from 'react';
import { getDaysInMonth, nextYears, previousYears } from '../../../../../utils/dates';
import { CircularProgress } from '@mui/material';

function NewObjectPopoverTerminationDate({
  consumerLastEmploymentTerminationDate,
  updateConsumer,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [terminationDate, setTerminationDate] = useState({
    year: String(moment(consumerLastEmploymentTerminationDate || undefined).year()),
    month: String(moment(consumerLastEmploymentTerminationDate || undefined).month()),
    date: String(moment(consumerLastEmploymentTerminationDate || undefined).date()),
  });

  const handleSave = async () => {
    setSpinner(true);
    await updateConsumer({
      lastEmploymentTerminationDate: moment({
        year: terminationDate.year,
        month: terminationDate.month,
        date: terminationDate.date,
      }).format('YYYY-MM-DD'),
    });
    setSpinner(false);
    setIsOpen(false);
  };

  const handleDelete = async () => {
    setIsOpen(false);
    setDeleting(true);
    await updateConsumer({
      lastEmploymentTerminationDate: null,
    });
    setDeleting(false);
  };

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Label className="bo-new-object-input-label mt-3">Last Employment Termination Date</Label>
      <div className="bo-new-object-text-input-container">
        <Button
          id="popover_termination_date_form"
          className="border-0 bg-transparent d-flex m-0 p-0 w-100 d-flex align-items-center"
          onClick={handleToggle}
        >
          <div className="me-auto">
            <Input
              className="bo-new-object-text-input bo-w-150"
              type="text"
              readOnly
              value={
                consumerLastEmploymentTerminationDate
                  ? moment(consumerLastEmploymentTerminationDate).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
          <div
            className={`bo-new-object-input-button-container${
              deleting ? ' bo-new-object-input-button-container-active' : ''
            }`}
          >
            {deleting ? (
              <div className="h-100 text-dark">
                <CircularProgress color="inherit" size={16} />
              </div>
            ) : (
              <div className="bo-new-object-input-button-icon">
                <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
              </div>
            )}
          </div>
        </Button>
        {!deleting && (
          <div className="bo-new-object-trash-button-container">
            <Button
              className="bo-new-object-input-button-icon"
              onClick={handleDelete}
              key="delete"
              disabled={spinner}
            >
              <FontAwesomeIcon icon={faTrashAlt} color="#5946A4" size="sm" />
            </Button>
          </div>
        )}
      </div>
      <Popover
        target="popover_termination_date_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-new-object-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <Label className="bo-new-object-popover-form-menu-label">Year</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={terminationDate.year}
              onChange={e => {
                if (
                  !moment({
                    year: e.target.value,
                    month: terminationDate.month,
                    date: terminationDate.date,
                  }).isValid()
                ) {
                  setTerminationDate({
                    ...terminationDate,
                    year: e.target.value,
                    date: moment({ year: e.target.value, month: terminationDate.month })
                      .endOf('month')
                      .date(),
                  });
                } else {
                  setTerminationDate({ ...terminationDate, year: e.target.value });
                }
              }}
            >
              {[...previousYears(10), ...nextYears(20, moment().add(1, 'year').year())].map(
                year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ),
              )}
            </Input>
            <Label className="bo-new-object-popover-form-menu-label mt-2">Month</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={terminationDate.month}
              onChange={e => {
                if (
                  !moment({
                    year: terminationDate.year,
                    month: e.target.value,
                    date: terminationDate.date,
                  }).isValid()
                ) {
                  setTerminationDate({
                    ...terminationDate,
                    month: e.target.value,
                    date: moment({ year: terminationDate.year, month: e.target.value })
                      .endOf('month')
                      .date(),
                  });
                } else {
                  setTerminationDate({ ...terminationDate, month: e.target.value });
                }
              }}
            >
              {[...Array(12)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option value={i} key={i}>
                  {moment().month(i).format('MMMM')}
                </option>
              ))}
            </Input>
            <Label className="bo-new-object-popover-form-menu-label mt-2">Day</Label>
            <Input
              type="select"
              className="bo-new-object-popover-form-menu-input bo-w-150"
              value={terminationDate.date}
              onChange={e => setTerminationDate({ ...terminationDate, date: e.target.value })}
            >
              {getDaysInMonth(terminationDate.month, terminationDate.year).map(x => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
            </Input>
            <div className="mt-2 d-flex justify-content-between">
              <Button className="bo-new-object-button" onClick={handleSave}>
                Save
              </Button>
              <Button className="bo-new-object-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default NewObjectPopoverTerminationDate;
