import { useState, useEffect, memo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import moment from 'moment';
import { makeRequest } from '../../../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import StyledTableCell from '../../../../components/StyledTableCell';
import TablePaginationActions from '../../../../components/TablePaginationActions';
import { useTable } from '../../../../hooks/useTable';

function ConsumerUserActivity({ consumerId }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [consumerActivity, setConsumerActivity] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useTable({
    initRowsPerPage: 20,
  });

  const getActivity = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_activity`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const activity = await makeRequest(config);

      setConsumerActivity(activity);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="2">
          <Button className="bo-button-inline bo-button-gray" onClick={getActivity}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width="250px">
                Timestamp
              </StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consumerActivity.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(activity => (
              <TableRow hover tabIndex={-1} key={activity.timestamp}>
                <StyledTableCell align="left" width="250px">
                  {moment.utc(activity.timestamp).local().format('MMM DD, YYYY H:mm (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">{activity.description}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        className="bo-pagination"
        component="div"
        colSpan={3}
        count={consumerActivity.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Spinner>
  );
}

export default memo(ConsumerUserActivity);
