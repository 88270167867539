import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NewObjectRedFlagsWidget({ consumerFlags }) {
  return (
    <div className="bo-error-border-container rounded w-100">
      {consumerFlags.map(flag => (
        <div key={flag.type} className="d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faFlag} color="red" size="xs" />
          <p className="bo-new-object-text text-danger">{flag.type}</p>
        </div>
      ))}
    </div>
  );
}

export default NewObjectRedFlagsWidget;
