import { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function SuperuserPasswordForm({ superuser, formErrors, handleChange, renderError }) {
  const [pwdShow, setPwdShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  return (
    <>
      <FormGroup>
        <div className="position-relative">
          <Input
            className={formErrors && formErrors.password ? 'bo-input bo-input-error' : 'bo-input'}
            type={pwdShow ? 'text' : 'password'}
            name="password"
            id="password"
            placeholder="Password"
            value={superuser.password ? superuser.password : ''}
            noValidate
            onChange={e => handleChange(e)}
          />
          <div className="bo-eye-wrapper" onClick={() => setPwdShow(!pwdShow)}>
            <FontAwesomeIcon icon={pwdShow ? faEyeSlash : faEye} />
          </div>
        </div>
        {'password' in formErrors ? renderError(formErrors.password) : ''}
      </FormGroup>
      <FormGroup>
        <div className="position-relative">
          <Input
            className={
              formErrors && formErrors.confirmPassword ? 'bo-input bo-input-error' : 'bo-input'
            }
            type={confirmShow ? 'text' : 'password'}
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm password"
            value={superuser.confirmPassword ? superuser.confirmPassword : ''}
            noValidate
            onChange={e => handleChange(e)}
          />
          <div className="bo-eye-wrapper" onClick={() => setConfirmShow(!confirmShow)}>
            <FontAwesomeIcon icon={confirmShow ? faEyeSlash : faEye} />
          </div>
        </div>
        {'confirmPassword' in formErrors ? renderError(formErrors.confirmPassword) : ''}
      </FormGroup>
    </>
  );
}

export default SuperuserPasswordForm;
