import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Input, Label } from 'reactstrap';
import { useEnv } from '../../../../../../context/env-context';
import { useAuth } from '../../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../../utils/makeRequest';
import { PAYROLL_FREQUENCIES, PAYROLL_STATUS_BADGE } from '../../../../../../constants/consumers';
import AutoSizer from 'react-virtualized-auto-sizer';
import { toast } from 'react-toastify';

function NewObjectGeneralInfoSubTab({
  consumerId,
  consumerPayrollStatus,
  consumerLockPayrollDay,
  consumerPayrollFrequency,
  consumerPayrollDay1,
  consumerPayrollDay2,
  getConsumer,
  profileJson,
  getProfileJson,
  setSpinner,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, apiOriginMonitor } = useEnv();

  const [biWeeklyDay, setBiWeeklyDay] = useState(null);

  const getBiweeklyDay = async date => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_biweekly_day`,
      method: 'GET',
      params: {
        date,
      },
    };

    try {
      const response = await makeRequest(config);

      setBiWeeklyDay(response?.biWeeklyDay);

      return response?.biWeeklyDay;
    } catch (error) {
      toast.error(error.message);

      return null;
    }
  };

  useEffect(() => {
    if (biWeeklyDay === null && consumerPayrollFrequency === 'BIWEEKLY') {
      getBiweeklyDay(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const predict = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/predict`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    try {
      await makeRequest(config);

      toast.success('Prediction job initiated successfully');
      getConsumer(consumerId, false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const detectPayroll = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/payroll/detect`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);

        toast.success('Payroll parameters detected and payroll profile updated successfully');

        await getProfileJson(consumerId);
        await getConsumer(consumerId, false);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const runProfilePayroll = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/payroll/profile`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    await makeRequest(config);
  };

  const profilePayroll = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      try {
        await runProfilePayroll();

        toast.success('Payroll profile updated successfully');

        await getProfileJson(consumerId);
        await getConsumer(consumerId, false);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const setConsumerPayrollParams = async (
    payrollFrequency,
    payrollDay1,
    payrollDay2,
    lockPayrollDay,
    runPayrollProfiling,
  ) => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/set_consumer_payroll_params`,
      method: 'POST',
      data: JSON.stringify({
        consumerId,
        payrollFrequency,
        payrollDay1: payrollDay1 === null ? null : Number(payrollDay1),
        payrollDay2: payrollDay2 === null ? null : Number(payrollDay2),
        lockPayrollDay,
      }),
    };

    try {
      await makeRequest(config);

      toast.success('New consumer payroll params saved!');

      if (runPayrollProfiling) {
        await runProfilePayroll();

        if (
          window.confirm(
            'Payroll profile has been updated, do you want to run “Re-predict” ' +
              'in order to refresh consumers offer and prediction data?',
          )
        ) {
          await predict();
          await getProfileJson(consumerId);
        } else {
          await getProfileJson(consumerId);
          await getConsumer(consumerId, false);
        }
      } else {
        await getProfileJson(consumerId);
        await getConsumer(consumerId, false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updatePayrollFrequency = async frequency => {
    if (frequency === 'WEEKLY') {
      await setConsumerPayrollParams(frequency, 4, null, consumerLockPayrollDay, true);
    }

    if (frequency === 'BIWEEKLY') {
      const payrollDay1 = await getBiweeklyDay(moment().startOf('isoWeek').format('YYYY-MM-DD'));

      if (payrollDay1 !== null && payrollDay1 !== undefined) {
        await setConsumerPayrollParams(frequency, payrollDay1, null, consumerLockPayrollDay, true);
      }
    }

    if (frequency === 'SEMI_MONTHLY') {
      await setConsumerPayrollParams(frequency, 1, 16, consumerLockPayrollDay, true);
    }

    if (frequency === 'MONTHLY') {
      await setConsumerPayrollParams(frequency, 1, null, consumerLockPayrollDay, true);
    }
  };

  const renderPayrollDay = () => {
    if (
      consumerPayrollFrequency === 'WEEKLY' ||
      consumerPayrollFrequency === 'BIWEEKLY' ||
      consumerPayrollFrequency === 'MONTHLY' ||
      consumerPayrollFrequency === 'SEMI_MONTHLY'
    ) {
      let days = [];

      if (consumerPayrollFrequency === 'WEEKLY') {
        const weekdays = moment.weekdays();

        // [{key: 'Monday', value: 0}, {key: 'Tuesday', value: 1}, ..., {key: 'Sunday', value: 6}]
        days = [...weekdays.slice(1), weekdays[0]].map((day, index) => ({
          key: day,
          value: index,
        }));
      } else if (consumerPayrollFrequency === 'BIWEEKLY') {
        const twoWeekValues =
          biWeeklyDay === 7
            ? [7, 8, 9, 10, 11, 12, 13, 0, 1, 2, 3, 4, 5, 6]
            : [...Array(14).keys()];

        // two weeks starting from the current week monday
        days = twoWeekValues.map((num, idx) => ({
          key: moment().startOf('isoWeek').add(idx, 'days').format('dddd DD/MM/yyyy'),
          value: num,
        }));
      } else if (
        consumerPayrollFrequency === 'MONTHLY' ||
        consumerPayrollFrequency === 'SEMI_MONTHLY'
      ) {
        // [{key: 1, value: 1}, {key: 2, value: 2}, ..., {key: 28, value: 28}]
        days = [...Array(28).keys()].map(value => ({ key: value + 1, value: value + 1 }));
      }

      return (
        <div className="d-flex">
          <Input
            className="bo-new-object-popover-form-menu-input bo-w-180"
            type="select"
            value={consumerPayrollDay1}
            onChange={e =>
              setConsumerPayrollParams(
                consumerPayrollFrequency,
                e.target.value,
                consumerPayrollDay2,
                true,
                true,
              )
            }
          >
            {days.map(day => (
              <option key={day.key} value={day.value}>
                {day.key}
              </option>
            ))}
            {(consumerPayrollFrequency === 'MONTHLY' ||
              consumerPayrollFrequency === 'SEMI_MONTHLY') && (
              <option value={-1}>Last month day</option>
            )}
          </Input>
          {consumerPayrollFrequency === 'SEMI_MONTHLY' && (
            <Input
              className="bo-new-object-popover-form-menu-input bo-w-180 ms-2"
              type="select"
              value={consumerPayrollDay2}
              onChange={e =>
                setConsumerPayrollParams(
                  consumerPayrollFrequency,
                  consumerPayrollDay1,
                  e.target.value,
                  true,
                  true,
                )
              }
            >
              {days.map(day => (
                <option key={day.key} value={day.value}>
                  {day.value}
                </option>
              ))}
              <option value={-1}>Last month day</option>
            </Input>
          )}
          <div className="d-flex align-items-center ms-3">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              checked={consumerLockPayrollDay}
              onChange={e =>
                setConsumerPayrollParams(
                  consumerPayrollFrequency,
                  consumerPayrollDay1,
                  consumerPayrollDay2,
                  e.target.checked,
                  false,
                )
              }
            />
            <Label className="bo-new-object-text-md ms-1">Lock</Label>
          </div>
        </div>
      );
    }

    return <Badge className="bo-new-object-inline-badge">N/A</Badge>;
  };

  const numberOfCycles = useMemo(() => {
    if (profileJson?.profile?.payroll?.cycles) {
      return Object.values(profileJson?.profile?.payroll?.cycles).filter(
        v => v.totalAmount > 0 && v.status === 'final',
      ).length;
    }

    return null;
  }, [profileJson]);

  const last3CyclesAvarage = useMemo(() => {
    if (profileJson?.profile?.payroll?.average) {
      return `$${Number(Math.round(profileJson?.profile?.payroll?.average)).toLocaleString(
        'en-US',
        {
          minimumFractionDigits: 2,
        },
      )}`;
    }

    return null;
  }, [profileJson]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ height, width }} className="overflow-auto bo-table-container">
          <div className="m-3 d-flex gap-2">
            <Button
              className="bo-new-object-button"
              disabled={!profileJson?.profile?.payroll}
              onClick={detectPayroll}
            >
              Detect Payroll
            </Button>
            <Button
              className="bo-new-object-button"
              disabled={!profileJson?.profile?.payroll}
              onClick={profilePayroll}
            >
              Profile Payroll
            </Button>
          </div>
          <div className="mx-3 my-2 d-flex align-items-center gap-2">
            <Label className="bo-new-object-text-md bo-w-180">Payroll Status:</Label>
            <Badge
              className="bo-new-object-inline-badge"
              color={PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.color}
            >
              {PAYROLL_STATUS_BADGE[consumerPayrollStatus]?.text || 'Unknown'}
            </Badge>
          </div>
          <div className="mx-3 my-2 d-flex align-items-center gap-2">
            <Label className="bo-new-object-text-md bo-w-180">Payroll Frequency:</Label>
            {consumerPayrollStatus === 'NOT_DETECTED' ? (
              <Badge className="bo-new-object-inline-badge">N/A</Badge>
            ) : (
              <Input
                type="select"
                className="bo-new-object-popover-form-menu-input bo-w-180"
                value={consumerPayrollFrequency}
                onChange={e => updatePayrollFrequency(e.target.value)}
              >
                <option disabled value="UNKNOWN">
                  Unknown
                </option>
                {Object.entries(PAYROLL_FREQUENCIES).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Input>
            )}
          </div>
          <div className="mx-3 my-2 d-flex align-items-center gap-2">
            <Label className="bo-new-object-text-md bo-w-180">Payroll Day:</Label>
            {renderPayrollDay()}
          </div>
          <div className="mx-3 my-2 d-flex align-items-center gap-2">
            <Label className="bo-new-object-text-md bo-w-180">Number of Cycles:</Label>
            <p className="bo-new-object-text-md">{numberOfCycles}</p>
          </div>
          <div className="mx-3 my-2 d-flex align-items-center gap-2">
            <Label className="bo-new-object-text-md bo-w-180">Last 3 Cycles average:</Label>
            <p className="bo-new-object-text-md">{last3CyclesAvarage}</p>
          </div>
        </div>
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectGeneralInfoSubTab);
