import { Row } from 'reactstrap';
import AggregatorsActions from './AggregatorsActions';
import AggregatorsStatusFilterForm from './forms/AggregatorsStatusFilterForm';
import AggregatorsSearchForm from './forms/AggregatorsSearchForm';
import AggregatorsQuickAccessForm from './forms/AggregatorsQuickAccessForm';

function AggregatorsFormController({
  spinner,
  statusFilter,
  changeStatusFilter,
  search,
  searchKey,
}) {
  return (
    <Row className="mb-2 align-items-center">
      <div className="d-xl-flex align-items-center">
        <div className="me-2 mb-2">
          <AggregatorsActions />
        </div>
        <div className="mb-2">
          <AggregatorsStatusFilterForm
            spinner={spinner}
            statusFilter={statusFilter}
            changeStatusFilter={changeStatusFilter}
          />
        </div>
        <div className="ms-auto d-flex gap-2 mb-2">
          <AggregatorsQuickAccessForm />
          <AggregatorsSearchForm search={search} searchKey={searchKey} spinner={spinner} />
        </div>
      </div>
    </Row>
  );
}

export default AggregatorsFormController;
