import { memo, useEffect, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

function TableMultiLineText({ value, uniqueId, lineClamp = 3 }) {
  const ref = useRef(null);
  const [textTruncated, setTextTruncated] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(m =>
        setTextTruncated(m[0].target.scrollHeight > m[0].target.clientHeight),
      );

      resizeObserver.observe(ref.current, { attributes: true });
    }
  }, []);

  return (
    <div
      ref={ref}
      style={{
        overflow: 'hidden',
        display: '-webkit-box',
        verticalAlign: 'middle',
        WebkitLineClamp: lineClamp,
        lineClamp,
        WebkitBoxOrient: 'vertical',
      }}
      id={uniqueId}
    >
      {value}
      {textTruncated && (
        <UncontrolledTooltip
          placement="top"
          target={uniqueId}
          style={{ maxWidth: 400 }}
          delay={{ show: 200 }}
        >
          {value}
        </UncontrolledTooltip>
      )}
    </div>
  );
}

export default memo(TableMultiLineText);
