import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  activityTable: {
    marginTop: 12,
    width: '100%',
    borderRadius: 14,
    backgroundColor: '#ffffff',
  },
  activityTableRow: {
    flexDirection: 'row',
    width: '100%',
  },
  activityTableTopRow: {
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    backgroundColor: '#F8F7FA',
  },
  activityTableRowBorder: {
    borderBottomColor: '#F0EDF3',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  activityTableRowTitleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '40%',
  },
  activityTableRowTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  activityTableValueContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '20%',
  },
  activityTableRowValue: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
});
