import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { string_to_slug } from '../../../utils/slug';
import { useEnv } from '../../../context/env-context';
import { makeRequest } from '../../../utils/makeRequest';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function ProspectsActions({ aggregator, openUploadProspectsModal, setSpinner }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [actionsOpen, setActionsOpen] = useState(false);

  const actionsToggle = () => setActionsOpen(!actionsOpen);

  const getMailchimpFile = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/prospects/mailchimp`,
      method: 'GET',
      params: { aggregatorId: aggregator.id },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    } finally {
      setSpinner(false);
    }
  };

  const downloadMailchimpFile = async () => {
    const binaryData = [];
    const data = await getMailchimpFile();

    if (data) {
      binaryData.push(data);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `mailchimp_contacts_${string_to_slug(aggregator.name)}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const getProspectsFile = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/prospects/tokens`,
      method: 'GET',
      params: { aggregatorId: aggregator.id },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    } finally {
      setSpinner(false);
    }
  };

  const downloadProspectsFile = async () => {
    const binaryData = [];
    const data = await getProspectsFile();

    if (data) {
      binaryData.push(data);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `prospects_signup_urls_${string_to_slug(aggregator.name)}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  return (
    <Dropdown isOpen={actionsOpen} toggle={actionsToggle}>
      <DropdownToggle className="bo-dropdown" caret>
        Actions&nbsp;&nbsp;&nbsp;
      </DropdownToggle>
      <DropdownMenu className="bo-dropdown-menu">
        <DropdownItem className="bo-dropdown-menu-item">
          <Link className="bo-button-link" to={`/prospect/n${aggregator.id}`}>
            New prospect
          </Link>
        </DropdownItem>
        <DropdownItem className="bo-dropdown-menu-item" onClick={openUploadProspectsModal}>
          Upload prospects
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem className="bo-dropdown-menu-item" onClick={downloadMailchimpFile}>
          Export prospects to Mailchimp
        </DropdownItem>
        <DropdownItem className="bo-dropdown-menu-item" onClick={downloadProspectsFile}>
          Export prospects with URLs
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProspectsActions;
