import ReactModal from 'react-modal';
import { Row, Col, Input, Button } from 'reactstrap';
import { modalStyle } from '../../../../utils/modal';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { string_to_slug } from '../../../../utils/slug';
import { toast } from 'react-toastify';

function AggregatorUniqueUrlsModal({
  showModal,
  closeModal,
  aggregator,
  urlsCount,
  handleCountChange,
  setSpinner,
}) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const getUrls = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/token/unique`,
      method: 'GET',
      params: { aggregatorId: aggregator.id, count: urlsCount },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      toast.error(error.message);

      return null;
    } finally {
      setSpinner(false);
    }
  };

  const downloadFile = async () => {
    closeModal();

    const binaryData = [];
    const data = await getUrls();

    if (data) {
      binaryData.push(data);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `signup_urls_${string_to_slug(aggregator.name)}.txt`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={modalStyle()}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={closeModal}
      >
        <h5>Unique URLs creator</h5>
        <Input
          className="mt-3 bo-input"
          type="text"
          name="urlsCount"
          id="urlsCount"
          placeholder="Number of URLs"
          value={urlsCount}
          onChange={handleCountChange}
        />
        <Row>
          <Col className="text-center">
            <Button className="mt-5 bo-button" onClick={downloadFile}>
              Create
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={closeModal}>
              Close
            </Button>
          </Col>
        </Row>
      </ReactModal>
    </div>
  );
}

export default AggregatorUniqueUrlsModal;
