import { useState } from 'react';

export const useTable = ({ initSortBy, initSortOrder, initRowsPerPage = 25 }) => {
  const [sortBy, setSortBy] = useState(initSortBy);
  const [sortOrder, setSortOrder] = useState(initSortOrder);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortHandler = field => {
    if (sortBy === field) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  return {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    setTotalRows,
    rows,
    setRows,
    handleChangePage,
    handleChangeRowsPerPage,
    sortHandler,
  };
};
