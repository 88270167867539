import { useEffect, useRef, useState } from 'react';

function IframeFullHeight({ title, width, htmlBody }) {
  const ref = useRef();
  const [height, setHeight] = useState('0px');

  const onLoad = () => {
    setHeight(`${ref.current.contentWindow.document.body.scrollHeight + 20}px`);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <iframe
      ref={ref}
      onLoad={onLoad}
      title={title}
      srcDoc={htmlBody}
      width="100%"
      height={height}
      scrolling="no"
      frameBorder="0"
      style={{
        maxWidth: width,
        width: '100%',
        overflow: 'hidden',
      }}
    />
  );
}

export default IframeFullHeight;
