import moment from 'moment';
import StyledTableCell from '../../../../components/StyledTableCell';
import { EVENT_LOG_TYPE_COLOR } from '../../../../constants/fundings';
import { TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { memo } from 'react';

function FundingEventsLog({ fundingLog }) {
  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle" aria-label="enhanced table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Timestamp</StyledTableCell>
            <StyledTableCell align="left">Type</StyledTableCell>
            <StyledTableCell align="left">Description</StyledTableCell>
            <StyledTableCell align="left">User</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fundingLog
            .sort((a, b) => b.id - a.id)
            .map(log => (
              <TableRow hover tabIndex={-1} key={log.id}>
                <StyledTableCell align="left">
                  {moment.utc(log.timestamp).local().format('MMM DD, YYYY H:mm:ss (UTCZ)')}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <p className={`${EVENT_LOG_TYPE_COLOR[log.type] || 'text-dark'}`}>{log.type}</p>
                </StyledTableCell>
                <StyledTableCell align="left">{log.description}</StyledTableCell>
                <StyledTableCell align="left">{log.user}</StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(FundingEventsLog);
