import { Col, Label, Input } from 'reactstrap';
import moment from 'moment';
import { memo } from 'react';

function FundingNextRepaymentDate({ fundingRepaymentMode, fundingNextRepaymentDate }) {
  const disabled = fundingRepaymentMode === 'FORCE' || fundingRepaymentMode === 'CUSTOM';

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label className={disabled ? 'bo-text-light-gray' : ''}>Next Repayment Date:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className={`bo-input ${disabled ? 'bg-light' : ''}`}
          type="text"
          name="nextDate"
          id="nextDate"
          value={
            fundingNextRepaymentDate
              ? moment.utc(fundingNextRepaymentDate).format('MMM DD, YYYY')
              : ''
          }
          readOnly
          disabled={disabled}
        />
      </Col>
    </>
  );
}

export default memo(FundingNextRepaymentDate);
