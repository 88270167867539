import { memo, useState } from 'react';
import { Row, Col, Button, Label, Input } from 'reactstrap';
import { Spinner } from '../../../../components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

function ConsumerKyc({
  consumerId,
  consumerKycStatus,
  consumerAmlStatus,
  consumerFraudIndicator,
  setConsumerStatus,
  getConsumer,
}) {
  const [spinner, setSpinner] = useState(false);

  const refresh = async () => {
    setSpinner(true);
    await getConsumer(consumerId);
    setSpinner(false);
  };

  const changeValue = async e => {
    setSpinner(true);
    await setConsumerStatus(e.target.name, e.target.value);
    setSpinner(false);
  };

  return (
    <Spinner visible={spinner} text="">
      <Row className="pt-4 ms-1">
        <Col sm="12">
          <Button className="bo-button-inline bo-button-gray" onClick={refresh}>
            <FontAwesomeIcon icon={faArrowRotateRight} size="lg" color="white" />
          </Button>
        </Col>
      </Row>
      <Row className="ms-1 mt-3 mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>KYC Status:</Label>
        </Col>
        <Col md={3} className="bo-same-line">
          <Input
            className="bo-select"
            type="select"
            name="kycStatus"
            id="kycStatus"
            noValidate
            value={consumerKycStatus}
            onChange={changeValue}
          >
            <option value="NEW_CONSUMER">New Consumer</option>
            <option value="PASSED">Passed</option>
            <option value="PASSED_MANUAL_OVERRIDE">Passed - Manual Override</option>
            <option value="PASSED_ID_VERIFICATION">Passed - ID Verification</option>
            <option value="FAILED_AUTOMATIC_VERIFICATION">Failed - Automatic Verification</option>
            <option value="ID_VERIFICATION_SENT">ID Verification Sent</option>
            <option value="FAILED_MANUAL_REVIEW">Failed - Manual Review</option>
            <option value="REJECTED">Rejected</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-1 mb-2 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>AML Status:</Label>
        </Col>
        <Col md={3} className="bo-same-line">
          <Input
            className="bo-select"
            type="select"
            name="amlStatus"
            id="amlStatus"
            noValidate
            value={consumerAmlStatus}
            onChange={changeValue}
          >
            <option value="NEW_CONSUMER">New Consumer</option>
            <option value="CLEARED">Cleared</option>
            <option value="CLEARED_MANUAL_OVERRIDE">Cleared - Manual Override</option>
            <option value="PENDING_REVIEW">Pending Review</option>
            <option value="REJECTED">Rejected</option>
          </Input>
        </Col>
      </Row>
      <Row className="ms-1 mb-1 pb-1 align-items-center">
        <Col md={2} className="bo-same-line">
          <Label>Fraud Indicator:</Label>
        </Col>
        <Col md={3} className="bo-same-line">
          <Input
            className="bo-select"
            type="select"
            name="fraudIndicator"
            id="fraudIndicator"
            noValidate
            value={consumerFraudIndicator}
            onChange={changeValue}
          >
            <option value="CLEAR">Clear</option>
            <option value="CLEARED_MANUAL_OVERRIDE">Cleared - Manual override</option>
            <option value="FRAUD_SUSPECTED_WAITING_FOR_REVIEW">
              Fraud Suspected - Waiting for review
            </option>
            <option value="FRAUD_SUSPECTED_UNDER_REVIEW">Fraud Suspected - Under review</option>
            <option value="BLOCKED_FOR_FRAUD">Blocked for fraud</option>
          </Input>
        </Col>
      </Row>
    </Spinner>
  );
}

export default memo(ConsumerKyc);
