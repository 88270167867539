import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import { makeRequest } from '../../utils/makeRequest';
import ProspectSinupUrl from './components/ProspectSignupUrl';
import ProspectActionRow from './components/ProspectActionRow';
import ProspectFormController from './components/ProspectFormController';
import Loading from '../../components/Loading';

function Prospect() {
  const params = useParams();
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [prospect, setProspect] = useState(null);
  const [signupUrl, setSignupUrl] = useState(null);

  const prospectId = params.id.charAt(0) === 'n' ? 'new' : params.id;
  const aggregatorId = params.id.charAt(0) === 'n' ? params.id.slice(1) : null;

  const getAggregator = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginAggregator}/get/${id}`,
        method: 'GET',
      };

      try {
        const data = await makeRequest(config);

        return data;
      } catch (error) {
        toast.error(error.message);

        return null;
      }
    },
    [getAccessTokenSilently, apiOriginAggregator],
  );

  const getProspect = useCallback(
    async id => {
      if (id === 'new') {
        const aggregator = await getAggregator(aggregatorId);

        if (aggregator) {
          setProspect({
            id: 'new',
            aggregatorId,
            aggregatorName: aggregator.name,
            useEmployeeIdProspects: aggregator.useEmployeeIdProspects,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            employeeId: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            zip: null,
            dateOfBirth: null,
          });
        }

        return;
      }

      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config1 = {
        token,
        url: `${apiOriginAggregator}/prospect/get/${id}`,
        method: 'GET',
      };

      const config2 = {
        token,
        url: `${apiOriginAggregator}/prospect/token`,
        method: 'GET',
        params: { prospectId: id },
      };

      try {
        const data = await makeRequest(config1);

        setProspect(data);

        const url = await makeRequest(config2);

        setSignupUrl(url);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        toast.error(error.message);
      }
    },
    [getAccessTokenSilently, apiOriginAggregator, aggregatorId, getAggregator],
  );

  useEffect(() => {
    getProspect(prospectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectId]);

  const changeValue = useCallback(e => {
    setProspect(prevProspect => ({
      ...prevProspect,
      [e.target.name]: e.target.name === 'state' ? e.target.value.toUpperCase() : e.target.value,
    }));
    setSignupUrl(null);
  }, []);

  if (!prospect) {
    return <Spinner visible={spinner} text="Loading..." />;
  }

  return (
    <Spinner visible={spinner} text="">
      <Container className="mb-5 pb-1 ">
        <Row className="mb-3">
          <Link to={`/prospects/${prospect.aggregatorId}`}>&lt; Back to Prospects</Link>
        </Row>
        <Row className="mb-3">
          <h2>Prospect</h2>
        </Row>
        <ProspectFormController prospect={prospect} changeValue={changeValue} />
        <ProspectSinupUrl signupUrl={signupUrl} />
        <ProspectActionRow prospect={prospect} getProspect={getProspect} setSpinner={setSpinner} />
      </Container>
    </Spinner>
  );
}

export default withAuthenticationRequired(Prospect, { onRedirecting: () => <Loading /> });
