import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { PatternFormat } from 'react-number-format';

function LastFourSsnForm({ last4ssn, changeValue }) {
  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>Last 4 digits of SSN:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <PatternFormat
          name="last4ssn"
          id="last4ssn"
          format="####"
          mask="_"
          className="bo-input"
          allowEmptyFormatting
          customInput={Input}
          value={last4ssn || ''}
          onChange={changeValue}
        />
      </Col>
    </>
  );
}

export default memo(LastFourSsnForm);
