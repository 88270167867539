import { Input } from 'reactstrap';

function ConsumersStatusFilterForm({ statusFilter, changeStatusFilter, spinner }) {
  return (
    <Input
      className="bo-filter"
      type="select"
      name="statusFilter"
      id="statusFilter"
      placeholder="filter"
      noValidate
      disabled={spinner}
      value={statusFilter}
      onChange={e => changeStatusFilter('consumers', e.target.value)}
    >
      <option value="">All Statuses</option>
      <option value="PENDING">Pending</option>
      <option value="MONITOR_ONLY">Monitor Only</option>
      <option value="ACTIVE">Active</option>
      <option value="SUSPENDED">Suspended</option>
      <option value="TERMINATED">Terminated</option>
    </Input>
  );
}

export default ConsumersStatusFilterForm;
