import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  billingTable: {
    marginTop: 12,
    width: '100%',
    borderRadius: 14,
    backgroundColor: '#ffffff',
  },
  billingTableRow: {
    flexDirection: 'row',
    width: '100%',
  },
  billingTableRowBorder: {
    borderBottomColor: '#F0EDF3',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  billingTableRowTitleContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '50%',
  },
  billingTableRowTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  billingTableValueContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '50%',
    backgroundColor: '#F8F7FA',
  },
  billingTableValueTopRight: {
    borderTopRightRadius: 14,
  },
  billingTableValueBottomRight: {
    borderBottomRightRadius: 14,
  },
  billingTableRowValue: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  billingTableRowTotal: {
    fontWeight: 600,
  },
});
