import { memo } from 'react';
import { Col, Input, Label } from 'reactstrap';

function FundingBridgePointsSoFarForm({ fundingStatus, bridgePointsBalanceOpenFundingBalance }) {
  const getValue = () => {
    if (fundingStatus === 'CLOSED' || fundingStatus === 'CANCELLED') {
      return '';
    }

    return bridgePointsBalanceOpenFundingBalance || '';
  };

  return (
    <>
      <Col md={2} className="bo-same-line">
        <Label>BridgePoints™ So Far:</Label>
      </Col>
      <Col md={2} className="bo-same-line">
        <Input
          className="bo-input"
          type="text"
          name="openFundingBalance"
          id="openFundingBalance"
          value={getValue()}
          readOnly
        />
      </Col>
      <Col md={2} className="bo-same-line" />
    </>
  );
}

export default memo(FundingBridgePointsSoFarForm);
