import moment from 'moment';
import { saveAs } from 'file-saver';

const RISK_INDICATORS_ORDER = [
  'EL_C',
  'EL_S',
  'EL_P',
  'PD_EOD',
  'PD_PLUS1_EOD',
  'PD_DEBIT',
  'PD_PLUS1_DEBIT',
  'OD_NSF',
  'NBD',
  'NBD_C',
];

const exportRiskIndicatorsFile = async riskIndicators => {
  const blob = new Blob([JSON.stringify(riskIndicators)], { type: 'text/plain;charset=utf-8' });

  saveAs(blob, 'risk_indicators.json');
};

const showIndicatorValueUnit = (key, value) => {
  if (key === 'EL_S') return `$${value}`;

  if (
    key === 'PD_EOD' ||
    key === 'PD_PLUS1_EOD' ||
    key === 'PD_DEBIT' ||
    key === 'PD_PLUS1_DEBIT' ||
    key === 'NBD'
  )
    return `${Number(value * 100).toLocaleString('en-US', {
      maximumFractionDigits: 1,
    })}%`;

  return value;
};

const getWeeksArray = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const weeksArray = [];

  while (start < end) {
    weeksArray.push(end.clone().format('YYYY-MM-DD'));
    end.subtract(1, 'week');
  }

  weeksArray.push(start.format('YYYY-MM-DD'));

  return weeksArray;
};

const getAggregatedData = (key, weeksArray, data, dataAggregationKey) => {
  if (!data || weeksArray.length === 0) {
    return [];
  }

  const aggregatedData = weeksArray.map((week, i) => {
    if (i === weeksArray.length - 1) return null;

    const from = weeksArray[i + 2]
      ? moment(weeksArray[i + 1]).add(1, 'day')
      : moment(weeksArray[i + 1]);
    const to = moment(week);

    const periodObj = {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      period: `${from.format('YYYY-MM-DD')} to ${to.format('YYYY-MM-DD')}`,
    };

    const dataInTimePeriod = data.filter(d =>
      moment(d[dataAggregationKey]).isBetween(from, to, null, '[]'),
    );

    let aggregation;

    let metadata;

    if (key === 'EL_C' || key === 'OD_NSF' || key === 'NBD_C') {
      aggregation = dataInTimePeriod.length;
    }

    if (key === 'EL_S') {
      aggregation = dataInTimePeriod.reduce((acc, cur) => acc + cur.amount, 0);
    }

    if (key === 'EL_P') {
      aggregation = [...new Set(dataInTimePeriod.map(x => x.provider))].length;
      metadata = [...new Set(dataInTimePeriod.map(x => x.provider))];
    }

    if (key === 'PD_EOD' || key === 'PD_PLUS1_EOD') {
      aggregation = dataInTimePeriod.filter(x => x.ratio < 0.3).length;
    }

    if (key === 'PD_DEBIT' || key === 'PD_PLUS1_DEBIT') {
      aggregation = dataInTimePeriod.filter(x => x.ratio > 0.7).length;
    }

    if (key === 'NBD') {
      const diff = moment(to).diff(from, 'day') + 1;

      aggregation = (dataInTimePeriod.length / diff) * 100;
    }

    return { ...periodObj, aggregation, metadata };
  });

  aggregatedData.pop();

  return aggregatedData.sort((a, b) => new Date(a.from) - new Date(b.from));
};

const getRiskIndicatorChart = (key, riskIndicator) => {
  if (!riskIndicator) {
    return {
      xAxisData: [],
      data: [],
      metadata: [],
    };
  }

  const weeksArray = getWeeksArray(riskIndicator.from, riskIndicator.to);

  let dataAggregationKey = '';

  if (key === 'EL_C' || key === 'EL_S' || key === 'EL_P' || key === 'OD_NSF') {
    dataAggregationKey = 'transactionDate';
  }

  if (
    key === 'PD_EOD' ||
    key === 'PD_PLUS1_EOD' ||
    key === 'PD_DEBIT' ||
    key === 'PD_PLUS1_DEBIT' ||
    key === 'NBD' ||
    key === 'NBD_C'
  ) {
    dataAggregationKey = 'date';
  }

  const aggregatedData = getAggregatedData(key, weeksArray, riskIndicator.data, dataAggregationKey);

  return {
    xAxisData: aggregatedData.map(x => x.period),
    data: aggregatedData.map(x => x.aggregation),
    metadata: aggregatedData.map(x => x.metadata).filter(x => !!x),
  };
};

const getRiskIndicatorTooltipLabel = (key, dataIndex, metadata) => {
  if (key === 'EL_C' || key === 'OD_NSF' || key === 'NBD_C') return 'Count';

  if (key === 'EL_S') return 'Sum';

  if (key === 'EL_P') return `${metadata[dataIndex].join(', ')}`;

  if (key === 'PD_EOD' || key === 'PD_PLUS1_EOD') return 'Count (ratio < 0.3)';

  if (key === 'PD_DEBIT' || key === 'PD_PLUS1_DEBIT') return 'Count (ratio > 0.7)';

  return undefined;
};

const getRiskIndicatorTooltipValue = (key, value) => {
  if (
    key === 'EL_C' ||
    key === 'OD_NSF' ||
    key === 'NBD_C' ||
    key === 'EL_P' ||
    key === 'PD_EOD' ||
    key === 'PD_PLUS1_EOD' ||
    key === 'PD_DEBIT' ||
    key === 'PD_PLUS1_DEBIT'
  )
    return `${value}`;

  if (key === 'EL_S') return `$${value}`;

  if (key === 'NBD')
    return `${value.toLocaleString('en-US', {
      maximumFractionDigits: 1,
    })}%`;

  return undefined;
};

export {
  RISK_INDICATORS_ORDER,
  exportRiskIndicatorsFile,
  showIndicatorValueUnit,
  getRiskIndicatorChart,
  getRiskIndicatorTooltipLabel,
  getRiskIndicatorTooltipValue,
};
